import React, {useState} from "react";
import {
  SendVerifyEmailCommand,
  useVerificationVerifyCodeMutation,
  useVerificationVerifyEmailMutation,
  VerificationVerifyCodeApiArg,
  VerificationVerifyEmailApiArg,
  VerifyPhoneCodeCommand,
} from "../../features/verification/verification-api";
import {Field, Form, Formik} from "formik";
import {
  Box,
  Button,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {Trans, useTranslation} from "react-i18next";
import {toast} from "react-hot-toast";
import {useLazyUserGetInfoQuery} from "../../features/user/user-api";
import * as yup from "yup";

interface CodeVerificationFormValues {
  verificationCode: string;
}

const codeVerificationFormInitialValues: CodeVerificationFormValues = {
  verificationCode: "",
};

const getVerificationCodeSchema = (t: (text: string) => string) =>
  yup.object().shape({
    verificationCode: yup
      .string()
      .required(t("validation.code.required"))
      .length(6, t("validation.code.length"))
      .matches(/^\d+$/, t("validation.code.digits")),
  });

interface CodeVerificationFragmentProps {
  verificationMethod: "email" | "phone";
  onResendCode?: () => void;
  containerSx?: SxProps<Theme>;
}

export default function CodeVerificationFragment({
  verificationMethod,
  onResendCode,
  containerSx = {},
}: CodeVerificationFragmentProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();

  const [isCheckingCode, setIsCheckingCode] = useState<boolean>(false);

  const [verifyEmailCode, {isLoading: isLoadingEmailCodeVerify}] =
    useVerificationVerifyEmailMutation();
  const [verifyPhoneCode, {isLoading: isLoadingPhoneCodeVerify}] =
    useVerificationVerifyCodeMutation();
  const [getUserInfo] = useLazyUserGetInfoQuery();

  const handleSubmitError = (error: any) => {
    console.error("[onSubmitVerificationCode]", error);
  };

  const onSubmitVerificationCode = async (
    values: CodeVerificationFormValues,
  ) => {
    if (isCheckingCode) return;

    setIsCheckingCode(true);
    try {
      const checkCode =
        verificationMethod === "email"
          ? (values: SendVerifyEmailCommand) => {
              const commandArgs: VerificationVerifyEmailApiArg = {
                organizationId: process.env
                  .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
                verifyEmailCommand: values,
              };
              return verifyEmailCode(commandArgs);
            }
          : (values: VerifyPhoneCodeCommand) => {
              const commandArgs: VerificationVerifyCodeApiArg = {
                organizationId: process.env
                  .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
                verifyPhoneCodeCommand: values,
              };
              return verifyPhoneCode(commandArgs);
            };

      const response = await checkCode(values);
      if ((response as any)?.error) {
        return handleSubmitError(
          new Error(
            `Received error ${
              (response as any).error?.status || "with unknown status"
            }`,
          ),
        );
      }

      await getUserInfo();
      toast.success(
        verificationMethod === "email"
          ? t("toasts.emailWasVerified")
          : t("toasts.phoneNumberWasVerified"),
      );
    } catch (error) {
      handleSubmitError(error);
    } finally {
      setIsCheckingCode(false);
    }
  };

  return (
    <Grid sx={containerSx}>
      <Formik
        onSubmit={onSubmitVerificationCode}
        initialValues={codeVerificationFormInitialValues}
        validationSchema={getVerificationCodeSchema(t)}
      >
        <Form id="verificationCodeForm">
          <Box>
            <Trans i18nKey="profile.codeVerification.codeWasSent">
              Verification code was sent to your
            </Trans>{" "}
            {verificationMethod === "email" ? (
              <Trans i18nKey="profile.codeVerification.email">Email</Trans>
            ) : (
              <Trans i18nKey="profile.codeVerification.number">number</Trans>
            )}
            .
          </Box>
          <Box component="span">
            <Typography component="span" variant="body1">
              <Trans i18nKey="isPinCodeWasSend">Did not receive a code?</Trans>
            </Typography>
            {!isDesktop ? <br /> : <>&nbsp;</>}
            <Button
              variant="link"
              disabled={isLoadingEmailCodeVerify || isLoadingPhoneCodeVerify}
              data-testid={`resend-email-code-btn`}
              onClick={onResendCode}
            >
              <Trans i18nKey="resendPinCodeToEmailButton">Click here</Trans>
            </Button>
            &nbsp;
            <Typography component="span" variant="body1">
              <Trans i18nKey="resendPinCodeLabel">to resend it</Trans>
            </Typography>
          </Box>
          <Grid
            xs
            md={1}
            sx={{
              minWidth: {
                xs: "0",
                md: "185px",
              },
              marginTop: "16px",
            }}
          >
            <Field
              fullWidth
              component={TextField}
              size={isDesktop ? "normal" : "small"}
              id="verificationCode"
              name="verificationCode"
              label={
                verificationMethod === "email"
                  ? t("profile.editEmail.verificationCode")
                  : t("profile.editPhoneNumber.verificationCode")
              }
              placeholder="000000"
              data-testid="input-account-verification-code"
              disabled={false}
              InputLabelProps={{shrink: true}}
            />
          </Grid>
          <Grid
            xs
            md={1}
            sx={{
              minWidth: {
                xs: 0,
                md: "185px",
              },
              marginTop: "32px",
            }}
          >
            <LoadingButton
              fullWidth
              type="submit"
              form="verificationCodeForm"
              variant="contained"
              color="secondary"
              data-testid="btn-verify-code"
              disabled={isLoadingEmailCodeVerify || isLoadingPhoneCodeVerify}
              loading={isLoadingEmailCodeVerify || isLoadingPhoneCodeVerify}
              loadingPosition="end"
              endIcon={<></>}
              sx={{
                textTransform: "none",
              }}
            >
              <Trans i18nKey={"verify"}>Verify</Trans>
            </LoadingButton>
          </Grid>
        </Form>
      </Formik>
    </Grid>
  );
}
