import React from "react";
import {MenuItemType, Module, ModuleConfig} from "../core/base.module";
import GettingStarted from "./pages";
import {ParcelGettingStarted} from "./pages/pacelGetStarted";
import HomeIcon from "@mui/icons-material/Home";

export interface GettingStartedConfiguration extends ModuleConfig {
  disable?: boolean;
  isParcel?: boolean;
  prohibitedItemsLink?: {
    en?: string;
    ru?: string;
    ua?: string;
  };
}

export class GettingStartedModule implements Module {
  static _instance: GettingStartedModule;
  config: GettingStartedConfiguration;

  private constructor(config?: GettingStartedConfiguration) {
    this.config = config || {};
  }

  static getInstance(
    config?: GettingStartedConfiguration,
  ): GettingStartedModule {
    if (!GettingStartedModule._instance) {
      GettingStartedModule._instance = new GettingStartedModule();
    } else if (config) {
      GettingStartedModule._instance.config = config;
    }
    return GettingStartedModule._instance;
  }

  getPages() {
    if (!this.config || this.config.disabled !== false) {
      return [];
    }
    if (this.config.isParcel) {
      return [
        {
          path: "getting-started",
          element: <ParcelGettingStarted config={this.config} />,
        },
      ];
    }
    return [
      {
        path: "getting-started",
        element: <GettingStarted config={this.config} />,
      },
    ];
  }

  getMenuItems(): MenuItemType[] {
    if (!this.config || this.config.disabled !== false) {
      return [];
    }
    return [
      {
        page: "getting-started",
        to: "../getting-started",
        i18nKey: "menuItemHome",
        isInternal: true,
        svgIcon: HomeIcon,
      },
    ];
  }

  getMenuActions(): MenuItemType[] {
    return [];
  }
}
