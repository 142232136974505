import * as yup from "yup";

export const getEditAccountEmailSchema = (
  t: (text: string) => string,
  initialEmail: string,
) =>
  yup.object().shape({
    email: yup
      .string()
      .required(t("validation.common.emailRequired"))
      .email(t("validation.common.emailFormat"))
      .max(256, t("validation.common.emailLengthMax"))
      .notOneOf([initialEmail], t("validation.common.emailNotInitial")),
  });
