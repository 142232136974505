import React from "react";
import {Button, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ContactPaymentMethodSoftDeleteApiArg,
  useContactPaymentMethodSoftDeleteMutation,
} from "../../../features/paymentMethod/paymentMethod-api";
import {LoadingButton} from "@mui/lab";
import {useGetPaymentMethods} from "../hooks/useGetPaymentMethods";
import {DeleteCardModalProps} from "../pages/interfaces";

export default function DeleteCardModal({
  setOpen,
  paymentId,
  setPaymentMethods,
}: DeleteCardModalProps) {
  const handleClose = () => setOpen(false);

  const [deleteContactPaymentMethod, {isLoading}] =
    useContactPaymentMethodSoftDeleteMutation();

  const softDelete = async (contactPaymentMethodId: string) => {
    const commandArgs: ContactPaymentMethodSoftDeleteApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      softDeleteCommandValues: {contactPaymentMethodId: contactPaymentMethodId},
    };
    return deleteContactPaymentMethod(commandArgs);
  };

  const {fetchPaymentMethods} = useGetPaymentMethods();

  const handleDelete = () => {
    softDelete(paymentId).then((response) => {
      if (response) {
        fetchPaymentMethods()
          .then((result: any) => {
            setPaymentMethods(result?.data?.items);
          })
          .finally(() => handleClose());
      }
    });
  };

  return (
    <Grid px={{xs: 0, md: 2}}>
      <Typography
        data-testid="delete-card-modal-description"
        variant="body1"
        sx={{fontSize: "24px"}}
      >
        <Trans i18nKey="profile.paymentMethods.deleteModalText">
          Delete bank card?
        </Trans>
      </Typography>
      <Button
        type="button"
        variant="outlined"
        color="primary"
        sx={{mt: 2, mr: {xs: 1, md: 2}, width: "150px"}}
        data-testid="no-delete-card-button"
        onClick={handleClose}
      >
        <Trans i18nKey="no">No</Trans>
      </Button>
      <LoadingButton
        sx={{mt: 2, width: "150px"}}
        type="button"
        variant="contained"
        color="secondary"
        data-testid="yes-delete-card-button"
        onClick={handleDelete}
        loading={isLoading}
        loadingPosition="end"
        endIcon={<></>}
      >
        <Trans i18nKey="yes">Yes</Trans>
      </LoadingButton>
    </Grid>
  );
}
