import React from "react";
import {Module, ModuleConfig} from "../core/base.module";
import AllPurchases, {
  createConsolidatedLink,
  createPurchaseLink,
} from "./AllPurchases/pages";
import EditPurchases from "./EditPurchases/pages";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import AddBoxIcon from "@mui/icons-material/AddBox";

export interface PurchasesConfiguration extends ModuleConfig {
  disabled?: boolean;
}

export class PurchasesModule implements Module {
  static _instance: PurchasesModule;
  config: PurchasesConfiguration;

  private constructor(config: PurchasesConfiguration) {
    this.config = config;
  }

  static getInstance(config?: PurchasesConfiguration) {
    if (!PurchasesModule._instance) {
      PurchasesModule._instance = new PurchasesModule(config || {});
    } else if (config) {
      PurchasesModule._instance.config = config;
    }
    return PurchasesModule._instance;
  }

  getPages() {
    if (!this.config || this.config?.disabled !== false) {
      return [];
    }
    return [
      {
        path: "purchases",
        element: <AllPurchases />,
      },
      {
        path: "purchases/:purchaseId",
        element: <EditPurchases />,
      },
    ];
  }

  getMenuItems() {
    if (!this.config || this.config?.disabled !== false) {
      return [];
    }
    return [
      {
        page: "purchases",
        to: "../purchases",
        i18nKey: "menuItemPurchases",
        isInternal: true,
        svgIcon: ShoppingBagIcon,
      },
    ];
  }

  getMenuActions() {
    if (!this.config || this.config?.disabled !== false) {
      return [];
    }
    return [
      {
        page: "purchases-createConsolidated",
        to: createConsolidatedLink,
        i18nKey: "consolidatedShipment.createConsolidatedShipment",
        isInternal: true,
        svgIcon: AddBoxIcon,
      },
      {
        page: "purchases-addNew",
        to: createPurchaseLink,
        i18nKey: "addNewPurchase",
        isInternal: true,
        svgIcon: AddBoxIcon,
      },
    ];
  }
}
