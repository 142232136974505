import {useCallback} from "react";

export type RequiredField = {
  fieldName: string;
  isRequired: boolean;
};

export const useFormRequiredFields = (
  requiredFields?: RequiredField[] | null,
) => {
  const isFieldRequired = useCallback(
    (fieldName: string) => {
      if (!requiredFields) return false;

      return requiredFields.some(
        (field: any) =>
          field.fieldName == fieldName && field.isRequired === true,
      );
    },
    [requiredFields],
  );

  const renderRequiredMark = useCallback(
    (fieldName: string) => {
      if (isFieldRequired(fieldName)) {
        return " *";
      }
      return "";
    },
    [isFieldRequired],
  );

  return {
    isFieldRequired,
    renderRequiredMark,
  };
};

export const getValidationSchemaRequiredFields = (validationSchema: any) => {
  if (validationSchema?.fields) {
    return Object.entries(validationSchema.fields)
      .filter((field) => !!(field && field.length == 2 && field[0] && field[1]))
      .map((field: any) => {
        return {
          fieldName: field[0],
          isRequired: field[1]?.spec?.presence == "required",
        };
      });
  }

  return null;
};
