import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ContactAddressDto} from "../contact/contact-api";
import {UserDto} from "./user-api";

export interface UserState extends UserDto {
  billingAddress: ContactAddressDto | null;
}

const initialState: UserState = {
  userId: null,
  contactId: null,
  accountNumber: null,
  email: null,
  firstName: null,
  lastName: null,
  userName: null,
  emailConfirmed: null,
  paymentMethodConfirmed: null,
  phoneNumberConfirmed: null,
  contactAddressConfirmed: null,
  links: null,
  billingAddress: null,
  acknowledgementConfirmed: null,
};

export const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    logout: () => {
      return initialState;
    },
    setStorageUserInfo: (state: UserDto, action: PayloadAction<UserDto>) => {
      state.accountNumber = action.payload.accountNumber;
      state.email = action.payload.email;
      state.phoneNumber = action.payload.phoneNumber;
      state.contactId = action.payload.contactId;
      state.emailConfirmed = action.payload.emailConfirmed;
      state.paymentMethodConfirmed = action.payload.paymentMethodConfirmed;
      state.phoneNumberConfirmed = action.payload.phoneNumberConfirmed;
      state.contactAddressConfirmed = action.payload.contactAddressConfirmed;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.acknowledgementConfirmed = action.payload.acknowledgementConfirmed;
    },
    setUserEmailConfirmed: (state) => {
      state.emailConfirmed = true;
    },
    setUserPhoneConfirmed: (state) => {
      state.phoneNumberConfirmed = true;
    },
    setUserAddressConfirmed: (state) => {
      state.contactAddressConfirmed = true;
    },
    setUserPaymentConfirmed: (state) => {
      state.paymentMethodConfirmed = true;
    },
    setNameSurname: (
      state: UserDto,
      action: PayloadAction<{
        firstName?: string | null;
        lastName?: string | null;
      }>,
    ) => {
      const {firstName, lastName} = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
    },
    setBillingAddress: (
      state: UserState,
      action: PayloadAction<ContactAddressDto | null>,
    ) => {
      state.billingAddress = action.payload;
    },
    setUserPhoneNumber: (
      state: UserState,
      action: PayloadAction<string | undefined>,
    ) => {
      state.phoneNumber = action.payload;
    },
    getUserState: (state) => {
      return state;
    },
    setAcknowledgementConfirmed: (state) => {
      state.acknowledgementConfirmed = true;
    },
  },
});

export default userSlice.reducer;

export const {
  logout,
  setStorageUserInfo,
  getUserState,
  setUserEmailConfirmed,
  setUserPhoneConfirmed,
  setUserAddressConfirmed,
  setUserPaymentConfirmed,
  setNameSurname,
  setBillingAddress,
  setAcknowledgementConfirmed,
  setUserPhoneNumber,
} = userSlice.actions;
