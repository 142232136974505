import React, {useState} from "react";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Radio,
  Typography,
} from "@mui/material";
import {CreditCard} from "@mui/icons-material";
import {Trans} from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import {cardIconsLogos} from "../../../common/components/cardIcons";
import ModalForm from "../../../common/Modal/components/modalForm";
import DeleteCardModal from "./deleteCardModal";
import SuccessModal from "../../../common/components/successModal";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import {PaymentMethodDetailsProps} from "../pages/interfaces";

const cardIsActive = {
  border: 1,
  backgroundColor: "#2F81EF",
  borderColor: "#D8E6FB",
  borderRadius: {xs: "8px", md: "16px"},
  boxShadow: {xs: "0px 0px 8px rgba(15, 74, 132, 0.1)", md: "none"},
  "& .activeDefault": {
    color: "#FFFFFF",
  },
  "& .cardElementText": {
    fontWeight: 600,
  },
  "& .MuiButtonBase-root.MuiRadio-root.Mui-checked": {
    color: "#FFFFFF",
  },
};

export default function PaymentMethodDetails({
  defaultLoading,
  cardDataValues,
  setCardData,
  setAddingNewCard,
  cardType,
  cardNumber,
  selectedValue,
  handleChange,
  setPaymentMethods,
}: PaymentMethodDetailsProps) {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleDeleteOpen = () => setDeleteOpen(true);

  const handleClick = (value: any) => {
    handleChange(value, handleOpen);
  };

  const handleVerifyCard = () => {
    setCardData(cardDataValues);
    setAddingNewCard(true);
  };

  const cardSX = {
    border: 1,
    borderColor: "#D8E6FB",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
    borderRadius: {xs: "8px", md: "16px"},
    "&:hover": {
      md: {
        backgroundColor: cardDataValues.isVerified ? "#D8E6FB" : "#FFF2F2",
        borderColor: "transparent",
        "& .cardElement": {
          color: "#205A9C",
        },
        "& .cardElementText": {
          fontWeight: 600,
        },
      },
    },
  };
  return (
    <Card
      sx={
        selectedValue === cardDataValues.contactPaymentMethodId
          ? cardIsActive
          : cardSX
      }
    >
      <Card
        sx={{
          borderRadius: {xs: "8px", md: "16px"},
          borderBottom: "1px solid #D8E6FB",
          boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
        }}
      >
        <Box sx={{display: "flex", flexDirection: "column", p: 1}}>
          <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <img
              className="card-icon-img"
              src={cardIconsLogos[cardType.toLowerCase()]}
              alt={`${cardType}`}
              width={"25%"}
              data-testid="card-type-icon"
            />
            <IconButton
              onClick={handleDeleteOpen}
              data-testid="delete-card-icon-button"
            >
              <DeleteIcon sx={{fontSize: "20px", color: "primary.dark"}} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <CreditCard sx={{color: "text.primary", m: 1}} />
            <Typography
              variant="body3"
              data-testid="card-number-text"
              sx={{
                color: "primary.dark",
              }}
            >
              ****&nbsp;****
            </Typography>
            <Typography
              variant="body3"
              sx={{color: "text.primary"}}
              fontSize={{xs: "0.8rem", md: "1rem"}}
              data-testid="card-number-last-digits"
            >
              &nbsp;{cardNumber}
            </Typography>
          </Box>
          <ModalForm open={deleteOpen} setOpen={setDeleteOpen}>
            <DeleteCardModal
              setOpen={setDeleteOpen}
              paymentId={cardDataValues.contactPaymentMethodId}
              setPaymentMethods={setPaymentMethods}
            />
          </ModalForm>
        </Box>
      </Card>
      {cardDataValues.isVerified ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: defaultLoading ? "default" : "pointer",
            pt: defaultLoading ? 0.5 : 0,
          }}
          onClick={() =>
            defaultLoading
              ? undefined
              : handleClick(cardDataValues.contactPaymentMethodId)
          }
        >
          {defaultLoading ? (
            <CircularProgress size={20} sx={{ml: 2, mr: 1}} />
          ) : (
            <Radio
              checked={selectedValue === cardDataValues.contactPaymentMethodId}
              value={cardDataValues.contactPaymentMethodId}
              name="radio-buttons"
              inputProps={{"aria-label": "A"}}
              className="cardElementText cardElement"
              data-testid="card-radio-button"
            />
          )}

          <Typography
            variant="caption1"
            py={1}
            className="cardElementText activeDefault cardElement"
            data-testid="card-make-main-button"
          >
            {selectedValue === cardDataValues.contactPaymentMethodId ? (
              <Trans i18nKey="profile.paymentMethods.mainBankCard">
                Main bank card
              </Trans>
            ) : (
              <Trans i18nKey="profile.paymentMethods.makeMain">
                Make it the main
              </Trans>
            )}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: defaultLoading ? "default" : "pointer",
            pt: 0.5,
          }}
          onClick={defaultLoading ? () => undefined : handleVerifyCard}
        >
          <Typography
            variant="caption1"
            py={1}
            className="cardElementText"
            color="error"
            data-testid="card-verify-button"
          >
            <Trans i18nKey="profile.paymentMethods.verifyCard">
              Verify card
            </Trans>
          </Typography>
          <PrivacyTipIcon
            sx={{fontSize: "20px", ml: 1}}
            color="error"
            data-testid="card-verify-button"
          />
        </Box>
      )}

      <ModalForm open={open} setOpen={setOpen}>
        <SuccessModal
          setOpen={setOpen}
          content={
            <Trans i18nKey="profile.paymentMethods.bankCardSuccess">
              Bank card successfully made main!
            </Trans>
          }
        />
      </ModalForm>
    </Card>
  );
}
