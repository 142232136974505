import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import PurchaseList from "./purchaseList";
import Sort from "../../../common/components/sort";
import PurchasesFilter from "./purchasesFilter";
import PurchasesSearch from "./purchasesSearch";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import {Trans} from "react-i18next";
import MobileListAddButton from "../../../common/components/mobileListAddButton";
import {useDispatch} from "react-redux";

type PurchasesListFormProps = {
  createConsolidatedLink: string;
  createPurchaseLink: string;
};

export default function PurchasesListForm({
  createConsolidatedLink,
  createPurchaseLink,
}: PurchasesListFormProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  return (
    <Grid>
      <Grid
        display={"flex"}
        flexDirection={{xs: "column", md: "row"}}
        flexWrap={"wrap"}
        alignItems={{xs: "start", md: "center"}}
        justifyContent={"space-between"}
        columnGap={"44px"}
        rowGap={"24px"}
        margin={{xs: "24px 0 0 0", md: 0}}
      >
        <Grid
          display={"flex"}
          flexDirection={"row"}
          justifyContent={{xs: "space-between", md: "start"}}
          width={{xs: "100%"}}
          alignItems={"center"}
          rowGap={{xs: "12px", md: 0}}
          columnGap={{xs: "24px", md: "16px"}}
        >
          {isDesktop ? (
            <PurchasesFilter />
          ) : (
            <Typography variant={"h3"}>
              <Trans
                i18nKey={
                  isDesktop ? "titleYourPurchases" : "titleYourPurchasesXs"
                }
              >
                Your Purchases
              </Trans>
            </Typography>
          )}
          <Sort
            sortParam={"created"}
            containerSx={{
              height: "fit-content",
              margin: 0,
            }}
            buttonSx={{
              boxShadow: "none !important",
              height: "32px!important",
              whiteSpace: "nowrap",
            }}
            defaultSortEarliestValue={true}
          />
        </Grid>
        <PurchasesSearch />
        {!isDesktop && (
          <Grid width={1}>
            <MobileListAddButton
              link={createConsolidatedLink}
              i18nKey={"consolidatedShipment.createConsolidatedShipment"}
            />

            <MobileListAddButton
              link={createPurchaseLink}
              i18nKey={"addNewPurchase"}
            />
          </Grid>
        )}
      </Grid>
      <Grid sx={{marginTop: "8px"}}>
        <PurchaseList />
      </Grid>
    </Grid>
  );
}
