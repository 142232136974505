import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {useChangeLanguageParam} from "../hooks/useChangeLanguageParam";
import {Typography} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const languages = [{value: "EN"}, {value: "RU"}, {value: "UA"}];

export default function LanguageSelect({
  languageColor = "primary.white",
}: {
  languageColor?: string;
}) {
  const {i18n} = useTranslation();
  const location = useLocation();
  const {changeLanguageParam, language} = useChangeLanguageParam();

  return (
    <Box width={"max-content"}>
      <Select
        sx={{
          width: "100%",
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {border: 0},
          paddingLeft: 0,
        }}
        IconComponent={(props) => (
          <KeyboardArrowDownIcon
            {...props}
            style={{
              fill:
                languageColor === "primary.white" ? "#ffffff" : languageColor,
            }}
          />
        )}
        defaultValue={language.toUpperCase() || "EN"}
        displayEmpty
        onChange={async (ev) => {
          const code = ev.target.value.toLowerCase();
          await i18n.changeLanguage(code);
          changeLanguageParam(location.pathname, code);
        }}
        renderValue={(value) => {
          return (
            <Box sx={{display: "flex", gap: 1}}>
              <Typography color={languageColor}>{value}</Typography>
            </Box>
          );
        }}
      >
        {languages.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{display: "flex", justifyContent: "center"}}
          >
            <Typography color="primary.dark">{option.value}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
