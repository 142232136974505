import {
  AccountingTransactionsGetAccountingTransactionApiArg,
  DocumentTemplateType,
  useAccountingTransactionsGetAccountingTransactionGqlMutation,
} from "../../../features/accountingTransaction/accountingTransaction-api";
import {
  AccountingTransactionPaymentsGetAccountingTransactionPaymentsApiArg,
  GetAccountingTransactionPaymentsQueryValues,
  useAccountingTransactionPaymentsGetAccountingTransactionPaymentsMutation,
} from "../../../features/accountingTransactionPayment/accountingTransactionPayment-api";

export const useGetInvoice = () => {
  const [getAccountingTransactionPayments] =
    useAccountingTransactionPaymentsGetAccountingTransactionPaymentsMutation();
  const [getAccountingTransactionGql] =
    useAccountingTransactionsGetAccountingTransactionGqlMutation();

  const getInvoiceGql = async (accountingTransactionId: number) => {
    const commandArgs: AccountingTransactionsGetAccountingTransactionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      accountingTransactionId: accountingTransactionId,
    };
    return getAccountingTransactionGql(commandArgs);
  };

  const getPayments = async (
    request: GetAccountingTransactionPaymentsQueryValues,
  ) => {
    const commandArgs: AccountingTransactionPaymentsGetAccountingTransactionPaymentsApiArg =
      {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        getAccountingTransactionsQueryValues: request,
      };
    return getAccountingTransactionPayments(commandArgs);
  };

  const getRenderedDocumentLink = (
    documentTemplateType: DocumentTemplateType,
    metadata: any,
    invoiceNumber: string,
    token?: string,
  ) => {
    const organizationId = process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number;
    const metadataString = JSON.stringify(metadata);

    const documentType =
      Object.keys(DocumentTemplateType)[
        Object.values(DocumentTemplateType).indexOf(documentTemplateType)
      ];

    const renderedDocumentLink = `${process.env.REACT_APP_API_ENDPOINT}/api/organizations/${organizationId}/accountingTransactions/getRenderedDocument?documentTemplateType=${documentType}&metadata=${metadataString}&invoiceNumber=${invoiceNumber}&authorization=Bearer ${token}`;

    return renderedDocumentLink;
  };

  return {
    getInvoiceGql,
    getPayments,
    getRenderedDocumentLink,
  };
};
