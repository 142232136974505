import {
  ContactGetContactsApiArg,
  useContactGetContactsQuery,
} from "../../../features/contact/contact-api";
import {useAppSelector} from "../../../store";

export const useGetContacts = (filter?: string | null) => {
  const vendorsSort = useAppSelector(
    (state) => state?.organizationConfigState?.modules?.purchases?.vendorsSort,
  );

  const args: ContactGetContactsApiArg = {
    organizationId: process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
    filter: filter,
    sort: vendorsSort,
  };

  const {
    data: contactsData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useContactGetContactsQuery(args);

  return {
    contactsData,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
