import * as yup from "yup";

export const getForgotPasswordSchema = (t: any) => {
  const schema = yup.object().shape({
    username: yup
      .string()
      .required(t("validation.common.emailRequired"))
      .email(t("validation.common.emailFormat"))
      .max(256, t("validation.common.emailLengthMax")),
  });

  return schema;
};
