import React, {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  INVOICES_QUERY_DEFAULT_FILTER,
  INVOICES_QUERY_DEFAULT_LIMIT,
  INVOICES_QUERY_DEFAULT_OFFSET,
} from "./invoiceList";
import { useAppSelector } from "../../../store";
import { buildJobTypeFilter } from "../../../../utils/helper.utils";

interface FilterItem {
  filterId: number;
  filterName: string;
  backendFilterName: string;
  isChosen: boolean;
  i18nKey: string;
}

const getDefaultFilterItems = (): FilterItem[] => {
  return [
    {
      filterId: 0,
      i18nKey: "invoices.allInvoices",
      filterName: "All invoices",
      backendFilterName: "",
      isChosen: true,
    },
    {
      filterId: 1,
      i18nKey: "invoices.paidInvoices",
      filterName: "Paid",
      backendFilterName: "accountingTransactionStatus:Paid",
      isChosen: false,
    },
    {
      filterId: 2,
      i18nKey: "invoices.unpaidInvoices",
      filterName: "Not Paid",
      backendFilterName: "accountingTransactionStatus:Open",
      isChosen: false,
    },
  ];
};

export default function InvoicesFilter() {
  const [, setSearchParams] = useSearchParams();

  const [filterState, setFilterState] = useState<FilterItem[]>(
    getDefaultFilterItems(),
  );

  const jobTypes = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules.parcels?.jobType,
  );

  function handleClick(filterId: number) {
    const newFilterItems = [...filterState];

    newFilterItems.forEach((i) => {
      if (i.filterId == filterId) {
        i.isChosen = !i.isChosen;
      } else {
        i.isChosen = false;
      }
    });

    if (newFilterItems.every((filter) => !filter.isChosen)) {
      newFilterItems[0].isChosen = true;
    }

    setFilterState(newFilterItems);
  }

  useEffect(() => {
    const chosenFiltersBackendNames: string[] = [];
    let hasNonNullFilter = false;
    for (const filter of filterState) {
      if (filter.isChosen) {
        chosenFiltersBackendNames.push(filter.backendFilterName);
        if (filter.backendFilterName) {
          hasNonNullFilter = true;
        }
      }
    }

    const combinedFilters = hasNonNullFilter
      ? `${INVOICES_QUERY_DEFAULT_FILTER} AND (${chosenFiltersBackendNames.join(
          " OR ",
        )})`
      : INVOICES_QUERY_DEFAULT_FILTER;
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set("filter", combinedFilters + buildJobTypeFilter(jobTypes));
      prevSearchParams.set("offset", INVOICES_QUERY_DEFAULT_OFFSET);
      prevSearchParams.set("limit", INVOICES_QUERY_DEFAULT_LIMIT);
      return prevSearchParams;
    });
  }, [filterState]);

  return (
    <Grid
      display={"flex"}
      gap={"16px"}
      sx={{
        margin: 0,
        overflowX: {xs: "scroll", md: "visible"},
        maxWidth: {xs: "100vw", md: "auto"},
        "::-webkit-scrollbar": {
          display: "none",
        },
        padding: {xs: "24px 16px", md: 0},
      }}
    >
      {filterState.map(
        ({filterId, filterName, isChosen, i18nKey}: FilterItem) => (
          <Button
            data-testid={`btn-filter-${i18nKey}`}
            sx={{
              height: "32px!important",
              boxShadow: "none !important",
              margin: 0,
              whiteSpace: "nowrap",
              flexShrink: {xs: "0", md: "1"},
            }}
            key={filterId}
            variant={isChosen ? "contained" : "outlined"}
            onClick={() => handleClick(filterId)}
          >
            <Typography className="filterBtn" variant="caption2">
              <Trans i18nKey={i18nKey}>{filterName}</Trans>
            </Typography>
          </Button>
        ),
      )}
    </Grid>
  );
}
