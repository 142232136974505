import React from "react";
import {Card, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ShipmentProcessProps} from "../pages/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import i18next from "i18next";

export default function ShipmentProcess({
  parcelShipment,
}: ShipmentProcessProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const orders = [parcelShipment, ...(parcelShipment.shipments ?? [])];

  const lang = i18next.language;
  const eventTranslationKey = `event_translation_${lang}`;

  return (
    <Card
      sx={
        isDesktop
          ? {
              border: "1px solid #D8E6FB",
              boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
              borderRadius: "16px",
              marginBottom: "25px",
            }
          : {
              border: "none",
              boxShadow: "none",
            }
      }
    >
      <Grid
        container
        columns={{xs: 6, md: 5}}
        display={"flex"}
        justifyContent={"space-between"}
        m={{xs: 0, md: 4, lg: 4}}
      >
        {orders
          ?.flatMap((item) => item?.trackingEvents || [])
          ?.filter((event) => event.eventDefinition?.includeInTracking)
          ?.filter(
            (event) =>
              event.eventDefinition &&
              event.eventDefinition.customValues &&
              "show_in_portal" in event.eventDefinition.customValues &&
              Boolean(event.eventDefinition.customValues["show_in_portal"]),
          )
          ?.sort(
            (a: any, b: any) =>
              new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime(),
          )
          ?.map((event, index) => {
            console.log("EVENT", event);
            return (
              <>
                <Grid xs={6} md={5} key={index}>
                  <Grid
                    container
                    columns={{xs: 6, md: 5}}
                    pt={index ? 4 : 0}
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Grid
                      xs={2}
                      md={2}
                      gap={1}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Grid>
                        <Typography
                          data-testid={`shipment-process-date-${index}`}
                          variant="body4"
                        >
                          {event?.eventDate &&
                            new Date(event?.eventDate).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              },
                            )}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          data-testid={`shipment-process-time-${index}`}
                          variant="body4"
                          color={"#98A2B3"}
                        >
                          {event?.eventDate &&
                            new Date(event?.eventDate).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "2-digit",
                                hour12: true,
                              },
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={4}
                      md={3}
                      sx={{xs: 4, md: 3, textAlign: "right", paddingLeft: 1}}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={1}
                    >
                      <Grid>
                        <Typography
                          sx={{float: "right"}}
                          data-testid={`shipment-process-description-${index}`}
                          variant="body4"
                          color={index === 0 ? "#FF7D00" : ""}
                        >
                          {event.customValues &&
                            event.customValues.trackingNumber &&
                            `${event.customValues.trackingNumber} `}
                          {event.eventDefinition &&
                          event.eventDefinition.customValues &&
                          eventTranslationKey in
                            event.eventDefinition.customValues
                            ? event.eventDefinition.customValues[
                                eventTranslationKey
                              ]
                            : event.description}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          sx={{float: "right"}}
                          data-testid={`shipment-process-location-${index}`}
                          variant="body4"
                          color={"#98A2B3"}
                        >
                          {event?.location?.toUpperCase()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}
      </Grid>
    </Card>
  );
}
