import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {CircularProgress, Typography} from "@mui/material";
import {RadialProgressStepProps} from "./interfaces";
import {useTranslation} from "react-i18next";

export default function RadialProgressStep({
  stepIndex,
  steps,
}: RadialProgressStepProps) {
  const circleSize = 64;

  let sum = 0;
  const positions = steps?.map((val) => (sum += 1));
  const step = steps[stepIndex];

  const {t} = useTranslation();

  return (
    <Grid
      container
      justifyContent={"space-between"}
      id={"radial-progress-step"}
    >
      <Grid sx={{position: "relative", display: "flex", alignItems: "center"}}>
        <CircularProgress
          size={circleSize}
          thickness={2}
          value={100}
          variant={"determinate"}
          sx={{
            position: "absolute",
            height: circleSize,
            width: circleSize,
            top: 0,
            left: 0,
            color: "#D8E6FB",
          }}
        />
        <CircularProgress
          size={circleSize}
          thickness={2}
          value={(100 / sum) * positions[stepIndex]}
          variant={"determinate"}
          sx={{
            color: "#1877F2",
          }}
        />
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            position: "absolute",
            height: circleSize,
            width: circleSize,
            top: 0,
            left: 0,
          }}
        >
          <Typography align={"center"} variant={"body2"} color="#252631">
            {stepIndex + 1} of {steps.length}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Grid
          container
          direction={"column"}
          justifyContent={"space-around"}
          alignItems={"end"}
          sx={{height: "100%"}}
        >
          <Grid>
            <Typography variant={"h2"} color="#252631">
              {step.text}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant={"caption2"} color="#393A46">
              {0 <= stepIndex && stepIndex < steps.length
                ? `${t("verificationNextStep")}: ${steps[stepIndex].text}`
                : t("verificationLastStep")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
