import React from "react";
import {Chip, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans} from "react-i18next";

interface HowItWorksComponentProps {
  i18nKeyPrefix?: string;
  steps: {
    label: string;
    title: string;
    i18nKey: string;
    paragraph: string;
  }[];
  isDesktop: boolean;

  mobileStepHeight?: number;
}

export const HowItWorks = ({
  i18nKeyPrefix = "",
  steps,
  isDesktop,
  mobileStepHeight = 150,
}: HowItWorksComponentProps) => {
  const renderSwitch = (steps: any[], key = ""): JSX.Element => {
    const variant = steps.length;
    if (isDesktop) {
      switch (variant) {
        case 3:
          return (
            <Grid key={key}>
              <Grid
                position={"relative"}
                height={60}
                md={6}
                mdOffset={4}
                sx={{borderBottom: "1px dashed #2F81EF"}}
                mb={-2.5}
              />
              <Grid
                position={"relative"}
                zIndex={1}
                display={"flex"}
                flexDirection={"row"}
                textAlign={"center"}
              >
                <Grid md={2.5} />
                {steps.map((step, index) => {
                  return (
                    <Grid
                      key={index}
                      md={3}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={2}
                      px={2}
                    >
                      <Chip
                        label={step.label}
                        variant={"outlined"}
                        color={"primary"}
                        sx={{
                          height: 40,
                          width: 40,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h2">
                        <Trans i18nKey={i18nKeyPrefix + step.i18nKey}>
                          {step.title}
                        </Trans>
                      </Typography>
                      <Typography variant="body1">
                        <Trans
                          i18nKey={i18nKeyPrefix + step.i18nKey + "Paragraph"}
                        >
                          {step.paragraph}
                        </Trans>
                      </Typography>
                    </Grid>
                  );
                })}
                <Grid md={1} />
              </Grid>
            </Grid>
          );
        case 4:
          return (
            <Grid key={key}>
              <Grid
                position={"relative"}
                height={60}
                md={9}
                mdOffset={2.5}
                sx={{borderBottom: "1px dashed #2F81EF"}}
                mb={-2.5}
              />
              <Grid
                position={"relative"}
                zIndex={1}
                display={"flex"}
                flexDirection={"row"}
                textAlign={"center"}
              >
                <Grid md={1} />

                {steps.map((step, index) => {
                  return (
                    <Grid
                      key={index}
                      md={3}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={2}
                      px={2}
                    >
                      <Chip
                        label={step.label}
                        variant={"outlined"}
                        color={"primary"}
                        sx={{
                          height: 40,
                          width: 40,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h2">
                        <Trans i18nKey={i18nKeyPrefix + step.i18nKey}>
                          {step.title}
                        </Trans>
                      </Typography>
                      <Typography variant="body1">
                        <Trans
                          i18nKey={i18nKeyPrefix + step.i18nKey + "Paragraph"}
                        >
                          {step.paragraph}
                        </Trans>
                      </Typography>
                    </Grid>
                  );
                })}
                <Grid md={1} />
              </Grid>
            </Grid>
          );
        case 5:
          return (
            <Grid key={key}>
              <Grid
                position={"relative"}
                height={60}
                md={10}
                mdOffset={2}
                sx={{borderBottom: "1px dashed #2F81EF"}}
                mb={-2.5}
              />
              <Grid
                position={"relative"}
                zIndex={1}
                display={"flex"}
                flexDirection={"row"}
                textAlign={"center"}
              >
                <Grid md={1} />

                {steps.map((step, index) => {
                  return (
                    <Grid
                      key={index}
                      md={3}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={2}
                      px={2}
                    >
                      <Chip
                        label={step.label}
                        variant={"outlined"}
                        color={"primary"}
                        sx={{
                          height: 40,
                          width: 40,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h2">
                        <Trans i18nKey={i18nKeyPrefix + step.i18nKey}>
                          {step.title}
                        </Trans>
                      </Typography>
                      <Typography variant="body1">
                        <Trans
                          i18nKey={i18nKeyPrefix + step.i18nKey + "Paragraph"}
                        >
                          {step.paragraph}
                        </Trans>
                      </Typography>
                    </Grid>
                  );
                })}
                <Grid md={1} />
              </Grid>
            </Grid>
          );
        case 6:
          return (
            <>
              {steps.map((step, index) => {
                if (index % 3 == 0) {
                  return renderSwitch(
                    [steps[index], steps[index + 1], steps[index + 2]],
                    index.toString(),
                  );
                }
              })}
            </>
          );
        default:
          return <Grid></Grid>;
      }
    } else {
      return (
        <>
          <Grid
            position={"relative"}
            zIndex={1}
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
          >
            <Grid
              position={"absolute"}
              width={36}
              height={`calc(100% - ${mobileStepHeight + "px"} )`}
              md={6}
              mdOffset={4}
              sx={{borderRight: "1px dashed #2F81EF"}}
              marginTop={mobileStepHeight / 2 + "px"}
              marginBottom={"10px"}
            />
            {steps.map((step, index) => {
              return (
                <Grid
                  key={index}
                  md={3}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={2}
                  px={2}
                  height={mobileStepHeight}
                  zIndex={1}
                >
                  <Chip
                    label={step.label}
                    variant={"outlined"}
                    color={"primary"}
                    sx={{
                      minWidth: 40,
                      height: 40,
                      width: 40,
                      borderRadius: "50%",
                    }}
                  />
                  <Grid
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"left"}
                  >
                    <Typography
                      variant="h2"
                      mb={0.5}
                      fontSize={"1.3rem !important"}
                    >
                      <Trans i18nKey={i18nKeyPrefix + step.i18nKey}>
                        {step.title}
                      </Trans>
                    </Typography>
                    <Typography variant="body1" fontSize={"0.9rem !important"}>
                      <Trans
                        i18nKey={i18nKeyPrefix + step.i18nKey + "Paragraph"}
                      >
                        {step.paragraph}
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </>
      );
    }
  };

  return renderSwitch(steps);
};
