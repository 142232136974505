import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import MainPageHeader from "../../headers/components/mainPageHeader";
import MainPageFooter from "../../footers/components/mainPageFooter";
import MainMobileFooter from "../../footers/components/mainMobileFooter";
import MainMobileHeader from "../../headers/components/mainMobileHeader";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useAppSelector} from "../../../store";

export default function MainLayout({
  backLink,
  showPrev,
  statusBar,
  fullWidth,
  children,
  handlePrevStep,
  pageTitle,
}: any) {
  const navigate = useNavigate();
  const handlePrev = handlePrevStep
    ? handlePrevStep
    : () => {
        navigate(backLink);
        !backLink && showPrev(false);
        window.scrollTo(0, 0);
      };

  const showPaymentMethod = useAppSelector(
    (state: any) =>
      !state.organizationConfigState?.modules?.profile?.paymentMethod?.disabled,
  );

  return (
    <Grid columns={{xs: 6, md: 14}}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid xs={6} md={14}>
        <Grid
          container
          direction={"column"}
          sx={{
            minHeight: "100vh",
            backgroundColor: {xs: "#F5F9FF"},
          }}
        >
          <MainPageHeader
            backLink={backLink}
            showPrev={showPrev}
            showPaymentMethod={showPaymentMethod}
          />
          <MainMobileHeader handlePrev={handlePrev} />
          <Grid
            container
            direction="column"
            flexGrow={1}
            columns={{xs: 6, md: 14}}
            sx={{
              backgroundColor: {xs: "#F5F9FF", md: "white"},
            }}
          >
            {statusBar && (
              <Grid md={14} sx={{px: {xs: 2, md: 0}, mb: {xs: 2, md: 0}}}>
                {statusBar}
              </Grid>
            )}
            <Grid container columns={{xs: 6, md: 14}} flexGrow={1}>
              {fullWidth ? (
                <Grid md={14}>{children}</Grid>
              ) : (
                <>
                  <Grid md />
                  <Grid md={12} flex={1}>
                    {children}
                  </Grid>
                  <Grid md />
                </>
              )}
            </Grid>
          </Grid>
          <MainPageFooter />
          <MainMobileFooter />
        </Grid>
      </Grid>
    </Grid>
  );
}
