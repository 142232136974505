import React from "react";
import {IconButton, SvgIcon, Typography} from "@mui/material";
import Link from "../../common/components/link";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans} from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";

type ExtraMenuButtonProps = {
  i18nKey?: string;
  caption?: string;
  style?: any;
  link?: string;
  onClick?: any;
  svgIcon?: any;
};
export default function ExtraMenuButton({
  i18nKey,
  caption = "",
  style,
  link,
  onClick,
  svgIcon,
}: ExtraMenuButtonProps) {
  return (
    <Link
      to={link}
      style={{textDecoration: "none", color: "inherit", ...style}}
    >
      <Grid onClick={onClick} container alignItems="center">
        <IconButton color="primary">
          <SvgIcon component={svgIcon ?? AddBoxIcon} />
        </IconButton>
        <Typography variant="body4" sx={{marginLeft: "8px"}}>
          <Trans i18nKey={i18nKey}>{caption}</Trans>
        </Typography>
      </Grid>
    </Link>
  );
}
