import React, {useEffect, useState} from "react";
import {SxProps, Theme, useMediaQuery, useTheme} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Field, Form, Formik} from "formik";
import {getEditAccountNameSchema} from "../pages/validation";
import {Trans, useTranslation} from "react-i18next";
import {useAppSelector} from "../../../store";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {
  useLazyUserGetInfoQuery,
  useUserUpdateInfoMutation,
} from "../../../features/user/user-api";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {toastError} from "../../../common/utils/toastMessages";

const formElementStyles: SxProps<Theme> = {
  minWidth: {
    xs: "0",
    md: "394px",
  },
};

export interface EditNameFormValues {
  firstName: string;
  lastName: string;
  organizationId: number;
  contactId?: number | null;
}

export default function EditAccountNameForm() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.userState);

  const [updateUserInfo] = useUserUpdateInfoMutation();
  const [getUserInfo] = useLazyUserGetInfoQuery();

  const [initialValues, setInitialValues] = useState<EditNameFormValues>({
    firstName: userState?.firstName || "",
    lastName: userState?.lastName || "",
    organizationId: process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
    contactId: userState.contactId,
  });
  const [isUpdatingUserName, setIsUpdatingUserName] = useState<boolean>(false);

  useEffect(() => {
    setInitialValues({
      firstName: userState?.firstName || "",
      lastName: userState?.lastName || "",
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactId: userState.contactId,
    });
  }, [userState?.firstName, userState?.lastName]);

  const onSubmit = async (values: EditNameFormValues) => {
    if (isUpdatingUserName) return;

    setIsUpdatingUserName(true);
    try {
      const updateResponse = await updateUserInfo({
        updateUserInfoCommand: values,
      });
      if ((updateResponse as any)?.error) {
        return;
      }

      const userInfoResponse = await getUserInfo();
      if (userInfoResponse?.isError) {
        return;
      }

      toast.success(t("toasts.updatedUserName"));
      navigate("../profile");
    } catch (error) {
      toastError(error);
    } finally {
      setIsUpdatingUserName(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getEditAccountNameSchema(t)}
      onSubmit={onSubmit}
    >
      <Form id="editAccountNameForm">
        <Grid
          container
          columns={12}
          display="flex"
          flexDirection="column"
          rowGap="32px"
          mt={{
            xs: 4,
            md: 1,
          }}
        >
          <Grid
            display="flex"
            flexDirection="column"
            rowGap={{
              xs: "16px",
              md: "24px",
            }}
          >
            <Grid xs md={3} sx={formElementStyles}>
              <Field
                fullWidth
                component={TextField}
                size={isDesktop ? "normal" : "small"}
                id="firstName"
                name="firstName"
                label={t("profile.editName.firstName")}
                placeholder=""
                data-testid="input-account-first-name"
                disabled={isUpdatingUserName}
              />
            </Grid>
            <Grid xs md={3} sx={formElementStyles}>
              <Field
                fullWidth
                component={TextField}
                size={isDesktop ? "normal" : "small"}
                id="lastName"
                name="lastName"
                label={t("profile.editName.lastName")}
                placeholder=""
                data-testid="input-account-last-name"
                disabled={isUpdatingUserName}
              />
            </Grid>
          </Grid>
          <Grid xs md={3} sx={formElementStyles}>
            <LoadingButton
              fullWidth
              type="submit"
              form="editAccountNameForm"
              variant="contained"
              color="secondary"
              data-testid="btn-save-account-name"
              disabled={isUpdatingUserName}
              loading={isUpdatingUserName}
              loadingPosition="end"
              endIcon={<></>}
            >
              <Trans i18nKey="save">Save</Trans>
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
