import React, {ReactNode, useEffect} from "react";
import GoogleAnalyticsContext from "./googleAnalitics.context";

interface GoogleAnalyticsProviderProps {
  children: ReactNode;
}

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

const GAId = process.env.REACT_APP_GA as string;

const GoogleAnalyticsProvider: React.FC<GoogleAnalyticsProviderProps> = ({
  children,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GAId}`;
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
    window.gtag("js", new Date());
    window.gtag("config", GAId, {debug: true});

    window.gtag("config", GAId, {
      page_path: window.location.pathname,
    });
  }, []);

  const sendEvent = (
    category: string,
    action: string,
    label: string,
    value: number,
  ) => {
    if (window.gtag) {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }
  };

  return (
    <GoogleAnalyticsContext.Provider value={{sendEvent}}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export default GoogleAnalyticsProvider;
