import React from "react";
import {Trans} from "react-i18next";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ProfileTabMenu from "../../components/profileTabMenu";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import EditAccountPhoneNumberForm from "../components";
import {RootState, useAppSelector} from "../../../store";
import PhoneFragment from "../../../Verifications/components/phoneFragment";

const EditAccountPhoneNumber = () => {
  const usePhoneVerification =
    useAppSelector(
      (state: RootState) =>
        state.organizationConfigState?.modules?.verification
          ?.usePhoneVerification,
    ) ?? true;

  return (
    <MainLayout
      pageTitle="Phone Details"
      backLink="../profile"
      statusBar={<ProfileTabMenu selectedTab={1} />}
    >
      <ContentLayout
        title={
          <Trans i18nKey={"profile.tabs.editPhoneNumber"}>
            Editing a phone number
          </Trans>
        }
        backLink="../profile"
        content={
          usePhoneVerification ? (
            <EditAccountPhoneNumberForm />
          ) : (
            <PhoneFragment
              step={{
                name: "phone",
              }}
            />
          )
        }
        containerSx={{mb: "24px"}}
      />
    </MainLayout>
  );
};

export default EditAccountPhoneNumber;
