import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "@mui/system";

interface CardProps {
  children: React.ReactNode;
  columns?: number;
  sx?: any;
  onClick?: any;
}

export default function Card({children, columns = 12, sx, onClick}: CardProps) {
  const theme = useTheme();

  return (
    <Grid
      sx={{
        boxShadow: {
          md: `0px 0px 8px 0px ${theme.palette.primary.shadow}`,
          xs: "none",
        },
        cursor: onClick ? "pointer" : "default",
        width: "100%",
        border: "1px solid",
        borderColor: theme.palette.info.border,
        borderRadius: {xs: 0, md: "16px"},
        borderRightWidth: {xs: 0, md: "1px"},
        borderLeftWidth: {xs: 0, md: "1px"},
        padding: {xs: 2, md: 3},
        flexWrap: "nowrap",
        ...sx,
      }}
      container
      display={"flex"}
      columns={columns}
      direction={{md: "row", xs: "column"}}
      onClick={onClick}
    >
      {children}
    </Grid>
  );
}
