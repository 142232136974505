import React from "react";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ProfileTabMenu from "../../components/profileTabMenu";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import {Trans} from "react-i18next";
import BillingAddressDifferencesText from "../components";

const BillingAddressInformation = () => {
  return (
    <MainLayout
      pageTitle="Billing Address Information"
      backLink="../profile"
      statusBar={<ProfileTabMenu selectedTab={1} />}
    >
      <ContentLayout
        title={
          <Trans i18nKey={"profile.whatsMyBillingAddressTitle"}>
            What is the difference between Billing and Shipping Address?
          </Trans>
        }
        withoutTitle={true}
        withoutMobileTitle={true}
        backLink="../profile"
        content={<BillingAddressDifferencesText />}
        containerSx={{mb: "24px"}}
      />
    </MainLayout>
  );
};

export default BillingAddressInformation;
