import {SubmitHandler} from "react-hook-form";
import {
  ResetPasswordCommand,
  UserResetPasswordApiArg,
  useUserResetPasswordMutation,
} from "../../features/user/user-api";
import {useParams} from "react-router-dom";
import {useState} from "react";

type UrlParams = {
  userId: string;
  code: string;
};

export const useResetPasswordSubmit = () => {
  const {userId, code} = useParams<keyof UrlParams>() as UrlParams;
  const [notificationStatus, setNotificationStatus] = useState<any>(null);
  const [sendLink, {isLoading, isError, isSuccess}] =
    useUserResetPasswordMutation();

  const submit: SubmitHandler<ResetPasswordCommand> = (values) => {
    const commandArgs: UserResetPasswordApiArg = {
      resetPasswordCommand: {...values, code, userId},
    };
    setNotificationStatus(null);
    sendLink(commandArgs);
  };

  return {
    notificationStatus,
    setNotificationStatus,
    isLoading,
    isError,
    isSuccess,
    submit,
  };
};
