import {v4 as uuidv4} from "uuid";
import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    organizationConfigGetMany: build.query<
      OrganizationConfigGetManyApiResponse,
      OrganizationConfigGetManyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/organizationConfigs`,
        params: {
          PortalName: queryArg.portalName,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          Sort: queryArg.sort,
          Filter: queryArg.filter,
          Search: queryArg.search,
          Summary: queryArg.summary,
          Include: queryArg.include,
          Fields: queryArg.fields,
        },
      }),
      extraOptions: {
        alwaysAnonymous: true,
      },
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};

export type OrganizationConfigGetManyApiResponse =
  /** status 200  */ PortalPagedResultOfPortalOrganizationConfigDto;

export type OrganizationConfigGetManyApiArg = {
  organizationId: number;
  portalName?: string;
  limit?: number;
  offset?: number;
  sort?: string;
  filter?: string;
  search?: string;
  summary?: boolean;
  include?: string;
  fields?: string;
};
export type PortalOrganizationConfigDto = {
  configName: string;
  customValues?: {
    [key: string]: any;
  };
};
export type PortalPagedResultOfPortalOrganizationConfigDto = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  summary?: {
    [key: string]: any;
  };
  items: PortalOrganizationConfigDto[];
};
export type GetOrganizationConfigsQuery = {
  organizationId: number;
  limit: number;
  offset: number;
  sort?: string;
  filter?: string;
  search?: string;
  summary: boolean;
  include?: string;
  fields?: string;
};
export const {useOrganizationConfigGetManyQuery} = injectedRtkApi;
