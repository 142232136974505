import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Divider, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import {InvoicePaymentInfoProps} from "./interfaces";
import {getFormattedDate} from "../../../../utils/formatting.utils";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";

const invoicePaymentInfo = ({payment}: InvoicePaymentInfoProps) => {
  return (
    <Grid>
      <Grid container direction="column" columnSpacing={1} ml={4} mt={4} mr={4}>
        <Grid
          container
          direction="row"
          rowSpacing={1}
          justifyContent="space-between"
        >
          <Grid>
            <Typography variant="body1">
              <Trans i18nKey={"invoices.paymentDate"}>Date of payment</Trans>
            </Typography>
            <Typography data-testid={`invoice-pay-date`} variant="body4">
              {getFormattedDate(payment?.paymentDate ?? "")}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1">
              <Trans i18nKey={"invoices.paymentForm"}>Form of payment</Trans>
            </Typography>
            <Typography data-testid={`invoice-pay-form`} variant="body4">
              CreditCard
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1">
              <Trans i18nKey={"invoices.amountApplied"}>Amount applied</Trans>
            </Typography>
            <Typography data-testid={`invoice-pay-amount`} variant="body4">
              {getFormattedPrice(payment?.amountReceived)}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          sx={{
            position: "relative",
            borderBlockColor: "#D8E6FB",
            zIndex: "1",
            mt: 4,
          }}
        ></Divider>
      </Grid>
    </Grid>
  );
};

export default invoicePaymentInfo;
