import React, {useCallback, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Chip, Link, Typography, useTheme} from "@mui/material";
import {Trans} from "react-i18next";
import {CountryDto} from "../../features/countries/countries-api";
import {renderFlag} from "../../../utils/renderFlag.utils";
import {getPascalizeCountryName} from "../../../utils/helper.utils";
import {
  CountryFilter,
  useGetCountries,
} from "../../common/hooks/useGetCountries";
import {RootState, useAppSelector} from "../../store";
import i18next from "i18next";

interface CountriesListComponentProps {
  limit?: number;
  align?: "left" | "center";
  i18nKeyPrefix?: string;
  countries?: CountryDto[] | null;
}

export const CountriesList = ({
  limit = 3,
  align = "left",
  i18nKeyPrefix = "countries.",
}: CountriesListComponentProps) => {
  const theme = useTheme();

  const config = useAppSelector(
    (state: RootState) => state.organizationConfigState.modules.gettingStarted,
  );

  const {data: countriesValues} = useGetCountries({
    filter: CountryFilter.Delivery,
  });

  const [showAllCountries, setShowAllCountries] = useState(false);

  const getCountryLink = useCallback(
    (country: CountryDto) => {
      return `${config?.countryLinkPrefix?.[i18next.language] ?? ""}${
        getPascalizeCountryName(country?.name)?.toLowerCase() ?? ""
      }`;
    },
    [config, i18next.language],
  );

  return (
    <Grid
      mb={3}
      sx={{
        ".MuiChip-outlined": {
          borderColor: theme.palette.info.border,
          paddingX: "16px",
          paddingY: "12px",
          height: 40,
          borderRadius: "32px",
        },
        ".MuiLink-root": {
          marginRight: "8px",
          marginBottom: "16px",
          borderRadius: "32px",
        },
        ".MuiChip-label": {
          color: "#393A46",
          overflow: "auto",
        },
        ".MuiChip-icon": {
          width: 20,
        },
      }}
      display={"flex"}
      flexWrap={"wrap"}
      justifyContent={align}
      data-testid={"countriesList"}
    >
      {countriesValues
        ? countriesValues.map((country, index) => {
            if (index < limit || showAllCountries) {
              return (
                <Link
                  key={`${country?.countryCode}-${index}`}
                  href={getCountryLink(country)}
                  target="_blank"
                >
                  <Chip
                    icon={renderFlag(country.countryCode ?? "")}
                    label={
                      <Typography variant={"caption2"} ml={1}>
                        <Trans
                          i18nKey={
                            i18nKeyPrefix +
                            getPascalizeCountryName(country.name)
                          }
                        >
                          {country.name}
                        </Trans>
                      </Typography>
                    }
                    color={"primary"}
                    variant={"outlined"}
                    data-testid={`country-${country.countryCode}`}
                    sx={{cursor: "pointer"}}
                  />
                </Link>
              );
            }
          })
        : null}
      {!showAllCountries && (
        <Chip
          label={
            countriesValues ? (
              <Typography
                variant={"caption2"}
                color={theme.palette.primary.main}
              >
                <Trans i18nKey={i18nKeyPrefix + "allCountries"}>
                  All countries
                </Trans>
              </Typography>
            ) : (
              <Typography
                variant={"caption2"}
                color={theme.palette.primary.main}
              >
                <Trans i18nKey={i18nKeyPrefix + "loading"}>
                  Loading countries ...
                </Trans>
              </Typography>
            )
          }
          color={"primary"}
          variant={"outlined"}
          onClick={() => {
            setShowAllCountries(true);
          }}
        />
      )}
    </Grid>
  );
};
