import React, {useEffect} from "react";
import {useAppSelector} from "../../../store";
import NotVerifiedIcon from "@mui/icons-material/NewReleases";
import VerifiedIcon from "@mui/icons-material/Verified";
import defaultUserPicture from "../../../../images/photo-none.jpg";
import {Box, Typography} from "@mui/material";
import Button from "@mui/material/Button";

function ProfileMenu() {
  const userState = useAppSelector((state) => state.userState);

  const [verified, setVerified] = React.useState<boolean>(false);

  useEffect(() => {
    setVerified(
      (userState.emailConfirmed ?? false) &&
        (userState.phoneNumberConfirmed ?? false) &&
        (userState.contactAddressConfirmed ?? false) &&
        (userState.paymentMethodConfirmed ?? false),
    );
  }, [userState]);

  return (
    <Button>
      <Box
        sx={{
          position: "relative",
          width: "45px",
          height: "45px",
          minWidth: "45px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "10px",
            top: "10px",
            width: "25px",
            height: "25px",
            minWidth: "25px",
            borderRadius: "50%",
          }}
        >
          <img
            src={defaultUserPicture}
            alt={"user picture"}
            style={{width: "100%", height: "100%", borderRadius: "50%"}}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: "0px",
            bottom: "0px",
            width: "20px",
            height: "20px",
            minWidth: "20px",
          }}
        >
          <Typography fontSize="20px" lineHeight="20px">
            {verified ? (
              <VerifiedIcon fontSize="inherit" color="success" />
            ) : (
              <NotVerifiedIcon fontSize="inherit" color="error" />
            )}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
}

export default ProfileMenu;
