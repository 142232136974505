import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {Trans, useTranslation} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import {TextField} from "formik-mui";
import {VerificationStepSubmit} from "./interfaces";
import MaskedInput from "react-text-mask";
import {LoadingButton} from "@mui/lab";
import {Field, Form, Formik} from "formik";
import {useCookies} from "react-cookie";
import {getChargedAmountSchema, getPaymentSchema} from "../pages/validation";
import {SubmitHandler} from "react-hook-form";
import {
  SendChargedAmountCommand,
  useVerificationChargedAmountMutation,
  useVerificationVerifyChargedAmountMutation,
  VerificationChargedAmountApiArg,
  VerificationVerifyChargedAmountApiArg,
  VerifyChargedAmountCommand,
} from "../../features/verification/verification-api";
import "../../../style/cardTypes.scss";
import creditCardType from "credit-card-type";
import {cardTypesLogos} from "../../common/components/cardTypes";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useAppSelector} from "../../store";
import {useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";
import coinSwap from "./../../../images/coinSwap.png";
import {VerificationState} from "../../features/verification/verification-slice";

const initialValuesSend: SendChargedAmountCommand = {
  contactPaymentMethodId: null,
  cardNumber: "",
  holderName: "",
  expDate: "",
  code: "",
  contactId: 0,
  cardType: "",
};

export default function PaymentVerificationForm({
  step,
  handlePrev,
  handleNext,
}: VerificationStepSubmit) {
  const {t} = useTranslation();

  const [displayVerify, setDisplayVerify] = useState("none");
  const [hideGettingCode, setHideGettingCode] = useState("flex");
  const [cardType, setCardType] = useState<string>("unknown");
  const [notificationStatus, setNotificationStatus] = useState<any>(false);
  const [verificationStatus, setVerificationStatus] = useState<any>(null);
  const [contactPaymentMethodId, setContactPaymentMethodId] =
    useState<string | null | undefined>(null);
  const [cookies, setCookie, removeCookie] = useCookies(["VPFACH"]);
  const navigate = useNavigate();

  const contactId: number | null | undefined = useAppSelector(
    (state) => state.userState.contactId,
  );

  const verificationState = useAppSelector(
    (state: any) => state.verificationState as VerificationState,
  );
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [sendChargedAmount, {isLoading, isError, isSuccess}] =
    useVerificationChargedAmountMutation();

  const [
    verifyChargedAmount,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerify,
      isSuccess: isSuccessVerify,
    },
  ] = useVerificationVerifyChargedAmountMutation();

  const initialValuesVerify: VerifyChargedAmountCommand = {
    contactPaymentMethodId: null,
    chargedAmount: "",
    sendChargedAmount: "",
    verifyChargedAmount: "",
    username: useAppSelector((state) => state.userState.email),
  };

  useEffect(() => {
    if (isSuccess) {
      setNotificationStatus(true);
    }
    if (isError) {
      setNotificationStatus(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccessVerify) {
      toast.success(`${t("toasts.chargedAmountVerificationSuccess")}`);
      handleNext();
    }
    if (isErrorVerify) {
      setVerificationStatus(false);
    }
  }, [isLoadingVerify]);

  const onSubmit: SubmitHandler<SendChargedAmountCommand> = async (values) => {
    const toastLoadingId = "toast-loading";
    toast.loading(t("loading"), {id: toastLoadingId});

    if (contactPaymentMethodId) {
      values.contactPaymentMethodId = contactPaymentMethodId;
    }
    values.contactId = contactId;
    values.cardType = cardType;
    const commandArgs: VerificationChargedAmountApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      sendChargedAmountCommand: values,
    };
    setNotificationStatus(null);

    try {
      const response: any = await sendChargedAmount(commandArgs);

      if (!response.error && response.data) {
        setContactPaymentMethodId(response.data.contactPaymentMethodId);
        setCookie("VPFACH", response.data.hexAmount, {maxAge: 172800});
        if (response.data?.showAmount) {
          toast.success(`Your test amount: ${response.data.showAmount}`, {
            duration: 10000,
          });
        } else {
          toast.success(t("toasts.verifyDebitedAmount"));
        }
      }
    } finally {
      toast.remove(toastLoadingId);
    }
  };

  const onVerify: SubmitHandler<VerifyChargedAmountCommand> = (values) => {
    values.sendChargedAmount = values?.chargedAmount?.toString();
    values.verifyChargedAmount = cookies.VPFACH;
    const commandArgs: VerificationVerifyChargedAmountApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      verifyChargedAmountCommand: values,
    };
    setVerificationStatus(null);
    verifyChargedAmount(commandArgs)
      .unwrap()
      .then(() => {
        removeCookie("VPFACH");
        navigate("../getting-started");
      })
      .catch((onrejected) => console.error("error", onrejected));
  };

  useEffect(() => {
    if (notificationStatus) {
      setDisplayVerify("flex");
      setHideGettingCode("none");
    }
  }, [notificationStatus]);

  return (
    <Grid container flexDirection={"column"} lg={8}>
      <Grid mb={"30px"}>
        <Typography variant="h3">
          <Trans i18nKey="addCard">Add card.</Trans>
        </Typography>
        <Typography variant="h3">
          <Trans i18nKey="checkChargedAmount">
            Check and enter charged amount
          </Trans>
        </Typography>
      </Grid>
      <Grid
        mb={"1rem"}
        display={
          verificationState.contactAddress.verified == true ? "none" : "block"
        }
      >
        <Typography color={"error.main"}>
          <Trans i18nKey={"delivery.paymentMethod.verificationNeeded"} />{" "}
          <Link
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={handlePrev}
          >
            <Trans i18nKey={"delivery.paymentMethod.completeBilling"} />
          </Link>
        </Typography>
      </Grid>
      <Grid>
        <Formik
          onSubmit={(values: SendChargedAmountCommand) => {
            onSubmit(values);
          }}
          initialValues={initialValuesSend}
          validationSchema={getPaymentSchema(t)}
        >
          {({isSubmitting}) => (
            <Form>
              <div className="card-wrapper">
                <Field
                  id="cardNumber"
                  name="cardNumber"
                  autoComplete="cardNumber"
                >
                  {({field, form, meta}: any) => (
                    <MaskedInput
                      {...field}
                      mask={[
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      disabled={!verificationState.contactAddress.verified}
                      guide={false}
                      onInput={(str: BaseSyntheticEvent) => {
                        const currentCardNumber: string = (
                          str.target.value as string
                        ).replaceAll(" ", "");
                        const cardTypes = creditCardType(currentCardNumber);
                        if (cardTypes.length > 0)
                          if (currentCardNumber === "") {
                            setCardType("unknown");
                          } else {
                            setCardType(cardTypes[0].type);
                          }
                        else {
                          setCardType("unknown");
                        }

                        if (currentCardNumber.match(/^\d{16}$/)) {
                          (
                            document.querySelector(
                              '[name="holderName"]',
                            ) as HTMLElement
                          ).focus();
                        }
                      }}
                      render={(innerRef, props) => (
                        <TextField
                          {...props}
                          inputRef={innerRef}
                          fullWidth
                          label={t("cardNumber")}
                          placeholder="0000 0000 0000 0000"
                          size={isDesktop ? "medium" : "small"}
                          field={field}
                          form={form}
                          meta={meta}
                          disabled={
                            (isSubmitting && notificationStatus == null) ||
                            (isSubmitting && notificationStatus)
                          }
                        />
                      )}
                    />
                  )}
                </Field>
                {isDesktop && (
                  <div className={"card-type"}>
                    <img
                      className="card-type-img"
                      src={cardTypesLogos[cardType]}
                      alt={`${cardType}`}
                      width={"100%"}
                    />
                  </div>
                )}
              </div>

              <Field
                id="holderName"
                name="holderName"
                sx={{mb: 2}}
                size={isDesktop ? "normal" : "small"}
                fullWidth
                label={t("holderName")}
                placeholder="ALEX JOHNSON"
                component={TextField}
                disabled={
                  (isSubmitting && notificationStatus == null) ||
                  (isSubmitting && notificationStatus)
                }
                onInput={(str: BaseSyntheticEvent) => {
                  str.target.value = (str.target.value as string).toUpperCase();
                }}
              />

              <Grid container justifyContent={"space-between"}>
                <Field id="expDate" name="expDate">
                  {({field, form, meta}: any) => (
                    <MaskedInput
                      {...field}
                      mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                      guide={false}
                      onInput={(str: BaseSyntheticEvent) => {
                        const expDate: string = str.target.value;
                        if (expDate.match(/^\d{2}\/\d{2}$/)) {
                          (
                            document.querySelector(
                              '[name="code"]',
                            ) as HTMLElement
                          ).focus();
                        }
                      }}
                      render={(innerRef, props) => (
                        <TextField
                          {...props}
                          inputRef={innerRef}
                          sx={{mb: 2, width: "48%"}}
                          size={isDesktop ? "medium" : "small"}
                          label={t("expDate")}
                          placeholder="03/22"
                          field={field}
                          form={form}
                          meta={meta}
                          disabled={
                            (isSubmitting && notificationStatus == null) ||
                            (isSubmitting && notificationStatus)
                          }
                        />
                      )}
                    />
                  )}
                </Field>

                <Field id="code" name="code">
                  {({field, form, meta}: any) => (
                    <MaskedInput
                      {...field}
                      mask={
                        cardType === "american-express" ||
                        cardType === "hipercard"
                          ? [/\d/, /\d/, /\d/, /\d/]
                          : [/\d/, /\d/, /\d/]
                      }
                      guide={false}
                      render={(innerRef, props) => (
                        <TextField
                          {...props}
                          inputRef={innerRef}
                          sx={{mb: 2, width: "48%"}}
                          size={isDesktop ? "medium" : "small"}
                          label="CVC/CVV"
                          placeholder={
                            cardType === "american-express" ||
                            cardType === "hipercard"
                              ? "0000"
                              : "000"
                          }
                          field={field}
                          form={form}
                          meta={meta}
                          disabled={
                            (isSubmitting && notificationStatus == null) ||
                            (isSubmitting && notificationStatus)
                          }
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>

              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={"30px"}
                mt={{xs: "10px", sm: "20px"}}
                sx={{
                  display: {xs: "none", md: "flex"},
                }}
              >
                <LoadingButton
                  sx={{p: 1, width: "48%"}}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={
                    (isSubmitting && notificationStatus) ||
                    !verificationState.contactAddress.verified
                  }
                  loading={isSubmitting && notificationStatus == null}
                  loadingPosition="end"
                  endIcon={<></>}
                  id={"startVerification"}
                >
                  <Trans i18nKey="startVerification">Start verification</Trans>
                </LoadingButton>

                {notificationStatus && (
                  <Grid
                    container
                    justifyContent={"start"}
                    alignItems={"center"}
                    width={"48%"}
                  >
                    <CheckCircleIcon
                      color={"primary"}
                      sx={{fontSize: "24px"}}
                    />
                    <Typography
                      variant="body2"
                      ml={"8px"}
                      sx={{
                        color: "primary.dark",
                      }}
                    >
                      <Trans i18nKey="fundsWereCharged">
                        Funds were charged
                      </Trans>
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                direction="row"
                columns={{xs: 6, md: 6}}
                sx={{
                  display: {
                    xs: `${hideGettingCode}`,
                    md: "none",
                  },
                  position: "fixed",
                  bottom: "72px",
                  width: "100%",
                  pr: "35px",
                }}
              >
                <Grid xs={3} md={3} pr={1}>
                  <Button
                    fullWidth
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={handleNext}
                    sx={{fontSize: {xs: "0.775rem", sm: "0.875rem"}}}
                  >
                    <Trans i18nKey="skip">Skip</Trans>
                  </Button>
                </Grid>
                <Grid xs={3} md={3} pl={1}>
                  <LoadingButton
                    sx={{
                      p: 1,
                      width: "100%",
                      fontSize: {xs: "0.775rem", sm: "0.875rem"},
                    }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={
                      (isSubmitting && notificationStatus) ||
                      !verificationState.contactAddress.verified
                    }
                    loading={isSubmitting && notificationStatus == null}
                    loadingPosition="end"
                    endIcon={<></>}
                    id={"startVerification"}
                  >
                    <Trans i18nKey="startVerification">
                      Start verification
                    </Trans>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        <Formik
          onSubmit={(values: VerifyChargedAmountCommand) => {
            onVerify(values);
          }}
          initialValues={initialValuesVerify}
          validationSchema={getChargedAmountSchema(t)}
        >
          {({isSubmitting}) => (
            <Form>
              <Grid
                container
                direction="column"
                columns={{xs: 6, md: 6}}
                sx={{
                  display: {xs: `${displayVerify}`, md: "flex"},
                }}
              >
                <Typography
                  variant="body2"
                  mb={"5px"}
                  sx={{
                    color: "primary.dark",
                  }}
                >
                  <Trans i18nKey="enterChargedAmount">
                    Enter charged amount here
                  </Trans>
                </Typography>
                <Field id="chargedAmount" name="chargedAmount">
                  {({field, form, meta}: any) => (
                    <MaskedInput
                      {...field}
                      mask={[/\d/, ".", /\d/, /\d/]}
                      guide={false}
                      render={(innerRef, props) => (
                        <TextField
                          {...props}
                          inputRef={innerRef}
                          sx={{mb: 2, mt: 1}}
                          size={isDesktop ? "medium" : "small"}
                          fullWidth
                          label={t("chargedAmount")}
                          placeholder="0.00"
                          InputProps={{
                            startAdornment: "$",
                          }}
                          field={field}
                          form={form}
                          meta={meta}
                          disabled={
                            (isSubmitting && verificationStatus == null) ||
                            (isSubmitting && verificationStatus)
                          }
                        />
                      )}
                    />
                  )}
                </Field>

                <Grid container alignItems="center">
                  <Grid>
                    <img
                      src={coinSwap}
                      alt="Icon"
                      width="15"
                      height="15"
                      style={{marginRight: 5}}
                    />
                  </Grid>
                  <Grid>
                    <Link
                      href="https://minfin.com.ua/ua/currency/converter/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        display: "inline-block",
                        marginBottom: "5px",
                      }}
                    >
                      <Trans i18nKey={"currencyConverter"}>
                        Currency converter
                      </Trans>
                    </Link>
                  </Grid>
                </Grid>

                {notificationStatus && (
                  <Grid container>
                    <Typography
                      variant="body2"
                      mb={"5px"}
                      sx={{
                        color: "primary.dark",
                      }}
                    >
                      <Trans i18nKey="fundsWerentCharged">
                        Funds weren’t charged?
                      </Trans>
                      &nbsp;
                      <Box
                        display="inline"
                        sx={{
                          color: "#2F81EF",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const startVerificationButton =
                            document.querySelector(
                              "#startVerification",
                            ) as HTMLButtonElement;
                          startVerificationButton.removeAttribute("disabled");
                          startVerificationButton.click();
                        }}
                      >
                        <Trans i18nKey="clickHere">Click here</Trans>
                      </Box>
                      &nbsp;
                      <Trans i18nKey="toChargeItAgain">
                        to charge it again
                      </Trans>
                    </Typography>
                  </Grid>
                )}

                <Grid container direction="column" columns={{xs: 6, md: 5}}>
                  <Grid mb={"30px"} mt={"20px"} md={5}>
                    <Grid container direction="row" columns={{xs: 6, md: 4}}>
                      <Grid xs={3} md={2} pr={1}>
                        {!step.required ? (
                          <Button
                            fullWidth
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={handleNext}
                          >
                            <Trans i18nKey="skip">Skip</Trans>
                          </Button>
                        ) : null}
                      </Grid>
                      <Grid xs={3} md={2} pl={1}>
                        <LoadingButton
                          disabled={!verificationState.contactAddress.verified}
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="secondary"
                          loading={isSubmitting && verificationStatus == null}
                          loadingPosition="end"
                          endIcon={<></>}
                        >
                          <Trans i18nKey="verify">Verify</Trans>
                        </LoadingButton>
                      </Grid>
                      {verificationStatus === false && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: "light",
                            color: "red",
                            mt: 2,
                          }}
                        >
                          <Trans i18nKey="verificationFailed">
                            Verification failed&nbsp;
                          </Trans>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
