import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

export const useChangeLanguageParam = () => {
  const [language, setLanguage] = useState(useParams().lang || "en");
  const navigate = useNavigate();

  const changeLanguageParam = (path: string, code: string) => {
    const pathAsArray = path.split("/");
    pathAsArray.splice(pathAsArray.indexOf(language), 1, code);
    setLanguage(code);
    navigate(pathAsArray.join("/") + location.search);
  };

  return {changeLanguageParam, language};
};
