import {useAppSelector} from "../../store";
import React, {useMemo} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Button, Typography} from "@mui/material";
import Link from "../../common/components/link";
import ModalForm from "../../common/Modal/components/modalForm";
import {Trans} from "react-i18next";

export const useContinueVerificationModal = () => {
  const user = useAppSelector((state: any) => state.userState);

  const isUserAllowedForRequest = useMemo(() => {
    if (user) return user.paymentMethodConfirmed;
  }, [user]);

  const render = () => {
    return (
      <ModalForm open={!isUserAllowedForRequest}>
        <Grid px={{xs: 0, md: 2}}>
          <Typography
            data-testid="modal-modal-description"
            variant="subtitle1"
            component="h6"
          >
            <Trans i18nKey="verification.needPaymentMethodVerified">
              To continue creating Ship Request, please complete your
              registration by verifying your payment method.
            </Trans>
          </Typography>
          <Link to={`../verifications/`}>
            <Button
              data-testid="btn-ok-modal-modal"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{mt: 3}}
            >
              <Trans i18nKey="verification.continueVerification">
                Continue verification
              </Trans>
            </Button>
          </Link>
        </Grid>
      </ModalForm>
    );
  };

  return {render};
};
