import {useAppSelector} from "../../../store";
import {OrderDto, PurchaseBox} from "../../../features/order/order-api";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";

export const usePurchaseDetailsSubmit = () => {
  const userContactId = useAppSelector(
    (state: any) => state.userState.contactId,
  );

  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.createUpdatePurchaseWorkflowId,
  );

  const [runWorkflow, {isLoading, isError, isSuccess}] =
    useWorkflowExecutionMutation();

  const userId = useAppSelector((state: any) => state.userState.contactId);

  const onSubmit = async (values: OrderDto) => {
    if (values?.commodities?.length && values.commodities.length > 0) {
      for (let i = 0; i < values.commodities.length; i++) {
        values.commodities[i].pieces = 1;
      }
    }

    values.commodities = [
      PurchaseBox(values.trackingNumber ?? "", values.commodities) as any,
    ];

    values.billToContactId = userId;
    values.divisionId = process.env
      .REACT_APP_PORTAL_DIVISION_ID as unknown as number;
    values.organizationId = process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number;

    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: workflowId,
      values: {
        variables: {
          orderId: values?.orderId != 0 ? values?.orderId : null,
          order: values,
        },
      },
    };

    return await runWorkflow(executeWorkflowApiArgs);
  };

  return {
    onSubmit,
    isLoading,
    isError,
    isSuccess,
    userContactId,
  };
};
