import {useEffect} from "react";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
  TokenGetApiArg,
  useTokenGetMutation,
} from "../../features/auth/auth-api";
import {useLazyUserGetInfoQuery} from "../../features/user/user-api";
import {useAppSelector} from "../../store";
import {useVerificationSteps} from "../OrganizationConfig/useVerificationSteps";

export const useLoginSubmitHandler = () => {
  const {t} = useTranslation();

  const [getToken, {isLoading: isTokenLoading, isSuccess: isTokenSuccess}] =
    useTokenGetMutation();

  const [getUserInfo, {isFetching: isUserLoading, isSuccess: isUserSuccess}] =
    useLazyUserGetInfoQuery();

  const token = useAppSelector((state) => state.authState.access_token);

  const userState = useAppSelector((state) => state.userState);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("return_url");

  const {verified} = useVerificationSteps();

  useEffect(() => {
    if (isTokenSuccess && token) {
      getUserInfo();
    }
  }, [isTokenLoading, token, userState]);

  useEffect(() => {
    if (
      isUserSuccess &&
      !isUserLoading &&
      userState != null &&
      userState.contactId
    ) {
      toast.success(t("toasts.loginSuccess"));
      if (!verified) {
        navigate("../verifications", {
          replace: true,
        });
      } else if (returnUrl) {
        navigate(returnUrl, {replace: true});
      } else {
        navigate("../getting-started", {replace: true});
      }
    }
  }, [isUserLoading, userState]);

  const onGoogleSubmitHandler = (values: any) => {
    const commandArgs: TokenGetApiArg = {
      getTokenCommand: {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        divisionId: process.env
          .REACT_APP_PORTAL_DIVISION_ID as unknown as number,
        ...values,
      },
    };
    getToken(commandArgs);
  };

  const isLoginLoading = isTokenLoading && isUserLoading;

  return {getToken, onGoogleSubmitHandler, isLoginLoading};
};
