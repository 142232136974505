import * as yup from "yup";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const getEditAccountPhoneNumberSchema = (
  t: (text: string) => string,
  initialPhoneNumber: string,
) =>
  yup.object().shape({
    sendingType: yup
      .string()
      .required(t("validation.phoneVerification.requiredSendingType"))
      .oneOf(
        ["call", "sms"],
        t("validation.phoneVerification.invalidSendingType"),
      ),
    phoneNumber: yup
      .string()
      .required(t("validation.phoneVerification.requiredNumber"))
      .min(8, t("validation.phoneVerification.numberLengthMin"))
      .matches(phoneRegExp, t("profile.editPhoneNumber.phoneNumberIsNotValid"))
      .notOneOf(
        [initialPhoneNumber],
        t("validation.common.phoneNumberNotInitial"),
      ),
  });
