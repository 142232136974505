import React from "react";
import {Button, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Unstable_Grid2";

interface SuccessModalProps {
  setOpen: any;
  content: any;
}

export default function SuccessModal({setOpen, content}: SuccessModalProps) {
  const handleClose = () => setOpen(false);

  return (
    <Grid px={{xs: 0, md: 2}}>
      <CheckCircleIcon color={"primary"} sx={{fontSize: "56px"}} />
      <Typography
        data-testid="modal-modal-title"
        variant="subtitle1"
        component="h6"
        sx={{fontWeight: "bold"}}
      >
        {content}
      </Typography>
      <Button
        fullWidth
        type="button"
        variant="contained"
        color="secondary"
        sx={{mt: 3}}
        onClick={handleClose}
      >
        OK
      </Button>
    </Grid>
  );
}
