import React from "react";
import {Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {ForgotPasswordSubmit} from "./interfaces";
import {Trans} from "react-i18next";
import {t} from "i18next";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-mui";
import {getForgotPasswordSchema} from "../pages/validation";
import {ArrowBackIos} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import Link from "../../common/components/link";
import {ResetPassworValues} from "../pages";

const initialValues: ResetPassworValues = {
  username: "",
};

export default function ForgotPassword({
  onSubmit,
  notificationStatus,
}: ForgotPasswordSubmit) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container columns={{xs: 6, md: 6}} justifyContent="center">
      <Grid xs={6} md={4}>
        <Grid display={{xs: "none", md: "flex"}}>
          <Link to="../login">
            <ArrowBackIos sx={{mt: 3, color: "black"}} />
          </Link>
        </Grid>
        <Grid container direction="column">
          <Typography
            variant="h1"
            component="h1"
            sx={{fontWeight: "bold", mt: 3, mb: 1}}
          >
            <Trans i18nKey="forgotPassword">Forgot password?</Trans>
          </Typography>
          <Typography
            display={{xs: "none", md: "flex"}}
            variant="subtitle1"
            sx={{fontWeight: "light", color: "primary.dark", mb: 3}}
          >
            <Trans i18nKey="resetInstructions">
              No worries, we will sent you reset instructions.
            </Trans>
          </Typography>
          <Typography
            display={{xs: "flex", md: "none"}}
            variant="subtitle1"
            sx={{fontWeight: "light", color: "primary.dark", mb: 3}}
          >
            <Trans i18nKey="resetInstructionsMobile">
              No worries!
              <br /> We will sent you reset instructions.
            </Trans>
          </Typography>
        </Grid>
        <Formik
          onSubmit={(values: ResetPassworValues) => {
            onSubmit(values);
          }}
          initialValues={initialValues}
          validationSchema={getForgotPasswordSchema(t)}
        >
          {({isSubmitting}) => (
            <Form>
              <Field
                fullWidth
                component={TextField}
                label={t("email")}
                placeholder="example@example.ex"
                name="username"
                size={isDesktop ? "medium" : "small"}
                data-testid="input-email"
                autoComplete="username"
                disabled={
                  (isSubmitting && notificationStatus == null) ||
                  (isSubmitting && notificationStatus)
                }
              />
              {notificationStatus && (
                <Typography
                  variant="subtitle2"
                  sx={{fontWeight: "light", color: "primary.dark", mt: 2}}
                >
                  <Trans i18nKey="recoveryInstructions">
                    Recovery password instructions was sent to your email. Did
                    not receive an email? Please check your spam folder or try
                    to resend an email.&nbsp;
                  </Trans>
                </Typography>
              )}
              <Grid
                xs={6}
                sx={{
                  position: {xs: "fixed", md: "inherit"},
                  bottom: {xs: 0},
                  pr: {xs: 4, md: 0},
                }}
              >
                <LoadingButton
                  sx={{p: 1, mt: 6, mb: 2, height: "50px !important"}}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  data-testid="btn-submit"
                  disabled={isSubmitting && notificationStatus}
                  loading={isSubmitting && notificationStatus == null}
                  loadingPosition="end"
                  endIcon={<></>}
                >
                  <Trans i18nKey="resetPassword">Reset Password</Trans>
                </LoadingButton>
                <Button
                  fullWidth
                  component={Link}
                  sx={{
                    p: 1,
                    mb: 2,
                    display: {xs: "flex", md: "none"},
                  }}
                  type="button"
                  variant="text"
                  color="primary"
                  to={"../login" + location.search}
                >
                  <Trans i18nKey="backToLogin">Back to Login</Trans>
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
