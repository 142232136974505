import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {ReactComponent as Logo} from "../../../../images/TRTLogoWithYellow.svg";
import LanguageSelect from "../../LanguageSelect/components";
import {Box} from "@mui/material";

export default function Header({isAuth}: any) {
  return (
    <Grid container columns={{xs: 6, md: 6}} alignItems={"center"}>
      <Grid
        sx={{mt: 2}}
        xs={4}
        md={4}
        maxHeight={{xs: "90px", md: "90px"}}
        mdOffset={1}
      >
        <Logo height={50} width={64} fill="#3E80FF" />
      </Grid>
      <Grid container xs md justifyContent={{xs: "end", md: "start"}}>
        <LanguageSelect languageColor="primary.dark" />
      </Grid>
    </Grid>
  );
}
