import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {CircularProgress, Typography} from "@mui/material";
import i18next, {t} from "i18next";
import {getFormattedDate} from "../../../../utils/formatting.utils";
import {
  CommodityDto,
  EntityTypes,
  OrderDto,
  OrderEntityDto,
} from "../../../features/order/order-api";
import EditIcon from "@mui/icons-material/Edit";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {Trans} from "react-i18next";

export default function ConfirmationEditPackageStepCard({
  purchases,
  switchStepCallback,
}: {
  purchases: OrderDto[] | null;
  switchStepCallback: any;
}): JSX.Element {
  const renderSeller = (index: number) => {
    const seller = purchases?.[index]?.orderEntities?.find(
      (x: OrderEntityDto) => x.entityType == EntityTypes.Vendor,
    )?.contactName;
    return (
      <Grid display={"flex"} whiteSpace={"nowrap"}>
        {seller && (
          <>
            <Typography component={"span"} variant="caption1">
              {t("purchases.purchaseCard.seller")}
            </Typography>
            <Typography
              data-testid="seller-name"
              component={"span"}
              variant="caption1"
              color={"primary.dark"}
              textOverflow={"ellipsis"}
              ml={0.7}
              maxWidth={{sm: "50ch", xs: "20ch"}}
              overflow={"hidden"}
            >
              {seller}
            </Typography>
          </>
        )}
      </Grid>
    );
  };

  const getTotalDeclaredValue = (commoditiesData: CommodityDto[]) => {
    let sum = 0;
    commoditiesData.forEach((commodities: any) =>
      commodities.forEach((innerCommodities: any) => {
        sum += innerCommodities.containerCommodities.reduce(
          (acc: number, cur: CommodityDto) =>
            acc + (cur?.unitaryValue ?? 0) * (cur?.quantity ?? 0),
          0,
        );
        return sum;
      }),
    );
    return sum;
  };

  return (
    <Grid xs={6} md={13}>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        xs={6}
        md={13}
        columns={{md: 13, xs: 6}}
        sx={{
          border: "1px solid #D8E6FB",
          borderBottom: "0",
          borderRadius: {md: "16px 16px 0 0"},
        }}
        p={{md: 3, xs: 2}}
        pb={{md: 0}}
      >
        <Grid
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid mb={3}>
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Inter Medium",
                fontWeight: 500,
              }}
            >
              {t("consolidatedShipment.itemsInYourPackage")}
            </Typography>
          </Grid>

          <Grid>
            <EditIcon
              sx={{
                fontSize: "24px",
                color: "primary.dark",
                cursor: "pointer",
              }}
              onClick={switchStepCallback}
              data-testid={`btn-confirmation-edit-package`}
            />
          </Grid>
        </Grid>
        {purchases && purchases.length ? (
          purchases.map((purchase, index) => {
            return (
              <>
                <Grid display={"flex"} flexDirection={"row"} mb={3}>
                  <Grid xs={6} md={4} pr={{md: 2, xs: 0}}>
                    {index === 0 && (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontFamily: "Inter Regular",
                          fontWeight: 600,
                          mb: 2,
                        }}
                      >
                        {t("purchasesInfo")}
                      </Typography>
                    )}
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      flexWrap={"nowrap"}
                      justifyContent={"space-between"}
                    >
                      <Grid mr={1} mb={1} container>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Inter Regular",
                            fontWeight: 600,
                          }}
                        >
                          {t("purchase")}:&nbsp;
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Inter Regular",
                            fontWeight: 600,
                          }}
                          color="primary"
                          display={"inline-block"}
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                        >
                          {purchase?.trackingNumber ?? ""}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography component={"span"} variant="caption1">
                        {t("purchases.purchaseCard.status")}
                      </Typography>
                      <Typography
                        data-testid="order-status"
                        component={"span"}
                        variant="caption1"
                        color={"primary.dark"}
                      >
                        {purchase?.orderStatus?.customValues &&
                          purchase?.orderStatus?.customValues[
                            `portal_name_${i18next.language}`
                          ] &&
                          t(
                            purchase?.orderStatus?.customValues[
                              `portal_name_${i18next.language}`
                            ],
                          )}
                      </Typography>
                    </Grid>
                    {renderSeller(index)}
                    <Grid>
                      <Typography component={"span"} variant="caption1">
                        {t("purchases.purchaseCard.created")}
                      </Typography>
                      <Typography
                        data-testid="conformation-purchase-created-date"
                        component={"span"}
                        variant="caption1"
                        color={"primary.dark"}
                      >
                        {getFormattedDate(
                          new Date(purchase?.created?.toString() ?? ""),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={6}
                    md={9}
                    pl={{md: 2, xs: 0}}
                    sx={{
                      borderLeft: {md: "1px solid #D8E6FB", xs: "none"},
                      height: "available",
                    }}
                  >
                    <Grid container flexDirection={"column"}>
                      {index === 0 && (
                        <Grid container mb={2}>
                          <Grid md={6}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "Inter Regular",
                                fontWeight: 600,
                              }}
                            >
                              {t("delivery.confirmation.items")}
                            </Typography>
                          </Grid>
                          <Grid md={5}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "Inter Regular",
                                fontWeight: 600,
                              }}
                            >
                              {t("delivery.confirmation.quantity")}
                            </Typography>
                          </Grid>
                          <Grid md={1} textAlign={"right"}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "Inter Regular",
                                fontWeight: 600,
                              }}
                            >
                              {t("delivery.confirmation.value")}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {purchase?.commodities?.length &&
                      purchase?.commodities[0].containerCommodities &&
                      purchase?.commodities[0].containerCommodities.map(
                        (
                          commodity: CommodityDto,
                          index: number,
                          commodities: CommodityDto[],
                        ) => {
                          return (
                            <Grid
                              container
                              mb={index !== commodities.length - 1 ? 2 : 0}
                              key={`commodity-${index}`}
                            >
                              <Grid md={6}>
                                <Typography variant={"body1"}>
                                  {commodity.description}
                                </Typography>
                              </Grid>
                              <Grid md={5}>
                                <Typography variant={"body1"}>
                                  {commodity.quantity}
                                </Typography>
                              </Grid>
                              <Grid md={1} textAlign={"right"}>
                                <Typography variant={"body1"}>
                                  {getFormattedPrice(commodity.unitaryValue)}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        },
                      )}
                  </Grid>
                </Grid>
              </>
            );
          })
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <Grid
        xs={12}
        container
        sx={{
          background: {
            md: "#f5f9ff",
            xs: "#D8E6FB",
          },
          paddingX: 3,
          borderRadius: {
            md: "0 0 16px 16px",
            xs: "0",
          },
          marginX: {
            md: 0,
            xs: -2,
          },
          paddingY: {xs: 2, md: 2},
          typography: "h2",
          alignItems: "center",
          justifyContent: "right",
          border: "1px solid #D8E6FB",
          borderTop: "0",
        }}
      >
        <Grid
          component="span"
          sx={{
            background: "inherit",
            zIndex: 1,
            paddingRight: "5px",
          }}
        >
          <Trans i18nKey="declaredValue">Declared Value</Trans>:
        </Grid>
        <Grid
          data-testid="total-declared-value"
          component="span"
          id="index"
          sx={{
            background: "inherit",
            zIndex: 1,
            paddingLeft: "2px",
          }}
        >
          {purchases &&
            getFormattedPrice(
              getTotalDeclaredValue(
                purchases.map((item: any) => item.commodities),
              ),
            )}
        </Grid>
      </Grid>
    </Grid>
  );
}
