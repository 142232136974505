import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {Trans} from "react-i18next";
import NotVerifiedIcon from "@mui/icons-material/NewReleases";
import VerifiedIcon from "@mui/icons-material/Verified";
import {ReactComponent as NotVerifiedWhiteIcon} from "../../../images/NotVerifiedWhiteIcon.svg";
import {ReactComponent as VerifiedWhiteIcon} from "../../../images/VerifiedWhiteIcon.svg";
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {useAppSelector} from "../../store";
import defaultUserPicture from "../../../images/photo-none.jpg";
import {logout} from "../../features/auth/auth-slice";
import {
  logout as userLogout,
  setBillingAddress,
} from "../../features/user/user-slice";
import {setInitialVerificationState} from "../../features/verification/verification-slice";
import AddressBox, {getAddressElement} from "./addressBox";
import {getAddresses} from "../data/addresses";
import {useVerificationSteps} from "../OrganizationConfig/useVerificationSteps";
import {getConsigneeLocation} from "../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";
import i18next, {t} from "i18next";
import {toastError} from "../utils/toastMessages";
import {
  ContactGetContactApiArg,
  useContactGetContactMutation,
} from "../../features/contact/contact-api";

interface DropdownItem {
  label: string;
  i18nKey: string;
  link: string;
}

const DROPDOWN_ITEMS: DropdownItem[] = [
  {
    label: "Complete verification",
    i18nKey: "profile.tabs.completeVerification",
    link: "../verifications",
  },
  {
    label: "My account profile",
    i18nKey: "profile.tabs.accountProfile",
    link: "../profile",
  },
  {
    label: "My saved addresses",
    i18nKey: "profile.tabs.savedAddresses",
    link: "../profile/savedAddresses",
  },
  {
    label: "Payment methods",
    i18nKey: "profile.tabs.paymentMethods",
    link: "../profile/paymentMethods",
  },
  {
    label: "Change password",
    i18nKey: "profile.tabs.changePassword",
    link: "../profile/changePassword",
  },
];

type ProfileMenuProps = {
  showPaymentMethod?: boolean;
};

export default function ProfileMenu({showPaymentMethod}: ProfileMenuProps) {
  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);

  const userState = useAppSelector((state) => state.userState);
  const isUserLogged = !!userState?.contactId;
  const addresses = getAddresses();

  const showBillingAddress = useAppSelector(
    (state) =>
      state?.organizationConfigState?.modules?.profile?.showBillingAddress,
  );
  const [billingAddressLines, setBillingAddressLines] = useState<string[]>([]);

  const {fullyVerified} = useVerificationSteps();

  const handleToggleMenu = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(userLogout());
    dispatch(setInitialVerificationState());
    navigate("/");
  };

  const [getContact] = useContactGetContactMutation();

  const getContactAsync = async (request: number | null | undefined) => {
    const commandArgs: ContactGetContactApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactId: request || 0,
    };
    return getContact(commandArgs);
  };

  const getUserBillingAddress = (): any => {
    let address = userState.billingAddress;

    if (!address) {
      getContactAsync(userState?.contactId)
        .then((response: any) => {
          if (response.data) {
            const billingAddress =
              response?.data?.contactAddresses?.find(
                (address: any) => address.addressType === "Billing",
              ) ?? null;
            if (billingAddress) {
              address = billingAddress;
              dispatch(setBillingAddress(billingAddress));
            }
          }
        })
        .catch((error) => {
          toastError(error);
        });
    }

    if (address)
      getConsigneeLocation(address, i18next.language, t).then((result) => {
        setBillingAddressLines([
          [
            address?.contact?.contactFirstName,
            address?.contact?.contactLastName,
          ].join(" "),
          address?.contact?.phoneNumber ?? "",
          ...result.filter(
            (value) => value && value.length && value.length > 0,
          ),
        ]);
      });
  };

  useEffect(() => {
    if (showBillingAddress) getUserBillingAddress();
  }, [userState]);

  return isUserLogged ? (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          borderRadius: "8px 8px 0 0",
          zIndex: "100",
          marginLeft: isLargeDesktop ? "0" : "30px",
        }}
      >
        <Button
          sx={{
            zIndex: 2,
            padding: "auto",
            cursor: "pointer",
            transition: "color 0.3s ease",
            borderBottom: "none",
            height: "fit-content",
            "&:hover": {
              backgroundColor: "initial",
            },
          }}
          disableRipple
          onClick={handleToggleMenu}
        >
          <Box
            sx={{
              position: "relative",
              width: "45px",
              height: "45px",
              minWidth: "45px",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "10px",
                top: "10px",
                width: "25px",
                height: "25px",
                minWidth: "25px",
                borderRadius: "50%",
              }}
            >
              <img
                src={defaultUserPicture}
                alt={"user picture"}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                }}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                right: "0px",
                bottom: "0px",
                width: "20px",
                height: "20px",
                minWidth: "20px",
              }}
            >
              <Typography fontSize="20px" lineHeight="20px">
                {fullyVerified ? (
                  <VerifiedWhiteIcon fontSize="inherit" />
                ) : (
                  <NotVerifiedWhiteIcon fontSize="inherit" />
                )}
              </Typography>
            </Box>
          </Box>
          <Typography
            component={"div"}
            variant={"button"}
            sx={{
              color: "primary.white",
              transition: "color 0.3s ease",
              marginLeft: "8px",
              whiteSpace: isOpen ? "initial" : "nowrap",
              lineBreak: isOpen ? "anywhere" : "initial",
              textTransform: "none",
            }}
          >
            <Box
              sx={{
                flexWrap: isOpen ? "wrap" : "initial",
                columnGap: "4px",
                lineHeight: "120%",
                textAlign: "start",
                width: isOpen ? "clamp(115px, 125px, 135px)" : "fit-content",
                textTransform: "none",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {userState?.firstName && userState?.lastName ? (
                  <>
                    <Box
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "10ch",
                      }}
                    >
                      {isLargeDesktop || userState.firstName.length === 1
                        ? userState.firstName
                        : userState.firstName[0] + "."}
                    </Box>
                    &nbsp;
                    <Box>
                      {userState.lastName.length === 1
                        ? userState.lastName
                        : userState.lastName[0] + "."}
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      maxWidth: isLargeDesktop ? "10ch" : "5ch",
                      overflow: "hidden",
                      textTransform: "none",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {userState?.email || "User"}
                  </Box>
                )}
              </Box>
              <Typography variant="caption3">
                {userState?.accountNumber ? (
                  `Suite: ${userState?.accountNumber}`
                ) : (
                  <Trans i18nKey="noAccountNumber">No account number</Trans>
                )}
              </Typography>
            </Box>
          </Typography>
        </Button>
        <Box
          sx={{
            zIndex: 2,
            position: "absolute",
            backgroundColor: "primary.white",
            minWidth: "max-content",
            overflow: "visible",
            borderRadius: "8px 8px 8px 8px",
            border: "1px solid #D8E6FB",
            display: isOpen ? "flex" : "none",
            boxShadow: isOpen ? "0px 5px 8px rgba(15, 74, 132, 0.1)" : "none",
            flexDirection: "row",
            right: 0,
            a: {
              color: "black",
              textDecoration: "none",
              display: "block",
              transition: "color 0.3s ease",
            },
            "a:hover": {
              backgroundColor: "#f1f1f1",
            },
            "a:hover .MuiTypography-root": {
              color: "#252631",
            },

            "> a": {
              paddingY: "3px",
            },
          }}
        >
          {showBillingAddress && userState?.contactAddressConfirmed ? (
            <Box sx={{padding: "16px", alignItems: "center"}}>
              <Box>
                <Typography
                  sx={{
                    color: "#3E80FF",
                  }}
                  variant={"h3"}
                >
                  <Trans i18nKey="profile.menu.billingAddress">
                    Billing address
                  </Trans>
                </Typography>
              </Box>{" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  lineBreak: "anywhere",
                  marginTop: 1,
                  alignItems: "flex-start",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #D8E6FB",
                    boxShadow: "0px 0px 8px 0px #0F4A841A",
                    backgroundColor: "white",
                    borderRadius: "16px",
                    paddingY: "1rem",
                    paddingX: "1rem",
                    minWidth: "320px",
                  }}
                  width={"100%"}
                  justifyContent={"start"}
                >
                  <Grid container direction={"column"}>
                    {billingAddressLines?.map((addressLine: any) => {
                      return getAddressElement(addressLine);
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>
          ) : null}
          {!showBillingAddress ? (
            <Box sx={{padding: "16px", alignItems: "center"}}>
              <Box>
                <Typography
                  sx={{
                    color: "#3E80FF",
                  }}
                  variant={"h3"}
                >
                  <Trans i18nKey="yourUSAddresses">Your US addresses</Trans>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  lineBreak: "anywhere",
                  marginTop: 1,
                  alignItems: "flex-start",
                  gap: "16px",
                }}
              >
                {addresses.map((address, index) => {
                  return <AddressBox address={address} key={index} />;
                })}
              </Box>
            </Box>
          ) : null}
          <Divider
            sx={{
              paddingX: 0,
              marginTop: "16px",
              marginBottom: "16px",
              borderColor: "#D8E6FB",
              borderRightWidth: "1px",
            }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Box sx={{paddingX: "16px", display: "block"}}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                columnGap: "6px",
                padding: "16px 3px",
              }}
            >
              {fullyVerified ? (
                <>
                  <VerifiedIcon fontSize="small" color="success" />
                  <Typography
                    variant="caption3"
                    color="success.main"
                    lineHeight="18px"
                  >
                    <Trans i18nKey="verified">Verified</Trans>
                  </Typography>
                </>
              ) : (
                <>
                  <NotVerifiedIcon fontSize="small" color="error" />
                  <Typography
                    variant="caption3"
                    color="error.main"
                    lineHeight="18px"
                  >
                    <Trans i18nKey="notVerified">Not Verified</Trans>
                  </Typography>
                </>
              )}
            </Box>
            {DROPDOWN_ITEMS.map(
              ({label, link, i18nKey}, index) =>
                (!label.toLowerCase().includes("payment") ||
                  (label.toLowerCase().includes("payment") &&
                    showPaymentMethod)) && (
                  <NavLink
                    key={label}
                    to={link}
                    data-testid={`profile-dropdown-${i18nKey}`}
                    onClick={handleCloseMenu}
                  >
                    <Box sx={{marginBottom: "5px"}}>
                      <Typography
                        variant="button"
                        sx={{
                          color: index === 0 ? "error.main" : "primary.dark",
                          textTransform: "none",
                          fontSize: "13px",
                          lineHeight: "22px",
                        }}
                      >
                        <Trans i18nKey={i18nKey}>{label}</Trans>
                      </Typography>
                    </Box>
                  </NavLink>
                ),
            ).slice(fullyVerified ? 1 : 0)}
            <Box
              sx={{
                paddingBottom: "8px",
                margin: "0 auto 8px",
                cursor: "pointer",
                textTransform: "none",
                width: "100%",
                ":hover": {
                  backgroundColor: "#2F81EF20",
                },
              }}
              onClick={handleLogout}
            >
              <Typography
                variant="button"
                sx={{
                  color: "#2F81EF",
                  textTransform: "none",
                  fontSize: "13px",
                  lineHeight: "22px",
                }}
              >
                <Trans i18nKey={"profile.menu.logout"}>Log Out</Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  ) : (
    <NavLink
      style={{
        color: "#2F81EF",
        textDecoration: "none",
        marginLeft: isLargeDesktop ? "0" : "40px",
      }}
      to={"/"}
    >
      <Typography
        sx={{
          textTransform: "uppercase",
          ":hover": {
            textDecoration: "underline",
          },
        }}
        variant="button"
      >
        <Trans i18nKey={"profile.menu.login"}>Log In</Trans>
      </Typography>
    </NavLink>
  );
}
