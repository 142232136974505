import {useLocation} from "react-router-dom";
import {
  AttachmentDto,
  AttachmentDtoPagedResult,
  S3UriTypes,
} from "../modules/features/attachment/attachment-api";
import {IDeliveryMethod} from "../modules/Delivery/DeliveryMethod/components/interfaces";
import {RatePerType} from "../modules/features/deliveryMethod/deliveryMethod-api";
import {getFormattedRatesRange} from "./formatting.utils";
import {getFormattedPrice} from "./formatCurrency.utils";
import {
  ChargeGqlDto,
  CreateChargeCommand,
  GroupedCharges,
  ICustomValuesGQL,
} from "../modules/features/order/order-api";

export type QuerySearchParams = {
  limit?: number;
  offset?: number;
  sort?: string | undefined;
  filter?: string | undefined;
  search?: string | undefined;
};
export const useQuery = (): any => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  return Object.fromEntries(urlSearchParams.entries());
};

const getGroupChargeTestId = (groupName: string | null | undefined) => {
  if (
    groupName &&
    ["Shipping", "First Mile Shipping", "Insurance", "Discount"].includes(
      groupName,
    )
  ) {
    if (groupName.includes("Shipping")) groupName = "Shipping";
    return `${groupName.toLowerCase()}-cost`;
  } else if (groupName) {
    return "approved-additional-services-cost";
  } else return "charge-cost";
};

export const groupChargesByName = (charges: ChargeGqlDto[], lang: string) => {
  const groups: GroupedCharges[] = [];

  charges.forEach((charge) => {
    const nameCharge =
      (charge.accountingItem?.customValues &&
      `service_description_${lang}` in charge.accountingItem.customValues
        ? charge.accountingItem?.customValues[`service_description_${lang}`]
        : null) ??
      charge.description ??
      "";

    let group = groups.find((g) => g.name === nameCharge);
    if (!group) {
      group = {
        name: nameCharge,
        charges: [],
        totalPrice: 0,
        totalAmount: 0,
        totalQuantity: 0,
        unit: "",
        estimatedCost: "",
        testId: getGroupChargeTestId(charge?.description),
      };
      groups.push(group);
    }

    group.charges.push(charge);
    group.totalPrice += charge.price ?? 0;
    group.totalAmount += charge.totalAmount ?? charge.amount ?? 0;
    group.totalQuantity += charge.quantity ?? 0;
    group.unit = charge.unit ?? "";
    if (charge?.rate?.estimatedCost)
      group.estimatedCost = charge?.rate?.estimatedCost;
  });

  return groups;
};

export const getAttachmentsLinks = (
  attachments: AttachmentDtoPagedResult,
  token?: string,
): AttachmentDtoPagedResult => {
  const newAttachments = {...attachments};
  newAttachments.items = attachments.items?.map((item) => {
    return getAttachmentLinks(item, token);
  });
  return newAttachments;
};

export const getAttachmentLinks = (
  attachment: AttachmentDto,
  token?: string,
): AttachmentDto => {
  const newItem = {...attachment};
  let url = `${process.env.REACT_APP_API_ENDPOINT}/api/organizations/${attachment.organizationId}/attachments/${attachment.attachmentId}/getAttachmentContent?uriType=${S3UriTypes.FileUri}&authorization=Bearer ${token}`;
  newItem.fileUri = url;
  url = `${process.env.REACT_APP_API_ENDPOINT}/api/organizations/${attachment.organizationId}/attachments/${attachment.attachmentId}/getAttachmentContent?uriType=${S3UriTypes.PreviewUri}&authorization=Bearer ${token}`;
  newItem.previewUri = url;
  url = `${process.env.REACT_APP_API_ENDPOINT}/api/organizations/${attachment.organizationId}/attachments/${attachment.attachmentId}/getAttachmentContent?uriType=${S3UriTypes.ThumbnailUri}&authorization=Bearer ${token}`;
  newItem.thumbnailUri = url;
  return newItem;
};

export const getPascalizeCountryName = (
  countryName?: string | null,
): string => {
  if (countryName) {
    const indexOfParenthesis = countryName.indexOf("(");
    if (indexOfParenthesis !== -1) {
      countryName = countryName.slice(0, indexOfParenthesis).trimEnd();
    }
    if (countryName.includes(" ")) {
      const countryNameParts = countryName.split(" ");
      countryName = countryNameParts
        .map((el) => {
          return el.slice(0, 1).toUpperCase() + el.slice(1).toLowerCase();
        })
        .join("");
    }
    return countryName;
  }
  return "";
};

export const stageColor = (stageColor?: string) => {
  const color = stageColor ?? "#000000";
  const backgroundColor = color + "25";
  return {
    backgroundColor,
    color,
  };
};

export const getDeliveryPrice = (
  deliveryMethod: IDeliveryMethod | null | undefined,
  t: any,
  showTotalCost?: boolean,
  charges?: CreateChargeCommand[] | null | undefined,
) => {
  if (!showTotalCost) {
    const tariff = deliveryMethod?.tariff;
    const unitType = t(
      `deliveryMethod${
        deliveryMethod?.unitType ?? deliveryMethod?.tariff?.unitType
      }`,
    );
    if (
      tariff &&
      tariff.ratePerType === RatePerType.Range &&
      tariff?.rateData
    ) {
      if (tariff.rateData.length > 1) {
        return getFormattedRatesRange(tariff, t);
      } else {
        return `${getFormattedPrice(tariff.rateData[0]?.rateValue)}`;
      }
    }
    if (tariff && tariff.ratePerType === RatePerType.Unit && tariff.rateData) {
      if (tariff.rateData.length > 1) {
        return getFormattedRatesRange(tariff, t, unitType);
      } else {
        return `${getFormattedPrice(
          tariff.rateData[0]?.rateValue,
        )} ${unitType}`;
      }
    }
  } else {
    if (charges) {
      const shippingCharges = charges?.filter(
        (x) => x.values?.accountingItemName?.toLowerCase() === "shipping",
      );

      let total = 0;

      if (shippingCharges && shippingCharges.length > 0) {
        shippingCharges.forEach(
          (charge) => (total += charge.values?.totalAmount ?? 0),
        );
      }
      return `${getFormattedPrice(total)}`;
    } else {
      return `${getFormattedPrice(deliveryMethod?.totalAmount)}`;
    }
  }
  return null;
};

export const destructurizeGQLCustomValues = (
  customValues: ICustomValuesGQL[],
) => {
  let newCustomValues = {};
  customValues.forEach((x) => {
    newCustomValues = {...newCustomValues, ...{[x.key]: x.value}};
  });
  return newCustomValues;
};

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const buildJobTypeFilter = (jobTypes: string[]) => {
  if (!jobTypes || jobTypes.length === 0) return "";
  return ` AND (Jobs.CustomValues.jobType:${jobTypes.join(
    " OR Jobs.CustomValues.jobType:",
  )})`;
};

type StringObject = {[key: string]: string};
type KeyValueArray = {key: string; value: string}[];

function isStringObject(obj: any): obj is StringObject {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  for (const key in obj) {
    if (typeof obj[key] !== "string") {
      return false;
    }
  }

  return true;
}

function isKeyValueArray(obj: any): obj is KeyValueArray {
  if (!Array.isArray(obj)) {
    return false;
  }

  for (const item of obj) {
    if (
      typeof item !== "object" ||
      item === null ||
      !("key" in item) ||
      !("value" in item) ||
      typeof item.key !== "string"
    ) {
      return false;
    }
  }

  return true;
}

export const getCustomValue = (
  customValues: StringObject | KeyValueArray | null | undefined,
  key: string | null | undefined,
) => {
  if (customValues && key) {
    if (isStringObject(customValues)) {
      return customValues[key];
    } else if (isKeyValueArray(customValues)) {
      const item = customValues.find((item) => item.key === key);
      return item?.value;
    }
  }
  return null;
};
