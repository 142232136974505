import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CircularProgress, Stack, Typography} from "@mui/material";
import AdditionalServiceCard from "./additionalServiceCard.component";
import {
  AccountingItemDto,
  AccountingItemsGetAdditionalServicesApiArg,
  useAccountingItemsGetAdditionalServicesQuery,
} from "../../../features/accountingItem/accountingItems-api";
import Grid from "@mui/material/Unstable_Grid2";
import {AdditionalServicesListProps} from "./interfaces";
import {toastError} from "../../../common/utils/toastMessages";
import {getLangCodeFromI18n} from "../../../app/app.component";

const AdditionalServicesList = ({
  isLoadingCurrency,
  setAdditionalServices,
  insuranceAccountingItemCode,
}: AdditionalServicesListProps) => {
  const {t} = useTranslation();

  const [accountingItems, setAccountingItems] = useState<
    AccountingItemDto[] | undefined
  >([]);

  const args: AccountingItemsGetAdditionalServicesApiArg = {
    organizationId: process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
  };

  const {
    data: servicesData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useAccountingItemsGetAdditionalServicesQuery(args);

  const lang = getLangCodeFromI18n();

  useEffect(() => {
    if (isSuccess) {
      setAccountingItems(servicesData);
    }
    if (isError) {
      toastError(error);
    }
  }, [isLoading]);

  return (
    <>
      {!accountingItems?.length && !isLoading && !isLoadingCurrency && (
        <Grid sx={{display: "flex", justifyContent: "start", mt: 2}}>
          <Typography variant="body4">
            {t("delivery.additionalServices.noServicesYet")}
          </Typography>
        </Grid>
      )}
      {isLoading && isLoadingCurrency && (
        <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
          <CircularProgress />
        </Grid>
      )}
      <Stack spacing={{xs: 1, md: 2}}>
        {accountingItems?.map((data: any, index: number) => (
          <AdditionalServiceCard
            data-testid={`additional-service-card-${index}`}
            key={data.accountingItemId}
            index={index}
            serviceData={data}
            setAdditionalServices={setAdditionalServices}
            insuranceAccountingItemCode={insuranceAccountingItemCode}
          >
            {lang == "en" && data.customValues.service_description_en}
            {lang == "ru" && data.customValues.service_description_ru}
            {lang == "ua" && data.customValues.service_description_ua}
          </AdditionalServiceCard>
        ))}
      </Stack>
    </>
  );
};

export default AdditionalServicesList;
