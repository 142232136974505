import {useState} from "react";
import {useWorkflowExecutionMutation} from "../../features/workflowTriggers/workflowExecution-api";

export const useForgotPasswordSubmit = () => {
  const [notificationStatus, setNotificationStatus] = useState<any>(null);
  const [runWorkflow, {isLoading, isError, error, isSuccess}] =
    useWorkflowExecutionMutation();

  return {
    notificationStatus,
    setNotificationStatus,
    runWorkflow,
    isLoading,
    isError,
    error,
    isSuccess,
  };
};
