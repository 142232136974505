import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import LoginBackground from "../../components/loginBackground";
import Header from "../../headers/components/header";
import {Helmet} from "react-helmet";

type SplitPageProps = {
  contentSide: string;
  main: any;
  pageTitle: any;
  backside?: any;
  background?: string;
};
export default function SplitPage({
  contentSide,
  main,
  pageTitle,
  backside,
  background = "#ffffff",
}: SplitPageProps) {
  return (
    <Grid
      container
      columns={{xs: 6, md: 14}}
      sx={{
        height: "100vh",
        width: "100%",
        background: `${background} !important`,
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {contentSide == "left" && (
        <>
          <Grid xs={6} md={6}>
            <Grid container direction={"column"} justifyContent="space-between">
              <Grid sx={{px: {xs: 2, md: 0}}}>
                <Header isAuth={false} />
              </Grid>
            </Grid>
            <Grid sx={{px: {xs: 2, md: 0}}}>{main}</Grid>
          </Grid>
          <LoginBackground />
        </>
      )}
      {contentSide == "leftCentered" && (
        <>
          <Grid
            display="flex"
            flexDirection="column"
            justifyContent={"space-between"}
            xs={6}
            md={6}
            sx={{px: {xs: 2, md: 0}}}
          >
            <Grid>
              <Header isAuth={false} />
            </Grid>
            <Grid height={"100%"}>{main}</Grid>
            <Grid />
          </Grid>
          <LoginBackground />
        </>
      )}
      {contentSide == "right" && (
        <>
          <Grid
            md={6}
            sx={{
              display: {xs: "none", md: "block"},
              paddingBottom: "73px",
              backgroundColor: "#F5F9FF",
            }}
          >
            <Grid
              container
              direction={"column"}
              justifyContent={"space-between"}
              sx={{height: "100%"}}
            >
              <Header isAuth={true} />
              {backside}
            </Grid>
          </Grid>
          {main}
        </>
      )}
    </Grid>
  );
}
