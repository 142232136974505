import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {LinearProgress, Typography} from "@mui/material";
import {LinearProgressStepProps} from "./interfaces";
import VerifiedIcon from "@mui/icons-material/Verified";

export default function LinearProgressStep({
  stepIndex,
  steps,
}: LinearProgressStepProps) {
  let columns = 0;
  const positions = steps?.map((val) => (columns += 1));

  const items = steps?.map((s, i) => {
    return (
      <Grid key={i}>
        <Grid container alignItems={"center"}>
          <Typography
            variant={"caption2"}
            color={i <= stepIndex ? "black" : "gray"}
            align={"center"}
          >
            {s.text}
          </Typography>
          {s.checked && <VerifiedIcon sx={{marginLeft: 1}} color={"success"} />}
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid>
      <LinearProgress
        value={(100 / columns) * positions[stepIndex]}
        variant={"determinate"}
      />
      <Grid container columns={columns} justifyContent="space-between">
        {items}
      </Grid>
    </Grid>
  );
}
