import {OrderDto} from "../../../features/order/order-api";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {useAppSelector} from "../../../store";

export const usePurchaseUpdate = (orderId: number) => {
  const [
    runWorkflow,
    {
      isLoading: isUpdateProcess,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useWorkflowExecutionMutation();
  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.createUpdatePurchaseWorkflowId,
  );

  const userId = useAppSelector((state: any) => state.userState.contactId);

  const onUpdate = async (values: OrderDto) => {
    const updatedCommodities = values.commodities?.map((el: any) => {
      const isNewCommodity = isNaN(el?.uuid);
      return {
        description: el?.description,
        note: el?.note,
        quantity: el?.quantity,
        unitaryValue: el?.unitaryValue,
        commodityTypeId: el?.commodityTypeId,
        commodityId: isNewCommodity ? null : el.uuid,
        weight: el?.weight,
        customValues: el?.customValues,
        weightUnit: el?.weightUnit,
        pieces: 1,
        unit: isNewCommodity ? 1 : el?.unit,
        isRemoved: el?.isRemoved,
      };
    });

    values.divisionId = process.env
      .REACT_APP_PORTAL_DIVISION_ID as unknown as number;
    values.organizationId = process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number;
    values.billToContactId = userId;
    values.commodities = updatedCommodities;

    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: workflowId,
      values: {
        variables: {
          orderId: orderId != 0 ? orderId : null,
          order: values,
        },
      },
    };

    return await runWorkflow(executeWorkflowApiArgs);
  };

  return {
    onUpdate,
    isUpdateProcess,
    isUpdateError,
    isUpdateSuccess,
  };
};
