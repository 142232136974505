import React, {useEffect, useState} from "react";
import {
  Box,
  Checkbox,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {AdditionalServiceCardProps} from "./interfaces";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";

const boxStyle = {
  backgroundColor: "white",
  border: "1px solid #D8E6FB",
  borderRadius: "16px",
  boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
  py: {xs: 1, md: 2},
  px: {xs: 1, md: 0},
  cursor: "pointer",
  "&:hover": {
    md: {
      backgroundColor: "#D8E6FB",
      borderColor: "transparent",
      "& .additionalServiceCost": {
        color: "#205A9C",
      },
    },
  },
};

const AdditionalServiceCard = ({
  children,
  index,
  serviceData,
  setAdditionalServices,
  insuranceAccountingItemCode,
}: AdditionalServiceCardProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const {currentOrder} = useDeliveryHelper();

  const [serviceState, setServiceState] = useState<boolean>(
    currentOrder?.charges?.some(
      (charge: any) =>
        charge?.values?.accountingItemId === serviceData.accountingItemId,
    ),
  );

  const handleChange = () => {
    setServiceState((prev) => !prev);
  };

  useEffect(() => {
    if (serviceState === true) {
      setAdditionalServices((services: any) => {
        if (serviceData.itemCode === insuranceAccountingItemCode) {
          services[serviceData.itemCode] = serviceData.price;
        } else {
          services[serviceData.itemCode] = 1;
        }
        return {...services};
      });
    }
    if (serviceState === false) {
      setAdditionalServices((services: any) => {
        delete services[serviceData.itemCode];
        return {...services};
      });
    }
  }, [serviceState]);

  return (
    <Box sx={boxStyle} onClick={handleChange}>
      <Grid
        container
        columns={{xs: 10, md: 12}}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid xs={1} md={1}>
          <Grid container justifyContent="center" pl={{xs: 1, md: 4}}>
            <Checkbox
              sx={{
                color: "primary.light",
                mb: "2px",
                ml: {xs: 2, md: 0},
                mr: 3,
              }}
              id={`checkbox-service-state-${index}`}
              data-testid={`checkbox-service-state-${index}`}
              checked={serviceState}
            />
          </Grid>
        </Grid>
        <Grid xs={9} md={8}>
          <Typography
            variant="body1"
            sx={{textAlign: "start", userSelect: "none", lineHeight: "25px"}}
          >
            {children}&nbsp;
            {!isDesktop && (
              <Typography
                variant="body4"
                sx={{
                  color: "primary.main",
                  userSelect: "none",
                }}
              >
                -&nbsp;{getFormattedPrice(serviceData.price)}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid md />
        {isDesktop && (
          <Grid
            md={2}
            container
            justifyContent="flex-end"
            sx={{paddingRight: "20px"}}
          >
            <Typography
              className="additionalServiceCost"
              variant="body4"
              sx={{
                color: "primary.main",
                userSelect: "none",
              }}
            >
              {getFormattedPrice(serviceData.price)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AdditionalServiceCard;
