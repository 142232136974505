import React, {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {Trans, useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {
  Button,
  FormControl,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Field, Form, Formik, FormikProps} from "formik";
import {TextField} from "formik-mui";
import {SubmitHandler} from "react-hook-form";
import {
  useVerificationVerifyAddressMutation,
  VerificationVerifyAddressApiArg,
  VerifyAddressCommandValues,
} from "../../features/verification/verification-api";
import {getAddressSchema} from "../pages/validation";
import {VerificationStepSubmit} from "./interfaces";
import {useAppSelector} from "../../store";
import {
  GetStatesQueryValues,
  StateDto,
  StatesGetApiArg,
  useStatesGetMutation,
} from "../../features/states/states-api";
import Autocomplete from "@mui/material/Autocomplete";
import {useDispatch} from "react-redux";
import {setNameSurname} from "../../features/user/user-slice";
import {
  setContactAddress,
  verifyContactAddress,
} from "../../features/verification/verification-slice";
import {getLangCodeFromI18n} from "../../app/app.component";
import {
  UpdateUserInfoCommand,
  useUserUpdateInfoMutation,
} from "../../features/user/user-api";
import {toastError} from "../../common/utils/toastMessages";
import {
  UpdateContactAddressApiArg,
  useUpdateContactAddressMutation,
} from "../../features/contactAddress/contactAddress-api";
import {
  CountryFilter,
  useGetCountries,
} from "../../common/hooks/useGetCountries";
import {
  getValidationSchemaRequiredFields,
  useFormRequiredFields,
} from "../../common/hooks/useFormRequiredFields";

type ContactAddressFieldName =
  | "firstName"
  | "lastName"
  | "countryCode"
  | "regionCode"
  | "district"
  | "city"
  | "postalCode"
  | "streetName"
  | "houseNumber"
  | "apartment";

export interface AddressVerificationFragmentProps
  extends VerificationStepSubmit {
  initialValues?: any;
  onUpdate?: any;
}

export default function AddressVerificationFragment({
  step,
  handleNext,
  initialValues: addressInitialValues = null,
  onUpdate = null,
}: AddressVerificationFragmentProps) {
  const lang = getLangCodeFromI18n();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const contactId = useAppSelector((state) => state.userState.contactId);
  const userState = useAppSelector((state) => state.userState);
  const initialContactAddress = useAppSelector(
    (state) => state.verificationState.contactAddress.value,
  );

  const verificationConfig = useAppSelector(
    (state: any) => state.organizationConfigState.modules.verification,
  );

  const initialValues: VerifyAddressCommandValues = addressInitialValues ?? {
    firstName: initialContactAddress?.firstName ?? "",
    lastName: initialContactAddress?.lastName ?? "",
    countryCode:
      initialContactAddress?.countryCode ??
      verificationConfig?.defaultCountryCode ??
      "",
    regionCode: initialContactAddress?.regionCode ?? "",
    district: initialContactAddress?.district ?? "",
    city: initialContactAddress?.city ?? "",
    postalCode: initialContactAddress?.postalCode ?? "",
    streetName: initialContactAddress?.streetName ?? "",
    houseNumber: initialContactAddress?.houseNumber ?? "",
    apartment: initialContactAddress?.apartment ?? "",
  };

  const [regions, setRegions] = useState<StateDto[]>();
  const [countryCode, setCountryCode] = useState<string | null>(
    initialValues.countryCode ?? null,
  );
  const [addressFormat, setAddressFormat] = useState<string | null>(null);
  const [validationSchema, setValidationSchema] = useState(
    getAddressSchema(t, addressFormat),
  );

  const {data: countries} = useGetCountries({filter: CountryFilter.Default});

  const [verifyAddress, {isLoading, isSuccess}] =
    useVerificationVerifyAddressMutation();

  const [updateContactAddress] = useUpdateContactAddressMutation();
  const [updateUserInfo] = useUserUpdateInfoMutation();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`${t("toasts.addressVerificationSuccess")}`);
      handleNext();
    }
  }, [isLoading]);

  const handleVerifyAddress: SubmitHandler<VerifyAddressCommandValues> = (
    values,
  ) => {
    values.contactId = contactId;
    const commandArgs: VerificationVerifyAddressApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      verifyAddressCommandValues: {
        ...values,
        customValues: {
          toAddressHouseNumber: values?.houseNumber ?? "",
          toAddressDistrict: values?.district ?? "",
        },
      },
    };
    dispatch(
      setNameSurname({
        firstName: values.firstName,
        lastName: values.lastName,
      }),
    );
    verifyAddress(commandArgs);
    dispatch(verifyContactAddress(initialValues));
  };

  const handleUpdateAddress: SubmitHandler<VerifyAddressCommandValues> = async (
    values,
  ) => {
    setIsUpdating(true);
    try {
      const commandArgs: UpdateContactAddressApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactAddressId: addressInitialValues.contactAddressId,
        updateContactAddressCommandValues: {
          addressLine: values.streetName || "",
          addressLine2: values.apartment || "",
          cityName: values.city || "",
          countryCode: values.countryCode || "",
          postalCode: values.postalCode || "",
          stateCode: values.regionCode || "",
          customValues: {
            toAddressHouseNumber: values?.houseNumber,
            toAddressDistrict: values?.district,
          },
        },
      };
      if (
        (userState?.firstName !== values.firstName ||
          userState?.lastName !== values.lastName) &&
        values.firstName &&
        values.lastName
      ) {
        const updateUserValues: UpdateUserInfoCommand = {
          organizationId: process.env
            .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
          contactId,
          firstName: values.firstName ?? "",
          lastName: values.lastName ?? "",
        };

        await updateUserInfo({updateUserInfoCommand: updateUserValues});

        dispatch(
          setNameSurname({
            firstName: values.firstName,
            lastName: values.lastName,
          }),
        );
      }

      await updateContactAddress(commandArgs);
      toast.success(`${t("toasts.addressWasUpdated")}`);

      onUpdate(values);
    } catch (error: any) {
      toastError(error);
    }
    setIsUpdating(false);
  };

  const [getStates] = useStatesGetMutation();

  const getRegions = async (request: GetStatesQueryValues) => {
    const commandArgs: StatesGetApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      getStatesQueryValues: request,
    };
    return getStates(commandArgs);
  };

  useEffect(() => {
    if (countryCode) {
      const getRegionsAsync = async () => {
        const response: any = await getRegions({
          offset: 0,
          limit: 100,
          sort: "name",
          filter: `countryCode:${countryCode} AND CustomValues.state_is_active:true`,
          search: "",
        });
        setRegions(response.data?.items);
      };
      getRegionsAsync();
    }
  }, [countryCode]);

  useEffect(() => {
    const selectedCountry = countries?.find(
      (country) => country.countryCode === countryCode,
    );
    if (selectedCountry) {
      const customAddressFormat =
        selectedCountry?.customValues?.["address-format"];
      setAddressFormat(customAddressFormat === "US" ? "US" : "NonUS");
    }
  }, [countryCode, countries]);

  useEffect(() => {
    setValidationSchema(getAddressSchema(t, addressFormat));
  }, [addressFormat, t]);

  const handleFieldChange = async (
    formikProps: FormikProps<VerifyAddressCommandValues>,
    fieldName: ContactAddressFieldName,
    value: any,
  ) => {
    formikProps.setFieldValue(fieldName, value);
    formikProps.validateField(fieldName);
    dispatch(setContactAddress(formikProps.values));
  };

  const {renderRequiredMark} = useFormRequiredFields(
    getValidationSchemaRequiredFields(validationSchema),
  );

  const renderFieldsByAddressFormat = (
    formikProps: FormikProps<VerifyAddressCommandValues>,
  ) => {
    if (addressFormat === "US") {
      return (
        <>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6} lg={4}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={
                    t("addressLineLabel") + renderRequiredMark("streetName")
                  }
                  name="streetName"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "streetName",
                      event.target.value,
                    )
                  }
                  data-testid="input-streetname"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6} lg={4}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={
                    t("addressLine2Label") + renderRequiredMark("apartment")
                  }
                  name="apartment"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "apartment",
                      event.target.value,
                    )
                  }
                  data-testid="input-apartment"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6} lg={4}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("cityLabel") + renderRequiredMark("city")}
                  name="city"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(formikProps, "city", event.target.value)
                  }
                  data-testid="input-city"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3} lg={2}>
                <Autocomplete
                  options={regions ?? []}
                  getOptionLabel={(option: any) => {
                    return option.customValues &&
                      `state_${lang}` in option.customValues
                      ? option.customValues[`state_${lang}`]
                      : option.name;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.stateCode === value.stateCode
                  }
                  disabled={!countryCode}
                  value={
                    regions?.find(
                      (region) =>
                        region.stateCode === formikProps.values.regionCode,
                    ) ?? null
                  }
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      size={isDesktop ? "medium" : "small"}
                      label={t("stateLabel") + renderRequiredMark("regionCode")}
                      data-testid="input-region"
                      name="regionCode"
                    />
                  )}
                  onChange={(_: any, value: StateDto | null) =>
                    formikProps.setFieldValue("regionCode", value?.stateCode)
                  }
                />
              </Grid>
              <Grid xs={2} md={3} lg={2}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={
                    t("postalCodeLabel") + renderRequiredMark("postalCode")
                  }
                  name="postalCode"
                  autoComplete="postal-code"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "postalCode",
                      event.target.value,
                    )
                  }
                  data-testid="input-postalcode"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
    if (addressFormat === "NonUS") {
      return (
        <>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3} lg={2}>
                <Autocomplete
                  options={regions ?? []}
                  getOptionLabel={(option: any) => {
                    return option.customValues &&
                      `state_${lang}` in option.customValues
                      ? option.customValues[`state_${lang}`]
                      : option.name;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.stateCode === value.stateCode
                  }
                  disabled={!countryCode}
                  value={
                    regions?.find(
                      (region) =>
                        region.stateCode === formikProps.values.regionCode,
                    ) ?? null
                  }
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      size={isDesktop ? "medium" : "small"}
                      label={
                        t("regionLabel") + renderRequiredMark("regionCode")
                      }
                      data-testid="input-region"
                      name="regionCode"
                    />
                  )}
                  onChange={(_: any, value: StateDto | null) =>
                    formikProps.setFieldValue("regionCode", value?.stateCode)
                  }
                />
              </Grid>
              <Grid xs={6} md={3} lg={2}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("districtLabel") + renderRequiredMark("district")}
                  name="district"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "district",
                      event.target.value,
                    )
                  }
                  data-testid="input-district"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3} lg={2}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("cityLabel") + renderRequiredMark("city")}
                  name="city"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(formikProps, "city", event.target.value)
                  }
                  data-testid="input-city"
                />
              </Grid>
              <Grid xs={6} md={3} lg={2}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={
                    t("postalCodeLabel") + renderRequiredMark("postalCode")
                  }
                  name="postalCode"
                  autoComplete="postal-code"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "postalCode",
                      event.target.value,
                    )
                  }
                  data-testid="input-postalcode"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6} lg={4}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("streetLabel") + renderRequiredMark("streetName")}
                  name="streetName"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "streetName",
                      event.target.value,
                    )
                  }
                  data-testid="input-streetname"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3} lg={2}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={
                    t("houseNumberLabel") + renderRequiredMark("houseNumber")
                  }
                  name="houseNumber"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "houseNumber",
                      event.target.value,
                    )
                  }
                  data-testid="input-housenumber"
                />
              </Grid>
              <Grid xs={6} md={3} lg={2}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("apartmentLabel") + renderRequiredMark("apartment")}
                  name="apartment"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "apartment",
                      event.target.value,
                    )
                  }
                  data-testid="input-apartment"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
    return null;
  };
  return (
    <Formik
      onSubmit={(values: VerifyAddressCommandValues) =>
        addressInitialValues === null
          ? handleVerifyAddress(values)
          : handleUpdateAddress(values)
      }
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<VerifyAddressCommandValues>) => (
        <Form>
          <Grid container direction={"column"} rowGap={4}>
            <Grid sx={{display: {xs: "none", md: "block"}}}>
              <Typography variant={"h3"}>
                <Trans i18nKey={"fillOutAddressInformation"}>
                  Fill out your contact and address details
                </Trans>
              </Typography>
            </Grid>
            <Grid sx={{display: {xs: "block", md: "none"}}}>
              <Typography variant={"h3"}>
                <Trans i18nKey={"fillOutAddress"}>Fill out your address</Trans>
              </Typography>
            </Grid>
            <Grid>
              <Grid container direction={"column"} gap={2}>
                <Grid>
                  <Grid container columns={6} spacing={2}>
                    <Grid xs={6} md={3} lg={2}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        size={isDesktop ? "normal" : "small"}
                        label={
                          t("firstNameLabel") + renderRequiredMark("firstName")
                        }
                        name="firstName"
                        autoComplete="given-name"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                          handleFieldChange(
                            formikProps,
                            "firstName",
                            event.target.value,
                          )
                        }
                        data-testid="input-firstname"
                      />
                    </Grid>
                    <Grid xs={6} md={3} lg={2}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        size={isDesktop ? "normal" : "small"}
                        label={
                          t("lastNameLabel") + renderRequiredMark("lastName")
                        }
                        name="lastName"
                        autoComplete="family-name"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                          handleFieldChange(
                            formikProps,
                            "lastName",
                            event.target.value,
                          )
                        }
                        data-testid="input-lastname"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container columns={6} spacing={2}>
                    <Grid xs={6} md={6} lg={4}>
                      <FormControl
                        sx={{width: "100%"}}
                        size={isDesktop ? "medium" : "small"}
                      >
                        <Field
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          select
                          size={isDesktop ? "normal" : "small"}
                          label={
                            t("countryLabel") +
                            renderRequiredMark("countryCode")
                          }
                          name="countryCode"
                          data-testid="select-country"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            handleFieldChange(
                              formikProps,
                              "countryCode",
                              event.target.value,
                            );
                            setCountryCode(event.target.value);
                            handleFieldChange(formikProps, "regionCode", "");
                          }}
                        >
                          {countries &&
                            countries
                              .filter((country) => country.countryCode)
                              .map((country) => (
                                <MenuItem
                                  key={country.countryCode ?? ""}
                                  value={country.countryCode ?? ""}
                                  data-testid={`option-${country.countryCode}`}
                                >
                                  {country.customValues &&
                                  `country_translation_${lang}` in
                                    country.customValues
                                    ? country.customValues[
                                        `country_translation_${lang}`
                                      ]
                                    : country.name}
                                </MenuItem>
                              ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {renderFieldsByAddressFormat(formikProps)}
              </Grid>
            </Grid>
            <Grid>
              <Grid container columns={6} columnSpacing={2}>
                {!onUpdate ? (
                  <>
                    <Grid xs={3} md={3} lg={2}>
                      {!step.required ? (
                        <Button
                          onClick={handleNext}
                          variant={"outlined"}
                          fullWidth
                          data-testid="btn-skip"
                        >
                          <Trans i18nKey={"skip"}>Skip</Trans>
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid xs={3} md={3} lg={2}>
                      <LoadingButton
                        variant="contained"
                        fullWidth
                        type="submit"
                        disabled={isLoading}
                        data-testid="btn-submit"
                        color="secondary"
                      >
                        <Trans i18nKey={"verify"}>Verify</Trans>
                      </LoadingButton>
                    </Grid>
                  </>
                ) : (
                  <Grid xs={6} md={3} lg={2}>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={isUpdating}
                      data-testid="btn-submit"
                      color="secondary"
                    >
                      <Trans i18nKey={"save"}>Save</Trans>
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
