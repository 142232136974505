import React from "react";
import {Button, IconButton} from "@mui/material";
import Link from "../../common/components/link";
import {useTranslation} from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";

type MobileListAddButtonProps = {
  i18nKey?: string;
  style?: any;
  link?: string;
  onClick?: () => void;
};
export default function MobileListAddButton({
  i18nKey = "",
  style,
  link,
  onClick,
}: MobileListAddButtonProps) {
  const {t} = useTranslation();
  return (
    <Link
      to={link}
      style={{display: "block", width: "100%", marginBottom: "16px", ...style}}
    >
      <Button
        variant={"outlined"}
        color={"primary"}
        fullWidth
        sx={{
          minHeight: "50px",
          borderRadius: "8px",
          textTransform: "none",
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <IconButton color="primary">{<AddBoxIcon />}</IconButton>
        {t(i18nKey)}
      </Button>
    </Link>
  );
}
