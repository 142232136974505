import * as yup from "yup";

export const getValidationSchema = (t: any) => {
  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t("validation.common.passwordRequired"))
      .min(6, t("validation.common.passwordLengthMin"))
      .max(100, t("validation.common.passwordLengthMax")),
    email: yup
      .string()
      .required(t("validation.common.emailRequired"))
      .email(t("validation.common.emailFormat"))
      .max(256, t("validation.common.emailLengthMax")),
  });

  return schema;
};
