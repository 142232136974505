import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {InvoiceCardProps} from "./interfaces";
import {getLangCodeFromI18n} from "../../../app/app.component";
import {getCustomValue} from "../../../../utils/helper.utils";
import {useAppSelector} from "../../../store";
import {getChargeRelatedOrderTrackingNumber} from "./invoiceDetailsTable";

const cardTextStyle = {
  variant: "body2",
  fontSize: "1rem",
};

export default function InvoiceDetailsCard({invoiceCharge}: InvoiceCardProps) {
  const lang = getLangCodeFromI18n();
  const {t} = useTranslation();

  const showTrackingNumberForCodes = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules?.invoices
        ?.showTrackingNumbersCodes,
  );
  const showTrackingNumberForOrderType = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules?.invoices
        ?.showTrackingNumbersOrderType,
  );

  const chargeRelatedOrderTrackingNumber = getChargeRelatedOrderTrackingNumber(
    invoiceCharge,
    showTrackingNumberForCodes,
    showTrackingNumberForOrderType,
  );

  return (
    <Card
      sx={{
        mt: 3,
        border: "1px solid #D8E6FB",
        boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
        padding: 0.5,
        borderRadius: "16px",
      }}
    >
      <Accordion
        sx={{
          boxShadow: 0,
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid>
            <Typography
              variant="body3"
              sx={{wordWrap: "break-word"}}
              display={"inline-block"}
              width={"calc(100vw - 110px)"}
            >
              {getCustomValue(
                invoiceCharge.accountingItem?.customValues,
                `service_description_${lang}`,
              ) ??
                invoiceCharge.accountingItem?.description ??
                invoiceCharge.description ??
                t("invoices.noDescription")}
              {chargeRelatedOrderTrackingNumber
                ? ` (${chargeRelatedOrderTrackingNumber})`
                : ""}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{pl: 2, pt: 0, pb: 0}}>
          <CardContent sx={{padding: 0}}>
            <Grid sx={{display: "flex", justifyContent: "space-between"}}>
              <Typography sx={cardTextStyle}>
                {t("quantityPurchaseItem")}:{" "}
              </Typography>
              <Typography sx={cardTextStyle}>
                {invoiceCharge.quantity}
              </Typography>
            </Grid>
            <Grid
              mt={1}
              sx={{display: "flex", justifyContent: "space-between"}}
            >
              <Typography sx={cardTextStyle}>
                {t("invoices.price")}:{" "}
              </Typography>
              <Typography sx={cardTextStyle}>${invoiceCharge.price}</Typography>
            </Grid>
            <Grid
              mt={1}
              sx={{display: "flex", justifyContent: "space-between"}}
            >
              <Typography sx={cardTextStyle}>
                {t("invoices.amount")}:{" "}
              </Typography>
              <Typography sx={cardTextStyle}>
                ${invoiceCharge.amount}
              </Typography>
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
