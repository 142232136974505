import {
  UserVerifyResetPasswordCodeApiArg,
  useUserVerifyResetPasswordCodeMutation,
} from "../../features/user/user-api";
import {useParams} from "react-router-dom";

type UrlParams = {
  userId: string;
  code: string;
};

export const usePasswordVerify = () => {
  const {userId, code} = useParams<keyof UrlParams>() as UrlParams;

  const [
    verifyCode,
    {
      isLoading: isVerifyLoading,
      error: verifyError,
      isSuccess: isVerifySuccess,
      isError: isVerifyError,
    },
  ] = useUserVerifyResetPasswordCodeMutation();

  const verify = () => {
    const Code = code.replace(/\+/g, "%2B").replace(/\//g, "%2F");
    const commandArgs: UserVerifyResetPasswordCodeApiArg = {
      verifyUserCodeCommand: {code: Code, userId},
    };
    verifyCode(commandArgs);
  };

  return {
    verify,
    isLoading: isVerifyLoading,
    error: verifyError,
    isSuccess: isVerifySuccess,
    isError: isVerifyError,
  };
};
