import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {QuerySearchParams, useQuery} from "../../../../utils/helper.utils";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import Pagination from "../../../common/components/pagination";
import ParcelShipmentTable from "./parcelShipmentTable";
import {
  GetOrdersCommandValues,
  OrderGetOrdersApiArg,
  OrderGQLDtoPagedResult,
  QueryKeysEnum,
  useOrderGetOrdersGQLMutation,
} from "../../../features/order/order-api";
import {useAppSelector} from "../../../store";
import i18next from "i18next";
import {mapI18LangToCulture} from "../../../common/utils/mapI18LangToCulture";

export const PARCEL_SHIPMENTS_QUERY_DEFAULT_OFFSET = "0";
export const PARCEL_SHIPMENTS_QUERY_DEFAULT_LIMIT = "8";
export const PARCEL_SHIPMENTS_QUERY_DEFAULT_FILTER =
  'orderType:ParcelShipment AND NOT OrderStatus.OrderStatusName:"Canceled"';

export default function ParcelShipmentList() {
  const {t} = useTranslation();
  const lang = i18next.language;
  const userContactId = useAppSelector(
    (state: any) => state.userState.contactId,
  );

  const jobTypes = useAppSelector(
    (state: any) => state.organizationConfigState?.modules.parcels?.jobType,
  );

  const buildAdditionalFilter = (jobTypes: string[]) => {
    if (!jobTypes || jobTypes.length === 0) return "";
    return ` AND (Jobs.CustomValues.jobType:${jobTypes.join(
      " OR Jobs.CustomValues.jobType:",
    )})`;
  };

  const {
    offset = PARCEL_SHIPMENTS_QUERY_DEFAULT_OFFSET,
    limit = PARCEL_SHIPMENTS_QUERY_DEFAULT_LIMIT,
    sort = "-created",
    filter = PARCEL_SHIPMENTS_QUERY_DEFAULT_FILTER +
      buildAdditionalFilter(jobTypes),
    search = "",
  } = useQuery() as QuerySearchParams;

  const [parcelShipments, setParcelShipments] =
    useState<OrderGQLDtoPagedResult>();
  const [getOrders] = useOrderGetOrdersGQLMutation();

  const getParcelShipments = async (request: GetOrdersCommandValues) => {
    const commandArgs: OrderGetOrdersApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      getOrdersCommandValues: request,
    };
    return getOrders(commandArgs);
  };

  useEffect(() => {
    getParcelShipments({
      offset: Number(offset),
      limit: Number(limit),
      sort,
      filter,
      search,
      queryKey: QueryKeysEnum.ParcelShipmentsInformation,
      lang: mapI18LangToCulture(lang),
    }).then((response: any) => {
      setParcelShipments(response?.data?.orders);
    });
  }, [offset, limit, sort, filter, search, userContactId]);

  if (!parcelShipments?.items?.length) {
    return (
      <>
        <Grid container justifyContent={"center"} my={5}>
          <Typography mb={2} variant={"h2"} color={"primary.dark"}>
            {!parcelShipments
              ? t("loading")
              : t("parcelShipments.allParcels.noParcels")}
          </Typography>
        </Grid>
      </>
    );
  }

  return (
    <Grid container flexDirection={"column"} position={"relative"}>
      <ParcelShipmentTable parcelShipments={parcelShipments.items} />

      <Pagination
        limit={Number(limit)}
        offset={Number(offset)}
        sort={sort}
        filter={filter}
        search={search}
        totalCount={parcelShipments?.totalCount}
      />
    </Grid>
  );
}
