import React from "react";
import {Trans} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import InvoiceDetails from "../components";

const InvoiceDetailLayout = () => {
  const params = useParams();
  const location = useLocation();
  const invoiceState = location?.state?.item;

  return (
    <MainLayout pageTitle="Invoice Details" backLink="../invoices">
      <ContentLayout
        title={
          <>
            <Trans i18nKey={"invoices.invoice"}>Invoice</Trans>
            {" #"}
            {params.trackingNumber && params.trackingNumber.trim() !== ""
              ? params.trackingNumber
              : params.invoiceId}
          </>
        }
        backLink={invoiceState == null ? "../parcelShipments" : "../invoices"}
        content={<InvoiceDetails />}
        isPurchase={false}
      />
    </MainLayout>
  );
};

export default InvoiceDetailLayout;
