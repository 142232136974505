import React from "react";
import Flags from "country-flag-icons/react/3x2";
import {hasFlag} from "country-flag-icons";

export const renderFlag = (countryCode: string): JSX.Element => {
  const flag = countryCode === "EN" ? "US" : countryCode;
  if (hasFlag(flag)) {
    const Flag = Flags[flag as keyof typeof Flags];
    return <Flag />;
  }
  return <></>;
};
