import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {TextField} from "formik-mui";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans, useTranslation} from "react-i18next";
import {IndeterminateCheckBox} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import {
  SendChargedAmountCommand,
  useVerificationChargedAmountMutation,
  useVerificationVerifyChargedAmountMutation,
  VerificationChargedAmountApiArg,
  VerificationVerifyChargedAmountApiArg,
  VerifyChargedAmountCommand,
} from "../../../features/verification/verification-api";
import {VerificationsSubmit} from "../../../Verifications/components/interfaces";
import {useCookies} from "react-cookie";
import {useAppSelector} from "../../../store";
import {SubmitHandler} from "react-hook-form";
import {
  getChargedAmountSchema,
  getExistingPaymentSchema,
  getPaymentSchema,
} from "../../../Verifications/pages/validation";
import MaskedInput from "react-text-mask";
import creditCardType from "credit-card-type";
import {LoadingButton} from "@mui/lab";
import ModalForm from "../../../common/Modal/components/modalForm";
import SuccessModal from "../../../common/components/successModal";
import toast from "react-hot-toast";
import Link from "@mui/material/Link";
import coinSwap from "./../../../../../src/images/coinSwap.png";

const initialValuesSend: SendChargedAmountCommand = {
  contactPaymentMethodId: null,
  cardNumber: "",
  holderName: "",
  expDate: "",
  code: "",
};

export default function NewCardDetails({
  handleVisibilityClick,
  cardData,
  onSuccess,
}: VerificationsSubmit) {
  const [initialCardValuesSend, setInitialCardValuesSend] =
    useState<SendChargedAmountCommand>(initialValuesSend);
  const [displayVerify, setDisplayVerify] = useState("none");
  const [hideGettingCode, setHideGettingCode] = useState("flex");
  const [cardType, setCardType] = useState<string>("unknown");
  const [notificationStatus, setNotificationStatus] = useState<any>(null);
  const [verificationStatus, setVerificationStatus] = useState<any>(null);
  const [cookies, setCookie, removeCookie] = useCookies(["VPFACH"]);
  const [inputValue, setInputValue] = useState("");
  const [isContinueButtonUndisabled, setIsContinueButtonUndisabledValue] =
    useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isDisplayStartVerify, setIsDisplayStartVerify] = useState("block");
  const [isAuthorize, setIsAuthorize] = useState(false);
  const [isNewCard, setisNewCard] = useState(false);
  const [open, setOpen] = useState(false);
  const [contactPaymentMethodId, setContactPaymentMethodId] = useState<
    string | null | undefined
  >(null);

  const contactId: number | null | undefined = useAppSelector(
    (state) => state.userState.contactId,
  );

  const handleCheckBoxChange = (event: any) => {
    setIsAuthorize((current) => !current);
  };

  const [sendChargedAmount, {isLoading, isError, isSuccess}] =
    useVerificationChargedAmountMutation();

  const [
    verifyChargedAmount,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerify,
      isSuccess: isSuccessVerify,
    },
  ] = useVerificationVerifyChargedAmountMutation();

  const initialValuesVerify: VerifyChargedAmountCommand = {
    contactPaymentMethodId: null,
    chargedAmount: "",
    sendChargedAmount: "",
    verifyChargedAmount: "",
    username: useAppSelector((state) => state.userState.email),
  };

  const [contextInitialValuesVerify, setContextInitialValuesVerify] =
    useState<VerifyChargedAmountCommand>(initialValuesVerify);

  useEffect(() => {
    if (cardData) {
      const cardDataSend: SendChargedAmountCommand = {
        contactPaymentMethodId: cardData.contactPaymentMethodId,
        cardNumber: cardData.description as string,
        holderName: cardData.cardHolderName as string,
        expDate: cardData.expDate as string,
        code: "",
      };
      const contextVerify = {...contextInitialValuesVerify};
      contextVerify.contactPaymentMethodId = cardData.contactPaymentMethodId;
      setContextInitialValuesVerify(contextVerify);
      setInitialCardValuesSend(cardDataSend);
      setNotificationStatus(true);
      setisNewCard(false);
    } else setisNewCard(true);
  }, [cardData]);

  useEffect(() => {
    if (isSuccess) {
      setNotificationStatus(true);
      setIsDisplayStartVerify("none");
    }
    if (isError) {
      if (cardData) setNotificationStatus(true);
      else setNotificationStatus(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccessVerify) {
      setDisplayVerify("none");
      handleVisibilityClick(false);
      if (onSuccess && contactPaymentMethodId)
        onSuccess(contactPaymentMethodId);
    }
    if (isErrorVerify) {
      // setError("Password reset error", error); TODO TOAST
      setVerificationStatus(false);
    }
  }, [isLoadingVerify]);

  const onSubmit: SubmitHandler<SendChargedAmountCommand> = async (values) => {
    const toastLoadingId = "toast-loading";
    toast.loading(t("loading"), {id: toastLoadingId});

    if (contactPaymentMethodId) {
      values.contactPaymentMethodId = contactPaymentMethodId;
    }
    values.cardType = cardType;
    values.contactId = contactId;

    const commandArgs: VerificationChargedAmountApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      sendChargedAmountCommand: values,
    };
    setNotificationStatus(null);

    try {
      const response: any = await sendChargedAmount(commandArgs);
      if (!response.error) {
        setContactPaymentMethodId(response.data["contactPaymentMethodId"]);
        setCookie("VPFACH", response.data["hexAmount"], {maxAge: 172800});
        if (response.data["showAmount"]) {
          toast.success(`Your test amount: ${response.data["showAmount"]}`, {
            duration: 10000,
          });
        } else {
          toast.success(t("toasts.verifyDebitedAmount"));
        }
      }
    } finally {
      toast.remove(toastLoadingId);
    }
  };

  const onVerify: SubmitHandler<VerifyChargedAmountCommand> = (values) => {
    values.sendChargedAmount = values?.chargedAmount?.toString();
    values.verifyChargedAmount = cookies.VPFACH;
    values.contactPaymentMethodId = cardData
      ? cardData.contactPaymentMethodId
      : null;
    const commandArgs: VerificationVerifyChargedAmountApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      verifyChargedAmountCommand: values,
    };
    setVerificationStatus(null);
    verifyChargedAmount(commandArgs)
      .unwrap()
      .then(() => {
        removeCookie("VPFACH");
        setOpen(true);
      })
      .catch((onrejected) => console.error("error", onrejected));
  };

  const {t} = useTranslation();

  useEffect(() => {
    if (notificationStatus) {
      setDisplayVerify("flex");
      setHideGettingCode("none");
    }
  }, [notificationStatus]);

  return (
    <Box
      sx={{
        border: {xs: 0, md: "1px solid #D8E6FB"},
        borderRadius: {xs: 0, md: "16px"},
        boxShadow: {xs: 0, md: "0px 0px 8px rgba(15, 74, 132, 0.1)"},
        backgroundColor: "white",
        height: "100%",
        width: "100%",
      }}
      mb={{xs: 0, md: 4}}
    >
      <Grid
        container
        flexDirection={"column"}
        md={12}
        xs={6}
        mt={{md: 2, xs: -4}}
        ml={{xs: 0, md: 2}}
        columnSpacing={0}
        width={"100%"}
      >
        <Grid display={isDesktop ? "block" : isDisplayStartVerify}>
          <Grid container direction={"row"}>
            <Typography
              color="black"
              variant={isDesktop ? "h1" : "h3"}
              pt={{md: 0.7, xs: 1}}
              fontSize={{md: "32px", lg: "32px"}}
            >
              <Trans i18nKey={cardData ? "verifyYourCard" : "addingNewCard"}>
                Adding new card
              </Trans>
            </Typography>
            <Grid ml={isDesktop ? 0 : "auto"}>
              <Button
                onClick={() => handleVisibilityClick(false)}
                sx={{
                  justifyContent: "end",
                  "&:hover": {backgroundColor: "transparent"},
                }}
                disableRipple
              >
                <IndeterminateCheckBox
                  style={{fontSize: "30px", padding: 0}}
                  color="primary"
                />
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <Formik
              onSubmit={(values: SendChargedAmountCommand) => {
                onSubmit(values);
              }}
              enableReinitialize
              initialValues={initialCardValuesSend}
              validationSchema={
                isNewCard ? getPaymentSchema(t) : getExistingPaymentSchema(t)
              }
            >
              {({isSubmitting}) => (
                <Form>
                  <Grid md={6.6} mt={{md: 2, xs: 2}} mb={0}>
                    <div className="card-wrapper">
                      <Field
                        id="cardNumber"
                        name="cardNumber"
                        autoComplete="cardNumber"
                      >
                        {({field, form, meta}: any) => (
                          <MaskedInput
                            {...field}
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            guide={false}
                            onInput={(str: BaseSyntheticEvent) => {
                              const currentCardNumber: string = (
                                str.target.value as string
                              ).replaceAll(" ", "");
                              const cardTypes =
                                creditCardType(currentCardNumber);
                              if (cardTypes.length > 0)
                                if (currentCardNumber === "") {
                                  setCardType("unknown");
                                } else {
                                  setCardType(cardTypes[0].type);
                                }
                              else {
                                setCardType("unknown");
                              }

                              if (currentCardNumber.match(/^\d{16}$/)) {
                                (
                                  document.querySelector(
                                    '[name="holderName"]',
                                  ) as HTMLElement
                                ).focus();
                              }
                            }}
                            render={(innerRef, props) => (
                              <TextField
                                {...props}
                                inputRef={innerRef}
                                fullWidth
                                label={t("cardNumber")}
                                placeholder="0000 0000 0000 0000"
                                field={field}
                                form={form}
                                meta={meta}
                                disabled={
                                  (isSubmitting &&
                                    notificationStatus == null) ||
                                  (isSubmitting && notificationStatus) ||
                                  cardData !== null
                                }
                                size={isDesktop ? "medium" : "small"}
                                inputProps={{
                                  "data-testid": "input-card-number",
                                }}
                              />
                            )}
                          />
                        )}
                      </Field>
                    </div>

                    <Field
                      id="holderName"
                      name="holderName"
                      sx={{mb: 2, p: 0}}
                      fullWidth
                      label={t("holderName")}
                      placeholder="ALEX JOHNSON"
                      component={TextField}
                      disabled={isSubmitting}
                      onInput={(str: BaseSyntheticEvent) => {
                        str.target.value = (
                          str.target.value as string
                        ).toUpperCase();
                      }}
                      size={isDesktop ? "medium" : "small"}
                      inputProps={{"data-testid": "input-holder-name"}}
                    />

                    <Grid container justifyContent={"space-between"}>
                      <Field id="expDate" name="expDate">
                        {({field, form, meta}: any) => (
                          <MaskedInput
                            {...field}
                            mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                            guide={false}
                            onInput={(str: BaseSyntheticEvent) => {
                              const expDate: string = str.target.value;
                              if (expDate.match(/^\d{2}\/\d{2}$/)) {
                                (
                                  document.querySelector(
                                    '[name="code"]',
                                  ) as HTMLElement
                                ).focus();
                              }
                            }}
                            render={(innerRef, props) => (
                              <TextField
                                {...props}
                                inputRef={innerRef}
                                sx={{mb: 2, width: "48%"}}
                                label={t("expDate")}
                                placeholder="03/22"
                                field={field}
                                form={form}
                                meta={meta}
                                disabled={isSubmitting}
                                size={isDesktop ? "medium" : "small"}
                                inputProps={{"data-testid": "input-exp-date"}}
                              />
                            )}
                          />
                        )}
                      </Field>

                      <Field id="code" name="code">
                        {({field, form, meta}: any) => (
                          <MaskedInput
                            {...field}
                            mask={
                              cardType === "american-express" ||
                              cardType === "hipercard"
                                ? [/\d/, /\d/, /\d/, /\d/]
                                : [/\d/, /\d/, /\d/]
                            }
                            guide={false}
                            render={(innerRef, props) => (
                              <TextField
                                {...props}
                                inputRef={innerRef}
                                sx={{mb: 2, width: "48%"}}
                                label="CVC/CVV"
                                placeholder={
                                  cardType === "american-express" ||
                                  cardType === "hipercard"
                                    ? "0000"
                                    : "000"
                                }
                                field={field}
                                form={form}
                                meta={meta}
                                disabled={isSubmitting}
                                size={isDesktop ? "medium" : "small"}
                                inputProps={{"data-testid": "input-cvc-code"}}
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb={{xs: 0, md: "30px"}}
                    mt={{xs: "10px", sm: "20px"}}
                  >
                    <Grid md={11} mb={3}>
                      <Typography>
                        <Trans i18nKey={"weDoNotStore"}>
                          We do not store customers credit card data.
                        </Trans>
                      </Typography>
                      <Grid mt={2}>
                        <Typography>
                          <Trans i18nKey={"howItWorksText"}>
                            When you add a card, we will process a small
                            transaction on the card ($0.10 - $5.00 US). Youll
                            then need to check your credit card statement and
                            return to this page to verify the amount. After the
                            amount has been verified, the transaction will be
                            refunded, and youll be able to use your card for
                            Verified transactions. Once the verification process
                            has been initiated, you have 48 hours to complete it
                            before the transaction will be refunded.
                          </Trans>
                        </Typography>
                        <Grid display={{xs: "flex", md: "none"}} mt={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{color: "primary.light", mt: -1}}
                                value={isAuthorize}
                                onChange={handleCheckBoxChange}
                              />
                            }
                            label={t(
                              "delivery.paymentMethod.chargeNySelectedPaymentMethod",
                            )}
                            sx={{
                              display: "flex",
                              alignItems: "start",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <LoadingButton
                      data-testid="button-start-verification"
                      sx={{p: 1, width: {xs: "100%", md: "30%"}}}
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={
                        isDesktop
                          ? notificationStatus
                          : !notificationStatus && !isAuthorize
                      }
                      loading={isSubmitting && notificationStatus == null}
                      loadingPosition="end"
                      endIcon={<></>}
                      id={"startVerification"}
                    >
                      <Trans i18nKey="startVerification">
                        Start verification
                      </Trans>
                    </LoadingButton>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Formik
          onSubmit={(values: VerifyChargedAmountCommand) => {
            onVerify(values);
          }}
          initialValues={contextInitialValuesVerify}
          validationSchema={getChargedAmountSchema(t)}
        >
          {({isSubmitting}) => (
            <Form>
              <Grid
                container
                direction="column"
                columns={{xs: 6, md: 6}}
                sx={{
                  display: {xs: `${displayVerify}`, md: "flex"},
                }}
              >
                <Typography
                  variant="body2"
                  mt={{xs: 3, md: 0}}
                  mb={"5px"}
                  mr={{md: 2, xs: 0}}
                  sx={{
                    color: "dark",
                  }}
                >
                  <Trans
                    i18nKey={"delivery.paymentMethod.enterChargedAmountdebited"}
                  >
                    Enter the amount that was debited from the card
                  </Trans>
                </Typography>
                <Field id="chargedAmount" name="chargedAmount">
                  {({field, form, meta}: any) => (
                    <MaskedInput
                      {...field}
                      mask={[/\d/, ".", /\d/, /\d/]}
                      guide={false}
                      onInput={(str: BaseSyntheticEvent) => {
                        setInputValue(str.target.value);
                      }}
                      render={(innerRef, props) => (
                        <TextField
                          {...props}
                          inputRef={innerRef}
                          sx={{mb: 2, mt: 1, maxWidth: "60%"}}
                          label={t("chargedAmount")}
                          placeholder="0.00"
                          field={field}
                          form={form}
                          meta={meta}
                          disabled={
                            (isSubmitting && verificationStatus == null) ||
                            (isSubmitting && verificationStatus)
                          }
                          size={isDesktop ? "medium" : "small"}
                          inputProps={{
                            "data-testid": "input-charged-amount",
                          }}
                          InputProps={{
                            startAdornment: "$",
                          }}
                        />
                      )}
                    />
                  )}
                </Field>

                <Grid container alignItems="center">
                  <Grid>
                    <img
                      src={coinSwap}
                      alt="Icon"
                      width="15"
                      height="15"
                      style={{marginRight: 5}}
                    />
                  </Grid>
                  <Grid>
                    <Link
                      href="https://minfin.com.ua/ua/currency/converter/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        display: "inline-block",
                        marginBottom: "5px",
                      }}
                    >
                      <Trans i18nKey={"currencyConverter"}>
                        Currency converter
                      </Trans>
                    </Link>
                  </Grid>
                </Grid>

                {notificationStatus && (
                  <Grid container>
                    <Typography
                      variant="body3"
                      mb={"5px"}
                      sx={{
                        color: "primary.dark",
                      }}
                    >
                      <Trans i18nKey="fundsWerentCharged">
                        Funds weren’t charged?
                      </Trans>
                      &nbsp;
                      <Grid>
                        <Box
                          display="inline"
                          sx={{
                            color: "#2F81EF",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const startVerificationButton =
                              document.querySelector(
                                "#startVerification",
                              ) as HTMLButtonElement;
                            startVerificationButton.removeAttribute("disabled");
                            startVerificationButton.click();
                          }}
                        >
                          <Trans i18nKey="clickHere">Click here</Trans>
                        </Box>
                        &nbsp;
                        <Trans i18nKey="toChargeItAgain">
                          to charge it again
                        </Trans>
                      </Grid>
                    </Typography>
                  </Grid>
                )}

                <Grid container direction="column" columns={{xs: 6, md: 8}}>
                  <Grid mb={1} mt={1} md={3.3}>
                    <Grid xs={6} md={6} mb={3}>
                      <LoadingButton
                        data-testid="button-verify"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color={"secondary"}
                        loading={isSubmitting && verificationStatus == null}
                        loadingPosition="end"
                        endIcon={<></>}
                        sx={
                          isDesktop
                            ? {p: 0}
                            : {
                                p: 1,
                                mb: 9,
                                position: "fixed",
                                bottom: 0,
                                width: "90%",
                              }
                        }
                        disabled={!inputValue}
                        onClick={() => setIsContinueButtonUndisabledValue(true)}
                      >
                        <Typography
                          data-testid="btn-verify-new-card"
                          textTransform="none"
                        >
                          <Trans
                            i18nKey={isDesktop ? "verify" : "verifyAndContinue"}
                          >
                            Verify
                          </Trans>
                        </Typography>
                      </LoadingButton>
                    </Grid>
                    {verificationStatus === false && (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: "light",
                          color: "red",
                          mt: 2,
                        }}
                      >
                        <Trans i18nKey="verificationFailed">
                          Verification failed&nbsp;
                        </Trans>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <ModalForm open={open} setOpen={setOpen}>
        <SuccessModal
          setOpen={setOpen}
          content="New bank card saved successfully"
        />
      </ModalForm>
    </Box>
  );
}
