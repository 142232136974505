import React from "react";
import {useTranslation} from "react-i18next";
import TabMenu from "../../common/components/tabMenu";
import {Tab} from "../../common/components/interfaces";
import {useNavigate} from "react-router-dom";
import {useMediaQuery, useTheme} from "@mui/material";
import {useAppSelector} from "../../store";

interface ProfileTabMenuProps {
  selectedTab: 1 | 2 | 3 | 4;
}

export default function ProfileTabMenu({selectedTab}: ProfileTabMenuProps) {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const showPaymentMethod = useAppSelector(
    (state: any) =>
      !state.organizationConfigState?.modules?.profile?.paymentMethod?.disabled,
  );

  const tabs: Tab[] = [
    {
      label: t("profile.tabs.accountProfile"),
      clickable: true,
      onClick: () => {
        navigate(`/${i18n.language}/profile`);
      },
    },
    {
      label: t("profile.tabs.savedAddresses"),
      clickable: true,
      onClick: () => {
        navigate(`/${i18n.language}/profile/savedAddresses`);
      },
    },
    {
      label: t("profile.tabs.paymentMethods"),
      clickable: true,
      onClick: () => {
        navigate(`/${i18n.language}/profile/paymentMethods`);
      },
    },
    {
      label: t("profile.tabs.changePassword"),
      clickable: true,
      onClick: () => {
        navigate(`/${i18n.language}/profile/changePassword`);
      },
    },
  ];

  return isDesktop ? (
    <TabMenu
      tabs={tabs}
      initialSelected={selectedTab}
      showPaymentMethod={showPaymentMethod}
    />
  ) : null;
}
