import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {AccountingTransactionGqlDto} from "../../../features/accountingTransaction/accountingTransaction-api";
import {getFormattedDate} from "../../../../utils/formatting.utils";
import {Badge, useMediaQuery, useTheme} from "@mui/material";
import InvoiceCard from "./invoiceCard";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {HashLink} from "react-router-hash-link";

export type InvoiceTableProps = {
  items: AccountingTransactionGqlDto[] | undefined | null;
};

const tableHeadStyle = {
  border: "2px solid #D8E6FB",
  fontSize: "1rem",
};

export default function InvoiceTable({items}: InvoiceTableProps) {
  const theme = useTheme();
  const {t} = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const tableHeaders = [
    t("invoices.invoiceNumber"),
    t("invoices.shipmentNumber"),
    t("invoices.creationDate"),
    t("invoices.paidDate"),
    t("invoices.dueDate"),
    t("invoices.status"),
    t("invoices.amount"),
  ];

  return (
    <>
      {isDesktop && (
        <TableContainer component={Paper} sx={{boxShadow: "none"}}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell sx={tableHeadStyle} align="left" key={header}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item) => (
                <TableRow
                  data-testid={`invoice-row-${item.accountingTransactionId}`}
                  key={item.accountingTransactionId}
                  sx={{border: "2px solid #D8E6FB", boxShadow: "none"}}
                >
                  <TableCell
                    data-testid={`invoice-number`}
                    component="th"
                    scope="row"
                    sx={{color: "#2F81EF", fontSize: "1rem"}}
                  >
                    <Link
                      style={{textDecoration: "none"}}
                      to={{
                        pathname: `../invoices/${item.accountingTransactionId}/tracking/${item.transactionNumber}`,
                      }}
                      state={{item}}
                    >
                      {item.transactionNumber}
                    </Link>
                  </TableCell>
                  <TableCell
                    data-testid={`invoice-shipment-number`}
                    align="left"
                    style={{fontWeight: "500", fontSize: "1rem"}}
                  >
                    {item?.jobs?.map((job) => {
                      return job.orders
                        ?.filter((o) => o.orderType === "ParcelShipment")
                        .map((parcelShipment, index) => {
                          return (
                            <React.Fragment
                              key={parcelShipment?.orderId ?? "" + index}
                            >
                              <HashLink
                                style={{textDecoration: "none"}}
                                to={
                                  parcelShipment?.orderId
                                    ? `../parcelShipments/${parcelShipment.orderId}`
                                    : "#"
                                }
                                state={{item}}
                              >
                                {parcelShipment?.trackingNumber ?? ""}
                              </HashLink>
                              {index < self.length - 1 && " "}
                            </React.Fragment>
                          );
                        });
                    })}
                  </TableCell>
                  <TableCell
                    data-testid={`invoice-created-on`}
                    align="left"
                    sx={{fontSize: "1rem"}}
                  >
                    {getFormattedDate(item?.created ?? "")}
                  </TableCell>
                  <TableCell
                    data-testid={`invoice-paid-date`}
                    align="left"
                    sx={{fontSize: "1rem"}}
                  >
                    {getFormattedDate(item?.paidDate ?? "")}
                  </TableCell>
                  <TableCell
                    data-testid={`invoice-due-date`}
                    align="left"
                    sx={{fontSize: "1rem"}}
                  >
                    {getFormattedDate(item?.dueDate ?? "")}
                  </TableCell>
                  <TableCell align="left" sx={{fontSize: "1rem"}}>
                    <span data-testid={`invoice-status`}>
                      {item.accountingTransactionStatus?.toLowerCase() ===
                      "paid"
                        ? t("invoices.statuses.paid")
                        : t("invoices.statuses.notPaid")}
                    </span>
                    <Badge
                      color={
                        item.accountingTransactionStatus === "Paid"
                          ? "success"
                          : "error"
                      }
                      badgeContent=" "
                      sx={{
                        marginLeft: 3,
                        marginBottom: 0.5,
                        "& .MuiBadge-badge": {
                          width: 14,
                          height: 14,
                          minWidth: 14,
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    data-testid={`invoice-amount`}
                    align="left"
                    sx={{fontSize: "1rem"}}
                  >
                    {getFormattedPrice(item.totalAmount)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!isDesktop &&
        items?.map((item) => (
          <InvoiceCard invoice={item} key={item.accountingTransactionId} />
        ))}
    </>
  );
}
