import React from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";

export default function CardDivider() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid sx={{paddingX: {md: "30px", xs: 0}, paddingY: {md: 0, xs: "20px"}}}>
      <Divider
        orientation={isDesktop ? "vertical" : "horizontal"}
        sx={{height: "100%", borderColor: theme.palette.info.border}}
        flexItem
      />
    </Grid>
  );
}
