import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import {Trans} from "react-i18next";
import i18next from "i18next";
import { PurchasesModule } from "../../Purchases/purchases.module";
import { ParcelModule } from "../../ParcelShipments/parcel.module";
import { InvoicesModule } from "../../Invoices/invoices.module";
import { DeliveryModule } from "../../Delivery/delivery.module";
import { GettingStartedModule } from "../../GettingStarted/getting-started.module";

export default function HeaderMenu() {
  type MenuItemType = {
    page: string;
    to: string;
    i18nKey: string;
    isInternal: boolean;
  };

  const zohoLang = i18next.language === "ua" ? "uk" : i18next.language;

  const menuItems: MenuItemType[] = [
    ...GettingStartedModule.getInstance().getMenuItems(),
    ...PurchasesModule.getInstance().getMenuItems(),
    ...ParcelModule.getInstance().getMenuItems(),
    ...DeliveryModule.getInstance().getMenuItems(),
    ...InvoicesModule.getInstance().getMenuItems(),
        {
      page: "help",
      to: `https://trtexpress.zohodesk.com/portal/${zohoLang}/newticket`,
      i18nKey: "menuItemHelp",
      isInternal: false,
    },
  ];
  const hoverStyle = {
    "&:hover": {
      color: "#FFF",
    },
  };
  const inActiveStyle = {
    color: "#D8E6FB",
    textDecoration: "none",
    marginRight: "2rem",
  };
  const activeStyle = {
    color: "#FFF",
    textDecoration: "none",
    marginRight: "2rem",
  };
  const externalLinkStyle = {
    color: "#D8E6FB",
    textDecoration: "none",
    marginRight: "2rem",
    padding: "6px 0px",
    justifyContent: "start",
  };

  return (
    <Box
      justifyContent={"flex-start"}
      sx={{display: "flex", alignItems: "center"}}
    >
      {menuItems.map(({page, to, i18nKey, isInternal}: MenuItemType) =>
        isInternal ? (
          <NavLink
            key={page}
            style={({isActive}) => (isActive ? activeStyle : inActiveStyle)}
            to={to}
            data-testid={`btn-${i18nKey}`}
          >
            <Typography sx={hoverStyle} variant="button">
              <Trans i18nKey={i18nKey}>{page.toUpperCase()}</Trans>
            </Typography>
          </NavLink>
        ) : (
          <Button
            data-testid={`btn-${i18nKey}`}
            style={externalLinkStyle}
            href={to}
            target="_blank"
            key={page}
            rel="noopener"
          >
            <Typography sx={hoverStyle} variant="button">
              <Trans i18nKey={i18nKey}>{page.toUpperCase()}</Trans>
            </Typography>
          </Button>
        ),
      )}
    </Box>
  );
}
