import React from "react";
import {Button, Card, Typography, useMediaQuery, useTheme} from "@mui/material";
import {PrintLabelProps} from "../pages/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans} from "react-i18next";
import printJS from "print-js";
import {toastError} from "../../../common/utils/toastMessages";
import {getCustomValue} from "../../../../utils/helper.utils";
import {useAppSelector} from "../../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
  WorkflowExecutionResultDto,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {toast} from "react-hot-toast";
import i18n, {t} from "i18next";
import parse from "html-react-parser";

export default function PrintLabel({parcelShipment}: PrintLabelProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [useWorkflow] = useWorkflowExecutionMutation();
  const lang = i18n.language;

  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.sendEmailWorkflowId,
  );

  const documentName = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules?.parcels?.printLabelDocumentName,
  );

  const fedexAttachmentPropertyName = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules?.parcels
        ?.fedexAttachmentPropertyName,
  );

  const email = useAppSelector((state: any) => state.userState?.email);

  const orderDocuments = parcelShipment.orderDocuments?.filter(
    (doc) => doc.documentTemplate?.documentTemplateType === documentName,
  );

  const attachmentDocuments = parcelShipment.documentAttachments?.filter(
    (attachment) =>
      attachment.attachmentId ==
      parcelShipment.customValues?.find(
        (x) => x.key == fedexAttachmentPropertyName,
      )?.value,
  );

  const orderDocument = orderDocuments
    ? orderDocuments[orderDocuments?.length - 1]
    : null;

  const attachmentDocument = attachmentDocuments
    ? attachmentDocuments[0]
    : null;

  const getLabelInstruction = () => {
    const labelInstructionCustomValue = `portal_label_instruction_${lang}`;
    const carriersWithLabelInstruction = parcelShipment.orderEntities?.filter(
      (x) =>
        x.entityType === "Carrier" &&
        getCustomValue(x.contact?.customValues, labelInstructionCustomValue) !=
          null,
    );
    const labelInstruction =
      carriersWithLabelInstruction && carriersWithLabelInstruction.length > 0
        ? getCustomValue(
            carriersWithLabelInstruction[0]?.contact?.customValues,
            labelInstructionCustomValue,
          )
        : null;

    if (!labelInstruction) {
      return null;
    }
    return parse(labelInstruction);
  };

  const printLabel = () => {
    printJS({
      printable:
        orderDocument?.linkToDocument ||
        attachmentDocument?.presignedFileUri ||
        "",
      type: "pdf",
    });
  };

  const downloadDocument = () => {
    fetch(
      orderDocument?.linkToDocument ||
        attachmentDocument?.presignedFileUri ||
        "",
      {
        referrerPolicy: "no-referrer",
      },
    )
      .then((response) => {
        if (!response.ok) {
          toastError("Failed to download document.");
        }
        return response.blob();
      })
      .then((blob) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = "document";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch(() => {
        toastError("Failed to download document.");
      });
  };

  const sendToEmail = () => {
    if (attachmentDocument) {
      //todo wf to send email using attachment
    } else if (orderDocument) {
      const variables = {
        recipientEmail: email,
        orderDocumentId: orderDocument.orderDocumentId,
        orderId: parcelShipment.orderId,
        localization: lang,
      };
      const commandArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId,
        values: {variables},
      };
      useWorkflow(commandArgs)
        .then((res) => {
          const result = res as WorkflowExecutionResultDto;
          if (result.error) {
            toastError("Failed to send document.");
          } else {
            toast.success(t("toasts.labelSuccessSent"));
          }
        })
        .catch(() => {
          toastError("Failed to send document.");
        });
    }
  };

  return (
    ((orderDocument || attachmentDocument) && (
      <Card
        sx={
          isDesktop
            ? {
                border: "1px solid #D8E6FB",
                boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
                borderRadius: "16px",
                marginBottom: "25px",
                background: "#F5F9FF",
              }
            : {
                border: "none",
                boxShadow: "none",
              }
        }
      >
        <Grid
          container
          columns={{xs: 6, md: 5}}
          display={"flex"}
          justifyContent={"space-between"}
          m={{xs: 0, md: 4, lg: 4}}
          sx={{
            marginBottom: {xs: 4},
          }}
        >
          <Typography
            variant="body4"
            sx={{
              textTransform: "none",
              width: "100%",
            }}
          >
            {getLabelInstruction() ?? (
              <Trans
                i18nKey={
                  "parcelShipments.shipmentDetails.printLabelDescription"
                }
              >
                Print the label and drop your package at one of our drop off
                locations
              </Trans>
            )}
          </Typography>
          <Grid
            container
            display={"flex"}
            sx={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button
              color={"secondary"}
              variant={"contained"}
              sx={{
                paddingX: 5,
                textTransform: "none",
                lineHeight: "18px",
                marginTop: "20px",
                marginRight: "10px",
                marginLeft: "10px",
                marginBottom: "5px",
              }}
              onClick={printLabel}
            >
              <Trans i18nKey={"parcelShipments.shipmentDetails.printLabel"}>
                Print Label
              </Trans>
            </Button>
          </Grid>
          <Grid
            container
            display={"flex"}
            sx={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button
              color={"inherit"}
              variant={"contained"}
              sx={{
                paddingX: 5,
                textTransform: "none",
                lineHeight: "18px",
                background: "#D8E6FB",
                marginTop: "20px",
                marginRight: "10px",
                marginLeft: "10px",
                marginBottom: "5px",
              }}
              onClick={downloadDocument}
            >
              <Trans i18nKey={"parcelShipments.shipmentDetails.download"}>
                Download
              </Trans>
            </Button>
          </Grid>
          {orderDocument && (
            <Grid
              container
              display={"flex"}
              sx={{
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <Button
                color={"inherit"}
                variant={"contained"}
                sx={{
                  paddingX: 5,
                  textTransform: "none",
                  lineHeight: "18px",
                  background: "#D8E6FB",
                  marginTop: "20px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  marginBottom: "5px",
                }}
                onClick={sendToEmail}
              >
                <Trans i18nKey={"parcelShipments.shipmentDetails.send"}>
                  Send to My Email
                </Trans>
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    )) || <></>
  );
}
