import {CommodityDto} from "../modules/features/order/order-api";

export const getCommoditiesTotalDeclaredValue = (
  commodities: CommodityDto[],
) => {
  let sum = 0;
  if (commodities && commodities.length > 0)
    sum += commodities.reduce(
      (acc: number, cur: CommodityDto) =>
        acc + (cur?.unitaryValue ?? 0) * (cur?.quantity ?? 0),
      0,
    );
  return sum;
};
