import React, {useEffect, useRef, useState} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {Field, Form, Formik, FormikProps} from "formik";
import Grid from "@mui/material/Unstable_Grid2";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {useAppSelector} from "../../../store";
import {getEditAccountEmailSchema} from "../pages/validation";
import {
  useVerificationEmailCodeMutation,
  VerificationEmailCodeApiArg,
} from "../../../features/verification/verification-api";
import CodeVerificationFragment from "../../components/codeVerificationFragment";
import {toast} from "react-hot-toast";
import {useLazyUserGetInfoQuery} from "../../../features/user/user-api";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GppBadIcon from "@mui/icons-material/GppBad";

interface EditEmailFormValues {
  email: string;
  organizationId: number;
}

export default function EditAccountEmailForm() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const userState = useAppSelector((state) => state.userState);

  const [sendEmailCode] = useVerificationEmailCodeMutation();
  const [getUserInfo] = useLazyUserGetInfoQuery();

  const [initialValues, setInitialValues] = useState<EditEmailFormValues>({
    email: userState?.email || "",
    organizationId: process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
  });
  const formRef = useRef<FormikProps<EditEmailFormValues>>(null);

  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);
  const [codeWasSent, setCodeWasSent] = useState<boolean>(false);

  useEffect(() => {
    setInitialValues((prev) => {
      prev.email = userState?.email || "";
      return {...prev};
    });
  }, [userState?.email]);

  const onEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<EditEmailFormValues>,
  ) => {
    setCodeWasSent(false);
    formikProps.handleChange(event);
  };

  const onSubmitEmail = async (
    values: EditEmailFormValues,
    isResendingCode = false,
  ) => {
    if (isSendingCode) return;

    setIsSendingCode(true);
    try {
      const commandArgs: VerificationEmailCodeApiArg = {
        organizationId: values.organizationId,
        sendNewEmailCodeCommand: values,
      };
      const response = await sendEmailCode(commandArgs);
      if ((response as any)?.error) {
        return;
      }

      if (isResendingCode) {
        toast.success(t("toasts.codeWasResent"));
      } else {
        await getUserInfo();
        setCodeWasSent(true);
        toast.success(t("toasts.emailWasUpdatedWithCode"), {duration: 10000});
      }
    } finally {
      setIsSendingCode(false);
    }
  };

  const onResendCode = async () => {
    const currentUserEmail =
      userState?.email || formRef?.current?.values?.email;
    if (currentUserEmail) {
      await onSubmitEmail({...initialValues, email: currentUserEmail}, true);
    }
  };

  return (
    <Grid
      container
      columns={12}
      display="flex"
      flexDirection="column"
      mt={{
        xs: 4,
        md: "32px",
      }}
    >
      <Grid xs={12}>
        <Typography component="div" variant="body2">
          <Trans i18nKey="profile.editEmail.enterANewEmail">
            Enter a new email
          </Trans>
        </Typography>
      </Grid>
      <Grid mt="16px">
        <Formik
          onSubmit={(values) => onSubmitEmail(values, false)}
          initialValues={initialValues}
          validationSchema={getEditAccountEmailSchema(t, initialValues.email)}
          innerRef={formRef}
        >
          {(formikProps: FormikProps<EditEmailFormValues>) => (
            <Form id="editAccountEmailForm">
              <Grid
                xs
                sm={6}
                md={3}
                sx={{
                  minWidth: {
                    xs: "0",
                    md: "394px",
                  },
                  marginTop: "6px",
                }}
              >
                <Field
                  fullWidth
                  component={TextField}
                  size={isDesktop ? "normal" : "small"}
                  id="email"
                  name="email"
                  label={t("email")}
                  placeholder=""
                  data-testid="input-account-email"
                  disabled={isSendingCode}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onEmailChange(event, formikProps)
                  }
                />
              </Grid>
              <Grid
                xs
                sm={6}
                md={1}
                sx={{
                  minWidth: {
                    xs: 0,
                    md: "185px",
                  },
                  marginTop: "22px",
                }}
              >
                <LoadingButton
                  fullWidth
                  type="submit"
                  form="editAccountEmailForm"
                  variant="contained"
                  color="secondary"
                  data-testid="btn-get-code-email"
                  disabled={isSendingCode || codeWasSent}
                  loading={isSendingCode}
                  loadingPosition="end"
                  endIcon={<></>}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <Trans i18nKey="save">Save</Trans>
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "48px",
          columnGap: "4px",
        }}
      >
        {userState?.emailConfirmed ? (
          <VerifiedUserIcon sx={{color: "success.main", width: "24px"}} />
        ) : (
          <GppBadIcon sx={{color: "error.main", width: "24px"}} />
        )}
        <Box>
          <Trans i18nKey="profile.editEmail.email">Email</Trans>{" "}
          <Typography
            component="span"
            variant="body3"
            sx={{lineBreak: "anywhere"}}
          >
            {userState?.email}
          </Typography>{" "}
          {userState?.emailConfirmed ? (
            <Trans i18nKey="profile.editEmail.isVerified">is verified</Trans>
          ) : (
            <Trans i18nKey="profile.editEmail.isNotVerified">
              is not verified
            </Trans>
          )}
          .
        </Box>
      </Box>
      {!userState?.emailConfirmed ? (
        <CodeVerificationFragment
          verificationMethod="email"
          onResendCode={onResendCode}
          containerSx={{marginTop: "24px"}}
        />
      ) : null}
    </Grid>
  );
}
