import {createContext, useContext} from "react";

type GoogleAnalyticsContextType = {
  sendEvent: (
    category: string,
    action: string,
    label: string,
    value: number,
  ) => void;
};

const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextType | null>(
  null,
);

export const useGoogleAnalytics = (): GoogleAnalyticsContextType => {
  const context = useContext(GoogleAnalyticsContext);
  if (!context) {
    throw new Error(
      "useGoogleAnalytics must be used within a GoogleAnalyticsProvider",
    );
  }
  return context;
};

export default GoogleAnalyticsContext;
