import {RootState, useAppSelector} from "../../store";

export const getContacts = () => {
  const transPrefix = "contactUsData.";

  const footerConfig = useAppSelector(
    (state: RootState) => state.organizationConfigState.modules.footer,
  );

  return [
    ...(footerConfig?.phones?.map((phone: any) => {
      return {
        key: "Phone",
        transKey: `${transPrefix}phone`,
        value: phone,
        href: `tel:${phone}`,
      };
    }) ?? []),
    ...(footerConfig?.emails?.map((email: any) => {
      return {
        key: "Email",
        transKey: `${transPrefix}email`,
        value: email,
        href: `mailto:${email}`,
      };
    }) ?? []),
  ];
};
