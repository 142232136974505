import React from "react";
import {FormControlLabel, Grid, Typography} from "@mui/material";
import {IOSSwitch} from "./iosSwitch";
import {Trans} from "react-i18next";
import {ThumbDownOffAlt, ThumbUpOffAlt} from "@mui/icons-material";

interface AutoPaymentProps {
  isAutoPay: boolean;
  handleChangeAutoPayment: () => void;
  disabled?: boolean;
  [props: string]: any;
}

export const AutoPayment = ({
  isAutoPay,
  handleChangeAutoPayment,
  disabled,
  ...props
}: AutoPaymentProps) => {
  return (
    <Grid container direction={"column"} {...props}>
      <FormControlLabel
        sx={{ml: 0, mb: 3}}
        control={
          <IOSSwitch
            sx={{mr: 1}}
            checked={isAutoPay}
            onChange={handleChangeAutoPayment}
            disabled={disabled}
          />
        }
        label={
          <Typography fontWeight={500} fontFamily={"Inter Medium"}>
            <Trans i18nKey="autoPay.autoPay">Auto pay</Trans>
          </Typography>
        }
      />
      <Typography mb={2} fontWeight={500}>
        1.&nbsp;
        {isAutoPay ? (
          <>
            <ThumbUpOffAlt
              color={"success"}
              sx={{mr: 1, verticalAlign: "text-bottom"}}
            />
            <Trans i18nKey={"delivery.paymentMethod.autoPaymentEnabled"}>
              <Typography color={"success.main"} component={"span"}>
                is enabled
              </Typography>
            </Trans>
          </>
        ) : (
          <>
            <ThumbDownOffAlt
              color={"error"}
              sx={{mr: 1, verticalAlign: "text-bottom"}}
            />
            <Trans i18nKey={"delivery.paymentMethod.autoPaymentDisabled"}>
              <Typography color={"error.main"} component={"span"}>
                is disabled
              </Typography>
            </Trans>
          </>
        )}
        &nbsp;
        <Trans i18nKey={"delivery.paymentMethod.autoPaymentP1"} />
      </Typography>
      <Typography fontWeight={500}>
        <Trans i18nKey={"delivery.paymentMethod.autoPaymentP2"} />
      </Typography>
    </Grid>
  );
};
