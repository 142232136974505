import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import InvoiceDetailsCard from "./invoiceDetailsCard";
import {InvoiceDetailsTableProps} from "./interfaces";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {getLangCodeFromI18n} from "../../../app/app.component";
import {getCustomValue} from "../../../../utils/helper.utils";
import {useAppSelector} from "../../../store";
import {ChargeGqlDto} from "../../../features/order/order-api";

const tableHeadStyle = {
  border: "2px solid #F5F9FF",
  fontSize: "20px",
  background: "#F5F9FF",
  fontWeight: "bold",
};

export const getChargeRelatedOrderTrackingNumber = (
  charge: ChargeGqlDto,
  chargeItemCodes: string[],
  orderType: string,
) => {
  if (
    chargeItemCodes &&
    charge?.accountingItem?.itemCode &&
    chargeItemCodes.includes(charge.accountingItem.itemCode) &&
    charge.orders &&
    charge.orders?.length > 0 &&
    charge.orders.find((x) => x.orderType == orderType)
  )
    return charge.orders.find((x) => x.orderType == orderType)?.trackingNumber;

  return null;
};

export default function InvoiceDetailsTable({
  invoice,
}: InvoiceDetailsTableProps) {
  const lang = getLangCodeFromI18n();

  const theme = useTheme();
  const {t} = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const invoiceCharges = invoice?.charges ?? [];
  const tableHeaders = [
    t("invoices.descriptionOfCharges"),
    t("quantityPurchaseItem"),
    t("invoices.price"),
    t("invoices.amount"),
  ];

  const showTrackingNumberForCodes = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules?.invoices
        ?.showTrackingNumbersCodes,
  );
  const showTrackingNumberForOrderType = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules?.invoices
        ?.showTrackingNumbersOrderType,
  );

  return (
    <>
      {isDesktop && (
        <Grid md={12}>
          <Grid container direction={"column"} md={12} columnSpacing={0}>
            <TableContainer component={Paper} sx={{boxShadow: "none"}}>
              <Table sx={{minWidth: 700}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header) => (
                      <TableCell sx={tableHeadStyle} align="left" key={header}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceCharges?.map((charge) => {
                    const chargeRelatedOrderTrackingNumber =
                      getChargeRelatedOrderTrackingNumber(
                        charge,
                        showTrackingNumberForCodes,
                        showTrackingNumberForOrderType,
                      );
                    return (
                      <TableRow
                        data-testid={`invoice-details-row-${charge.chargeId}`}
                        key={charge.accountingItemId}
                        sx={{
                          boxShadow: "none",
                          borderBottom: "2px solid white",
                          borderRight: "2px solid #F5F9FF",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          width="50%"
                          sx={{borderRight: "2px solid #F5F9FF"}}
                        >
                          <Typography
                            variant="body4"
                            data-testid={`invoice-details-description`}
                          >
                            {getCustomValue(
                              charge.accountingItem?.customValues,
                              `service_description_${lang}`,
                            ) ??
                              charge.accountingItem?.description ??
                              charge?.description ??
                              t(`invoices.noDescription`)}
                            {chargeRelatedOrderTrackingNumber
                              ? ` (${chargeRelatedOrderTrackingNumber})`
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{borderRight: "2px solid #F5F9FF"}}
                        >
                          <Typography
                            data-testid={`invoice-details-quantity`}
                            variant="body4"
                          >
                            {charge.quantity}&nbsp;
                            {charge.unit
                              ? charge.unit === "$"
                                ? charge.unit
                                : t(`${charge.unit.toLowerCase()}`)
                              : null}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{borderRight: "2px solid #F5F9FF"}}
                        >
                          <Typography
                            data-testid={`invoice-details-price`}
                            variant="body4"
                          >
                            {getFormattedPrice(charge.price)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{borderRight: "2px solid #F5F9FF"}}
                        >
                          <Typography
                            data-testid={`invoice-details-amount`}
                            variant="body4"
                          >
                            {getFormattedPrice(charge.amount)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container direction="row" sx={{borderTop: "2px solid #F5F9FF"}}>
            <Grid
              width={"50%"}
              pb={4}
              sx={{borderRight: "2px solid #F5F9FF"}}
            ></Grid>
            <Grid width={"50%"}>
              <Grid container direction="column" ml={2} pb={4} mr={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  mt={3}
                >
                  <Typography variant="h2">
                    <Trans i18nKey={"delivery.totalCost.total"}>Total</Trans>
                  </Typography>
                  <Grid
                    borderBottom="4px dotted #D8E6FB"
                    flexGrow={1}
                    ml={0.5}
                    height={"20px"}
                  />
                  <Typography
                    data-testid="invoice-details-total-amount"
                    variant="h2"
                  >
                    {getFormattedPrice(invoice?.totalAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isDesktop && (
        <Grid mt={6} xs={12}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              height: "56px",
              background: "#D8E6FB",
              padding: "0 0.5rem ",
              borderRadius: "16px 16px 0px 0px",
              ml: -2,
              mr: -2,
            }}
          >
            <Typography variant="h3" pl={1}>
              <Trans i18nKey={"invoices.descriptionOfCharges"}>
                Description of charges
              </Trans>
            </Typography>
          </Grid>
          {invoiceCharges?.map((charge) => (
            <InvoiceDetailsCard
              invoiceCharge={charge}
              key={charge.accountingItemId}
            />
          ))}
          <Grid container direction="column" ml={2} mr={2}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              mt={3}
              ml={-2}
              mr={-2}
            >
              <Typography variant="h3">
                <Trans i18nKey={"delivery.totalCost.total"}>Total</Trans>
              </Typography>
              <Grid
                borderBottom="3px dotted #D8E6FB"
                flexGrow={1}
                ml={0.5}
                height={"24px"}
              />
              <Typography variant="h3">${invoice?.totalAmount}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
