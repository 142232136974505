import React from "react";
import {MenuItemType, Module, ModuleConfig} from "../core/base.module";
import AllInvoices from "../Invoices/AllInvoices/pages";
import InvoiceDetails from "../Invoices/InvoiceDetails/pages";
import {Receipt} from "@mui/icons-material";

export interface InvoicesConfiguration extends ModuleConfig {
  disabled?: boolean;
}

export class InvoicesModule implements Module {
  static _instance: InvoicesModule;
  config: InvoicesConfiguration;

  private constructor(config?: InvoicesConfiguration) {
    this.config = config || {};
  }

  static getInstance(config?: InvoicesConfiguration): InvoicesModule {
    if (!InvoicesModule._instance) {
      InvoicesModule._instance = new InvoicesModule();
    } else if (config) {
      InvoicesModule._instance.config = config;
    }
    return InvoicesModule._instance;
  }

  getMenuItems() {
    if (!this.config || this.config.disabled !== false) {
      return [];
    }

    return [
      {
        page: "invoices",
        to: "../invoices",
        i18nKey: "menuInvoices",
        isInternal: true,
        svgIcon: Receipt,
      },
    ];
  }

  getPages() {
    if (!this.config || this.config.disabled !== false) {
      return [];
    }

    return [
      {
        path: "invoices",
        element: <AllInvoices />,
      },
      {
        path: "invoices/:id",
        element: <InvoiceDetails />,
      },
    ];
  }

  getMenuActions(): MenuItemType[] {
    return [];
  }
}
