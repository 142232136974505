import Grid from "@mui/material/Unstable_Grid2";
import React, {useEffect, useRef, useState} from "react";
import {DeliveryConfiguration} from "../delivery.module";
import {LoadingButton} from "@mui/lab";
import {
  Box,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {Trans} from "react-i18next";
import {
  ChargeDto,
  CreateChargeCommand,
  OrderDto,
} from "../../features/order/order-api";
import FormControl from "@mui/material/FormControl";
import AddressForm, {AddressFormValues} from "./components/addressForm";
import {t} from "i18next";
import {useAppSelector} from "../../store";
import PaymentCardForm, {
  PaymentCardFormValues,
} from "./components/paymentCardForm";
import {
  GetQuoteForOrderCommandValues,
  QuoteOrderResponse,
} from "../../features/shipmentQuote/shipmentQuote-api";
import {UseQuoteForOrder} from "../hooks/UseQuoteForOrder";
import {useDispatch} from "react-redux";
import {
  setBillingAddressValues,
  setPaymentCardValues,
} from "../../features/parcel/parcel-slice";
import {deliveryCostPerUnit} from "../AdditionalServices/components/additionalServiceFragment";
import {FormikProps} from "formik";
import TotalCostParcels from "../Insurance/components/totalCostParcels.component";
import {CountryFilter} from "../../common/hooks/useGetCountries";

export default function PayAndPrintLabel({
  config,
  pageTitle,
  handleNext,
  isSubmitting,
}: {
  pageTitle: string;
  config: DeliveryConfiguration;
  handleNext: () => Promise<void>;
  isSubmitting: boolean;
}) {
  const i18KeyPrefix = "delivery.payAndPrintLabel.";

  const [useBillingAddress, setUseBillingAddress] = useState(true);

  const currentCustomerId = useAppSelector(
    (state) => state.userState.contactId,
  );

  const [paymentCardFormError, setPaymentCardFormError] =
    useState<boolean>(false);
  const paymentCardFormRef = useRef<HTMLDivElement | null>(null);

  const [totalCost, setTotalCost] = useState<number | null>(null);

  const dispatch = useDispatch();
  const orderData = useAppSelector((state) => state.parcelState);
  const thisOrder: any = orderData.order;

  const handleUseBillingAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUseBillingAddress("true" == (event.target as HTMLInputElement).value);
  };

  const handleSubmit = () => {
    if (!thisOrder || !totalCost) return;
    if (paymentCardFormError || !orderData.paymentCardValues) {
      if (paymentCardFormRef?.current) {
        paymentCardFormRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
      return;
    }
    if (handleNext) {
      handleNext();
    }
  };

  const handleChangeAddressForm = (formValues: AddressFormValues) => {
    dispatch(setBillingAddressValues({billingAddressValues: formValues}));
  };

  const handleChangePaymentCardForm = (
    formikProps: FormikProps<PaymentCardFormValues>,
  ) => {
    dispatch(setPaymentCardValues({paymentCardValues: formikProps?.values}));
    setPaymentCardFormError(Object.keys(formikProps.errors).length != 0);
  };

  const [calculatedCharges, setCalculatedCharges] = useState<ChargeDto[]>([]);

  const {getOrderQuote} = UseQuoteForOrder();

  useEffect(() => {
    if (
      thisOrder &&
      thisOrder.contactValues?.contactId &&
      thisOrder.deliveryMethod?.rateId &&
      thisOrder.commodities
    ) {
      const order = {...thisOrder} as OrderDto;
      order.billToContactId = currentCustomerId;
      order.commodities = [
        {
          ...thisOrder.container,
          lastModified: new Date().toISOString(),
          created: new Date().toISOString(),
        },
      ];

      const additionalServices: any = {};

      thisOrder.charges
        .filter((x: CreateChargeCommand) => x.values?.rateId == null)
        .forEach((x: any) => {
          additionalServices[x.values.accountingItemCode] = x.values.quantity;
        });

      const finalMileValues: GetQuoteForOrderCommandValues = {
        order: order,
        routes: [{rateId: thisOrder.deliveryMethod?.rateId}],
        additionalServices: additionalServices,
      };

      const firstMileValues: GetQuoteForOrderCommandValues = {
        order: order,
        routes: [{rateId: thisOrder.deliveryToTrtMethod?.rateId}],
      };

      getOrderQuote(finalMileValues).then((response: any) => {
        const data: QuoteOrderResponse = response?.data;
        let charges: ChargeDto[] = [];
        data.routes?.forEach((route) => {
          route.results?.forEach((result) => {
            if (result.charges) charges = [...charges, ...result.charges];
          });
        });

        setTotalCost(
          charges.reduce((sum, currentCharge) => {
            if (currentCharge?.totalAmount) {
              return sum + currentCharge?.totalAmount;
            }
            return sum;
          }, 0),
        );
        setCalculatedCharges(charges);

        const chargesCommands = charges?.map((charge) => {
          const command: CreateChargeCommand = {
            organizationId: process.env
              .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
            values: {
              ...charge,
              chargeStatus:
                charge.accountingItemCode == config?.insuranceAccountingItemCode
                  ? "Open"
                  : "Pending",
            },
          };
          return command;
        });
      });
    }
  }, []);

  return (
    <Grid>
      <Grid container width={"100%"}>
        <Grid md={6} xs={12}>
          <Box sx={{position: "sticky", top: "100px"}}>
            <TotalCostParcels
              deliveryCostPerUnit={deliveryCostPerUnit(
                thisOrder,
                t,
                config?.showTotalCost,
              )}
              charges={calculatedCharges}
              showTotalCost={config?.showTotalCost}
              insuranceItemCode={config?.insuranceAccountingItemCode}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid mt={4} container width={"100%"} mb={2}>
        <Grid md={6} xs={12}>
          <Grid mb={2}>
            <Typography variant={"h3"} mb={1}>
              <Trans i18nKey={i18KeyPrefix + "provideYourCardDetails"}>
                Provide your card details
              </Trans>
            </Typography>
          </Grid>
          <Grid ref={paymentCardFormRef}>
            <PaymentCardForm onChange={handleChangePaymentCardForm} />
          </Grid>
        </Grid>
      </Grid>

      <Grid mb={2} md={6} xs={6}>
        <FormControl>
          <RadioGroup
            value={useBillingAddress}
            onChange={handleUseBillingAddressChange}
            row
          >
            <FormControlLabel
              control={<Radio />}
              label={t(i18KeyPrefix + "useBillingAddress")}
              value={true}
            />
            <FormControlLabel
              control={<Radio />}
              label={t(i18KeyPrefix + "enterDifferentAddress")}
              value={false}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid container width={"100%"} mb={4}>
        <Grid md={6} xs={12}>
          <Collapse in={!useBillingAddress}>
            <Typography variant={"h3"} mb={1}>
              <Trans i18nKey={i18KeyPrefix + "billingAddress"}>
                Billing Address
              </Trans>
            </Typography>
            <AddressForm
              onChange={handleChangeAddressForm}
              countryFilter={CountryFilter.Default}
            />
          </Collapse>
        </Grid>
      </Grid>

      <Grid
        width={"100%"}
        mb={4}
        display={"flex"}
        justifyContent={"flex-start"}
      >
        <Grid
          xs={6}
          md={4}
          justifyContent={"center"}
          mt={{md: 0, xs: 2}}
          mb={{md: 5, xs: 1}}
          mx={{md: 0, xs: 2}}
        >
          <LoadingButton
            data-testid="btn-pay-and-print-label"
            fullWidth
            type="button"
            variant="contained"
            color="secondary"
            sx={{
              p: 1,
            }}
            loading={isSubmitting}
            loadingPosition="center"
            endIcon={<></>}
            onClick={() => handleSubmit()}
          >
            <Typography variant="body3" textTransform={"none"}>
              <Trans i18nKey={i18KeyPrefix + "submitBtn"}>
                Pay & Print Label
              </Trans>
            </Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
