import React, {useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {AccountingTransactionGqlDto} from "../../../features/accountingTransaction/accountingTransaction-api";
import {getFormattedDate} from "../../../../utils/formatting.utils";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {AccountingTransactionStatus} from "../../../features/order/order-api";

export type InvoiceCardProps = {
  invoice: AccountingTransactionGqlDto;
};

export function getInvoiceStatusColors(status: AccountingTransactionStatus) {
  switch (status) {
    case "Paid":
      return {color: "#00BD75", backgroundColor: "#00BD7525"};
    case "Open" || "Void":
      return {color: "#FF7D00", backgroundColor: "#FF7D0025"};
    default:
      return {color: "#6C757D", backgroundColor: "#6C757D25"};
  }
}

export default function InvoiceCard({invoice}: InvoiceCardProps) {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const {t} = useTranslation();

  return (
    <Card
      sx={{
        marginTop: 2,
        border: "1px solid #D8E6FB",
        borderRadius: "16px",
        boxShadow: "none",
        padding: 2,
      }}
    >
      <Grid>
        <Grid container mb={1} alignItems={"center"} flexWrap={"wrap"}>
          <Typography variant="body4">
            <Trans i18nKey={"invoices.invoiceNumber"}>Invoice number</Trans>
          </Typography>
          &nbsp;
          <Link
            style={{textDecoration: "none"}}
            to={{
              pathname: `../invoices/${invoice.accountingTransactionId}/tracking/${invoice.transactionNumber}`,
            }}
            state={{invoice}}
          >
            <Typography variant="body4" color={"primary"}>
              {invoice?.transactionNumber}
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Chip
        label={
          invoice?.accountingTransactionStatus?.toLowerCase() === "paid"
            ? t("invoices.statuses.paid")
            : t("invoices.statuses.notPaid")
        }
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          height: "24px",
          p: "0 6px",
          ...getInvoiceStatusColors(
            invoice?.accountingTransactionStatus ?? "Void",
          ),
        }}
      />
      <Accordion
        disableGutters
        elevation={0}
        square
        sx={{
          boxShadow: 0,
          "&:before": {
            display: "none",
          },
          ".Mui-expanded": {
            minHeight: "0px !important",
          },
        }}
      >
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setShowDetails(!showDetails)}
          sx={{
            padding: 0,
            outline: "none",
            minHeight: 0,
            marginTop: "6px",

            "&:focus": {
              background: "inherit",
            },

            ".MuiAccordionSummary-content": {
              marginTop: "12px",
              marginBottom: "0",
            },
          }}
        >
          <Typography variant="caption2" sx={{color: "#2F81EF"}}>
            {showDetails
              ? t("invoices.hideDetails")
              : t("invoices.showDetails")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{padding: 0, paddingTop: 2}}>
          <CardContent sx={{padding: 0, paddingBottom: "0!important"}}>
            <Grid>
              <Typography variant="caption3">
                <Trans i18nKey={"invoices.shipmentNumber"}>
                  Shipment number
                </Trans>
                :{" "}
              </Typography>
              {invoice?.jobs?.map((job) => {
                return job.orders
                  ?.filter((o) => o.orderType === "ParcelShipment")
                  .map((parcelShipment, index) => {
                    return (
                      <Link
                        style={{textDecoration: "none"}}
                        key={parcelShipment?.orderId ?? "" + index}
                        to={
                          parcelShipment?.orderId
                            ? {
                                pathname: `../parcelShipments/${parcelShipment?.orderId}`,
                              }
                            : {
                                pathname: undefined,
                              }
                        }
                        state={{invoice}}
                      >
                        <Typography variant="caption3" color={"primary"}>
                          {parcelShipment?.trackingNumber ?? ""}
                        </Typography>
                      </Link>
                    );
                  });
              })}
            </Grid>
            <Grid>
              <Typography variant="caption3">
                {t("invoices.creationDate")}:{" "}
              </Typography>
              <Typography variant="caption3">
                {getFormattedDate(invoice?.created || "")}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption3">
                {t("invoices.paidDate")}:{" "}
              </Typography>
              <Typography variant="caption3">
                {getFormattedDate(invoice?.paidDate || "")}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption3">
                {t("invoices.dueDate")}:{" "}
              </Typography>
              <Typography variant="caption3">
                {getFormattedDate(invoice?.dueDate || "")}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption3">
                {t("invoices.amount")}:{" "}
              </Typography>
              <Typography variant="caption3">{invoice.totalAmount}</Typography>
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
