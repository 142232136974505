import React, {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import ResetPasswordForm from "../components";
import SplitPage from "../../common/SplitPage/components/splitPage";
import LinkExpired from "../components/linkExpired";
import {usePasswordVerify} from "../hooks/usePasswordVerify";
import {useResetPasswordSubmit} from "../hooks/useResetPasswordSubmit";
import {toastError} from "../../common/utils/toastMessages";

const ResetPassword = () => {
  const {t} = useTranslation();

  const [isValidLink, setIsValidLink] = useState<null | boolean>(null);

  const {
    verify,
    isLoading: isVerifyLoading,
    error: verifyError,
    isSuccess: isVerifySuccess,
    isError: isVerifyError,
  } = usePasswordVerify();

  const {
    notificationStatus,
    setNotificationStatus,
    isLoading,
    isError,
    isSuccess,
    submit,
  } = useResetPasswordSubmit();

  useEffect(() => {
    verify();
  }, []);

  useEffect(() => {
    if (isVerifySuccess) {
      setIsValidLink(true);
    }
    if (isVerifyError) {
      toastError(verifyError);
      if (
        (verifyError as any).data.detail ===
        "Password reset failed. Link expired."
      )
        setIsValidLink(false);
    }
  }, [isVerifyLoading]);

  useEffect(() => {
    if (isSuccess) {
      setNotificationStatus(true);
    }
    if (isError) {
      setNotificationStatus(false);
    }
  }, [isLoading]);

  return (
    <>
      {isValidLink ? (
        <SplitPage
          contentSide="left"
          main={
            <ResetPasswordForm
              onSubmit={submit}
              notificationStatus={notificationStatus}
            />
          }
          pageTitle="Reset Password"
        />
      ) : (
        isValidLink === false && (
          <SplitPage
            contentSide="left"
            main={<LinkExpired />}
            pageTitle="Reset Password"
          />
        )
      )}
    </>
  );
};

export default ResetPassword;
