import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ContentLayout from "../../common/ContentLayout/components/contentLayout";
import MainLayout from "../../common/MainLayout/components/mainLayout";
import AdditionalServiceFragment from "../AdditionalServices/components/additionalServiceFragment";
import DeliveryMethodFragment from "../DeliveryMethod/components/deliveryMethodFragment";
import {useMediaQuery, useTheme} from "@mui/material";
import StatusBar from "../components/statusBar";
import ConfirmationFragment from "../Confirmation/components/confirmationFragment";
import CustomsDeclarationFragment from "../Declaration/components";
import DeliveryAddressFragment from "../DeliveryAddress/components/deliveryAddressFragment";
import PaymentMethodFragment from "../PaymentMethod/components/paymentMethodFragment";
import {
  OrderSlice,
  setStepState,
} from "../../features/order/parcelShipment-slice";
import {useDispatch} from "react-redux";
import {ContactPaymentMethodDto} from "../../features/paymentMethod/paymentMethod-api";
import SelectPurchasesFragment from "../SelectPurchases/components/selectPurchasesFragment";
import {useOrganizationConfig} from "../../common/OrganizationConfig/useOrganizationConfig";
import {PortalOrganizationConfigDto} from "../../features/organizationConfig/organizationConfig-api";
import {useContinueVerificationModal} from "../hooks/useContinueVerificationModal";
import {checkObjectChangeByPath} from "../../common/utils/checkObjectChangeByPath";
import {useDeliveryHelper} from "../hooks/useDeliveryHelper";

type DeliveryStep = {
  key: string;
  component: JSX.Element;
  title: string;
  statusBarTitle: string;
  dependencies?: string[];
  isDisabled?: boolean;
};

type DeliveryState = {
  orderData: OrderSlice;
};

const Delivery = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<ContactPaymentMethodDto | null>(null);
  const dispatch = useDispatch();

  const {moduleConfig} =
    useOrganizationConfig() || ({} as PortalOrganizationConfigDto);
  const deliveryConfig = moduleConfig?.customValues?.delivery;

  const {render: renderContinueVerificationModal} =
    useContinueVerificationModal();

  const {
    orderIds,
    stepKey,
    orderIdsParam,
    ordersData,
    currentOrderData,
    isConsolidated,
    completedSteps,
  } = useDeliveryHelper();

  const navigateToStep = (step: number) => {
    navigate(getLink(step));
  };

  const handlePrev = () => {
    const currentStep = getCurrentStepFromQuery();
    if (currentStep) {
      navigateToStep(currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const getLink = (step: number) => {
    if (step < 0) {
      return "../purchases";
    }
    const stepToNavigate = steps[step];
    return `../delivery/${orderIdsParam}/${
      stepToNavigate.key?.toString() ?? ""
    }`;
  };

  const handleNext = () => {
    if (completedSteps && completedSteps?.[currentStepIndex] === true) {
      const firstNotCompletedStep = getFirstNotCompletedStep(completedSteps);
      navigateToStep(firstNotCompletedStep);
    } else {
      dispatch(
        setStepState({
          orderIds: orderIds,
          step: currentStepIndex,
          isCompleted: true,
        }),
      );
      const firstNotCompletedStep = getFirstNotCompletedStep({
        ...completedSteps,
        [currentStepIndex]: true,
      });
      navigateToStep(firstNotCompletedStep);
    }
    window.scrollTo(0, 0);
  };

  const getLinkByStepKey = (stepKey: string) => {
    if (steps?.some((step) => step?.key == stepKey)) {
      return `../delivery/${orderIdsParam}/${stepKey}`;
    }
    return null;
  };

  const steps: DeliveryStep[] = useMemo(() => {
    return [
      {
        key: "selectPurchases",
        component: (
          <SelectPurchasesFragment
            handleNext={handleNext}
            pageTitle={"Ship Request - Select Purchases"}
          />
        ),
        title: t("delivery.statusBar.selectPurchasesTitle"),
        statusBarTitle: t("delivery.statusBar.selectPurchases"),
        isDisabled: !isConsolidated,
      },
      {
        key: "address",
        component: (
          <DeliveryAddressFragment
            handleNext={handleNext}
            pageTitle={"Ship Request - Delivery Address"}
          />
        ),
        title: t("delivery.statusBar.deliveryAddress"),
        statusBarTitle: t("delivery.statusBar.deliveryAddress"),
      },
      {
        key: "customs",
        component: (
          <CustomsDeclarationFragment
            handleNext={handleNext}
            pageTitle="Ship Request - Customs Declaration"
            config={deliveryConfig}
          />
        ),
        title: t("delivery.declaration.fillOutCustomsDeclarations"),
        statusBarTitle: t("delivery.statusBar.customsDeclaration"),
        dependencies: [
          "orderData.order.consigneeValues",
          "orderData.order.consigneeAddressValues",
        ],
      },
      {
        key: "method",
        component: (
          <DeliveryMethodFragment
            handleNext={handleNext}
            pageTitle="Ship Request - Delivery Methods"
            config={deliveryConfig}
          />
        ),
        title: t("delivery.declaration.deliveryMethod"),
        statusBarTitle: t("delivery.statusBar.deliveryMethod"),
        dependencies: [
          "orderData.order.consigneeValues",
          "orderData.order.consigneeAddressValues",
          "orderData.order.containerCommodities[0].customValues.dangerousItems",
        ],
      },
      {
        key: "additional",
        component: (
          <AdditionalServiceFragment
            handleNext={handleNext}
            pageTitle="Ship Request - Additional Services"
            config={deliveryConfig}
          />
        ),
        title: t("delivery.statusBar.additionalServices"),
        statusBarTitle: t("delivery.statusBar.additionalServices"),
      },
      {
        key: "payment",
        component: (
          <PaymentMethodFragment
            handleNext={handleNext}
            withInfoBox={true}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            pageTitle="Ship Request - Payment Methods"
          />
        ),
        title: t("delivery.statusBar.paymentMethod"),
        statusBarTitle: t("delivery.statusBar.paymentMethod"),
      },
      {
        key: "confirmation",
        component: (
          <ConfirmationFragment
            switchFragment={(stepKey: string) => {
              const link = getLinkByStepKey(stepKey);
              if (link) navigate(link);
              return;
            }}
            isConsolidated={isConsolidated}
            config={deliveryConfig}
          />
        ),
        title: t("delivery.statusBar.confirmation"),
        statusBarTitle: t("delivery.statusBar.confirmation"),
      },
    ].filter((x) => x.isDisabled !== true);
  }, [
    isConsolidated,
    handleNext,
    deliveryConfig,
    navigateToStep,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    t,
  ]);

  const getCurrentStepFromQuery = () => {
    if (stepKey) {
      return steps.findIndex((x) => x.key == stepKey) + 1;
    }
  };

  const [previousState, setPreviousState] = useState<DeliveryState>();
  const currentState: DeliveryState = useMemo(() => {
    return {
      orderData: currentOrderData,
    };
  }, [ordersData]);

  const getFirstNotCompletedStep = (completedSteps: {
    [step: number]: boolean;
  }) => {
    for (let step = 0; step < steps.length; step++) {
      if (!completedSteps?.[step]) {
        return step;
      }
    }
    return steps.length;
  };

  const currentStepIndex = useMemo(() => {
    const index = steps.findIndex((step) => step.key === stepKey);
    if (index < 0) {
      return 0;
    }
    return index;
  }, [steps, stepKey]);

  const checkStepsResetConditions = useCallback(
    (previousState: DeliveryState, currentState: DeliveryState) => {
      if (completedSteps) {
        Object.keys(completedSteps).forEach((stepStr) => {
          const step = parseInt(stepStr, 10);
          const currentStep = steps?.[step];
          if (
            step &&
            completedSteps[step] === true &&
            step > currentStepIndex &&
            currentStep?.dependencies
          ) {
            const stepDependencies = currentStep.dependencies;
            stepDependencies?.forEach((stepDependencyPath) => {
              const isStepDependencyChanged = checkObjectChangeByPath(
                previousState,
                currentState,
                stepDependencyPath,
              );
              if (isStepDependencyChanged) {
                dispatch(
                  setStepState({
                    orderIds: orderIds,
                    step: step,
                    isCompleted: false,
                  }),
                );
                return;
              }
            });
          }
        });
      }
    },
    [steps, completedSteps, currentStepIndex],
  );

  useEffect(() => {
    if (previousState) checkStepsResetConditions(previousState, currentState);
    setPreviousState(currentState);
  }, [currentState]);

  useEffect(() => {
    if (!stepKey) {
      const firstNotCompletedStep = getFirstNotCompletedStep(completedSteps);
      navigateToStep(firstNotCompletedStep);
      window.scrollTo(0, 0);
    }
  }, [stepKey, getFirstNotCompletedStep, completedSteps, navigateToStep]);

  return (
    <>
      <MainLayout
        handlePrevStep={handlePrev}
        statusBar={
          <StatusBar
            variant={isDesktop ? "linear" : "radial"}
            step={currentStepIndex}
            setStep={(step: number) => navigateToStep(step)}
            steps={steps?.map((x) => x.statusBarTitle)}
          />
        }
        pageTitle="Ship Request"
      >
        <ContentLayout
          content={steps[currentStepIndex]?.component}
          title={
            (
              <Trans i18nKey={steps[currentStepIndex]?.title} />
            ) as React.SetStateAction<any>
          }
          backLink={getLink(currentStepIndex - 1)}
          withoutTitle={
            currentStepIndex ===
            steps?.findIndex((step) => step.key === "additional")
          }
          withoutMobileTitle={true}
          handlePrev={handlePrev}
        />
      </MainLayout>
      {renderContinueVerificationModal()}
    </>
  );
};

export default Delivery;
