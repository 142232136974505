import React, {useEffect, useState} from "react";
import {
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Field, useFormikContext} from "formik";
import {TextField} from "formik-mui";
import {Trans, useTranslation} from "react-i18next";
import {CreateCommodityPortalCommand} from "../../../features/order/order-api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalForm from "../../../common/Modal/components/modalForm";
import RemoveItemModal from "./removeItemModal";
import {PurchaseItemDetailsProps} from "../pages/interfaces";
import {LoadingButton} from "@mui/lab";
import {useParams} from "react-router-dom";
import {useGetCommodityTypes} from "../hooks/useGetCommodityTypes";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {validatePositiveIntegerNumberInput} from "../pages/validation";

export default function PurchaseItemDetails({
  index,
  uuid,
  deleteItem,
  isProductLinkFieldVisible = true,
  isTotalPriceFieldVisible = false,
  defaultValues,
  onTotalChange,
  isDeclaration = false,
}: PurchaseItemDetailsProps) {
  const {t} = useTranslation();
  const formikContext = useFormikContext<any>();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const params = useParams();
  const isCreateMode = Number(params.purchaseId) == 0;

  const initialValues: CreateCommodityPortalCommand = {
    uuid: uuid,
    description: "",
    note: "",
    quantity: undefined,
    unitaryValue: undefined,
    weight: undefined,
    commodityTypeId: undefined,
    unit: undefined,
  };

  const [checked, setChecked] = useState<boolean>(true);

  const [commodity] = useState<CreateCommodityPortalCommand>(
    defaultValues || initialValues,
  );

  const {getCommodityTypes} = useGetCommodityTypes();

  useEffect(() => {
    const filter = commodity?.commodityTypeId
      ? `isActive:true OR commodityTypeId:${commodity.commodityTypeId}`
      : "isActive:true";
    getCommodityTypes({
      limit: 100000,
      filter: filter,
    });
  }, []);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const collapsedDesktopSize = 40;
  const collapsedMobileSize = 60;

  return (
    <Grid
      position={"relative"}
      zIndex={50}
      mx={{xs: -2, md: 0}}
      mt={{xs: -1, md: 0}}
    >
      <Grid
        container
        sx={{
          position: "absolute",
          display: {xs: "flex", md: "none"},
          backgroundColor: "error.main",
          alignItems: "center",
          justifyContent: "end",
          pt: -1,
          zIndex: -1,
          width: "100%",
          height: "100%",
        }}
      >
        <IconButton sx={{zIndex: 100, mr: "19px"}} onClick={handleOpen}>
          <DeleteIcon sx={{fontSize: "20px", color: "primary.white"}} />
        </IconButton>
      </Grid>
      <Grid sx={{backgroundColor: "white"}}>
        <Grid
          container
          columns={{xs: 6, md: 12}}
          sx={{
            justifyContent: "center",
            mb: {xs: checked ? 2 : 0, md: 0},
            mt: {xs: 1, md: 0},
          }}
        >
          <Grid md />
          <Grid xs={6} md={10} mt={{xs: 1, md: 0}}>
            <Collapse
              in={checked}
              collapsedSize={
                isDesktop ? collapsedDesktopSize : collapsedMobileSize
              }
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent={isDesktop ? "start" : "space-between"}
                mb={{xs: checked ? 0 : 2, md: 2}}
                onClick={isDesktop ? () => undefined : handleChange}
              >
                <Typography variant="h3" component="h3">
                  <Trans i18nKey="item">Item</Trans>
                </Typography>
                {isDesktop ? (
                  <IconButton
                    onClick={handleChange}
                    sx={{color: "primary.dark", ml: 1}}
                    data-testid={"btn-collapse-item"}
                  >
                    {checked ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                ) : checked ? (
                  <KeyboardArrowUpIcon sx={{color: "primary.dark", my: 2}} />
                ) : (
                  <KeyboardArrowDownIcon sx={{color: "primary.dark", my: 2}} />
                )}
                {isDesktop && (
                  <Grid container flex={1} sx={{justifyContent: "end"}}>
                    <IconButton
                      onClick={handleOpen}
                      sx={{color: "primary.dark"}}
                      data-testid={"btn-delete-item"}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Stack spacing={{xs: 2, md: 3}}>
                <Field
                  fullWidth
                  component={TextField}
                  name={`commodities[${index}].description`}
                  size={isDesktop ? "normal" : "small"}
                  label={t("descriptionPurchaseItem")}
                  placeholder="Blue woman jeans"
                  data-testid="input-commodity-description"
                  disabled={formikContext.isSubmitting}
                />
                {isProductLinkFieldVisible ? (
                  <Field
                    fullWidth
                    component={TextField}
                    name={`commodities[${index}].note`}
                    size={isDesktop ? "normal" : "small"}
                    label={t("linkPurchaseItem")}
                    placeholder="https://..."
                    data-testid="input-commodity-link"
                    disabled={formikContext.isSubmitting}
                  />
                ) : null}
                <Grid
                  container
                  direction={"row"}
                  columns={{xs: isTotalPriceFieldVisible ? 4 : 6, md: 3}}
                >
                  <Grid xs={2} md={1} sx={{pr: {xs: 1, md: 1}}}>
                    <Field
                      fullWidth
                      component={TextField}
                      name={`commodities[${index}].quantity`}
                      size={isDesktop ? "normal" : "small"}
                      label={t("quantityPurchaseItem")}
                      placeholder="0"
                      data-testid="input-commodity-quantity"
                      disabled={formikContext.isSubmitting}
                      onKeyDown={validatePositiveIntegerNumberInput}
                    />
                  </Grid>
                  <Grid xs={2} md={1} sx={{px: {xs: 1, md: 1}}}>
                    <Field
                      fullWidth
                      component={TextField}
                      name={`commodities[${index}].unitaryValue`}
                      size={isDesktop ? "normal" : "small"}
                      label={t("pricePerItemPurchaseItem")}
                      placeholder={getFormattedPrice(0)}
                      data-testid="input-commodity-price"
                      disabled={formikContext.isSubmitting}
                    />
                  </Grid>
                  <Grid
                    xs={2}
                    md={1}
                    sx={{pl: {xs: 1, md: 1}}}
                    display={isTotalPriceFieldVisible ? "none" : "initial"}
                  >
                    <Field
                      fullWidth
                      component={TextField}
                      name={`commodities[${index}].totalPrice`}
                      size={isDesktop ? "normal" : "small"}
                      label={t("totalPricePurchaseItem")}
                      placeholder={getFormattedPrice(0)}
                      disabled={formikContext.isSubmitting}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={
                        formikContext.values.commodities[index]?.quantity &&
                        formikContext.values.commodities[index]?.unitaryValue
                          ? (
                              formikContext.values.commodities[index].quantity *
                              formikContext.values.commodities[index]
                                .unitaryValue
                            ).toFixed(2)
                          : ""
                      }
                      data-testid="input-purchase-item-total-price"
                    />
                  </Grid>
                  <Grid xs={8} display={{xs: "flex", md: "none"}} mt={2}>
                    <LoadingButton
                      sx={{
                        p: 1,
                      }}
                      fullWidth
                      type="button"
                      variant="outlined"
                      color="error"
                      data-testid="btn-delete-item-purchase"
                      loading={open}
                      onClick={handleOpen}
                      loadingPosition="end"
                    >
                      <Trans i18nKey="delete">Delete</Trans>
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Stack>
            </Collapse>
          </Grid>
          <Grid md />
        </Grid>
        <Divider
          sx={{
            borderBlockColor: isDeclaration ? "#F5F9FF" : "#D8E6FB",
            mt: {xs: 0, md: 3},
            mb: {xs: 0, md: 3},
            display: {
              xs: "flex",
              md:
                !isCreateMode && formikContext.values?.commodities?.length === 1
                  ? "none"
                  : index === formikContext.values?.commodities?.length - 1
                  ? "none"
                  : "flex",
            },
          }}
        />
      </Grid>

      <ModalForm open={open} setOpen={setOpen}>
        <RemoveItemModal
          closeModal={handleClose}
          deleteItem={deleteItem}
          uuid={uuid}
          itemNumber={index + 1}
          onTotalChange={onTotalChange}
        />
      </ModalForm>
    </Grid>
  );
}
