import React, {useState} from "react";
import {OrderGQLDto} from "../../../features/order/order-api";
import {Trans, useTranslation} from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {getFormattedDate} from "../../../../utils/formatting.utils";
import {NavLink, useParams} from "react-router-dom";
import {useParcelShipmentData} from "../hooks/useShipmentData";
import {getCustomValue, stageColor} from "../../../../utils/helper.utils";
import {getContainerTotalWeight} from "./parcelShipmentTableRow";

interface ParcelShipmentCardProps {
  parcelShipment: OrderGQLDto;
}

export default function ParcelShipmentCard({
  parcelShipment,
}: ParcelShipmentCardProps) {
  const {lang} = useParams();
  const {t} = useTranslation();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const {invoices, statusColor, consignee} =
    useParcelShipmentData(parcelShipment);

  const invoicesTableCell = () => {
    const invoicesToShow = invoices?.filter(
      (invoice) => invoice.accountingTransactionId && invoice.transactionNumber,
    );
    return invoicesToShow?.length ? (
      invoicesToShow.map((invoice, index) => (
        <NavLink
          to={`../invoices/${invoice.accountingTransactionId}/tracking/${invoice.transactionNumber}`}
          data-testid={"link-to-order-details"}
          style={{
            textDecoration: "none",
          }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          key={`navlink-invoice-${index}`}
        >
          <Box
            component="span"
            sx={{
              lineBreak: "anywhere",
              color: "primary.main",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Typography variant="caption3">
              {`${invoice.transactionNumber}${
                index < invoicesToShow.length - 1 ? ", " : ""
              }`}
            </Typography>
          </Box>
        </NavLink>
      ))
    ) : (
      <Typography variant="caption3" sx={{color: "primary.main"}}>
        -
      </Typography>
    );
  };

  return (
    <Card
      sx={{
        border: "1px solid #D8E6FB",
        boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
        padding: "1rem 1rem 0",
        borderRadius: "16px",
        background: "primary.white",
        width: "100%",
      }}
    >
      <Grid>
        <Typography variant="body4">
          {t("parcelShipments.allParcels.trtShipmentNumber")}{" "}
        </Typography>
        <NavLink
          to={`./${parcelShipment.orderId}`}
          data-testid={"link-to-order-details"}
          style={{
            textDecoration: "none",
          }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <Typography
            variant="body4"
            sx={{
              color: "primary.main",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {"#"}
            {parcelShipment?.trackingNumber || "-"}
          </Typography>
        </NavLink>
      </Grid>
      <Box
        sx={{
          borderRadius: "16px",
          background: "primary.white",
          paddingX: "16px",
          width: "fit-content",
          marginTop: "12px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          ...stageColor(
            parcelShipment?.orderStatus?.customValues &&
              `portal_stage_color` in parcelShipment.orderStatus.customValues
              ? parcelShipment.orderStatus.customValues[`portal_stage_color`]
              : undefined,
          ),
        }}
      >
        <Typography variant="caption1">
          {parcelShipment?.orderStatus?.customValues &&
          `portal_name_${lang}` in parcelShipment.orderStatus.customValues
            ? parcelShipment.orderStatus.customValues[`portal_name_${lang}`]
            : parcelShipment?.orderStatus?.orderStatusName}
        </Typography>
      </Box>
      <Accordion
        disableGutters
        elevation={0}
        square
        sx={{
          boxShadow: 0,
          "&:before": {
            display: "none",
          },
          ".Mui-expanded": {
            minHeight: "0px !important",
          },
        }}
      >
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setShowDetails(!showDetails)}
          sx={{
            padding: 0,
            outline: "none",
            minHeight: 0,
            marginTop: "6px",

            "&:focus": {
              background: "inherit",
            },

            ".MuiAccordionSummary-content": {
              marginTop: "12px",
              marginBottom: "18px",
            },
          }}
        >
          <Typography variant="caption2" sx={{color: "primary.main"}}>
            {showDetails
              ? t("parcelShipments.shipmentCard.hideDetails")
              : t("parcelShipments.shipmentCard.showDetails")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{padding: 0}}>
          <CardContent sx={{padding: 0}}>
            <Grid>
              <Typography variant="caption3">
                <Trans i18nKey="parcelShipments.allParcels.invoice">
                  Invoice
                </Trans>
                {": "}
              </Typography>
              {invoicesTableCell()}
            </Grid>
            <Grid>
              <Typography variant="caption3">
                <Trans i18nKey="parcelShipments.allParcels.creationDate">
                  Creation date
                </Trans>
                {": "}
              </Typography>
              <Typography variant="caption3" sx={{color: "primary.dark"}}>
                {getFormattedDate(parcelShipment.created ?? "")}
              </Typography>
            </Grid>{" "}
            <Grid>
              <Typography variant="caption3">
                <Trans i18nKey="parcelShipments.allParcels.transportMethod">
                  Transport method
                </Trans>
                {": "}
              </Typography>
              <Typography variant="caption3" sx={{color: "primary.dark"}}>
                {t(
                  `transportMethods.${
                    getCustomValue(
                      parcelShipment.customValues,
                      "modeOfTransportationName",
                    ) ??
                    getCustomValue(
                      parcelShipment.customValues,
                      "modeOfTransportationId",
                    )
                  }`,
                )}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption3">
                <Trans i18nKey="parcelShipments.allParcels.destination">
                  Destination
                </Trans>
                {": "}
              </Typography>
              <Typography variant="caption3" sx={{color: "primary.dark"}}>
                {consignee?.contactAddress?.formattedAddress || "-"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption3">
                <Trans i18nKey="parcelShipments.allParcels.consignee">
                  Consignee
                </Trans>
                {": "}
              </Typography>
              <Typography variant="caption3" sx={{color: "primary.dark"}}>
                {consignee?.contact?.name || "-"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption3">
                <Trans i18nKey="parcelShipments.allParcels.weight">
                  Weight
                </Trans>
                {": "}
              </Typography>
              <Typography variant="caption3" sx={{color: "primary.dark"}}>
                {getContainerTotalWeight(
                  parcelShipment.commodities?.[0]?.commodity,
                ) ?? "-"}
              </Typography>
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
