import React from "react";
import {Box, Button, SxProps, Theme, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ArrowRightAlt} from "@mui/icons-material";

type SortProps = {
  sortParam: string;
  containerSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  defaultSortEarliestValue?: boolean;
};

export default function Sort({
  sortParam,
  containerSx = {},
  buttonSx = {},
  defaultSortEarliestValue = undefined,
}: SortProps) {
  const url = new URL(location.href);
  const sort = url.searchParams.get("sort");
  const [sortEarliest, setSortEarliest] = React.useState<boolean | undefined>(
    defaultSortEarliestValue !== undefined
      ? defaultSortEarliestValue
      : sort?.startsWith("-") || sort === "",
  );
  const navigate = useNavigate();
  return (
    <Box sx={{mt: 2, ...containerSx}}>
      <Button
        onClick={() => {
          setSortEarliest(!sortEarliest);
          url.searchParams.set(
            "sort",
            sortEarliest ? sortParam : `-${sortParam}`,
          );
          navigate(url.search);
        }}
        sx={{height: "auto", borderColor: "grey.500", ...buttonSx}}
        variant="outlined"
        data-testid="btn-sort-by-date"
      >
        <Typography
          sx={{display: "flex", flexWrap: "nowrap", textTransform: "none"}}
          color="black"
          variant="body1"
        >
          <Trans i18nKey={"sortByDate"}>Sort by: Date</Trans>
        </Typography>
        {sortEarliest ? (
          <ArrowRightAlt
            fontSize={"small"}
            sx={{
              color: "#393A46",
              transform: "rotate(90deg)",
            }}
          />
        ) : (
          <ArrowRightAlt
            fontSize={"small"}
            sx={{
              color: "#393A46",
              transform: "rotate(-90deg)",
            }}
          />
        )}
      </Button>
    </Box>
  );
}
