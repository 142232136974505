import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    modeOfTransportationsGetModeOfTransportation: build.query<
      ModeOfTransportationsGetModeOfTransportationApiResponse,
      ModeOfTransportationsGetModeOfTransportationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/modeOfTransportations/${queryArg.modeOfTransportationId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type ModeOfTransportationsGetModeOfTransportationApiResponse =
  /** status 200  */ ModeOfTransportationDto;
export type ModeOfTransportationsGetModeOfTransportationApiArg = {
  organizationId: number;
  modeOfTransportationId: number;
};
export type TransportationMethod =
  | "Ocean"
  | "Rail"
  | "Ground"
  | "Air"
  | "Mail"
  | "Unknown"
  | "Pipe";
export type TransportationMethodDescription =
  | "Vessel"
  | "VesselAndContainerized"
  | "Barge"
  | "Rail"
  | "RailAndContainerized"
  | "Truck"
  | "TruckAndContainerized"
  | "Auto"
  | "Pedestrian"
  | "RoadAndOther"
  | "Air"
  | "AirAndContainerized"
  | "Mail"
  | "PassengerAndHandCarried"
  | "FixedTransport";
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type ModeOfTransportationDto = {
  modeOfTransportationId?: number;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  transportationMethod?: TransportationMethod;
  transportationMethodDescription?: TransportationMethodDescription;
  usCustomsCode?: string | null;
  customValues?: {
    [key: string]: string;
  } | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  links?: LinkDto[] | null;
};
export const {useModeOfTransportationsGetModeOfTransportationQuery} =
  injectedRtkApi;
