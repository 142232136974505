import React from "react";
import {Box, IconButton, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PurchaseItemDetails from "./purchaseItemDetails";
import {Trans} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {FieldArray, useFormikContext} from "formik";
import {
  CreateCommodityPortalCommand,
  PurposeOfCommodityValues,
  WeightUnits,
} from "../../../features/order/order-api";
import uuid from "react-uuid";
import {PurchaseItemsListProps} from "../pages/interfaces";

const addingItem = () => {
  const addedCommodity: CreateCommodityPortalCommand = {
    uuid: uuid(),
    description: "",
    note: "",
    quantity: undefined,
    unitaryValue: undefined,
    commodityTypeId: undefined,
    unit: undefined,
    customValues: {
      purposeOfCommodity: PurposeOfCommodityValues.Gift,
      dangerousItems: "",
    },
    weightUnit: WeightUnits.Kg,
  };
  return addedCommodity;
};

export default function PurchaseItemsList({
  submitForm,
}: PurchaseItemsListProps) {
  const formikContext = useFormikContext<any>();

  return (
    <FieldArray name="commodities">
      {({push, remove}) => (
        <Box
          sx={{
            border: {xs: 0, md: "1px solid #D8E6FB"},
            borderRadius: {xs: 0, md: "16px"},
            boxShadow: {xs: 0, md: "0px 0px 8px rgba(15, 74, 132, 0.1)"},
            mb: {xs: 0, md: 6},
            height: "100%",
          }}
        >
          <Grid
            container
            columns={{xs: 6, md: 12}}
            sx={{display: {xs: "none", md: "flex"}, height: "100%"}}
          >
            <Grid md />
            <Grid xs={6} md={10}>
              <Typography
                variant="h3"
                component="h3"
                sx={{mt: {xs: 2, md: 5}, mb: 2}}
              >
                <Trans i18nKey="addPurchasedItems">
                  Add Items inside your Package
                </Trans>
              </Typography>
            </Grid>
            <Grid md />
          </Grid>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: {xs: 0, md: "16px"},
              height: "100%",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Grid>
                {formikContext.values?.commodities?.map(
                  (data: any, index: number) => {
                    return (
                      <PurchaseItemDetails
                        key={data.uuid}
                        uuid={data.uuid}
                        defaultValues={data}
                        index={index}
                        deleteItem={() => remove(index)}
                      />
                    );
                  },
                )}
                {formikContext.values?.commodities?.length === 0 && (
                  <Grid
                    container
                    columns={{xs: 6, md: 12}}
                    sx={{mb: {xs: 0, md: 3}, mt: {xs: 4, md: 3}}}
                  >
                    <Grid md />
                    <Grid xs={6} md={10} sx={{textAlign: "left"}}>
                      <Typography variant="h4" component="h4">
                        <Trans i18nKey="noItems">No items yet</Trans>
                      </Typography>
                    </Grid>
                    <Grid md />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                columns={{xs: 6, md: 12}}
                justifyContent="center"
                mb={3}
              >
                <Grid md />
                <Grid xs={6} md={10}>
                  <Grid container direction="row" justifyContent="end">
                    <IconButton
                      onClick={() => push(addingItem())}
                      color="primary"
                      sx={{mt: 1}}
                      data-testid="add-item-icon-button"
                      disabled={formikContext.isSubmitting}
                    >
                      {<AddBoxIcon />}
                    </IconButton>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 3,
                        mb: 2,
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      alignItems="end"
                      onClick={() => push(addingItem())}
                    >
                      <Trans
                        i18nKey={
                          formikContext.values?.commodities?.length > 0
                            ? "addOtherItem"
                            : "addItem"
                        }
                      >
                        Add other item
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid md />
              </Grid>
              <Grid display={{xs: "block", md: "none"}} mb="72px">
                <LoadingButton
                  sx={{
                    p: 1,
                    position: "sticky",
                  }}
                  fullWidth
                  type="submit"
                  form="addPurchaseForm"
                  variant="contained"
                  color="secondary"
                  data-testid="btn-save-purchase"
                  disabled={formikContext.isSubmitting}
                  loading={formikContext.isSubmitting}
                  loadingPosition="end"
                  endIcon={<></>}
                  onClick={() => submitForm()}
                >
                  <Trans i18nKey="save">Save</Trans>
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </FieldArray>
  );
}
