import React from "react";
import {Trans} from "react-i18next";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ProfileTabMenu from "../../components/profileTabMenu";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import EditAccountEmailForm from "../components";

const EditAccountEmail = () => {
  return (
    <MainLayout
      pageTitle="Email Details"
      backLink="../profile"
      statusBar={<ProfileTabMenu selectedTab={1} />}
    >
      <ContentLayout
        title={<Trans i18nKey={"profile.tabs.editEmail"}>Email editing</Trans>}
        backLink="../profile"
        content={<EditAccountEmailForm />}
        containerSx={{mb: "24px"}}
      />
    </MainLayout>
  );
};

export default EditAccountEmail;
