import * as yup from "yup";

export const getResetPasswordSchema = (t: any) => {
  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t("validation.common.passwordRequired"))
      .nullable(true),
    confirmPassword: yup
      .string()
      .required(t("validation.common.passwordRequired"))
      .nullable(true)
      .equals(
        [yup.ref("password")],
        t("validation.resetPassword.passwordMatch"),
      ),
  });

  return schema;
};
