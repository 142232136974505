import {Fade, ImageListItem, Modal} from "@mui/material";
import React, {useState} from "react";

type ImageWithModalProps = {
  refImg?: any | null;
  item: {
    uri: string;
    description?: string | null;
  };
};

export const ImageWithModal = ({item, refImg}: ImageWithModalProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = () => {
    setOpen(true);
  };

  return (
    <>
      <ImageListItem key={item.uri} sx={{borderRadius: "8px"}}>
        <img
          ref={refImg}
          style={{objectFit: "fill", cursor: "pointer"}}
          src={item.uri}
          alt={item?.description ?? ""}
          loading="lazy"
          onClick={handleImage}
        />
      </ImageListItem>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
      >
        <Fade in={open} timeout={500}>
          <img
            src={item.uri}
            alt="asd"
            style={{maxHeight: "80%", maxWidth: "80%"}}
          />
        </Fade>
      </Modal>
    </>
  );
};
