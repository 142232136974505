import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import EditPurchasesForm from "../components";
import i18next, {t} from "i18next";

const EditPurchases = () => {
  const [createMode, setCreateMode] = useState<boolean>(true);
  const [showItemsMobile, setShowItemsMobile] = useState<any>(false);

  const [titleMode, setTitleMode] = useState(t("titleAddPurchases"));

  type UrlParams = {
    purchaseId: string;
  };
  const {purchaseId} = useParams<keyof UrlParams>() as UrlParams;

  useEffect(() => {
    if (Number(purchaseId) != 0) {
      setCreateMode(false);
      setTitleMode(t("titleEditPurchases"));
    }
  }, [createMode]);

  useEffect(() => {
    createMode
      ? setTitleMode(t("titleAddPurchases"))
      : setTitleMode(t("titleEditPurchases"));
  }, [i18next.language]);

  return (
    <MainLayout
      backLink={!showItemsMobile && "../purchases"}
      showPrev={setShowItemsMobile}
      pageTitle="Purchase Information"
    >
      <ContentLayout
        title={titleMode}
        backLink="../purchases"
        content={
          <EditPurchasesForm
            showItemsMobile={showItemsMobile}
            setShowItemsMobile={setShowItemsMobile}
          />
        }
      />
    </MainLayout>
  );
};

export default EditPurchases;
