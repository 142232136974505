import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import i18next from "i18next";
import {toastError} from "../../../common/utils/toastMessages";
import {
  ContactGetContactApiArg,
  useContactGetContactMutation,
} from "../../../features/contact/contact-api";
import {useAppSelector} from "../../../store";
import {useTranslation} from "react-i18next";
import AddressVerificationFragment from "../../../Verifications/components/addressVerificationFragment";
import {VerifyAddressCommandValues} from "../../../features/verification/verification-api";
import {useNavigate} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {useDispatch} from "react-redux";
import {
  setBillingAddress,
  setNameSurname,
} from "../../../features/user/user-slice";

export default function EditResidentialAddressForm() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useAppSelector((state) => state.userState);
  const [billingAddressValues, setBillingAddressValues] =
    useState<VerifyAddressCommandValues | null>(null);
  const billingAddress = userState?.billingAddress;

  const [getContact] = useContactGetContactMutation();
  const getContactAsync = async (request: number | null | undefined) => {
    const commandArgs: ContactGetContactApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactId: request || 0,
    };
    return getContact(commandArgs);
  };

  const onAddressUpdate = (values: VerifyAddressCommandValues) => {
    dispatch(setBillingAddress(null));
    dispatch(
      setNameSurname({firstName: values.firstName, lastName: values.lastName}),
    );

    navigate("../profile");
  };

  useEffect(() => {
    if (!billingAddress) {
      getContactAsync(userState?.contactId)
        .then((response: any) => {
          if (response.data) {
            const billingAddress =
              response?.data?.contactAddresses?.find(
                (address: any) => address.addressType === "Billing",
              ) ?? null;
            if (billingAddress) {
              dispatch(setBillingAddress(billingAddress));
            }
          }
        })
        .catch((error) => {
          toastError(error);
        });
    } else {
      const addressValues: VerifyAddressCommandValues & {
        contactAddressId?: number;
      } =
        {
          contactAddressId: billingAddress.contactAddressId,
          contactId: billingAddress?.contactId ?? null,
          firstName: userState?.firstName ?? null,
          lastName: userState?.lastName ?? null,
          countryCode: billingAddress?.countryCode ?? null,
          regionCode: billingAddress?.stateCode ?? null,
          district: billingAddress?.customValues?.["toAddressDistrict"] ?? null,
          city: billingAddress?.cityName ?? null,
          postalCode: billingAddress?.postalCode ?? null,
          streetName: billingAddress?.addressLine ?? null,
          houseNumber:
            billingAddress?.customValues?.["toAddressHouseNumber"] ?? null,
          apartment: billingAddress?.addressLine2 ?? null,
          customValues: billingAddress?.customValues ?? null,
        } ?? null;

      setBillingAddressValues(addressValues);
    }
  }, [userState, i18next.language, t]);

  if (userState?.contactAddressConfirmed)
    return (
      <Grid>
        {billingAddressValues ? (
          <AddressVerificationFragment
            step={{name: "email", required: true}}
            initialValues={billingAddressValues}
            onUpdate={onAddressUpdate}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    );
  else
    return (
      <Grid>
        <AddressVerificationFragment step={{name: "email", required: true}} />
      </Grid>
    );
}
