export const mapI18LangToCulture = (lang: string) => {
  const mapDictionary: {[key: string]: string} = {
    en: "en-US",
    ru: "ru-RU",
    ua: "uk-UA",
  };

  if (lang in mapDictionary) {
    return mapDictionary[lang];
  } else {
    return "en-US";
  }
};
