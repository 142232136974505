import React, {useState} from "react";
import {
  IconButton,
  InputAdornment,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {
  useLazyUserGetInfoQuery,
  useUserUpdateInfoMutation,
} from "../../../features/user/user-api";
import {toast} from "react-hot-toast";
import {Field, Form, Formik} from "formik";
import Grid from "@mui/material/Unstable_Grid2";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {getChangePasswordSchema} from "../pages/validation";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {toastError} from "../../../common/utils/toastMessages";

const formElementStyles: SxProps<Theme> = {
  minWidth: {
    xs: "0",
    md: "394px",
  },
};

interface ChangePasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

const initialValues: ChangePasswordFormValues = {
  newPassword: "",
  confirmPassword: "",
};

export default function AccountPasswordChangeForm() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [updateUserInfo] = useUserUpdateInfoMutation();
  const [getUserInfo] = useLazyUserGetInfoQuery();

  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState<boolean>(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const onSubmit = async (values: ChangePasswordFormValues) => {
    if (isUpdatingPassword) return;

    setIsUpdatingPassword(true);
    try {
      const updateResponse = await updateUserInfo({
        updateUserInfoCommand: {password: values.newPassword},
      });
      if ((updateResponse as any)?.error) {
        return;
      }

      const userInfoResponse = await getUserInfo();
      if (userInfoResponse?.isError) {
        return;
      }

      toast.success(t("toasts.changedPassword"));
      navigate("../profile");
    } catch (error) {
      toastError(error);
    } finally {
      setIsUpdatingPassword(false);
    }
  };

  return (
    <Grid container columns={12} display="flex" flexDirection="column">
      <Grid xs={12} mt={{xs: "1rem", md: 0}}>
        <Typography component="div" variant="body1" color="#6C757D">
          <Trans i18nKey="profile.changePassword.enterANewPassword">
            Enter a new password
          </Trans>
        </Typography>
      </Grid>
      <Grid
        mt={{
          xs: 2,
          md: "30px",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={getChangePasswordSchema(t)}
          onSubmit={onSubmit}
        >
          <Form id="changePasswordForm">
            <Grid
              container
              columns={12}
              display="flex"
              flexDirection="column"
              rowGap="32px"
            >
              <Grid
                display="flex"
                flexDirection="column"
                rowGap={{
                  xs: "16px",
                  md: "24px",
                }}
              >
                <Grid xs md={3} sx={formElementStyles}>
                  <Field
                    fullWidth
                    component={TextField}
                    size={isDesktop ? "medium" : "small"}
                    label={t("profile.changePassword.newPassword")}
                    id="newPassword"
                    name="newPassword"
                    placeholder=""
                    data-testid="input-new-password"
                    autoComplete={"new-password"}
                    type={showNewPassword ? "text" : "password"}
                    disabled={isUpdatingPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            data-testid="btn-show-new-password"
                            aria-label="toggle new password visibility"
                            onClick={handleClickShowNewPassword}
                            edge="end"
                            sx={{opacity: "0.5"}}
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid xs md={3} sx={formElementStyles}>
                  <Field
                    fullWidth
                    component={TextField}
                    size={isDesktop ? "medium" : "small"}
                    id="confirmPassword"
                    name="confirmPassword"
                    label={t("profile.changePassword.confirmPassword")}
                    placeholder=""
                    data-testid="input-confirm-password"
                    autoComplete={"new-password"}
                    type={showConfirmPassword ? "text" : "password"}
                    disabled={isUpdatingPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            data-testid="btn-show-confirm-password"
                            aria-label="toggle confirm password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                            sx={{opacity: "0.5"}}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                xs
                md={1}
                sx={{
                  minWidth: {
                    xs: "0",
                    md: "185px",
                  },
                }}
              >
                <LoadingButton
                  fullWidth
                  type="submit"
                  form="changePasswordForm"
                  variant="contained"
                  color="secondary"
                  data-testid="btn-change-account-password"
                  disabled={isUpdatingPassword}
                  loading={isUpdatingPassword}
                  loadingPosition="end"
                  endIcon={<></>}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <Trans i18nKey="profile.changePassword.submitButton">
                    Change password
                  </Trans>
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
}
