import React from "react";
import {Trans} from "react-i18next";
import {Typography} from "@mui/material";
import amazonAddressForm from "../../../../images/amazonAddressForm.png";
import exampleAddressForm from "../../../../images/exampleAddressForm.png";
import billingAddressSameAsDeliveryForm from "../../../../images/billingAddressSameAsDeliveryForm.png";

export default function BillingAddressDifferencesText() {
  return (
    <Typography
      variant={"body1"}
      component={"div"}
      sx={{
        color: "#393A46",
        width: {
          xs: "100%",
          md: "clamp(65%, 941px, 100%)",
        },
        margin: {
          xs: "56px auto 0",
          sm: "56px auto 0",
          md: "56px 0 0",
        },
        padding: {
          xs: "0 16px",
          md: 0,
        },
        "> img": {
          display: "block",
          margin: "16px 0",
          width: {
            xs: "75vw",
            md: "650px",
          },
          maxWidth: {
            xs: "75vw",
            md: "650px",
          },
          height: "100%",
        },
        "> ul li": {
          margin: "25px 0",
        },
      }}
    >
      <Typography variant={"h1"}>
        <Trans i18nKey="profile.billingAddressInformation.whatIsTheDifference">
          What is the difference between Billing and Shipping Address?
        </Trans>
      </Typography>
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.forAmericansText"
      >
        For Americans, there is almost no difference, so many stores have a
        checkmark &quot;Your Billing Address is your Shipping Address&quot; by
        default, or something similar. But for buyers from other countries, the
        difference is very big! Therefore, this checkbox must be removed, and
        you must enter your billing address separately, on your own. The fact is
        that:
      </Trans>
      <ul>
        <Trans
          parent="li"
          i18nKey="profile.billingAddressInformation.shippingAddressListItem"
        >
          Shipping address - the address where your goods will be delivered.
        </Trans>
        <Trans
          parent="li"
          i18nKey="profile.billingAddressInformation.billingAddressListItem"
        >
          Billing address - the address where the payer lives, that is, the
          physical owner of the bank card from which the payment is made.
        </Trans>
      </ul>
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.whySuchConfusionText"
      >
        Why such confusion? The fact is that American stores are very careful
        about their safety. And it is important for them to know that you are
        the owner of the card with which you make the purchase. They really do
        not like carders and other intruders who embezzle other people&apos;s
        money. And so they try to resist them. By the way, this works: if a
        person somehow happened to have your credit card number, it is unlikely
        that he also has all your other data, including your place of residence
        and phone number.
      </Trans>
      <Typography variant={"h1"}>
        <Trans i18nKey="profile.billingAddressInformation.howToFillInBillingAddress">
          How to fill in Billing Address
        </Trans>
      </Typography>
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.manyUsersQuestionText"
      >
        Many users have a question: what is a billing address? There is no such
        thing in our country, it is an American invention. The fact is that in
        the United States for more than two hundred years, checks have been in
        circulation (and still go!) It was considered very convenient to pay by
        check. So, papers for write-offs from these checks and various bills
        were sent exactly to the billing address, the payment address. Banks got
        used to using it, and now they use it to confirm credit or debit cards.
      </Trans>
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.whenFillingBillingAddressText"
      >
        When filling in the billing address, you need to specify the data of the
        owner of the card with which you want to pay. His name, surname, address
        and so on. All this information is in your head (in theory), as well as
        in the bank agreement for issuing your card. Even if the card is
        virtual, the address is still there - on the agreement with the bank
        that you signed when opening the account. Since not all American stores
        are accustomed to customers from abroad, there are three options when
        filling out a billing address:
      </Trans>
      <b>1</b>.{" "}
      <Trans i18nKey="profile.billingAddressInformation.fillInBillingAddress">
        Fill in your real billing address.
      </Trans>
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.thisDoesntAlwaysWorkText"
      >
        This does not always work, but in most cases it is possible. The best
        option. For example, let&apos;s take Amazon:
      </Trans>
      <img src={amazonAddressForm} alt="example address form" />
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.sinceTheStoreText"
      >
        Since the store is loyal to customers from abroad, we can fill in our
        real address - including the country and city. You can specify the phone
        number that you have in your account (or your own).
      </Trans>
      <p>
        <b>2</b>.{" "}
        <Trans i18nKey="profile.billingAddressInformation.billingAddressOfMixedType">
          Billing address of mixed type.
        </Trans>
      </p>
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.itIsNotAlwaysPossibleText"
      >
        It is not always possible to indicate everything the way it is in a
        bank. Sometimes your country is simply not in the list of proposed
        countries. In this case, you have to fill in the billing address mixed,
        only half true. The country and state are America and Delaware, but all
        other information is yours. The more truthful information, the better.
      </Trans>
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.oneOfTheShopsText"
      >
        Here is one of the shops. Your country is simply not in the list of
        countries to choose from:
      </Trans>
      <img src={exampleAddressForm} alt="amazon address form" />
      <p>
        <b>3</b>.{" "}
        <Trans i18nKey="profile.billingAddressInformation.shippingAddressInsteadOfBilling">
          Shipping address instead of billing address.
        </Trans>
      </p>
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.ifTheRequiredFieldsText"
      >
        If the required fields simply do not exist, you can &quot;score&quot;
        and let the store use the delivery address as the billing address. It
        can also pass. Depends on the store and your luck. You still won&apos;t
        lose money. In extreme cases, the store will simply cancel the order,
        and will not send your product.
      </Trans>
      <img
        src={billingAddressSameAsDeliveryForm}
        alt="billing address as delivery"
      />
      <Trans
        parent="p"
        i18nKey="profile.billingAddressInformation.weRecommendText"
      >
        We recommend the first two options. Still, if the billing and shipping
        addresses match, there is a chance that your order will not be
        processed. and you&apos;re wasting your time. But there are stores for
        which, on the contrary, their coincidence is important (mostly these are
        small sites, large online hypermarkets do not indulge in this). So
        experiment! Some even manage to buy from Apple.com! But in any case, you
        need to know your address, to which the card is issued in the bank.
      </Trans>
    </Typography>
  );
}
