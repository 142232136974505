import {TariffOptions} from "../modules/features/deliveryMethod/deliveryMethod-api";

export const getFormattedDate = (date: string | Date, time = false): string => {
  if (date) {
    if (time) {
      return new Date(date).toLocaleString("en-US", {
        hour12: false,
      });
    } else {
      return new Date(date).toLocaleString("en-US", {
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }
  }
  return "";
};

export const getFormattedRatesRange = (
  tariff: TariffOptions,
  t: any,
  unitType?: string | null,
) => {
  const rangeLastIndex = tariff?.rateData ? tariff?.rateData?.length - 1 : 0;
  const rateData = tariff.rateData
    ? [...tariff.rateData].sort(
        (a, b) => (a?.rateValue || 0) - (b?.rateValue || 0),
      )
    : [];

  const minRate = rateData?.[0]?.rateValue;
  const maxRate = rateData?.[rangeLastIndex]?.rateValue;

  const formattedRatesRange = unitType
    ? `${t("deliveryMethodFrom")} $${minRate} / ${unitType.toLowerCase()} ${t(
        "deliveryMethodTo",
      )} $${maxRate} / ${unitType.toLowerCase()}`
    : `${t("deliveryMethodFrom")} $${minRate} ${t(
        "deliveryMethodTo",
      )} $${maxRate}`;

  return formattedRatesRange;
};
