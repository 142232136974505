import React, {useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import DeliveryMethodList from "../components/deliveryMethodList";
import {Button} from "@mui/material";
import {DeliveryMethodFragmentProps} from "./interfaces";
import {Trans} from "react-i18next";
import {Helmet} from "react-helmet";

export default function DeliveryMethodFragment({
  handleNext,
  pageTitle,
  config,
}: DeliveryMethodFragmentProps) {
  const [methodIsSelected, setMethodIsSelected] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid sx={{pt: 1}} container columns={{xs: 6, md: 12}}>
        <Grid xs={6} md={7} lg={6}>
          <DeliveryMethodList
            setMethodIsSelected={setMethodIsSelected}
            config={config}
          />
          <Grid mt={3} md={3} xs={6}>
            <Button
              data-testid="btn-continue-delivery-method"
              disabled={!methodIsSelected}
              fullWidth
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleNext}
              sx={{
                p: 1,
                mb: 3,
              }}
            >
              <Trans i18nKey="btnContinue">Continue</Trans>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
