import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import ReactPaginate from "react-paginate";
import "../../../style/pagination.scss";
import {useTranslation} from "react-i18next";

type PaginationProps = {
  limit: number;
  offset: number;
  sort: string;
  filter: string;
  search: string;
  totalCount?: number;
  isNewStyle?: boolean;
  style?: any;
};

export default function Pagination({
  limit,
  offset,
  sort,
  filter,
  search,
  totalCount,
  isNewStyle = false,
  style = {},
}: PaginationProps) {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [, setSearchParams] = useSearchParams();
  return (
    <Grid
      className={"pagination"}
      container
      alignItems={"center"}
      justifyContent={"end"}
      alignSelf={{md: "end", xs: "center"}}
      height={"52px"}
      mb={{md: "40px", xs: "50px"}}
      mt={{md: "47px", xs: "7px"}}
      width={"max-content"}
      zIndex={100}
      style={style}
      maxWidth={{xs: "100vw", md: "auto"}}
    >
      <ReactPaginate
        breakLabel="..."
        nextLabel={t("pagination.next")}
        previousLabel={t("pagination.prev")}
        onPageChange={(currentPage: {selected: number}) => {
          setSearchParams((prevSearchParams) => {
            prevSearchParams.set("search", search);
            prevSearchParams.set(
              "offset",
              (currentPage.selected * limit).toString(),
            );
            prevSearchParams.set("limit", limit.toString());
            prevSearchParams.set("sort", sort);
            prevSearchParams.set("filter", filter);
            return prevSearchParams;
          });

          navigate(window.location.pathname + window.location.search);
          window.scroll(0, 0);
        }}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={Math.ceil((totalCount ?? 0) / limit)}
        initialPage={Math.floor(offset / limit)}
        forcePage={Math.floor(offset / limit)}
        containerClassName={"pagination-container"}
        nextClassName={isNewStyle ? "pagination-next-no-bg" : "pagination-next"}
        previousClassName={
          isNewStyle ? "pagination-previous-no-bg" : "pagination-previous"
        }
        pageClassName={isNewStyle ? "pagination-page-no-bg" : "pagination-page"}
        breakClassName={
          isNewStyle ? "pagination-break-no-bg" : "pagination-break"
        }
        activeClassName={
          isNewStyle ? "pagination-active-no-bg" : "pagination-active"
        }
        previousLinkClassName={"pagination-previous-link"}
        nextLinkClassName={"pagination-next-link"}
        pageLinkClassName={"pagination-page-link"}
      />
    </Grid>
  );
}
