import React, {useState} from "react";
import {IconButton, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {Trans, useTranslation} from "react-i18next";
import {VerificationStep} from "../../common/OrganizationConfig/useVerificationSteps";

declare type VerificationBackgroundProps = {
  steps: VerificationStep[];
  stepIndex: number;
  handlePrev: () => void;
};

export default function VerificationBackground({
  stepIndex,
  handlePrev,
  steps,
}: VerificationBackgroundProps) {
  const [showHowItWorks, setShowHowItWorks] = useState<boolean>(true);

  const handleHowItWorksToggle = () => {
    setShowHowItWorks(!showHowItWorks);
  };

  const step = steps[stepIndex];

  const {t} = useTranslation();

  const message = (() => {
    switch (step.name) {
      case "email":
        return t("verificationStepEmail");
      case "phone":
        return t("verificationStepPhone");
      case "address":
        return t("verificationStepAddress");
      case "paymentMethod":
        return t("verificationStepPayment");
      case "acknowledgement":
        return t("verificationStepUserWarning");
    }
  })();

  return (
    <>
      <Grid sx={{marginTop: {md: "-100px", lg: "unset"}}}>
        <Grid container columns={6}>
          <Grid md={1} />
          <Grid md={4} sx={{display: "flex", alignItems: "center"}}>
            <IconButton
              onClick={() => handlePrev()}
              sx={{color: "black"}}
              data-testid={"btn-step-prev"}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{marginTop: {md: "-120px", lg: "unset"}}}>
        <Grid container columns={6}>
          <Grid md={1} />
          <Grid md={4}>
            <Typography variant={"h3"} sx={{fontSize: "2rem", mt: 3}}>
              <Trans i18nKey={"confirmValidityMessageFirst"}>
                Please confirm
              </Trans>
            </Typography>
            <Typography variant={"h1"}>{message}</Typography>
            <Grid mt={"24px"}>
              {step.name === "paymentMethod" && (
                <Typography
                  variant="body1"
                  sx={{
                    color: "#2F81EF",
                    textDecoration: "underline",
                    cursor: "pointer",
                    mb: "16px",
                    width: "max-content",
                  }}
                  onClick={handleHowItWorksToggle}
                >
                  <Trans i18nKey="howItWorks">How it works?</Trans>
                </Typography>
              )}

              {step.name === "paymentMethod" && showHowItWorks && (
                <Grid sx={{position: "absolute", width: "30%"}}>
                  <Typography variant={"caption1"} component={"p"} mb={"16px"}>
                    <Trans i18nKey="howItWorksText">
                      When you add a card, we will process a small transaction
                      on the card ($0.50 - $1.00 US) you will then need to check
                      your credit card statement and verify the charged amount.
                      After the amount has been verified, the transaction will
                      be refunded, and you will be able to use your card for
                      &#34;Verified&#34; transactions. Once the verification
                      process has been initiated, you have 48 hours to complete
                      it before the transaction will be refunded.
                    </Trans>
                  </Typography>
                  <Typography variant={"caption1"} component={"p"}>
                    <Trans i18nKey="weDoNotStore">
                      We do not store customers’ credit card data.
                    </Trans>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid></Grid>
      <Grid>
        <Grid container columns={6}>
          <Grid md={1} />
          <Grid md={4}>
            <Grid container wrap={"nowrap"} alignItems={"center"} columnGap={1}>
              <Grid xs={"auto"} sx={{display: "flex", alignItems: "center"}}>
                <InfoIcon style={{fill: "#2F81EF"}} />
              </Grid>
              <Grid>
                <Typography
                  variant={"body1"}
                  color={"gray"}
                  sx={{fontSize: "0.875rem"}}
                >
                  <Trans i18nKey={"identifyMessage"}>
                    In order to send Purchases through TRT Express we need
                    identify your personality
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
