import React from "react";
import {Trans} from "react-i18next";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ProfileTabMenu from "../../components/profileTabMenu";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import AccountPasswordChangeForm from "../components";

const AccountPasswordChange = () => {
  return (
    <MainLayout
      backLink="../profile"
      statusBar={<ProfileTabMenu selectedTab={4} />}
      pageTitle="Change Password"
    >
      <ContentLayout
        title={
          <Trans i18nKey={"profile.tabs.changePassword"}>Change password</Trans>
        }
        backLink="../profile"
        content={<AccountPasswordChangeForm />}
        containerSx={{mb: "24px"}}
      />
    </MainLayout>
  );
};

export default AccountPasswordChange;
