import React from "react";
import {Button, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";

interface DeleteContactAddressModalProps {
  handleClose: () => void;
  handleConfirm: () => void;
}

const DeleteContactAddressModal: React.FC<DeleteContactAddressModalProps> = ({
  handleClose,
  handleConfirm,
}) => {
  return (
    <Grid px={{xs: 0, md: 2}}>
      <Typography
        data-testid="delete-address-modal-description"
        variant="body1"
        sx={{fontSize: "24px"}}
      >
        <Trans i18nKey="profile.savedAddresses.deleteModalText">
          Are you sure you want to remove this address?
        </Trans>
      </Typography>
      <Button
        sx={{mt: 2, mr: {xs: 1, md: 2}}}
        onClick={handleClose}
        variant="outlined"
        color="primary"
      >
        <Trans i18nKey="profile.savedAddresses.cancel">Cancel</Trans>
      </Button>
      <Button
        sx={{mt: 2}}
        onClick={handleConfirm}
        variant="contained"
        color="secondary"
      >
        <Trans i18nKey="profile.savedAddresses.remove">Remove</Trans>
      </Button>
    </Grid>
  );
};

export default DeleteContactAddressModal;
