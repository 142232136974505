import {
  EntityTypes,
  OrderEntityGQLDto,
  OrderGQLDto,
} from "../../../features/order/order-api";
import {useEffect, useMemo, useState} from "react";
import {getShipmentStatusColor} from "../utils/parcelShipmentTable.utils";

export const useParcelShipmentData = (parcelShipment: OrderGQLDto) => {
  const invoices = parcelShipment?.invoices;

  const statusColor = useMemo<string>(
    () => getShipmentStatusColor(parcelShipment?.orderStatus?.orderStatusName),
    [parcelShipment?.orderStatus?.orderStatusName],
  );

  const [consignee, setConsignee] = useState<OrderEntityGQLDto>();

  useEffect(() => {
    setConsignee(
      parcelShipment?.orderEntities?.find(
        (order) => order?.entityType === EntityTypes.Consignee,
      ),
    );
  }, [parcelShipment]);

  return {
    invoices,
    statusColor,
    consignee,
  };
};
