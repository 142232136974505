import {basePortalApi as api} from "../basePortalApi";
import {StateDto} from "../states/states-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCities: build.mutation<CitiesGetApiResponse, CitiesGetApiArg>({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/cities`,
        method: "POST",
        body: queryArg.values,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type CitiesGetApiResponse = /** status 200  */ CityDto[];
export type CitiesGetApiArg = {
  organizationId: number;
  values: CitiesGetValues;
};
export type CitiesGetValues = {
  countryCode: string;
  search: string;
  limit: number;
};
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type CityDto = {
  organizationId?: number;
  cityId?: number;
  cityName: string;
  countryCode: string;
  countryName?: string | undefined;
  stateCode?: string | undefined;
  stateName?: string | undefined;
  state: StateDto;
  latitude?: number | undefined;
  longitude?: number | undefined;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export const {useGetCitiesMutation} = injectedRtkApi;
