import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Link, NavLink} from "react-router-dom";
import ExtraMenuItemIcon from "../../../../images/extraMenuItem.svg";
import ProfileMenu from "./profileMenu";
import {Trans} from "react-i18next";
import {Button} from "@mui/material";
import i18next from "i18next";
import ExtraMenuButton from "../../components/extraMenuButton";
import {MenuItemType} from "../../../core/base.module";
import {PurchasesModule} from "../../../Purchases/purchases.module";
import {ParcelModule} from "../../../ParcelShipments/parcel.module";
import {GettingStartedModule} from "../../../GettingStarted/getting-started.module";
import {DeliveryModule} from "../../../Delivery/delivery.module";
import {InvoicesModule} from "../../../Invoices/invoices.module";

interface ExtraMenuContentProps {
  onClick: (event: React.MouseEvent) => void;
  setExtraMenuOpen: (open: boolean) => void;
}

interface ExternalLinkProps {
  imagePath: string;
  label: string;
  href: string;
  i18nKey: string;
}

interface InternalLinkProps {
  imagePath: string;
  label: string;
  to: string;
  i18nKey: string;
}

const InternalLinkItem: React.FC<InternalLinkProps> = ({
  imagePath,
  label,
  to,
  i18nKey,
}) => (
  <Grid>
    <NavLink to={to} style={{textDecoration: "none", color: "inherit"}}>
      <Grid container direction="column" alignItems="center">
        <img src={imagePath} alt={label} />
        <Typography color="#393A46" variant="caption2">
          <Trans i18nKey={i18nKey}>{label}</Trans>
        </Typography>
      </Grid>
    </NavLink>
  </Grid>
);

const ExternalLinkItem: React.FC<ExternalLinkProps> = ({
  imagePath,
  label,
  href,
  i18nKey,
}) => (
  <Grid>
    <Button
      href={href}
      style={{textDecoration: "none", color: "inherit"}}
      target="_blank"
    >
      <Grid container direction="column" alignItems="center">
        <img src={imagePath} alt={label} />
        <Typography color="#393A46" variant="caption2">
          <Trans i18nKey={i18nKey}>{label}</Trans>
        </Typography>
      </Grid>
    </Button>
  </Grid>
);

function ExtraMenuContent({onClick, setExtraMenuOpen}: ExtraMenuContentProps) {
  const zohoLang = i18next.language === "ua" ? "uk" : i18next.language;

  const menuActions = [
    ...GettingStartedModule.getInstance().getMenuActions(),
    ...PurchasesModule.getInstance().getMenuActions(),
    ...ParcelModule.getInstance().getMenuActions(),
    ...DeliveryModule.getInstance().getMenuActions(),
    ...InvoicesModule.getInstance().getMenuActions(),
  ];

  return (
    <Grid
      container
      direction="column"
      sx={{
        position: "fixed",
        bottom: "64px",
        width: "100%",
        background: "#FFFFFF",
        borderRadius: "16px 16px 0 0",
        padding: "16px",
      }}
      onClick={onClick}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>
          {menuActions?.map(({page, to, i18nKey, svgIcon}: MenuItemType) => {
            return (
              <ExtraMenuButton
                key={page}
                link={to}
                onClick={() => setExtraMenuOpen(false)}
                i18nKey={i18nKey}
                svgIcon={svgIcon}
              />
            );
          })}
        </Grid>
        <Link to={`../profile`}>
          <ProfileMenu />
        </Link>
      </Grid>

      <Divider sx={{my: "16px"}} />

      <Grid container justifyContent="space-around" padding={"16px"}>
        <ExternalLinkItem
          imagePath={ExtraMenuItemIcon}
          label="Help"
          href={`https://trtexpress.zohodesk.com/portal/${zohoLang}/newticket`}
          i18nKey="extraMenuItemHelp"
        />
      </Grid>
    </Grid>
  );
}

export default function ExtraMenu({
  setExtraMenuOpen,
}: {
  setExtraMenuOpen: (open: boolean) => void;
}) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.2)",
        backdropFilter: "blur(5px)",
        zIndex: 1200,
      }}
      onClick={() => setExtraMenuOpen(false)}
    >
      <ExtraMenuContent
        setExtraMenuOpen={setExtraMenuOpen}
        onClick={(event) => event.stopPropagation()}
      />
    </div>
  );
}
