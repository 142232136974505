import React from "react";
import {Divider} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LinearCheckProgressStep from "../../common/components/linearCheckProgressStep";
import RadialProgressStep from "../../common/components/radialProgressStep";
import {StatusBarProps} from "./interfaces";

export default function StatusBar({
  variant,
  step,
  setStep,
  steps,
}: StatusBarProps) {
  const stepsObj = (steps || []).map((text) => {
    return {
      name: text.toLowerCase().replace(/ /g, "-"),
      checked: false,
      text: text,
    };
  });

  return variant == "linear" ? (
    <>
      <Grid
        container
        columns={14}
        justifyContent="center"
        sx={{position: "relative", zIndex: "2", mt: 2}}
      >
        <Grid xs={12}>
          <LinearCheckProgressStep
            steps={stepsObj}
            sizeList={new Array(stepsObj.length).fill(1)}
            stepIndex={step}
            setStep={setStep}
            orderStep={step}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{
          position: "relative",
          bottom: "2px",
          backgroundColor: "#D8E6FB",
          zIndex: "1",
        }}
      />
    </>
  ) : variant == "radial" ? (
    <RadialProgressStep steps={stepsObj} stepIndex={step} />
  ) : (
    <></>
  );
}
