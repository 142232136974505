import {useOrganizationConfigGetManyQuery} from "../../features/organizationConfig/organizationConfig-api";
import {getPortalNameFromDomain} from "../OrganizationConfig/useOrganizationConfig";
import {useEffect, useState} from "react";

export const useConsolidationAccountingItem = () => {
  const [consolidationItemCode, setConsolidationItemCode] = useState<
    string | null | undefined
  >("");

  const organizationId = process.env.REACT_APP_PORTAL_ORGANIZATION_ID
    ? parseInt(process.env.REACT_APP_PORTAL_ORGANIZATION_ID, 10)
    : 1;

  const portalName =
    process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain();

  const {data: configs, isSuccess} = useOrganizationConfigGetManyQuery({
    organizationId,
    portalName,
  });

  useEffect(() => {
    if (isSuccess && configs && configs.items && configs.items.length) {
      const consolidatedShipmentConfig = configs.items.find((config) =>
        config.configName.includes("consolidatedShipment"),
      );
      if (
        consolidatedShipmentConfig &&
        consolidatedShipmentConfig.customValues &&
        "consolidationAccountingItemCode" in
          consolidatedShipmentConfig.customValues
      ) {
        const itemCode =
          consolidatedShipmentConfig.customValues[
            "consolidationAccountingItemCode"
          ];
        setConsolidationItemCode(itemCode);
      }
    }
  }, [configs, isSuccess]);

  return consolidationItemCode;
};
