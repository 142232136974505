import {basePortalApi as api} from "../basePortalApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    countriesGet: build.query<CountriesGetApiResponse, CountriesGetApiArg>({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/countries`,
        params: {filter: queryArg.filter},
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type CountriesGetApiResponse = /** status 200  */ CountryDto[];
export type CountriesGetApiArg = {
  organizationId: number;
  filter?: string | null;
};
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type CountryDto = {
  countryCode?: string;
  name?: string | null;
  organizationId?: number;
  links?: LinkDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
};
export const {useCountriesGetQuery} = injectedRtkApi;
