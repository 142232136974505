import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {ReactComponent as Logo} from "../../../../images/TRTLogoWithYellow.svg";
import TwitterIcon from "../../../../images/twitterIcon.svg";
import FacebookIcon from "../../../../images/facebookIcon.svg";
import LinkedinIcon from "../../../../images/linkedinIcon.svg";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {Box, Link as MuiLink, useTheme} from "@mui/material";
import {Trans} from "react-i18next";
import {getAddresses} from "../../data/addresses";
import {getContacts} from "../../data/contacts";
import {RootState, useAppSelector} from "../../../store";

export const socialMediaLinks = (
  <Grid
    display={"flex"}
    maxWidth={"300px"}
    justifyContent={"space-around"}
    md={2}
  >
    <MuiLink
      underline="none"
      href="https://www.facebook.com/usatrt/"
      target="_blank"
      rel="noopener"
    >
      <img src={FacebookIcon} alt={"FacebookIcon"} />
    </MuiLink>
    <MuiLink
      underline="none"
      href="https://twitter.com/TRTIntl"
      target="_blank"
      rel="noopener"
    >
      <img src={TwitterIcon} alt={"TwitterIcon"} />
    </MuiLink>
    <MuiLink
      underline="none"
      href="https://www.linkedin.com/company/trt-international-ltd"
      target="_blank"
      rel="noopener"
    >
      <img src={LinkedinIcon} alt={"LinkedinIcon"} />
    </MuiLink>
  </Grid>
);

export default function MainPageFooter() {
  const footerConfig = useAppSelector(
    (state: RootState) => state.organizationConfigState.modules.footer,
  );

  const i18KeyPrefix = "gettingStarted.footer.";

  const date = new Date();
  const time = date.toLocaleTimeString("en-US", {
    timeZone: "America/New_York",
    hour: "2-digit",
    minute: "2-digit",
  });
  const currentYear = new Date().getFullYear();

  const theme = useTheme();

  const addresses = getAddresses();
  const contacts = getContacts();

  const showAddresses =
    useAppSelector(
      (state: any) =>
        state.organizationConfigState?.modules?.footer?.showAddresses,
    ) ?? true;

  return (
    <Grid
      display={{xs: "none", md: "flex"}}
      sx={{backgroundColor: "#F5F9FF", marginTop: 4}}
      container
      columns={14}
      justifyContent={"center"}
    >
      <Grid md={12}>
        <Grid container direction={"column"} sx={{height: "100%"}}>
          <Grid rowSpacing={0} alignItems={"start"} container md={12}>
            <Grid md={2}>
              <Grid container alignItems="center">
                <Logo height={71} width={121} fill="#3E80FF" />
              </Grid>
            </Grid>
            <Grid md={showAddresses ? 7 : 0}>
              {showAddresses ? (
                <>
                  <Typography sx={{mb: "12px"}} variant="h2" color="#2F81EF">
                    <Trans i18nKey="yourAmericanAddresses">
                      Your American addresses
                    </Trans>
                  </Typography>
                  <Box sx={{display: "flex"}}>
                    {addresses.map((address, index) => (
                      <Box sx={{mr: 8}} key={`address-${index}`}>
                        <Typography
                          sx={{display: "block", mt: 1, mb: "11px"}}
                          color={theme.palette.primary.darkBlue}
                          variant="caption2"
                        >
                          <Trans i18nKey={address.taxTitleKey}>
                            {address.taxTitleKey}
                          </Trans>
                        </Typography>
                        {address.addressLines.map((item, index) => (
                          <Typography
                            sx={{display: "block"}}
                            key={item.key + index}
                            variant="caption2"
                          >
                            {item.key + ": " + item.value}
                          </Typography>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </>
              ) : null}
            </Grid>
            <Grid
              md={footerConfig?.usefulLinks?.length > 0 ? 2 : 0}
              mdOffset={footerConfig?.usefulLinks?.length > 0 ? 4 : 0}
            >
              {footerConfig?.usefulLinks?.length > 0 ? (
                <Box>
                  <Typography sx={{mb: "11px"}} variant="h2" color="#2F81EF">
                    <Trans i18nKey={i18KeyPrefix + "usefulLinks"}>
                      Useful Links
                    </Trans>
                  </Typography>
                  {footerConfig.usefulLinks.map((item: any, index: number) => (
                    <MuiLink
                      key={item.key + index}
                      href={item.href}
                      underline={"hover"}
                      color={"inherit"}
                      target={"_blank"}
                      width={"max-content"}
                      display={"flex"}
                    >
                      <Typography
                        key={`${item}-${index}`}
                        sx={{display: "block"}}
                        variant="caption2"
                      >
                        <Trans i18nKey={i18KeyPrefix + item.key}>
                          {item.key}
                        </Trans>
                      </Typography>
                    </MuiLink>
                  ))}
                </Box>
              ) : null}
            </Grid>
            <Grid md={2}>
              <Box>
                <Typography sx={{mb: "11px"}} variant="h2" color="#2F81EF">
                  <Trans i18nKey="contactUs">Contact Us</Trans>
                </Typography>
                {contacts.map((item, index) => (
                  <MuiLink
                    key={item.key + index}
                    href={item.href}
                    underline={"hover"}
                    color={"inherit"}
                  >
                    <Typography sx={{display: "block"}} variant="caption2">
                      <Trans i18nKey={item.transKey}>{item.key}</Trans>
                      {": " + item.value}
                    </Typography>
                  </MuiLink>
                ))}
                <Typography
                  sx={{mt: 1}}
                  color={theme.palette.primary.darkBlue}
                  variant="caption2"
                >
                  <Trans i18nKey="timeInNewYork">Time in New York </Trans>{" "}
                  {time}
                </Typography>
              </Box>
            </Grid>
            <Grid md={2}>
              {footerConfig?.contactAddress?.length > 0 ? (
                <Box>
                  <Typography sx={{mb: "11px"}} variant="h2" color="#2F81EF">
                    <Trans i18nKey={i18KeyPrefix + "address"}>Address</Trans>
                  </Typography>
                  {footerConfig.contactAddress.map(
                    (item: any, index: number) => (
                      <Typography
                        key={`${item}-${index}`}
                        sx={{display: "block"}}
                        variant="caption2"
                      >
                        {item}
                      </Typography>
                    ),
                  )}
                </Box>
              ) : null}
            </Grid>
          </Grid>
          <Divider />
          <Grid sx={{my: "auto"}} alignItems={"center"} container md={12}>
            <Grid md={10}>
              <Typography variant="caption2" color="#608ABA">
                Copyright {currentYear} All right reserved TRT Express Services,
                LLC, 250 Port Street, Newark, NJ 07114 USA
              </Typography>
            </Grid>
            {socialMediaLinks}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
