import React, {useEffect, useState} from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Info, Search} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {CommodityTypeDto} from "../../../../features/commodityTypes/commodityTypes-api";
import {useGetCommodityTypes} from "../../../../Purchases/EditPurchases/hooks/useGetCommodityTypes";
import Pagination from "../../../../common/components/pagination";
import {QuerySearchParams, useQuery} from "../../../../../utils/helper.utils";
import {useSearchParams} from "react-router-dom";
import Done from "../../../../../images/cardIcons/done.svg";
import {Trans} from "react-i18next";
import {getLangCodeFromI18n} from "../../../../app/app.component";

interface HSCodeFragmentProps {
  handleClose: () => void;
  handleBack: () => void;
  itemCategory: string;
  index: number | undefined;
  onHandleChange?: any;
}

export default function HSCodeFragment({
  handleClose,
  handleBack,
  itemCategory,
  index,
  onHandleChange,
}: HSCodeFragmentProps) {
  const langCode = getLangCodeFromI18n();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCode, setSelectedCode] =
    useState<CommodityTypeDto | null>(null);

  const url = new URL(location.href);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [searchValue, setSearchValue] = useState<string>(() => {
    const searchQueryParamValue = url.searchParams.get("search");
    return searchQueryParamValue || "";
  });
  const {getCommodityTypes, commodityTypesPageResult} = useGetCommodityTypes();

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newSearchValue = event?.target?.value || "";

    setSearchParams((prevSearchParams) => {
      prevSearchParams.set("search", newSearchValue);
      prevSearchParams.set("offset", "0");
      prevSearchParams.set("limit", "5");
      return prevSearchParams;
    });

    setSearchValue(newSearchValue);
  };

  const {
    offset = "0",
    limit = "5",
    sort = "",
    filter = "",
    search = "",
  } = useQuery() as QuerySearchParams;

  const handleListItemClick = (
    event: any,
    index: number,
    value: CommodityTypeDto,
  ) => {
    setSelectedIndex(index);
    setSelectedCode(value);
  };

  const getData = () => {
    getCommodityTypes({
      offset: Number(offset),
      limit: Number(limit),
      sort,
      filter: `customValues.chapter_${langCode}:"${itemCategory}" AND isActive:true`,
      search,
    });
  };

  useEffect(() => {
    getData();
  }, [offset, limit, sort, filter, search]);

  useEffect(() => {
    getData();
  }, [itemCategory]);

  const onSubmit = () => {
    if (selectedCode && index != null) {
      onHandleChange(selectedCode);
      handleClose();
    }
  };

  return (
    <Grid md={12} maxWidth={"570px"}>
      <Grid
        container
        alignItems={"center"}
        justifyContent="flex-start"
        gap={"8px"}
        pt={{xs: 2, md: 0}}
        display={isDesktop ? "flex" : "none"}
      >
        <Info color="primary"></Info>
        <Typography variant="body4" fontSize={"18px"} fontWeight={"600"}>
          <Trans i18nKey={"delivery.declaration.findCode"}>
            Find your Item HS Code
          </Trans>
        </Typography>
      </Grid>
      <Grid
        container
        alignItems={"flex-start"}
        direction={"column"}
        p={2}
        mt={2}
        gap={"8px"}
        sx={{borderRadius: "16px", border: "1px solid #D8E6FB"}}
      >
        <Typography variant="body4" color="#6C757D">
          <Trans i18nKey={"delivery.declaration.yourItemCategory"}>
            Your item category:
          </Trans>
        </Typography>
        <Typography variant="body4">{itemCategory}</Typography>
        <Grid
          container
          alignItems={"center"}
          onClick={handleBack}
          sx={{cursor: "pointer"}}
          gap="8px"
        >
          <ArrowBackIcon fontSize="small" color="primary"></ArrowBackIcon>
          <Typography fontWeight={600} variant="body4" color={"#3E80FF"}>
            <Trans i18nKey={"delivery.declaration.goBackAndChange"}>
              Go back and change
            </Trans>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        mt={2}
        sx={{
          maxHeight: {md: "35vh", xs: "unset"},
          overflowY: {md: "scroll", xs: "unset"},
          overflowX: {md: "hidden", xs: "unset"},
        }}
      >
        <Grid
          display={isDesktop ? "flex" : "none"}
          justifyContent={"space-between"}
        >
          <Typography variant="body4" fontWeight={"500"} textAlign={"start"}>
            <Trans i18nKey={"delivery.declaration.chooseDescription"}>
              Please choose the best description of your Item
            </Trans>
          </Typography>
          <Typography color={"#6C757D"} variant="body4" fontWeight={"500"}>
            <Trans i18nKey={"delivery.declaration.2of2Steps"}>
              2 of 2 step
            </Trans>
          </Typography>
        </Grid>
        <Grid
          container
          display={isDesktop ? "none" : "flex"}
          direction={"column"}
          gap="16px"
          columnGap={0}
        >
          <Typography color={"#6C757D"} variant="body4" fontWeight={"500"}>
            <Trans i18nKey={"delivery.declaration.2of2Steps"}>
              2 of 2 step
            </Trans>
          </Typography>
          <Typography variant="h3" fontWeight={"500"} textAlign={"start"}>
            <Trans i18nKey={"delivery.declaration.chooseDescription"}>
              Please choose the best description of your Item
            </Trans>
          </Typography>
        </Grid>
        <Grid py={2}>
          <TextField
            fullWidth
            label="Category"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Grid>
        <Grid sx={{marginX: isDesktop ? "0" : "-16px"}}>
          <List sx={{width: "100%", bgcolor: "background.paper"}}>
            {commodityTypesPageResult?.items?.map((value, index) => {
              const labelId = `checkbox-list-label-${index}`;
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <div
                      style={{
                        paddingRight: "16px",
                        display: selectedIndex === index ? "flex" : "none",
                      }}
                    >
                      <img src={Done} alt="" />
                    </div>
                  }
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index, value)}
                  disablePadding
                  disableGutters
                  sx={
                    !isDesktop
                      ? {
                          borderTop: "1px solid rgb(62 128 255 / 18%)",
                          paddingY: "7px",
                        }
                      : null
                  }
                >
                  <ListItemButton
                    alignItems={"flex-start"}
                    sx={{padding: 0}}
                    role={undefined}
                    disableGutters
                    dense
                  >
                    <ListItemText
                      id={labelId}
                      primary={
                        <Grid paddingX={"16px"}>
                          <Typography
                            variant="body3"
                            style={{color: "#6C757D"}}
                            sx={{marginRight: "8px", fontSize: "11px"}}
                          >
                            {value.code}
                          </Typography>
                          <Typography
                            variant="body3"
                            style={{color: "#005CC7"}}
                          >
                            {value.customValues &&
                            `simplified_description_${langCode}` in
                              value.customValues
                              ? value.customValues[
                                  `simplified_description_${langCode}`
                                ]
                              : value.description ?? ""}
                          </Typography>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Grid
            container
            justifyContent={"center"}
            marginX={{md: "-20px"}}
            marginY={{xs: "-25px"}}
          >
            <Pagination
              limit={Number(limit)}
              offset={Number(offset)}
              sort={sort}
              filter={filter}
              search={search}
              totalCount={commodityTypesPageResult?.totalCount}
              isNewStyle={true}
              style={
                isDesktop
                  ? {marginBottom: "0px", marginTop: "20px"}
                  : {marginBottom: "25px", marginTop: "20px"}
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{mt: 2}}
        gap={"24px"}
        columnSpacing={3}
        mb={isDesktop ? 0 : 6}
      >
        <Grid md={4} xs={12}>
          <Button
            data-testid="btn-ok-modal-modal"
            fullWidth
            type="button"
            variant="contained"
            color="secondary"
            disabled={selectedIndex < 0}
            onClick={onSubmit}
          >
            <Trans i18nKey={"select"}>Select</Trans>
          </Button>
        </Grid>
        <Grid md={4} xs={12}>
          <Button
            data-testid="btn-ok-modal-modal"
            fullWidth
            type="button"
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            <Trans i18nKey={"profile.savedAddresses.cancel"}>Cancel</Trans>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
