import React from "react";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans} from "react-i18next";

export default function DividingOr() {
  return (
    <Grid
      md
      xs
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        "::before": {
          content: '""',
          borderBottom: "1px solid #ABABAB",
          flex: 1,
          marginRight: "20px",
        },
        "::after": {
          content: '""',
          borderBottom: "1px solid #ABABAB",
          flex: 1,
          marginLeft: "20px",
        },
      }}
    >
      <Typography variant="caption1">
        <Trans i18nKey="or">or</Trans>
      </Typography>
    </Grid>
  );
}
