import React, {useEffect, useState} from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  Radio,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans, useTranslation} from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {$enum} from "ts-enum-util";
import Divider from "@mui/material/Divider";
import {CheckboxWithLabel, RadioGroup, TextField} from "formik-mui";
import uuid from "react-uuid";
import {ErrorMessage, Field, FieldArray, Form, useFormikContext} from "formik";

import CustomsDeclarationItemsDetails from "./customsDeclarationItemsDetails";
import {
  CommodityDto,
  CommodityTypeDto,
  CreateCommodityPortalCommand,
  DangerousItemsValues,
  DimensionsUnits,
  PurposeOfCommodityValues,
  WeightUnits,
} from "../../../features/order/order-api";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import ExclamationMark from "../../../../images/cardIcons/exclamationMark.svg";
import {useNavigate} from "react-router-dom";
import {
  removeOrderStep,
  setCommoditiesState,
  setStepOrderIds,
} from "../../../features/order/parcelShipment-slice";
import {CountryTaxLimit} from "./interfaces";
import {useDispatch} from "react-redux";
import i18next from "i18next";
import {
  CountryFilter,
  useGetCountries,
} from "../../../common/hooks/useGetCountries";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";

const itemTextFieldStyle: SxProps<Theme> = {
  "& .MuiInputBase-root": {
    height: 40,

    "& input": {
      padding: "8px 12px",
    },
  },

  "& .MuiInputLabel-root": {
    // Label offset when it's not shrinked
    top: "-6px",
    maxWidth: "60%",
  },

  "& .MuiInputLabel-shrink": {
    // Remove label offset when it shrinks (moves to top of input border)
    top: "0",
  },
};

const commodityTypeDefault: CommodityTypeDto = {
  code: undefined,
  description: undefined,
};

const newItem = () => {
  const addedCommodity: CreateCommodityPortalCommand = {
    uuid: uuid(),
    description: "",
    note: "",
    quantity: undefined,
    unitaryValue: undefined,
    weight: undefined,
    pieces: 1,
    commodityTypeId: undefined,
    weightUnit: WeightUnits.Lb,
    dimensionsUnit: DimensionsUnits.In,
    containerCommodities: undefined,
    commodityType: commodityTypeDefault,
  };

  return addedCommodity;
};

const onTotalChange = (commodities: CommodityDto[]) => {
  let sum = 0;
  commodities.forEach((innerCommodities: any) => {
    if (innerCommodities) {
      sum += innerCommodities.reduce(
        (acc: number, cur: CommodityDto) =>
          acc + (cur?.unitaryValue ?? 0) * (cur?.quantity ?? 0),
        0,
      );
      return sum;
    }
  });
  return sum;
};

export default function CustomsDeclarationDetailsComponent({
  saveToStorage,
  isConsolidated,
  purchases,
  config,
}: any) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const formikContext = useFormikContext<any>();
  const navigate = useNavigate();

  const [commodities, setCommodities] = useState<any[]>(
    formikContext?.values?.map((item: any) => item?.commodities ?? null),
  );

  const lang = i18next?.language ?? "en";

  const {data: countries} = useGetCountries({filter: CountryFilter.Delivery});

  const {orderIds, currentOrder} = useDeliveryHelper();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [aboveLimitCountryTax, setAboveLimitCountryTax] =
    useState<CountryTaxLimit | null>(null);

  const country = countries?.find(
    (x) => x.countryCode == currentOrder?.consigneeAddressValues?.countryCode,
  );

  useEffect(() => {
    if (purchases && purchases.length > 0) {
      const isGovernmentChecked = purchases[0].isGovernmentChecked;
      const dangerousItems = purchases[0].container.customValues.dangerousItems;
      const purposeOfCommodity =
        purchases[0].container.customValues.purposeOfCommodity ??
        PurposeOfCommodityValues.Gift;
      purchases.forEach((purchase: any, index: number) => {
        formikContext.setFieldValue(`[${index}]`, purchase, false);
        formikContext.setFieldValue(
          `[${index}].isGovernmentChecked`,
          isGovernmentChecked,
          false,
        );
        formikContext.setFieldValue(
          `[${index}].container.customValues.dangerousItems`,
          dangerousItems,
          false,
        );
        formikContext.setFieldValue(
          `[${index}].container.customValues.purposeOfCommodity`,
          purposeOfCommodity,
          false,
        );
      });
    }
  }, [purchases]);

  useEffect(() => {
    setCommodities(
      formikContext?.values?.map((item: any) => item?.commodities ?? null),
    );
  }, [formikContext?.values]);

  useEffect(() => {
    if (commodities && currentOrder?.consigneeAddressValues?.countryCode) {
      if (
        country?.customValues &&
        "country_tax_free_limit" in country.customValues
      ) {
        const taxLimit = Number(country.customValues["country_tax_free_limit"]);
        const currency = {
          symbol: country.customValues["country_tax_free_limit_currency"]
            ? " "
            : "$",
          decimalPlaces: 2,
          currencyCode: country.customValues["country_tax_free_limit_currency"],
        };
        const totalDeclaredValue = onTotalChange(
          formikContext.values.map((item: any) => {
            if (item && item.commodities) return item.commodities;
          }),
        );
        if (totalDeclaredValue > taxLimit) {
          const countryTax: CountryTaxLimit = {
            country: country.name,
            taxLimit: taxLimit,
            currency,
          };
          setAboveLimitCountryTax(countryTax);
        } else {
          setAboveLimitCountryTax(null);
        }
      }
    }
  }, [commodities, currentOrder?.consigneeAddressValues?.countryCode]);

  useEffect(() => {
    if (formikContext.values[0].container.weightUnit === undefined) {
      formikContext.setFieldValue(
        "[0].container.weightUnit",
        config?.defaultWeightUnits ?? WeightUnits.Lb,
      );
    }
    if (formikContext.values[0].container.dimensionsUnit === undefined)
      formikContext.setFieldValue(
        "[0].container.dimensionsUnit",
        config?.defaultLengthUnits ?? DimensionsUnits.In,
      );
  }, []);

  useEffect(() => {
    if (commodities) {
      commodities.forEach((innerCommodities, index) => {
        if (!innerCommodities || innerCommodities?.length === 0) {
          formikContext.setFieldValue(`[${index}].commodities`, [newItem()]);
        } else {
          innerCommodities.forEach((commodity: any) => {
            if (!commodity.commodityType) {
              commodity.commodityType = commodityTypeDefault;
            }
          });
        }
      });
    }
  }, [commodities]);

  const checkboxesLabels = {
    [$enum(DangerousItemsValues).getKeyOrThrow(
      DangerousItemsValues.DangerousGoods,
    )]: t("delivery.declaration.potentiallyDangerousGoods"),
    [$enum(DangerousItemsValues).getKeyOrThrow(
      DangerousItemsValues.LithiumBatteryContainingGoods,
    )]: t("delivery.declaration.lithiumBatteryContainingGoods"),
  };

  const radioButtonsLabels = {
    [$enum(PurposeOfCommodityValues).getKeyOrThrow(
      PurposeOfCommodityValues.PersonalMerchandise,
    )]: t("delivery.declaration.personalMerchandise"),
    [$enum(PurposeOfCommodityValues).getKeyOrThrow(
      PurposeOfCommodityValues.BusinessMerchandise,
    )]: t("delivery.declaration.businessMerchandise"),
    [$enum(PurposeOfCommodityValues).getKeyOrThrow(
      PurposeOfCommodityValues.Gift,
    )]: t("delivery.declaration.gift"),
  };

  const tableHeadersStyle = {
    ...theme.typography.body4,
    padding: theme.spacing(1, 2.5),
    textAlign: "start",
    borderRight: "2px solid #F5F9FF",
    borderBottom: "2px solid #F5F9FF",
    fontWeight: "500",
    minHeight: "46px",
    display: "flex",
    alignItems: "center",
    pl: 2,
  };

  const isAnyDangerousItems = formikContext.values?.[0].container?.customValues
    ?.dangerousItems
    ? Object.values(
        formikContext.values?.[0].container?.customValues?.dangerousItems,
      ).some((value) => value)
    : false;

  const onFormChange = (
    purchaseIndex?: null | number,
    commodityIndex?: null | number,
  ) => {
    let commoditiesData = [];
    if (purchaseIndex !== null && purchaseIndex !== undefined) {
      commoditiesData = [...commodities[purchaseIndex]];
      let activeCommoditiesData = commoditiesData;
      if (commodityIndex !== null) {
        commoditiesData[commodityIndex ?? 0] = {
          ...commoditiesData[commodityIndex ?? 0],
          isRemoved: true,
        };
        activeCommoditiesData = commoditiesData.filter(
          (item, itemIndex) => !item.isRemoved,
        );
        if (activeCommoditiesData.length == 0) {
          commoditiesData.push(newItem());
        }
      }

      if (commoditiesData && commoditiesData.length > 0) {
        const commodity: CreateCommodityPortalCommand = {
          uuid: uuid(),
          description: "",
          note: "",
          quantity: undefined,
          unitaryValue: undefined,
          weight: formikContext.values?.[0].container?.weight,
          pieces: 1,
          commodityTypeId: undefined,
          weightUnit: formikContext.values?.[0].container?.weightUnit,
          dimensionsUnit: formikContext.values?.[0].container?.dimensionsUnit,
          volumeUnit: formikContext.values?.[0].container?.volumeUnit,
          customValues: formikContext.values?.[0].container?.customValues,
          containerCommodities: commoditiesData,
        };
        dispatch(
          setCommoditiesState({
            orderIds: orderIds,
            commodity,
          }),
        );

        const values = [...formikContext.values];
        if (activeCommoditiesData.length === 0) {
          if (
            !commoditiesData[commoditiesData.length - 1].description !== null &&
            commoditiesData[commoditiesData.length - 1].description !==
              undefined
          ) {
            if (
              commoditiesData[commoditiesData.length - 1].description.trim() !==
              ""
            ) {
              values[purchaseIndex].commodities = commoditiesData.filter(
                (el: any) => el.isRemoved,
              );
              saveToStorage(values);
            }

            formikContext.setFieldValue(
              `[${purchaseIndex}].commodities`,
              commoditiesData,
            );
          }
        } else {
          if (
            commoditiesData.every(
              (commodity: any) =>
                !!commodity.description &&
                commodity.description.trim() !== "" &&
                !!commodity.commodityType &&
                commodity.unitaryValue != null &&
                commodity.unitaryValue != undefined &&
                commodity.quantity != null &&
                commodity.quantity != undefined,
            )
          ) {
            values[purchaseIndex].commodities = commoditiesData.filter(
              (el: any) => el.isRemoved,
            );
            saveToStorage(values);

            formikContext.setFieldValue(
              `[${purchaseIndex}].commodities`,
              commoditiesData,
            );
          }
        }

        const dangerousItems = values[
          purchaseIndex
        ].container?.customValues?.dangerousItems
          ?.toString()
          .split(",");
        formikContext.setFieldValue(
          "[0].container.customValues.dangerousItems",
          dangerousItems,
        );
      }
    } else {
      commoditiesData = [...commodities];

      if (commoditiesData && commoditiesData.length > 0) {
        commoditiesData.forEach((commodities, purchaseIndex) => {
          const commodity: CreateCommodityPortalCommand = {
            uuid: uuid(),
            description: "",
            note: "",
            quantity: undefined,
            unitaryValue: undefined,
            weight: formikContext.values?.[0].container?.weight,
            pieces: 1,
            commodityTypeId: undefined,
            weightUnit: formikContext.values?.[0].container?.weightUnit,
            dimensionsUnit: formikContext.values?.[0].container?.dimensionsUnit,
            volumeUnit: formikContext.values?.[0].container?.volumeUnit,
            customValues: formikContext.values?.[0].container?.customValues,
            containerCommodities: commodities,
          };
          dispatch(
            setCommoditiesState({
              orderIds: orderIds,
              commodity,
            }),
          );
        });
      }
    }
  };

  useEffect(() => {
    if (
      currentOrder?.containerCommodities &&
      currentOrder.containerCommodities.length > 0
    ) {
      const commodity = currentOrder.containerCommodities[0];

      const fieldsToSet = [
        {
          key: "[0].container.weightUnit",
          value: commodity?.weightUnit,
        },
        {
          key: "[0].container.weight",
          value: commodity?.weight,
        },
        {
          key: "[0].container.customValues.dangerousItems",
          value: commodity?.customValues?.dangerousItems,
        },
        {
          key: "[0].container.customValues.purposeOfCommodity",
          value: commodity?.customValues?.purposeOfCommodity,
        },
      ];

      fieldsToSet.forEach((field) => {
        formikContext.setFieldValue(field.key, field.value);
      });
    }
  }, [navigation]);

  const onDeleteProductItem = (
    purchaseIndex: number,
    commodityIndex: number,
  ) => {
    if (commodities[purchaseIndex] && commodities[purchaseIndex].length === 1) {
      formikContext.setFieldValue(`[0].isGovernmentChecked`, false, false);
    }

    onFormChange(purchaseIndex, commodityIndex);
  };
  const onRemoveFromConsolidation = (purchaseIndex: number) => {
    const prevValues = [...formikContext.values];
    formikContext.setValues(
      prevValues.filter((_, index) => index !== purchaseIndex),
      false,
    );

    const newOrderIds = orderIds?.filter((_, index) => index !== purchaseIndex);
    if (newOrderIds && newOrderIds.length < 2) {
      dispatch(removeOrderStep(orderIds));
      newOrderIds.push(0);
      navigate(`../delivery/${newOrderIds?.join("&")}/selectPurchases/`);
    } else {
      dispatch(
        setStepOrderIds({
          previousOrderIds: orderIds,
          newOrderIds: newOrderIds,
        }),
      );
      navigate(`../delivery/${newOrderIds?.join("&")}/customs/`);
    }
  };
  return (
    <Form id="addCustomValuesCommodityForm" onBlur={() => onFormChange()}>
      <Grid
        container
        md={12}
        mb={2}
        direction="column"
        sx={{
          border: isDesktop ? "1px solid #2F81EF" : "none",
          fontSize: "16px",
          borderRadius: "16px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            height: "70px",
            background: {
              md: "#f5f9ff",
              xs: "#D8E6FB",
            },
            fontWeight: "bold",
            padding: "0 1rem ",
            borderRadius: "16px 16px 0 0",
            marginX: {
              md: 0,
              xs: -2,
            },
          }}
        >
          <Typography variant="h2">
            {isConsolidated ? (
              <Trans i18nKey="consolidatedShipment.title">
                Consolidated Shipment
              </Trans>
            ) : (
              <>
                <Trans i18nKey="purchaseTrackingNumber">
                  Purchase Tracking Number #
                </Trans>
                <span data-testid="purchase-tracking-number">
                  {formikContext.values?.[0].trackingNumber}
                </span>
              </>
            )}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: {md: "0 1rem", xs: "0"},
          }}
        />
        <FormControl
          sx={{mb: 1, mt: 3, ml: {md: 2, xs: 0}}}
          component="fieldset"
        >
          <Typography
            fontSize={{xs: "1rem", md: "1.25rem"}}
            sx={{typography: {md: "body2", xs: "body4"}}}
            mb={1}
          >
            <Trans i18nKey="delivery.declaration.packageDescription">
              How would you describe the contents of this package?
            </Trans>
          </Typography>
          <Field
            data-testid="radio-buttons-group-purpose-of-commodity"
            component={RadioGroup}
            name="[0].container.customValues.purposeOfCommodity"
            color="primary"
            aria-labelledby="demo-radio-buttons-group-label"
            row={isDesktop ? true : false}
          >
            {$enum(PurposeOfCommodityValues).map(
              (value, key, wrappedEnum, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Radio color="primary" sx={{color: "#ABABAB"}}></Radio>
                    }
                    value={key}
                    sx={{marginRight: "20px"}}
                    label={radioButtonsLabels[key]}
                    checked={
                      key ===
                      formikContext.values[0].container.customValues
                        .purposeOfCommodity
                    }
                  />
                );
              },
            )}
          </Field>
          {(formikContext.touched?.container as any)?.customValues
            ?.purposeOfCommodity &&
            (formikContext.errors?.container as any)?.customValues
              ?.purposeOfCommodity && (
              <FormHelperText
                sx={{color: "error.main", fontSize: "14px", margin: "0px"}}
              >
                {(
                  formikContext.errors?.container as any
                )?.customValues?.purposeOfCommodity?.toString()}
              </FormHelperText>
            )}
          <Divider sx={{borderBlockColor: "#F5F9FF", ml: -2, mt: 2}} />
          <Typography
            sx={{
              mt: {md: 3, xs: 2},
              mb: 2,
              typography: {md: "body2", xs: "body4"},
            }}
          >
            <Trans i18nKey="delivery.declaration.checkBoxApplyItems">
              Please check the boxes that apply to any of the items in the
              package
            </Trans>
          </Typography>
          <Grid
            md={4}
            mb={2}
            padding="0.3125rem 0.75rem"
            sx={{
              background: "#f5f9ff",
              borderRadius: "100px",
              width: {md: "fit-content !important", xs: "fit-content"},
              whiteSpace: {md: "nowrap", xs: "initial"},
              maxWidth: "100%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                typography: {md: "caption1", xs: "caption3"},
              }}
            >
              <Trans i18nKey="delivery.declaration.skipAction">
                You may skip this section if it does not apply to your items
              </Trans>
            </Typography>
          </Grid>
          <Grid
            data-testid="checkboxes-dangerous-items"
            container
            direction={"column"}
            gap={"20px"}
          >
            {$enum(DangerousItemsValues).map((value, key) => (
              <Field
                key={key}
                value={key}
                component={CheckboxWithLabel}
                type="checkbox"
                name="[0].container.customValues.dangerousItems"
                color="primary"
                Label={{
                  label: <div>{checkboxesLabels[key]}</div>,
                }}
                sx={{color: "#ABABAB", marginRight: 0}}
                onChange={(_: any, checked: boolean) => {
                  formikContext.values.map((_: any, index: number) => {
                    const prev =
                      formikContext.values[index].container.customValues
                        .dangerousItems;
                    if (checked) {
                      if (typeof prev === "object" && prev.length > 0) {
                        formikContext.setFieldValue(
                          `[${index}].container.customValues.dangerousItems`,
                          [...prev, key],
                        );
                      } else {
                        formikContext.setFieldValue(
                          `[${index}].container.customValues.dangerousItems`,
                          [key],
                        );
                      }
                    } else {
                      formikContext.setFieldValue(
                        `[${index}].container.customValues.dangerousItems`,
                        prev.filter((x: string) => x !== key),
                      );
                    }
                  });
                }}
              />
            ))}
          </Grid>
        </FormControl>
        <Grid
          sx={{
            display: "flex",
            flexDirection: {md: "row", xs: "column"},
            mt: 2,
            alignItems: {md: "center", xs: "flex-start"},
            justifyContent: {md: "inherit", xs: "center"},
            height: "70px",
            background: {md: "#f5f9ff", xs: "#D8E6FB"},
            padding: "0 1rem ",
            fontWeight: "bold",
            marginX: {
              md: 0,
              xs: -2,
            },
          }}
        >
          <Typography variant="h2">
            {isConsolidated ? (
              <Trans i18nKey="parcelInformation">Parcel information</Trans>
            ) : (
              <Trans i18nKey="purchaseInformation">Purchase information</Trans>
            )}
          </Typography>
        </Grid>

        {formikContext?.values?.map((purchase: any, purchaseIndex: number) => {
          return (
            <>
              {isConsolidated && (
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mt={6}
                  mb={3}
                  pl={2}
                  pr={6}
                >
                  <Typography
                    sx={{fontWeight: 600, fontSize: "18px", lineHeight: "24px"}}
                  >
                    <Trans i18nKey="consolidatedShipment.purchaseTrackingNumber">
                      Purchase tracking number:
                    </Trans>
                    &nbsp;{purchase?.trackingNumber}
                  </Typography>

                  <Typography
                    color="primary.main"
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => onRemoveFromConsolidation(purchaseIndex)}
                  >
                    <Trans i18nKey="consolidatedShipment.removeFromConsolidation">
                      Remove from consolidation
                    </Trans>
                  </Typography>
                </Grid>
              )}
              <FieldArray name={`[${purchaseIndex}].commodities`}>
                {({push, remove}) => (
                  <>
                    <Grid>
                      <Divider
                        sx={{
                          borderColor: "#D8E6FB",
                          marginX: {md: 0, xs: -2},
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      direction={"row"}
                      sx={{display: {md: "flex", xs: "none"}}}
                    >
                      <Grid xs={3} md={4.5} sx={tableHeadersStyle}>
                        <Trans i18nKey="itemName">Item name</Trans>
                      </Grid>
                      <Grid xs={2} md={3.5} sx={tableHeadersStyle}>
                        <Trans i18nKey="categoryPurchaseItem">Category</Trans>
                      </Grid>
                      <Grid xs={2} md={2} sx={tableHeadersStyle}>
                        <Trans i18nKey="declaredValue">Declared Value</Trans>
                      </Grid>
                      <Grid xs={1.5} md={1} sx={tableHeadersStyle}>
                        <Trans i18nKey="quantityPurchaseItem">Quantity</Trans>
                      </Grid>
                      <Grid xs={3.5} md={1} sx={tableHeadersStyle}></Grid>
                    </Grid>
                    <Grid
                      data-testid="purchase-item-block"
                      borderBottom={{
                        md:
                          commodities?.length < 1
                            ? "none"
                            : "2px solid #F5F9FF",
                        xs: "none",
                      }}
                    >
                      {commodities?.[purchaseIndex]?.map(
                        (data: any, commodityIndex: number) =>
                          !data.isRemoved && (
                            <CustomsDeclarationItemsDetails
                              key={data.uuid}
                              uuid={data.uuid}
                              purchaseIndex={purchaseIndex}
                              commodityIndex={commodityIndex}
                              deleteItem={() => {
                                remove(commodityIndex);
                                onDeleteProductItem(
                                  purchaseIndex,
                                  commodityIndex,
                                );
                              }}
                            />
                          ),
                      )}
                    </Grid>
                    {!isDesktop && (
                      <Divider sx={{borderColor: "#D8E6FB", marginX: -2}} />
                    )}
                    <Grid
                      container
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent="space-between"
                    >
                      <Grid
                        ml={{
                          md: 0,
                          xs: -2,
                        }}
                        display={"inline-flex"}
                        mt={{xs: 2, md: 0}}
                        mb={{xs: 1, md: 0}}
                        md={4}
                        xs={12}
                      >
                        <Button
                          sx={{"&:hover": {backgroundColor: "transparent"}}}
                          disableRipple
                          onClick={() => push(newItem())}
                        >
                          <IconButton color="primary">
                            <AddBoxIcon />
                          </IconButton>
                          <Typography
                            variant="body1"
                            style={{textTransform: "none"}}
                            color={"black"}
                          >
                            <Trans i18nKey="addYourGoods">Add your goods</Trans>
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid
                        md={3}
                        xs={12}
                        mdOffset={4}
                        sx={{
                          fontWeight: "bold",
                          minHeight: "40px",
                          padding: {
                            md: "1rem",
                            xs: "1rem 0",
                          },
                        }}
                      >
                        {!isConsolidated && (
                          <Field
                            fullWidth
                            component={TextField}
                            id="estimatedTotalWeight"
                            name="[0].container.weight"
                            label={t("totalWeightItem")}
                            variant="outlined"
                            size="medium"
                            sx={itemTextFieldStyle}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {config?.allowChangeWeightUnits ? (
                                    <>
                                      <Button
                                        data-testid="btn-purchase-kg"
                                        sx={{
                                          justifyContent: "end",
                                          "&:hover": {
                                            backgroundColor: "transparent",
                                          },
                                          minWidth: "unset",
                                          p: 1,
                                          color:
                                            formikContext.values?.[0]?.container
                                              ?.weightUnit === WeightUnits.Kg
                                              ? "black"
                                              : "grey",
                                        }}
                                        disableRipple
                                        onClick={() =>
                                          formikContext.setFieldValue(
                                            "[0].container.weightUnit",
                                            WeightUnits.Kg,
                                          )
                                        }
                                      >
                                        {t("kg")}
                                      </Button>
                                      |
                                      <Button
                                        data-testid="btn-purchase-lb"
                                        sx={{
                                          justifyContent: "end",
                                          "&:hover": {
                                            backgroundColor: "transparent",
                                          },
                                          p: 1,
                                          minWidth: "unset",
                                          color:
                                            formikContext.values?.[0]?.container
                                              ?.weightUnit === WeightUnits.Lb
                                              ? "black"
                                              : "grey",
                                        }}
                                        disableRipple
                                        onClick={() => {
                                          formikContext.setFieldValue(
                                            "[0].container.weightUnit",
                                            WeightUnits.Lb,
                                          );
                                        }}
                                      >
                                        {t("lb")}
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      data-testid="btn-purchase-unit"
                                      sx={{
                                        justifyContent: "end",
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                        minWidth: "unset",
                                        p: 1,
                                        color: "black",
                                      }}
                                      disableRipple
                                    >
                                      {t(
                                        (
                                          formikContext?.values[0].container
                                            .weightUnit as string
                                        )?.toLowerCase(),
                                      )}
                                    </Button>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Grid>
                      <Grid md={1} />
                    </Grid>
                  </>
                )}
              </FieldArray>
            </>
          );
        })}

        {!isDesktop && (
          <Divider
            sx={{borderBlockColor: "#D8E6FB", marginX: -2, marginBottom: 2}}
          />
        )}
        <Grid
          xs={12}
          container
          sx={{
            background: {
              md: "#f5f9ff",
              xs: "#D8E6FB",
            },
            paddingX: 2,
            borderRadius: {
              md: "0 0 16px 16px",
              xs: "0",
            },
            marginX: {
              md: 0,
              xs: -2,
            },
            paddingY: {xs: 2, md: 2},
            typography: "h2",
            alignItems: "center",
          }}
        >
          <Grid
            component="span"
            sx={{
              background: "inherit",
              zIndex: 1,
              paddingRight: "5px",
            }}
          >
            <Trans i18nKey="totalDeclaredValue">Total Declared Value</Trans>
          </Grid>
          <Grid
            component="span"
            md={2}
            xs
            sx={{
              position: "relative",
              // Dots between label and value
              "&::before": {
                content: '""',
                position: "absolute",
                bottom: "-0.5rem",
                width: "100%",
                height: "2.5px",
                lineHeight: 0,
                backgroundImage:
                  "linear-gradient(to right, #000 30%, rgba(255, 255, 255, 00) 10%)",
                backgroundPosition: "top",
                backgroundSize: "10px 3px",
                backgroundRepeat: "repeat-x",
                opacity: {xs: 0.2, md: 1},
              },
            }}
          ></Grid>
          <Grid
            data-testid="total-declared-value"
            component="span"
            id="index"
            sx={{
              background: "inherit",
              zIndex: 1,
              paddingLeft: "2px",
            }}
          >
            {getFormattedPrice(
              onTotalChange(
                formikContext.values.map((item: any) => {
                  if (item && item.commodities)
                    return item.commodities.filter((el: any) => !el.isRemoved);
                }),
              ),
            )}
          </Grid>
        </Grid>
      </Grid>
      {aboveLimitCountryTax && (
        <Grid mt={4} mb={2} md={6} className="card-tax-warning" p={3}>
          <Grid display={"flex"} justifyContent={{md: "space-between"}}>
            <Grid display={"flex"} gap={"8px"}>
              <img src={ExclamationMark} width={"24px"} />
              <Typography
                variant="body4"
                fontWeight="500"
                width="100%"
                color={{xs: "#C32014", md: "black"}}
              >
                <Trans i18nKey={"delivery.declaration.payTaxes"}>
                  You have to pay taxes in&nbsp;
                  {country &&
                  country?.customValues &&
                  `in_country_translation_${lang}` in country.customValues &&
                  country.customValues[`in_country_translation_${lang}`]
                    .length > 0
                    ? country.customValues[`in_country_translation_${lang}`]
                    : country?.name}
                  &nbsp;for value
                </Trans>
                <Typography
                  display={{xs: "flex", md: "none"}}
                  fontWeight="600"
                  color="#C32014"
                >
                  <Trans i18nKey={"delivery.declaration.moreThan"}>
                    &nbsp; more than
                  </Trans>{" "}
                  {getFormattedPrice(
                    aboveLimitCountryTax.taxLimit,
                    aboveLimitCountryTax.currency,
                  )}
                  {aboveLimitCountryTax.currency?.currencyCode
                    ? ` ${aboveLimitCountryTax.currency?.currencyCode}`
                    : ""}
                </Typography>
              </Typography>
            </Grid>
            <Grid display={{xs: "none", md: "flex"}} width="max-content">
              <Typography variant="body4" fontWeight="600" color="#C32014">
                <Trans i18nKey={"delivery.declaration.moreThan"}>
                  more than
                </Trans>{" "}
                {getFormattedPrice(
                  aboveLimitCountryTax.taxLimit,
                  aboveLimitCountryTax.currency,
                )}
                {aboveLimitCountryTax.currency?.currencyCode
                  ? ` ${aboveLimitCountryTax.currency?.currencyCode}`
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <MuiLink
            underline="none"
            href={
              "https://trtshopping.com/ua/" +
              (i18next.language !== "ua" ? i18next.language + "/" : "") +
              "destinations/ukraine/#customs-rules"
            }
            target="_blank"
            rel="noopener"
            display={"flex"}
          >
            <Trans i18nKey={"delivery.declaration.checkTheFormula"}>
              Check the formula for import tax and VAT cost
            </Trans>
          </MuiLink>
        </Grid>
      )}
      {!isAnyDangerousItems && (
        <Grid pt={1}>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="[0].isGovernmentChecked"
            color="primary"
            onChange={(_: any, checked: boolean) => {
              formikContext.values.map((_: any, index: number) => {
                formikContext.setFieldValue(
                  `[${index}].isGovernmentChecked`,
                  checked,
                );
              });
            }}
            data-testid="input-isGovernmentChecked"
            Label={{
              label: (
                <div>
                  <Typography>
                    <Trans i18nKey="delivery.declaration.isntdangerousItems">
                      I, the shipper, hereby state that my shipment does not
                      contain any&nbsp;
                      <MuiLink
                        underline="none"
                        href={
                          "https://trtshopping.com/" +
                          (i18next.language !== "ua"
                            ? `ua/${i18next.language}/`
                            : `${i18next.language}/`) +
                          "help/#safety-of-transport"
                        }
                        target="_blank"
                        rel="noopener"
                      >
                        dangerous or prohibited
                      </MuiLink>
                      &nbsp;for export goods. I consent to the search of my
                      shipment.
                    </Trans>
                  </Typography>
                </div>
              ),
            }}
            sx={{color: "#ABABAB"}}
          />
          <ErrorMessage
            name="[0].isGovernmentChecked"
            render={(msg: any) => (
              <Typography
                variant="subtitle2"
                color="error"
                sx={{fontWeight: "light", mb: 1}}
              >
                {msg}
              </Typography>
            )}
          />
        </Grid>
      )}
    </Form>
  );
}
