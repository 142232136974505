import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {IconButton} from "@mui/material";
import LanguageSelect from "../../LanguageSelect/components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useLocation} from "react-router-dom";
import {ReactComponent as Logo} from "../../../../images/TRTLogo.svg";
import {HashLink} from "react-router-hash-link";

export default function MainMobileHeader({handlePrev, step}: any) {
  const [contentGrid, setContentGrid] = useState<Element | null>(null);
  const [showMobileHeaderTitle, setShowMobileHeaderTitle] =
    useState<boolean>(false);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    let observer: IntersectionObserver;
    if (contentGrid !== null) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setShowMobileHeaderTitle(false);
            } else {
              setShowMobileHeaderTitle(true);
            }
          });
        },
        {
          threshold: 1,
        },
      );
      observer.observe(contentGrid);
    } else {
      const el = document.getElementById("radial-progress-step");
      setContentGrid(el);
    }
    return () => {
      if (observer! == null && contentGrid !== null) {
        (observer as IntersectionObserver).unobserve(contentGrid);
      }
    };
  }, [contentGrid]);

  return (
    <Grid
      display={{xs: "block", md: "none"}}
      sx={{
        height: "56px",
        position: "sticky",
        top: "0px",
        paddingLeft: "16px",
        paddingRight: "16px",
        boxShadow: "0px 0px 8px rgba(18, 70, 128, 0.2)",
        background: "#F5F9FF",
        zIndex: 1100,
        mb: 3,
      }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{height: "100%"}}
      >
        <Grid sx={{display: "flex", alignItems: "center", width: "10%"}}>
          <IconButton
            onClick={handlePrev}
            sx={{
              color: "black",
              visibility: path.includes("getting-started")
                ? "hidden"
                : "visible",
            }}
            data-testid={"btn-step-prev-mobile"}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Grid>

        <HashLink to="../getting-started#top">
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            height="80%"
            borderColor="red"
          >
            <Logo height={50} width={64} fill="#3E80FF" />
          </Grid>
        </HashLink>

        <Grid container direction="row">
          <LanguageSelect languageColor="primary.dark" />
        </Grid>
      </Grid>
    </Grid>
  );
}
