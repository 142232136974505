import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import LoginBackgroundImg from "../../../images/LoginBackground.png";

export default function LoginBackground() {
  const paperBackground = {backgroundImage: `url(${LoginBackgroundImg})`};

  return (
    <Grid
      display={{xs: "none", md: "flex"}}
      md={8}
      style={paperBackground}
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
  );
}
