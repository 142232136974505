import React from "react";
import {Trans} from "react-i18next";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ProfileTabMenu from "../../components/profileTabMenu";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import EditAccountNameForm from "../components";

const EditAccountName = () => {
  return (
    <MainLayout
      pageTitle="Personal Details"
      backLink="../profile"
      statusBar={<ProfileTabMenu selectedTab={1} />}
    >
      <ContentLayout
        title={<Trans i18nKey={"profile.tabs.editName"}>Name editing</Trans>}
        backLink="../profile"
        content={<EditAccountNameForm />}
        containerSx={{mb: "24px"}}
      />
    </MainLayout>
  );
};

export default EditAccountName;
