import React, {useEffect, useMemo, useState} from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  FilterOptionsState,
  IconButton,
  MenuItem,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Field, useFormikContext} from "formik";
import {TextField} from "formik-mui";
import {Trans, useTranslation} from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {CustomsDeclarationItemDetailsProps} from "./interfaces";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {
  CommodityTypeDto,
  CommodityTypesGetApiArg,
  useGetCommodityTypesMutation,
} from "../../../features/commodityTypes/commodityTypes-api";
import {validatePositiveIntegerNumberInput} from "../../../Purchases/EditPurchases/pages/validation";
import FilledArrowRightIcon from "../../../../images/filledArrowRightIcon.svg";
import ModalForm from "../../../common/Modal/components/modalForm";
import {CategoryModal} from "./categories/categoryModal";
import {CreateCommodityPortalCommand} from "../../../features/order/order-api";
import {getLangCodeFromI18n} from "../../../app/app.component";

const itemsStyle = {
  padding: {
    md: "1rem",
    xs: "0.5rem 0",
  },
  borderRight: {
    md: "2px solid #F5F9FF",
    xs: "none",
  },
  spacing: {xs: 0.5, md: 0},
  fontWeight: "bold",
  minHeight: "40px",
};

const itemTextFieldStyle: SxProps<Theme> = {
  "& .MuiInputBase-root": {
    height: 40,

    "& input": {
      padding: "8px 12px",
    },
  },

  "& .MuiInputLabel-root": {
    // Label offset when it's not shrinked
    top: "-6px",
  },

  "& .MuiInputLabel-shrink": {
    // Remove label offset when it shrinks (moves to top of input border)
    top: "0",
  },
};

export const defaultCommodityType: CommodityTypeDto = {
  code: undefined,
  description: undefined,
};

export default function CustomsDeclarationItemsDetails({
  uuid,
  deleteItem,
  purchaseIndex,
  commodityIndex,
}: CustomsDeclarationItemDetailsProps) {
  const langCode = getLangCodeFromI18n();
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formikContext = useFormikContext<any>();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [commodityTypeState, setCommodityTypeState] = useState<
    CommodityTypeDto | undefined
  >(
    formikContext.values?.[purchaseIndex]?.commodities?.[commodityIndex]
      ?.commodityType,
  );

  const [commodityTypeSearch, setCommodityTypeSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [commodityTypesData, setCommodityTypesData] =
    useState<CommodityTypeDto[] | null>(null);

  const [getCommodityTypes] = useGetCommodityTypesMutation();

  const loadCommodityTypes = (search?: string) => {
    const commodityTypesGetApiArgs: CommodityTypesGetApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      values: {
        search: search ?? commodityTypeSearch,
        limit: 300,
      },
    };
    setIsLoading(true);
    getCommodityTypes(commodityTypesGetApiArgs)
      .unwrap()
      .then((result) => {
        setCommodityTypesData(result as CommodityTypeDto[]);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadCommodityTypes();
  }, []);

  const switchOpen = () => {
    setIsOpen((prev: any) => !prev);
  };

  useEffect(() => {
    onCommodityTypeSelect();
  }, [commodityTypeState]);

  const onCommodityTypeSelect = () => {
    if (commodityTypeState && commodityTypeState.commodityTypeId) {
      const commodities = [...formikContext.values[purchaseIndex].commodities];
      const currentCommodity = {...commodities[commodityIndex]};
      const currentCommodityType = {...currentCommodity.commodityType};
      currentCommodityType.commodityTypeId = commodityTypeState.commodityTypeId;
      currentCommodity.commodityType = currentCommodityType;
      commodities[commodityIndex] = currentCommodity;
      const commodity: CreateCommodityPortalCommand = {
        uuid: uuid,
        description: "",
        note: "",
        quantity: undefined,
        unitaryValue: undefined,
        weight: formikContext.values?.[0]?.container?.weight,
        pieces: 1,
        commodityTypeId: undefined,
        weightUnit: formikContext.values?.[0]?.container?.weightUnit,
        customValues: formikContext.values?.[0]?.container?.customValues,
        containerCommodities: commodities,
      };
    }
  };

  const onHandleChange = (value: CommodityTypeDto | null) => {
    const entity: CommodityTypeDto = {
      code: value?.code ?? undefined,
      commodityTypeId: value?.commodityTypeId,
      created: value?.created,
      createdBy: value?.createdBy,
      customValues: value?.customValues,
      description: value?.description,
      isActive: value?.isActive,
      lastModified: value?.lastModified,
      lastModifiedBy: value?.lastModifiedBy,
      links: value?.links,
    };
    formikContext.setFieldValue(
      `[${purchaseIndex}].commodities[${commodityIndex}].commodityType`,
      entity,
    );
    setCommodityTypeState(entity);
  };

  const commodityTypeInput = useMemo(() => {
    return (
      <Autocomplete
        filterOptions={(
          options: CommodityTypeDto[],
          state: FilterOptionsState<CommodityTypeDto>,
        ) => {
          const search = state.inputValue.toLowerCase();
          return [
            {
              id: -1,
              description: t("delivery.declaration.knowHSCode"),
              code: "",
              disabled: true,
            },
            ...Array.from(
              new Set(
                options.filter((option) => {
                  if (langCode === "en") {
                    return (
                      option.code?.toLowerCase().includes(search) ||
                      option.description?.toLowerCase().includes(search) ||
                      (option.customValues &&
                        option.customValues[
                          `simplified_description_${langCode}`
                        ]
                          ?.toLowerCase()
                          .includes(search))
                    );
                  } else {
                    return (
                      option.code?.toLowerCase().includes(search) ||
                      (option.customValues &&
                        option.customValues[
                          `simplified_description_${langCode}`
                        ]
                          ?.toLowerCase()
                          .includes(search))
                    );
                  }
                }),
              ),
            ),
          ];
        }}
        onKeyUp={() => loadCommodityTypes()}
        disableClearable={true}
        options={commodityTypesData ?? []}
        getOptionLabel={(option: any) => {
          const code = option.code ?? "";
          const description =
            option.customValues &&
            `simplified_description_${langCode}` in option.customValues
              ? option.customValues[`simplified_description_${langCode}`]
              : option.description ?? "";
          return code && description ? `${description}` : "";
        }}
        isOptionEqualToValue={(option: any, value: any) =>
          option?.code == value?.code
        }
        forcePopupIcon={true}
        onChange={(_: any, value: any) => onHandleChange(value)}
        onInputChange={(_: any, value: string) => {
          setCommodityTypeSearch(value);
        }}
        onBlur={() => {
          if (!commodityTypeSearch || !commodityTypeState?.code) {
            setCommodityTypeState(defaultCommodityType);
            formikContext.setFieldValue(
              `[${purchaseIndex}].commodities[${commodityIndex}].commodityType.code`,
              null,
            );
            setCommodityTypeSearch("");
            loadCommodityTypes("");
          }
        }}
        value={commodityTypeState ?? defaultCommodityType}
        renderOption={(props: any, option: any, index: any) => {
          if (option.id === -1) {
            return (
              <MenuItem
                aria-readonly
                disableGutters={true}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px 16px",
                  margin: "0",
                  borderBottom: "1px solid #d8e6fb",
                  fontWeight: "400",
                  fontSize: isDesktop ? "13px" : "12px",
                  cursor: "pointer",
                  position: "sticky",
                  top: "-8px",
                  backgroundColor: "#fff",
                }}
                sx={[{"&:hover": {backgroundColor: "transparent"}}]}
                onClick={handleOpen}
              >
                <span>{`${option.code ?? ""} ${
                  option.description ?? ""
                }`}</span>
                <img src={FilledArrowRightIcon} />
              </MenuItem>
            );
          }
          return (
            <li {...props} key={`listItem-${index}-${option.code}`}>
              <span
                style={{
                  fontWeight: "400",
                  fontSize: isDesktop ? "16px" : "14px",
                }}
              >
                <span style={{fontSize: "11px", color: "darkgray"}}>
                  {option.code}
                </span>{" "}
                {option.customValues &&
                `simplified_description_${langCode}` in option.customValues
                  ? option.customValues[`simplified_description_${langCode}`]
                  : option.description ?? ""}
              </span>
            </li>
          );
        }}
        renderInput={(params) => {
          const code =
            commodityTypeState?.customValues &&
            commodityTypeSearch ===
              commodityTypeState?.customValues[
                `simplified_description_${langCode}`
              ]
              ? commodityTypeState?.code ?? ""
              : "";
          return (
            <Field
              {...params}
              component={TextField}
              fullWidth
              variant="outlined"
              size={"small"}
              label={t("categoryPurchaseItem")}
              placeholder={t("typeToSearchMore")}
              data-testid="input-commodityType"
              name={`[${purchaseIndex}].commodities[${commodityIndex}].commodityType.code`}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <div style={{display: "flex", alignItems: "center"}}>
                    <span style={{fontSize: "10px", marginRight: "2px"}}>
                      {code}
                    </span>
                  </div>
                ),
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
      />
    );
  }, [
    langCode,
    isLoading,
    commodityIndex,
    purchaseIndex,
    isDesktop,
    commodityTypeState,
    commodityTypeSearch,
    commodityTypesData,
  ]);

  return (
    <Grid container direction={"row"} columnGap={{xs: 1, md: 0}}>
      {!isDesktop && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          mb={{xs: isOpen ? 0 : 2, md: 2}}
          onClick={switchOpen}
        >
          <Typography variant="h3" component="h3">
            <Trans i18nKey="item">Item</Trans>&nbsp;
            {commodityIndex! + 1}
          </Typography>
          {isOpen ? (
            <KeyboardArrowUpIcon sx={{color: "primary.dark", my: 2}} />
          ) : (
            <KeyboardArrowDownIcon sx={{color: "primary.dark", my: 2}} />
          )}
        </Grid>
      )}
      <Collapse in={isDesktop || isOpen} sx={{width: "100%"}}>
        <Grid container direction={"row"} columnGap={{xs: 1, md: 0}}>
          <Grid xs={12} md={4.5} sx={itemsStyle}>
            <Field
              data-testid={`input-purchase-item-description-${commodityIndex}`}
              fullWidth
              component={TextField}
              id="description"
              name={`[${purchaseIndex}].commodities[${commodityIndex}].description`}
              label={t("descriptionPurchaseItem")}
              variant="outlined"
              size="medium"
              sx={itemTextFieldStyle}
            />
          </Grid>
          <Grid xs={12} md={3.5} sx={itemsStyle}>
            {commodityTypeInput}
          </Grid>
          <Grid xs={5.8} md={2} sx={itemsStyle}>
            <Field
              data-testid={`input-purchase-item-price-${commodityIndex}`}
              fullWidth
              component={TextField}
              id="unitaryValue"
              name={`[${purchaseIndex}].commodities[${commodityIndex}].unitaryValue`}
              label={t("pricePerItemPurchaseItem")}
              variant="outlined"
              size="medium"
              sx={itemTextFieldStyle}
              placeholder={getFormattedPrice(0)}
            />
          </Grid>
          <Grid xs md={1} sx={itemsStyle}>
            <Field
              data-testid={`input-purchase-item-quantity-${commodityIndex}`}
              fullWidth
              component={TextField}
              id="quantity"
              name={`[${purchaseIndex}].commodities[${commodityIndex}].quantity`}
              label={t("quantityPurchaseItem")}
              variant="outlined"
              size="medium"
              sx={itemTextFieldStyle}
              onKeyDown={validatePositiveIntegerNumberInput}
            />
          </Grid>
          {isDesktop ? (
            <Grid
              xs={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton aria-label="delete" onClick={() => deleteItem(uuid)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid xs={12}>
              <Button
                sx={{
                  p: 1,
                  marginY: 2,
                }}
                fullWidth
                type="button"
                variant="outlined"
                color="error"
                data-testid={`btn-delete-item-purchase-${commodityIndex}`}
                onClick={() => deleteItem(uuid)}
              >
                <Trans i18nKey="delete">Delete</Trans>
              </Button>
            </Grid>
          )}
        </Grid>
      </Collapse>
      {!isDesktop && (
        <Grid xs={12}>
          <Divider
            sx={{
              border: "1px solid #F5F9FF",
              marginX: -2,
            }}
          />
        </Grid>
      )}
      <ModalForm
        open={open}
        setOpen={setOpen}
        customStyle={{
          outline: "none",
          overflow: !isDesktop ? "hidden scroll" : "unset",
        }}
      >
        <CategoryModal
          handleClose={handleClose}
          index={commodityIndex}
          onHandleChange={onHandleChange}
        />
      </ModalForm>
    </Grid>
  );
}
