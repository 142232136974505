import * as yup from "yup";
import validator from "card-validator";

export const getPhoneSchema = (t: any) => {
  const schema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required(t("validation.phoneVerification.requiredNumber"))
      .min(8, t("validation.phoneVerification.numberLengthMin")),
    sendingType: yup
      .string()
      .required(t("validation.phoneVerification.requiredSendingType"))
      .oneOf(
        ["call", "sms"],
        t("validation.phoneVerification.invalidSendingType"),
      )
      .nullable(),
  });

  return schema;
};

export const getCodeSchema = (t: any) => {
  const schema = yup.object().shape({
    verificationCode: yup
      .string()
      .required(t("validation.code.required"))
      .length(6, t("validation.code.length"))
      .matches(/^\d+$/, t("validation.code.digits")),
  });
  return schema;
};

export const getPaymentSchema = (t: any) => {
  const schema = yup.object().shape({
    cardNumber: yup
      .string()
      .required(t("validation.payment.numberRequired"))
      .test(
        "cardNumberLength",
        t("validation.payment.incorrectNumber"),
        (str: string | undefined) => {
          return validator.number(str).isValid;
        },
      )
      .nullable(),
    holderName: yup
      .string()
      .required(t("validation.payment.nameRequired"))
      .test(
        "holderName",
        t("validation.payment.incorrectName"),
        (str: string | undefined) => {
          if (!str?.match(/^[A-Z]+\s[A-Z]+$/)) {
            return false;
          }
          return true;
        },
      )
      .nullable(),
    expDate: yup
      .string()
      .required(t("validation.payment.dateRequired"))
      .test(
        "dateFormat",
        t("validation.payment.dateFormat"),
        (str: string | undefined) => {
          if (str !== undefined) {
            if (!str.match(/^\d{2}\/\d{2}$/)) {
              return false;
            } else {
              const [month, year] = str
                .split("/")
                .map((item) => parseInt(item));
              const currentYear: number = parseInt(
                new Date().getFullYear().toString().slice(2),
              );
              const currentMonth: number = new Date().getMonth() + 1;

              const incorrectMonth: boolean = month < 1 || month > 12;
              const incorrectYear: boolean = year < currentYear;

              if (incorrectMonth || incorrectYear) {
                return false;
              } else {
                if (year === currentYear && month <= currentMonth) {
                  return false;
                }
              }
            }
          }
          return true;
        },
      )
      .nullable(),
    code: yup
      .string()
      .required(t("validation.payment.cvvRequired"))
      .test(
        "codeLength",
        t("validation.payment.cvvLength"),
        (str: string | undefined) => str?.length === 3 || str?.length === 4,
      )
      .nullable(),
  });

  return schema;
};

export const getExistingPaymentSchema = (t: any) => {
  const schema = yup.object().shape({
    holderName: yup
      .string()
      .required(t("validation.payment.nameRequired"))
      .test(
        "holderName",
        t("validation.payment.incorrectName"),
        (str: string | undefined) => {
          if (!str?.match(/^[A-Z]+\s[A-Z]+$/)) {
            return false;
          }
          return true;
        },
      )
      .nullable(),
    expDate: yup
      .string()
      .required(t("validation.payment.dateRequired"))
      .test(
        "dateFormat",
        t("validation.payment.dateFormat"),
        (str: string | undefined) => {
          if (str !== undefined) {
            if (!str.match(/^\d{2}\/\d{2}$/)) {
              return false;
            } else {
              const [month, year] = str
                .split("/")
                .map((item) => parseInt(item));
              const currentYear: number = parseInt(
                new Date().getFullYear().toString().slice(2),
              );
              const currentMonth: number = new Date().getMonth() + 1;

              const incorrectMonth: boolean = month < 1 || month > 12;
              const incorrectYear: boolean = year < currentYear;

              if (incorrectMonth || incorrectYear) {
                return false;
              } else {
                if (year === currentYear && month <= currentMonth) {
                  return false;
                }
              }
            }
          }
          return true;
        },
      )
      .nullable(),
    code: yup
      .string()
      .required(t("validation.payment.cvvRequired"))
      .test(
        "codeLength",
        t("validation.payment.cvvLength"),
        (str: string | undefined) => str?.length === 3 || str?.length === 4,
      )
      .nullable(),
  });

  return schema;
};

export const getChargedAmountSchema = (t: any) => {
  const schema = yup.object().shape({
    chargedAmount: yup
      .string()
      .required(t("validation.charge.required"))
      .matches(/^\d{1}\.\d{2}$/, t("validation.charge.format"))
      .nullable(),
  });

  return schema;
};

export const getEmailSchema = (t: any) => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t("validation.common.emailRequired"))
      .email(t("validation.common.emailFormat"))
      .max(256, t("validation.common.emailLengthMax")),
  });

  return schema;
};

export const getAddressSchema = (t: any, addressFormat: string | null) => {
  const baseSchema = {
    firstName: yup
      .string()
      .required(t("validation.address.firstNameRequired"))
      .max(50, t("validation.address.firstNameLength")),
    lastName: yup
      .string()
      .required(t("validation.address.lastNameRequired"))
      .max(50, t("validation.address.lastNameLength")),
    countryCode: yup.string().required(t("validation.address.countryRequired")),
    city: yup
      .string()
      .required(t("validation.address.cityRequired"))
      .max(60, t("validation.address.cityLength")),
    postalCode: yup
      .string()
      .required(t("validation.address.postalCodeRequired"))
      .min(4, t("validation.address.postalCodeLengthMin"))
      .max(10, t("validation.address.postalCodeLength")),
    companyName: yup.string().optional(),
  };

  if (addressFormat === "US") {
    return yup.object().shape({
      ...baseSchema,
      streetName: yup
        .string()
        .required(t("validation.address.addressLineRequired"))
        .max(70, t("validation.address.addressLineLength")),
      regionCode: yup.string().required(t("validation.address.stateRequired")),
      apartment: yup
        .string()
        .max(70, t("validation.address.addressLine2Length")),
    });
  }

  return yup.object().shape({
    ...baseSchema,
    district: yup.string().max(60, t("validation.address.districtLength")),
    streetName: yup
      .string()
      .required(t("validation.address.streetNameRequired"))
      .max(60, t("validation.address.streetNameLength")),
    regionCode: yup.string().required(t("validation.address.regionRequired")),
    houseNumber: yup
      .string()
      .required(t("validation.address.houseNumberRequired"))
      .max(10, t("validation.address.houseNumberLength")),
    apartment: yup.string().max(10, t("validation.address.apartmentLength")),
  });
};

export const getWarningSchema = (t: any) => {
  const schema = yup.object().shape({
    isConfirmed: yup
      .boolean()
      .oneOf([true], t("validation.userWarning.required")),
  });

  return schema;
};
