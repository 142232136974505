import React from "react";
import {MenuItemType, Module, ModuleConfig} from "../core/base.module";
import Delivery from "./pages";
import {ParcelDelivery} from "./pages/ParcelDelivery";

export interface DeliveryConfiguration extends ModuleConfig {
  disabled?: boolean;
  enableBoxSize?: boolean;
  addressCompanyName?: boolean;
  isParcelDelivery?: boolean;
  defaultWeightUnits?: string;
  defaultLengthUnits?: string;
  defaultVolumeUnits?: string;
  defaultOriginCountry?: string;
  showTotalCost?: boolean;
  rateEnableFieldName?: string;
  insuranceAccountingItemCode?: string;
  discountAccountingItemCode?: string;
  allowChangeWeightUnits?: boolean;
  shippingFromAddressesFilter?: string;
  deliveryAddressesFilter?: string;
  parcelValidation?: any;
  allowOriginCountryChange?: boolean;
}

export class DeliveryModule implements Module {
  static _instance: DeliveryModule;
  config: DeliveryConfiguration;

  constructor(config: DeliveryConfiguration) {
    this.config = config;
  }

  static getInstance(config?: DeliveryConfiguration): DeliveryModule {
    if (!DeliveryModule._instance) {
      DeliveryModule._instance = new DeliveryModule(config || {});
    } else if (config) {
      DeliveryModule._instance.config = config;
    }
    return DeliveryModule._instance;
  }

  getPages() {
    if (!this.config || this.config.disabled) {
      return [];
    }
    if (this.config.isParcelDelivery) {
      return [
        {
          path: "delivery/:orderId",
          element: <ParcelDelivery config={this.config} />,
        },
        {
          path: "delivery/:orderId/:step",
          element: <ParcelDelivery config={this.config} />,
        },
      ];
    }
    return [
      {
        path: "delivery/:orderIds",
        element: <Delivery />,
      },
      {
        path: "delivery/:orderIds/:step",
        element: <Delivery />,
      },
    ];
  }

  getMenuItems() {
    return [];
  }

  getMenuActions(): MenuItemType[] {
    return [];
  }
}
