import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import defaultUserPicture from "../../../../images/photo-none.jpg";
import {
  Box,
  Button,
  Skeleton,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import HelpIcon from "@mui/icons-material/Help";
import {useAppSelector} from "../../../store";
import {logout} from "../../../features/auth/auth-slice";
import {
  logout as userLogout,
  setBillingAddress,
} from "../../../features/user/user-slice";
import {useDispatch} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import EditProfileButton from "./editProfileButton";
import {toast} from "react-hot-toast";
import WarehouseAddressCard from "./warehouseAddressCard";
import VerifiedIcon from "@mui/icons-material/Verified";
import NotVerifiedIcon from "@mui/icons-material/NewReleases";
import {setInitialVerificationState} from "../../../features/verification/verification-slice";
import i18next from "i18next";
import {getAddresses} from "../../../common/data/addresses";
import {useVerificationSteps} from "../../../common/OrganizationConfig/useVerificationSteps";
import {
  ContactGetContactApiArg,
  useContactGetContactMutation,
} from "../../../features/contact/contact-api";
import {getConsigneeLocation} from "../../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";
import {toastError} from "../../../common/utils/toastMessages";
import {ProfileProps} from "../pages";

const profileDataWrapperStyles: SxProps<Theme> = {
  display: "flex",
  background: "#FFFFFF",
  boxShadow: {
    xs: "none",
    md: "0px 0px 8px rgba(15, 74, 132, 0.1)",
  },
  borderRadius: "16px",
};

const profileDataCellStyles: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  borderRight: {
    xs: "none",
    md: "1px solid #D8E6FB",
  },
  borderBottom: {
    xs: "1px solid #D8E6FB",
    md: "none",
  },
  padding: {xs: "16px", md: "24px"},
};

const profileDataLastCellStyles: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  borderBottom: {
    xs: "1px solid #D8E6FB",
    md: "none",
  },
  padding: {xs: "16px", md: "24px"},
};

const profileDataCellStyles2: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #D8E6FB",
  padding: {xs: "16px", md: "14px 24px"},
};

export default function AccountInfoForm({config}: ProfileProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const userState = useAppSelector((state) => state.userState);
  const {t} = useTranslation();

  const showBillingAddress = useAppSelector(
    (state) =>
      state?.organizationConfigState?.modules?.profile?.showBillingAddress,
  );

  const zohoLang = i18next.language === "ua" ? "uk" : i18next.language;

  const {fullyVerified} = useVerificationSteps();

  const [billingAddressLines, setBillingAddressLines] = useState<string[]>([]);

  const [getContact] = useContactGetContactMutation();

  const getContactAsync = async (request: number | null | undefined) => {
    const commandArgs: ContactGetContactApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactId: request || 0,
    };
    return getContact(commandArgs);
  };

  const addresses = getAddresses();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleEditPhoto = () => {
    toast.error("Photo upload is not yet implemented");
  };

  const handleEditName = () => {
    navigate("./editName");
  };

  const handleEditPhoneNumber = () => {
    navigate("./editPhoneNumber");
  };

  const handleEditEmailAddress = () => {
    navigate("./editEmail");
  };

  const handleEditResidentialAddress = () => {
    navigate("./editResidentialAddress");
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(userLogout());
    dispatch(setInitialVerificationState());
    navigate("/");
  };

  useEffect(() => {
    if (!userState?.billingAddress) {
      getContactAsync(userState?.contactId)
        .then((response: any) => {
          if (response.data) {
            const billingAddress =
              response?.data?.contactAddresses?.find(
                (address: any) => address.addressType === "Billing",
              ) ?? null;
            if (billingAddress) {
              dispatch(setBillingAddress(billingAddress));
            }
          }
        })
        .catch((error) => {
          toastError(error);
        });
    } else {
      getConsigneeLocation(userState?.billingAddress, i18next.language, t).then(
        (addressLines) => {
          setBillingAddressLines(addressLines);
        },
      );
    }
  }, [userState, i18next.language, t]);

  const VerificationStatus = ({verified}: any) => (
    <Box>
      <Typography variant="body1" sx={{color: "#252631"}}>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: "#252631",
              display: "flex",
              alignItems: "center",
            }}
          >
            {verified ? (
              <>
                <VerifiedIcon fontSize="small" color="success" />
                <Typography
                  variant="caption3"
                  color="success.main"
                  lineHeight="18px"
                  sx={{marginLeft: "5px"}}
                >
                  <Trans i18nKey="verified">Verified</Trans>
                </Typography>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <NotVerifiedIcon fontSize="small" color="error" />
                  <Typography
                    variant="caption3"
                    color="error.main"
                    lineHeight="18px"
                    sx={{marginLeft: "5px"}}
                  >
                    <Trans i18nKey="notVerified">Not Verified</Trans>
                  </Typography>
                </Box>
                <NavLink
                  to={"../verifications"}
                  data-testid={`profile-dropdown-profile.tabs.completeVerification`}
                  style={{textDecoration: "none"}}
                >
                  <Typography
                    variant="button"
                    sx={{
                      color: "#2F81EF",
                    }}
                  >
                    <Trans i18nKey={"profile.tabs.completeVerification"}>
                      Complete verification
                    </Trans>
                  </Typography>
                </NavLink>
              </Box>
            )}
          </Typography>
        </Box>
      </Typography>
    </Box>
  );

  return (
    <>
      <Grid
        xs
        md={6}
        sx={{
          ...profileDataCellStyles,
          borderBottom: {
            xs: "none",
            md: "none",
          },
          borderRight: {
            xs: "none",
            md: "none",
          },
          paddingX: {
            md: "0",
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: "80px",
              md: "100px",
            },
            height: {
              xs: "80px",
              md: "100px",
            },
            minWidth: {
              xs: "80px",
              md: "100px",
            },
            borderRadius: "50%",
            position: "relative",
            cursor: "pointer",
            ":hover": {
              opacity: 0.8,
            },
          }}
          onClick={handleEditPhoto}
        >
          <img
            src={defaultUserPicture}
            alt={"user picture"}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              width: "40px",
              height: "40px",
              minWidth: "40px",
              right: "0px",
              bottom: "0px",
              background: "#ffffff",
              borderRadius: "50%",
              border: "0.65px solid #D8E6FB",
              boxShadow:
                "0px 3px 1px -2px rgba(16, 66, 122, 0.2), 0px 2px 2px rgba(16, 66, 122, 0.14), 0px 1px 5px rgba(16, 66, 122, 0.12)",
              cursor: "pointer",
              transition: "opacity 0.3s ease",
              ":hover": {
                opacity: 0.8,
              },
            }}
          >
            <LocalSeeIcon
              sx={{
                width: "25px",
                height: "100%",
                minWidth: "13px",
                color: "#6C757D",
                margin: "auto",
              }}
            />
          </Box>
        </Box>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          rowGap={"2px"}
          marginLeft={"20px"}
          marginRight={"10px"}
        >
          <Box>
            <Typography variant="h3">
              {userState?.firstName || userState?.lastName
                ? `${userState?.firstName} ${userState?.lastName}`
                : userState?.email}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body4" sx={{color: "#252631"}}>
              {userState?.accountNumber ?? ""}
            </Typography>
          </Box>
          {isDesktop ? <VerificationStatus verified={fullyVerified} /> : null}
        </Grid>
        <EditProfileButton
          marginBottom={isDesktop ? "1.5rem" : "0"}
          marginLeft={"0"}
          onClick={handleEditName}
        />
      </Grid>
      <Grid
        sx={{
          ...profileDataWrapperStyles,
          flexDirection: {
            xs: "column",
            md: "row",
          },
          border: {
            xs: "none",
            md: "1px solid #D8E6FB",
          },
          maxWidth: {
            xs: "auto",
            md: "100%",
          },
          height: "auto",
        }}
      >
        <Grid
          sx={{
            paddingLeft: "16px",
            paddingBottom: "16px",
            borderBottom: {
              xs: "1px solid #D8E6FB",
              md: "none",
            },
          }}
        >
          {!isDesktop ? <VerificationStatus verified={fullyVerified} /> : null}
        </Grid>
        {!isDesktop && !showBillingAddress ? (
          <Grid xs md={4} sx={profileDataCellStyles}>
            <Grid sx={{width: "100%"}}>
              <Box>
                <Typography variant="body4">
                  <Trans i18nKey="yourUSAddresses">Your US addresses</Trans>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  lineBreak: "normal",
                  marginTop: "4px",
                  alignItems: "flex-start",
                }}
              >
                {addresses.map((address, index) => {
                  return <WarehouseAddressCard key={index} address={address} />;
                })}
              </Box>
            </Grid>
          </Grid>
        ) : null}

        <Grid
          xs
          md={6}
          sx={{...profileDataCellStyles, alignItems: "flex-start"}}
        >
          <Grid marginRight={"10px"}>
            <Box>
              <Typography variant="body4">
                <Trans i18nKey="profile.accountInfo.yourPhone">
                  Your phone
                </Trans>
              </Typography>
            </Box>
            <Box sx={{marginTop: "4px"}}>
              <Typography variant="body1" sx={{color: "#6C757D"}}>
                {userState?.phoneNumber || (
                  <Trans i18nKey="profile.accountInfo.userHasNoPhone">
                    User has no phone
                  </Trans>
                )}
              </Typography>
            </Box>
          </Grid>
          <EditProfileButton
            onClick={handleEditPhoneNumber}
            marginBottom={"auto"}
          />
        </Grid>
        <Grid
          xs
          md={6}
          sx={{...profileDataCellStyles, alignItems: "flex-start"}}
        >
          <Grid marginRight={"10px"}>
            <Box>
              <Typography variant="body4">
                <Trans i18nKey="profile.accountInfo.emailAddress">
                  Email address
                </Trans>
              </Typography>
            </Box>
            <Box
              sx={{
                lineBreak: "anywhere",
              }}
            >
              <Typography variant="body1" sx={{color: "#6C757D"}}>
                {userState?.email || (
                  <Trans i18nKey="profile.accountInfo.userHasNoEmail">
                    User has no email
                  </Trans>
                )}
              </Typography>
            </Box>
          </Grid>
          <EditProfileButton
            onClick={handleEditEmailAddress}
            marginBottom={"auto"}
          />
        </Grid>
        <Grid
          xs
          md={6}
          sx={{...profileDataLastCellStyles, alignItems: "flex-start"}}
        >
          <Grid marginRight={"10px"} width={"100%"}>
            <Box>
              <Typography variant="body4">
                <Trans i18nKey="profile.accountInfo.residentialAddress">
                  Residential address
                </Trans>
              </Typography>
            </Box>
            <Box sx={{marginTop: "4px"}}>
              {userState?.contactAddressConfirmed ? (
                <>
                  {billingAddressLines.length > 0 ? (
                    <Typography
                      variant="body1"
                      key={`address-line`}
                      sx={{color: "#6C757D"}}
                    >
                      {billingAddressLines.join(", ")}
                    </Typography>
                  ) : (
                    <>
                      <Skeleton />
                    </>
                  )}
                </>
              ) : (
                <Typography variant="body1" sx={{color: "#6C757D"}}>
                  Add your residential address
                </Typography>
              )}
            </Box>
          </Grid>
          <EditProfileButton
            onClick={handleEditResidentialAddress}
            marginBottom={"auto"}
          />
        </Grid>
        {!isDesktop ? (
          <>
            <NavLink
              to={"./savedAddresses"}
              style={{
                textDecoration: "none",
              }}
              onClick={scrollToTop}
            >
              <Grid xs md={4} sx={profileDataCellStyles}>
                <Typography
                  variant="body4"
                  sx={{
                    color: "#252631",
                  }}
                >
                  <Trans i18nKey="profile.tabs.savedAddresses">
                    My saved addresses
                  </Trans>
                </Typography>
              </Grid>
            </NavLink>
            {!config?.paymentMethod?.disabled && (
              <NavLink
                to={"./paymentMethods"}
                style={{
                  textDecoration: "none",
                }}
                onClick={scrollToTop}
              >
                <Grid xs md={4} sx={profileDataCellStyles}>
                  <Typography
                    variant="body4"
                    sx={{
                      color: "#252631",
                    }}
                  >
                    <Trans i18nKey="profile.tabs.paymentMethods">
                      Payment methods
                    </Trans>
                  </Typography>
                </Grid>
              </NavLink>
            )}
            <NavLink
              to={"./changePassword"}
              style={{
                textDecoration: "none",
              }}
              onClick={scrollToTop}
            >
              <Grid xs md={4} sx={profileDataCellStyles}>
                <Typography
                  id="changePSW"
                  variant="body4"
                  sx={{
                    color: "#252631",
                  }}
                >
                  <Trans i18nKey="profile.tabs.changePassword">
                    Password recovery
                  </Trans>
                </Typography>
              </Grid>
            </NavLink>
            <Button
              href={`https://trtexpress.zohodesk.com/portal/${zohoLang}/newticket`}
              style={{
                textDecoration: "none",
                color: "inherit",
                padding: 0,
                height: "max-content",
              }}
              target="_blank"
            >
              <Grid xs md={4} sx={profileDataCellStyles}>
                <Typography
                  variant="body4"
                  sx={{
                    color: "#252631",
                  }}
                >
                  <Trans i18nKey="profile.tabs.help">Help</Trans>
                </Typography>
              </Grid>
            </Button>
          </>
        ) : null}
      </Grid>
      {isDesktop && !showBillingAddress ? (
        <Grid
          display={"flex"}
          flexDirection={{md: "row", xs: "column"}}
          columnGap={4}
          sx={{
            "& > .MuiGrid2-root": {
              xs: {borderBottom: "1px solid #D8E6FB", borderRight: "none"},
              md: {borderRight: "1px solid #D8E6FB"},
            },
            "& > .MuiGrid2-root:last-child": {
              xs: {borderBottom: "none"},
              md: {borderBottom: "1px solid #D8E6FB"},
            },
            maxWidth: {
              xs: "auto",
              md: "clamp(920px, 800px + 24ch, 100%)",
            },
          }}
        >
          {addresses.map((address, index) => {
            return <WarehouseAddressCard address={address} key={index} />;
          })}
        </Grid>
      ) : null}

      <Grid
        sx={{
          ...profileDataCellStyles2,
          paddingY: {md: 3},
          paddingX: {md: 0},
          borderBottom: {
            xs: "1px solid #D8E6FB",
            md: "none",
          },
          borderTop: {
            xs: "1px solid #D8E6FB",
            md: "none",
          },
        }}
      >
        <HelpIcon
          sx={{
            width: "24px",
            minWidth: "24px",
            height: "100%",
            color: "#2F81EF",
            marginRight: {
              xs: "6px",
              md: "8px",
            },
          }}
        />
        <NavLink
          to={"./whatsMyBillingAddress"}
          style={{
            textDecoration: "none",
          }}
          onClick={scrollToTop}
        >
          <Typography
            variant="body4"
            sx={{
              color: "#2F81EF",
              cursor: "pointer",
              textDecoration: "none",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Trans i18nKey="profile.accountInfo.whatsMyBillingAddress">
              What&apos;s my billing address and what is it?
            </Trans>
          </Typography>
        </NavLink>
      </Grid>
      {!isDesktop ? (
        <Grid
          sx={{
            ...profileDataCellStyles2,
            borderBottom: {
              xs: "none",
              md: "none",
            },
            paddingBottom: {
              xs: 0,
              md: 0,
            },
          }}
          onClick={handleLogout}
        >
          <Box
            sx={{
              margin: "0",
              cursor: "pointer",
              padding: "0",
            }}
          >
            <Typography
              variant="body4"
              sx={{
                color: "#2F81EF",
                textTransform: "none",
              }}
            >
              <Trans i18nKey={"profile.menu.logout"}>Log out</Trans>
            </Typography>
          </Box>
        </Grid>
      ) : null}
    </>
  );
}
