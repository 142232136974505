import {
  GetQuoteForOrderCommandValues,
  ShipmentQuoteGetQuoteForOrderApiArg,
  useShipmentQuoteGetQuoteForOrderMutation,
} from "../../features/shipmentQuote/shipmentQuote-api";

export const UseQuoteForOrder = () => {
  const [getQuoteForOrder, {isLoading, isError, error}] =
    useShipmentQuoteGetQuoteForOrderMutation();

  const getOrderQuote = async (data: GetQuoteForOrderCommandValues) => {
    const commandArgs: ShipmentQuoteGetQuoteForOrderApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      getQuoteForOrderCommandValues: data,
    };
    return getQuoteForOrder(commandArgs);
  };

  return {
    getOrderQuote,
    isLoading,
    isError,
    error,
  };
};
