import {useLoginSubmitHandler} from "../../common/utils/loginSubmitHandler";
import {GetTokenCommand, TokenGetApiArg} from "../../features/auth/auth-api";

export const useLoginFormSubmit = () => {
  const {getToken, onGoogleSubmitHandler, isLoginLoading} =
    useLoginSubmitHandler();

  const onSubmitHandler = (values: GetTokenCommand) => {
    const commandArgs: TokenGetApiArg = {
      getTokenCommand: {
        ...values,
      },
    };
    getToken(commandArgs);
  };

  return {onSubmitHandler, onGoogleSubmitHandler, isLoginLoading};
};
