import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    currencyGetCurrencies: build.query<
      CurrencyGetCurrenciesApiResponse,
      CurrencyGetCurrenciesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/currency/getCurrencies`,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type CurrencyGetCurrenciesApiResponse = CurrencyDtoPagedResult;
export type CurrencyGetCurrenciesApiArg = {
  organizationId: number;
};
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type CurrencyDto = {
  currencyId?: number;
  created?: string;
  createdBy?: string | null;
  currencyCode?: string | null;
  currencyName?: string | null;
  decimalPlaces?: number | null;
  exchangeRateLastUpdate?: string | null;
  exchangeRateToPrimary?: number | null;
  gainLossAccountId?: number | null;
  gainLossAccountDescription?: string | null;
  isInactive?: boolean;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  symbol?: string | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  links?: LinkDto[] | null;
};
export type CurrencyDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: CurrencyDto[] | null;
  links?: LinkDto[] | null;
};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any | null;
};
export const {useCurrencyGetCurrenciesQuery} = injectedRtkApi;
