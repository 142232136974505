import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Sort from "../../../common/components/sort";
import ParcelShipmentsFilter from "./parcelShipmentsFilter";
import ParcelShipmentList from "./parcelShipmentList";
import ParcelShipmentsSearch from "./parcelShipmentsSearch";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import {Trans} from "react-i18next";

export default function ParcelShipmentsListForm() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid>
      <Grid
        display={"flex"}
        flexDirection={{xs: "column", md: "row"}}
        flexWrap={"wrap"}
        alignItems={{xs: "start", md: "center"}}
        justifyContent={"space-between"}
        columnGap={"44px"}
        rowGap={"24px"}
        margin={{xs: "24px 0 0 0", md: 0}}
      >
        <Grid
          display={"flex"}
          flexDirection={{xs: "row", md: "row"}}
          alignItems={{xs: "center", md: "center"}}
          justifyContent={{xs: "space-between", md: "normal"}}
          width={{xs: "100%", md: "auto"}}
          rowGap={{xs: "12px", md: 0}}
          columnGap={{xs: "24px", md: "16px"}}
        >
          {isDesktop ? (
            <ParcelShipmentsFilter />
          ) : (
            <Typography variant={"h3"}>
              <Trans i18nKey="parcelShipments.allParcels.titleYourParcels">
                Your Shipments
              </Trans>
            </Typography>
          )}
          <Sort
            sortParam={"created"}
            containerSx={{
              height: "fit-content",
              margin: 0,
            }}
            buttonSx={{
              boxShadow: "none !important",
              height: "32px!important",
              whiteSpace: "nowrap",
            }}
            defaultSortEarliestValue={true}
          />
        </Grid>
        <ParcelShipmentsSearch />
      </Grid>
      <Grid sx={{marginTop: "16px"}}>
        <ParcelShipmentList />
      </Grid>
    </Grid>
  );
}
