import React from "react";
import {VerificationStepSubmit} from "./interfaces";
import {Typography, Grid, FormControlLabel} from "@mui/material";
import {
  useVerificationVerifyAcknowledgementMutation,
  VerificationVerifyAcknowledgementApiArg,
  VerifyAcknowledgementCommand,
} from "../../features/verification/verification-api";
import {SubmitHandler} from "react-hook-form";
import {useDispatch} from "react-redux";
import {setAcknowledgementConfirmed} from "../../features/user/user-slice";
import {Field, Form, Formik, FormikProps, ErrorMessage} from "formik";
import {getWarningSchema} from "../pages/validation";
import {useTranslation, Trans} from "react-i18next";
import {Checkbox} from "formik-mui";
import {LoadingButton} from "@mui/lab";

export type UserAcknowledgementFragmentData = {
  isConfirmed: boolean;
};

export default function UserAcknowledgementFragment({
  step,
  handlePrev,
  handleNext,
}: VerificationStepSubmit) {
  const dispatch = useDispatch();
  const [verifyAcknowledgement, {isLoading, isSuccess}] =
    useVerificationVerifyAcknowledgementMutation();
  const {t} = useTranslation();
  const warningSchema = getWarningSchema(t);
  const initialValues: UserAcknowledgementFragmentData = {
    isConfirmed: false,
  };

  const handleVerifyAcknowledgement: SubmitHandler<VerifyAcknowledgementCommand> =
    (values) => {
      const commandArgs: VerificationVerifyAcknowledgementApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        verifyAcknowledgementCommand: {
          ...values,
        },
      };

      verifyAcknowledgement(commandArgs);
      dispatch(setAcknowledgementConfirmed());
      handleNext();
    };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={warningSchema}
      onSubmit={(values: VerifyAcknowledgementCommand) => {
        handleVerifyAcknowledgement(values);
      }}
    >
      {(formikProps: FormikProps<VerifyAcknowledgementCommand>) => (
        <Form>
          <Grid pt={1} mb={5}>
            <FormControlLabel
              control={
                <Field
                  component={Checkbox}
                  type="checkbox"
                  name="isConfirmed"
                  color="primary"
                  data-testid="input-acknowledgment"
                  sx={{color: "#ABABAB", padding: "0 9px"}}
                />
              }
              label={t("userVerificationWarning")}
              sx={{display: "flex", alignItems: "start"}}
            />
            <ErrorMessage
              name="isConfirmed"
              render={(msg: any) => (
                <Typography
                  variant="subtitle2"
                  color="error"
                  sx={{fontWeight: "light", mb: 1}}
                >
                  {msg}
                </Typography>
              )}
            />
          </Grid>
          <Grid container columns={{xs: 1, md: 12}} direction="row">
            <Grid md={3} xs={1} mr={{xs: 0, md: 3}}>
              <LoadingButton
                variant="contained"
                fullWidth
                type="submit"
                disabled={isLoading || formikProps.values.isConfirmed === false}
                data-testid="btn-submit"
                color="secondary"
                sx={{marginBottom: "1rem"}}
              >
                <Trans i18nKey={"btnConfirm"}>Confirm</Trans>
              </LoadingButton>
            </Grid>

            {!step.required && (
              <Grid md={3} xs={1}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  type="button"
                  disabled={isLoading}
                  data-testid="btn-skip"
                  color="primary"
                  onClick={handleNext}
                >
                  <Trans i18nKey="skip">Skip</Trans>
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
