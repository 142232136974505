import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Collapse,
  IconButton,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Trans} from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import React, {useState} from "react";

export interface WarehouseAddressCardProps {
  address: any;
}

const profileDataCellStyles2: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #D8E6FB",
  padding: {xs: "16px", md: "14px 24px"},
  ":last-child": {
    borderBottom: "none",
  },
};

export default function WarehouseAddressCard({
  address,
}: WarehouseAddressCardProps) {
  const theme = useTheme();

  const profileDataWrapperStyles: SxProps<Theme> = {
    display: "flex",
    background: "#FFFFFF",
    boxShadow: {
      xs: "none",
      md: "0px 0px 8px rgba(15, 74, 132, 0.1)",
    },
    borderRadius: "16px",
  };

  return (
    <Grid
      xs
      md={14}
      sx={{
        ...profileDataWrapperStyles,
        flexDirection: "column",
        border: "1px solid #D8E6FB",
        borderRadius: "16px",
        marginTop: {
          xs: "10px",
          md: "24px",
        },
        maxWidth: {
          xs: "fit-content",
          md: "unset",
        },
        minWidth: {
          xs: "100%",
          md: "unset",
        },
      }}
      flexBasis={{xs: "unset", md: "50%"}}
    >
      <Grid
        sx={{
          ...profileDataCellStyles2,
          paddingTop: {xs: "16px", md: "24px"},
          paddingBottom: {
            xs: "16px",
            md: "24px",
          },
          borderTop: {
            xs: "none",
            md: "none",
          },
          minHeight: {xs: "none", md: "96px"},
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Grid>
          <Typography
            variant={"caption3"}
            color={theme.palette.primary.darkBlue}
          >
            <Trans i18nKey={address?.taxTitleKey} />
          </Typography>
        </Grid>
      </Grid>
      <Collapse in={true} timeout={0}>
        {address?.addressLines.map((addressLine: any, index: any) => {
          return (
            <AddressLine
              addressLine={addressLine}
              key={addressLine?.key + index}
            ></AddressLine>
          );
        })}
      </Collapse>
    </Grid>
  );
}

interface AddressLineProps {
  addressLine: {key: string; value: string};
}

function AddressLine({addressLine}: AddressLineProps) {
  const [copiedAddress, setCopiedAddress] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const handleCopyAddress = async () => {
    await navigator.clipboard.writeText(addressLine.value);
    setCopiedAddress(true);
    setTimeout(() => setCopiedAddress(false), 2000);
  };

  return (
    <Grid
      sx={{
        ...profileDataCellStyles2,
        paddingTop: {xs: "7px", md: "14px"},
        paddingBottom: {xs: "7px", md: "14px"},
      }}
      onClick={async () => {
        if (!isDesktop) await handleCopyAddress();
      }}
    >
      <Typography
        variant={isDesktop ? "body1" : "caption1"}
        sx={{marginRight: {xs: 0, md: "65px"}}}
      >
        <Box
          component={"span"}
          sx={{
            color: "#6C757D",
          }}
        >
          {addressLine.key}:{" "}
        </Box>
        {addressLine.value}
      </Typography>
      <IconButton
        sx={{
          marginLeft: "auto",
          padding: "0px",
          transition: "opacity 0.3s ease",
          ":hover": {
            opacity: 0.8,
          },
        }}
        disableRipple
        onClick={() => handleCopyAddress()}
      >
        {copiedAddress ? (
          <CheckCircleIcon
            sx={{
              width: "16px",
              height: "100%",
              minWidth: "16px",
              color: "success.main",
            }}
          />
        ) : (
          <FileCopyIcon
            sx={{
              width: "16px",
              height: "100%",
              minWidth: "16px",
            }}
          />
        )}
      </IconButton>
    </Grid>
  );
}
