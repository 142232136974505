import AmericanExpress from "../../../images/cardIcons/amex.svg";
import Diners from "../../../images/cardIcons/diners.svg";
import Discover from "../../../images/cardIcons/discover.svg";
import Elo from "../../../images/cardIcons/elo.svg";
import Hiper from "../../../images/cardIcons/hiper.svg";
import HiperCard from "../../../images/cardIcons/hipercard.svg";
import JCB from "../../../images/cardIcons/jcb.svg";
import Maestro from "../../../images/cardIcons/maestro.svg";
import Mastercard from "../../../images/cardIcons/mastercard.svg";
import Mir from "../../../images/cardIcons/mir.svg";
import UnionPay from "../../../images/cardIcons/unionpay.svg";
import Visa from "../../../images/cardIcons/visa.svg";

export const cardIconsLogos: any = {
  "american-express": AmericanExpress,
  "diners-club": Diners,
  discover: Discover,
  elo: Elo,
  hiper: Hiper,
  hipercard: HiperCard,
  jcb: JCB,
  maestro: Maestro,
  mastercard: Mastercard,
  mir: Mir,
  unionpay: UnionPay,
  visa: Visa,
};
