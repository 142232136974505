import React, {useEffect} from "react";
import LoginForm from "../components";
import SplitPage from "../../common/SplitPage/components/splitPage";
import {useLoginFormSubmit} from "../hooks/useLoginFormSubmit";
import {logout} from "../../features/auth/auth-slice";
import {logout as userLogout} from "../../features/user/user-slice";
import {useDispatch} from "react-redux";

export const Login = () => {
  const {onSubmitHandler, onGoogleSubmitHandler, isLoginLoading} =
    useLoginFormSubmit();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLogout());
    dispatch(logout());
  }, []);

  return (
    <SplitPage
      contentSide="leftCentered"
      main={
        <LoginForm
          onSubmit={onSubmitHandler}
          googleSubmit={onGoogleSubmitHandler}
          isLoading={isLoginLoading}
        />
      }
      pageTitle="Login"
    />
  );
};

export default Login;
