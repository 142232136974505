import React, {BaseSyntheticEvent, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Field, Form, Formik, FormikProps} from "formik";
import MaskedInput from "react-text-mask";
import {TextField} from "formik-mui";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import {Trans} from "react-i18next";
import {t} from "i18next";
import {LoadingButton} from "@mui/lab";
import creditCardType from "credit-card-type";
import {getPaymentSchema} from "../../../Verifications/pages/validation";

type PaymentCardFieldName =
  | "cardNumber"
  | "holderName"
  | "expDate"
  | "code"
  | "cardType";

export interface PaymentCardFormValues {
  cardNumber: string;
  holderName: string;
  expDate: string;
  cardType: string;
  code: string;
}

export const defaultInitialValues: PaymentCardFormValues = {
  cardNumber: "",
  holderName: "",
  expDate: "",
  cardType: "unknown",
  code: "",
};

export const getDefaultPaymentCardInitialValues = () => {
  return {
    ...defaultInitialValues,
  };
};

const defaultSubmitBtnLabel = (
  <Trans i18nKey="startVerification">Start verification</Trans>
);

export default function PaymentCardForm({
  onSubmit,
  onChange,
  providedInitialValues,
  isDisabled = false,
  submitBtnLabel = defaultSubmitBtnLabel,
}: {
  onSubmit?: (values: PaymentCardFormValues) => Promise<any>;
  onChange?: any;
  providedInitialValues?: PaymentCardFormValues;
  isDisabled?: boolean;
  submitBtnLabel?: any;
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [initialValues] = useState<PaymentCardFormValues>(
    providedInitialValues ?? getDefaultPaymentCardInitialValues(),
  );

  const handleFieldChange = (
    formikProps: FormikProps<PaymentCardFormValues>,
    fieldName: PaymentCardFieldName,
    value: any,
  ) => {
    formikProps.setFieldValue(fieldName, value, true);
    const values = {...formikProps.values};
    values[fieldName] = value;
    formikProps.validateForm(values).then((errors) => {
      const props = {values, errors};
      if (onChange) onChange(props);
    });
  };

  return (
    <Grid>
      <Formik
        onSubmit={async (values: PaymentCardFormValues, formikHelpers) => {
          if (onSubmit) await onSubmit(values);
          formikHelpers.setSubmitting(true);
        }}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getPaymentSchema(t)}
      >
        {(formikProps: FormikProps<PaymentCardFormValues>) => {
          return (
            <Form>
              <Grid>
                <div className="card-wrapper">
                  <Field
                    id="cardNumber"
                    name="cardNumber"
                    autoComplete="cardNumber"
                  >
                    {({field, form, meta}: any) => (
                      <MaskedInput
                        {...field}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={false}
                        onInput={(str: BaseSyntheticEvent) => {
                          const currentCardNumber: string = (
                            str.target.value as string
                          ).replaceAll(" ", "");

                          handleFieldChange(
                            formikProps,
                            "cardNumber",
                            currentCardNumber,
                          );
                          const cardTypes = creditCardType(currentCardNumber);
                          if (cardTypes.length > 0)
                            if (currentCardNumber === "") {
                              form?.setFieldValue("cardType", "unknown");
                            } else {
                              form?.setFieldValue(
                                "cardType",
                                cardTypes[0].type,
                              );
                            }
                          else {
                            form?.setFieldValue("cardType", "unknown");
                          }

                          formikProps.validateForm().then((errors) => {
                            if (!errors.cardNumber) {
                              (
                                document.querySelector(
                                  '[name="holderName"]',
                                ) as HTMLElement
                              ).focus();
                            }
                          });
                        }}
                        render={(innerRef, props) => (
                          <TextField
                            {...props}
                            inputRef={innerRef}
                            fullWidth
                            label={t("cardNumber")}
                            placeholder="0000 0000 0000 0000"
                            field={field}
                            form={form}
                            meta={meta}
                            disabled={formikProps.isSubmitting || isDisabled}
                            size={isDesktop ? "medium" : "small"}
                            inputProps={{
                              "data-testid": "input-card-number",
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                </div>

                <Field
                  id="holderName"
                  name="holderName"
                  sx={{mb: 2, p: 0}}
                  fullWidth
                  label={t("holderName")}
                  placeholder="ALEX JOHNSON"
                  component={TextField}
                  disabled={formikProps.isSubmitting || isDisabled}
                  onInput={(str: BaseSyntheticEvent) => {
                    str.target.value = (
                      str.target.value as string
                    ).toUpperCase();
                  }}
                  size={isDesktop ? "medium" : "small"}
                  inputProps={{"data-testid": "input-holder-name"}}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "holderName",
                      event.target.value,
                    )
                  }
                />

                <Grid container justifyContent={"space-between"}>
                  <Field id="expDate" name="expDate">
                    {({field, form, meta}: any) => (
                      <MaskedInput
                        {...field}
                        mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                        guide={false}
                        onInput={(str: BaseSyntheticEvent) => {
                          const expDate: string = str.target.value;
                          handleFieldChange(formikProps, "expDate", expDate);
                          if (expDate.match(/^\d{2}\/\d{2}$/)) {
                            (
                              document.querySelector(
                                '[name="code"]',
                              ) as HTMLElement
                            ).focus();
                          }
                        }}
                        render={(innerRef, props) => (
                          <TextField
                            {...props}
                            inputRef={innerRef}
                            sx={{mb: 2, width: "48%"}}
                            label={t("expDate")}
                            placeholder="03/22"
                            field={field}
                            form={form}
                            meta={meta}
                            disabled={formikProps.isSubmitting || isDisabled}
                            size={isDesktop ? "medium" : "small"}
                            inputProps={{"data-testid": "input-exp-date"}}
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field
                    id="code"
                    name="code"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFieldChange(
                        formikProps,
                        "holderName",
                        event.target.value,
                      )
                    }
                  >
                    {({field, form, meta}: any) => {
                      return (
                        <MaskedInput
                          {...field}
                          mask={
                            form?.values?.cardType === "american-express" ||
                            form?.values?.cardType === "hipercard"
                              ? [/\d/, /\d/, /\d/, /\d/]
                              : [/\d/, /\d/, /\d/]
                          }
                          guide={false}
                          onInput={(str: BaseSyntheticEvent) => {
                            const code: string = str.target.value;
                            handleFieldChange(formikProps, "code", code);
                          }}
                          render={(innerRef, props) => (
                            <TextField
                              {...props}
                              inputRef={innerRef}
                              sx={{mb: 2, width: "48%"}}
                              label="CVC/CVV"
                              placeholder={
                                form?.values?.cardType === "american-express" ||
                                form?.values?.cardType === "hipercard"
                                  ? "0000"
                                  : "000"
                              }
                              field={field}
                              form={form}
                              meta={meta}
                              disabled={formikProps.isSubmitting || isDisabled}
                              size={isDesktop ? "medium" : "small"}
                              inputProps={{"data-testid": "input-cvc-code"}}
                            />
                          )}
                        />
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={{xs: 0, md: 1}}
                mt={{xs: 1, sm: 1}}
              >
                <Grid md={12} mb={2}>
                  <Typography sx={{textAlign: "justify"}}>
                    <Trans i18nKey={"delivery.payAndPrintLabel.authorization"}>
                      I authorize TRT Express Services LLC to charge my selected
                      payment method for shipping services and other additional
                      service I chose, parcel pick, additional packaging, or
                      parcel return. TRT Express Services LLC fully complies
                      with payment card industry data security standards, PCI
                      DSS.
                    </Trans>
                  </Typography>
                </Grid>
                {onSubmit && (
                  <LoadingButton
                    data-testid="button-start-verification"
                    sx={{p: 1, width: {xs: "100%", md: "30%"}}}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={formikProps.isSubmitting || isDisabled}
                    loading={formikProps.isSubmitting}
                    loadingPosition="end"
                    endIcon={<></>}
                    id={"startVerification"}
                  >
                    {submitBtnLabel}
                  </LoadingButton>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}
