import * as yup from "yup";

export const getPasswordSchema = (t: any) =>
  yup
    .string()
    .required(t("validation.common.passwordRequired"))
    .min(6, t("validation.common.passwordLengthMin"))
    .max(100, t("validation.common.passwordLengthMax"))
    .matches(/^(?=.*[a-z])/, t("validation.common.passwordOneLowercase"))
    .matches(/^(?=.*[A-Z])/, t("validation.common.passwordOneUppercase"))
    .matches(/^(?=.*\d)/, t("validation.common.passwordOneDigit"))
    .matches(
      /^(?=.*[!?@#$%^&*()`~\-+=|\\{};:"'<>,./_])/,
      t("validation.common.passwordOneNonAlphanumeric"),
    );

export const getRegisterSchema = (t: any) => {
  const schema = yup.object().shape({
    password: getPasswordSchema(t),
    email: yup
      .string()
      .required(t("validation.common.emailRequired"))
      .email(t("validation.common.emailFormat"))
      .max(256, t("validation.common.emailLengthMax")),
    termsCheck: yup
      .boolean()
      .required(t("validation.register.policyRequired"))
      .oneOf([true], t("validation.register.termsRequired")),
    captchaVerified: yup
      .boolean()
      .required(t("validation.register.captchaRequired"))
      .oneOf([true], t("validation.register.captchaRequired")),
  });

  return schema;
};
