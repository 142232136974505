import React, {useEffect, useState} from "react";
import {Button, IconButton, InputAdornment, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {ResetPasswordSubmit} from "./interfaces";
import {Trans, useTranslation} from "react-i18next";
import {Formik, Form, Field} from "formik";
import {TextField} from "formik-mui";
import {getResetPasswordSchema} from "../pages/validation";
import {ArrowBackIos, Visibility, VisibilityOff} from "@mui/icons-material";
import {ResetPasswordData} from "../pages/interfaces";
import SuccessModal from "./successModal";
import {LoadingButton} from "@mui/lab";
import {ResetPasswordCommand} from "../../features/user/user-api";
import Link from "../../common/components/link";
import ModalForm from "../../common/Modal/components/modalForm";

const initialValues: ResetPasswordData = {
  password: "",
  confirmPassword: "",
  grantType: "password",
  rememberMe: false,
};

export default function ResetPassword({
  onSubmit,
  notificationStatus,
}: ResetPasswordSubmit) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleOpen = () => setOpen(true);

    if (notificationStatus === true) handleOpen();
  }, [notificationStatus]);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };

  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((value) => !value);
  };

  const {t} = useTranslation();

  return (
    <Grid container columns={{xs: 6, md: 6}}>
      <Grid md />
      <Grid xs={6} md={4}>
        <Grid display={{xs: "none", md: "flex"}}>
          <IconButton sx={{p: 1, mt: 2, color: "black"}} href="../../login">
            <ArrowBackIos />
          </IconButton>
        </Grid>
        <Grid container direction="column">
          <Typography
            variant="h1"
            component="h1"
            sx={{fontWeight: "bold", mt: 3, mb: 1}}
          >
            <Trans i18nKey="resetPassword">Reset Password</Trans>
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{fontWeight: "light", color: "primary.dark", mb: 3}}
          >
            <Trans i18nKey="newPassword">Enter a new password</Trans>
          </Typography>
        </Grid>
        <Formik
          onSubmit={(values: ResetPasswordCommand) => {
            onSubmit(values);
          }}
          initialValues={initialValues}
          validationSchema={getResetPasswordSchema(t)}
        >
          {({isSubmitting}) => (
            <Form>
              <Field
                component={TextField}
                sx={{mb: 2}}
                fullWidth
                label={t("newPassword")}
                placeholder={t("newPassword")}
                name="password"
                data-testid="input-password"
                disabled={
                  (isSubmitting && notificationStatus == null) ||
                  (isSubmitting && notificationStatus)
                }
                autoComplete="password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="btn-show-password"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        sx={{opacity: "0.5"}}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Field
                component={TextField}
                fullWidth
                label={t("confirmPassword")}
                placeholder={t("confirmPassword")}
                name="confirmPassword"
                data-testid="input-confirm-password"
                disabled={
                  (isSubmitting && notificationStatus == null) ||
                  (isSubmitting && notificationStatus)
                }
                autoComplete="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="btn-show-password"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                        sx={{opacity: "0.5"}}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {notificationStatus && (
                <Typography
                  variant="subtitle2"
                  sx={{fontWeight: "light", color: "primary.dark", mt: 2}}
                >
                  <Trans i18nKey="resetLinkSent">
                    Password reset link has been sent to your email!
                  </Trans>
                </Typography>
              )}
              <Grid
                xs={6}
                sx={{
                  position: {xs: "fixed", md: "inherit"},
                  bottom: {xs: 0},
                  pr: {xs: 4, md: 0},
                }}
              >
                <LoadingButton
                  sx={{p: 1, mt: 6, mb: 2}}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  data-testid="btn-submit"
                  disabled={isSubmitting && notificationStatus}
                  loading={isSubmitting && notificationStatus == null}
                  loadingPosition="end"
                  endIcon={<></>}
                >
                  <Trans i18nKey="resetPassword">Reset Password</Trans>
                </LoadingButton>
                <Button
                  fullWidth
                  component={Link}
                  sx={{p: 1, mb: 2, display: {xs: "flex", md: "none"}}}
                  type="button"
                  variant="text"
                  color="primary"
                  to="../../login"
                >
                  <Trans i18nKey="backToLogin">Back to Login</Trans>
                </Button>
              </Grid>
              <ModalForm open={open} setOpen={setOpen}>
                <SuccessModal />
              </ModalForm>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid md />
    </Grid>
  );
}
