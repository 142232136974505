import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import SplitPage from "../../common/SplitPage/components/splitPage";
import {toastError} from "../../common/utils/toastMessages";
import ForgotPasswordForm from "../components";
import {useForgotPasswordSubmit} from "../hooks/useForgotPasswordSubmit";
import {WorkflowExecutionApiArg} from "../../features/workflowTriggers/workflowExecution-api";
import {useAppSelector} from "../../store";
import {getPortalNameFromDomain} from "../../common/OrganizationConfig/useOrganizationConfig";

export interface ResetPassworValues {
  username: string;
  isPortal?: boolean;
}

const ForgotPassword = () => {
  const {t} = useTranslation();

  const {
    notificationStatus,
    setNotificationStatus,
    runWorkflow,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useForgotPasswordSubmit();

  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows
        ?.sendResetPasswordEmailWorkflowId,
  );

  useEffect(() => {
    if (isSuccess) {
      setNotificationStatus(true);
    }
    if (isError) {
      toastError(error, t("toasts.passwordResetError"));
      setNotificationStatus(false);
    }
  }, [isLoading]);

  const portalName =
    process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain();

  const sendResetPasswordEmail = async (values: ResetPassworValues) => {
    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: workflowId,
      values: {
        variables: {
          userName: values.username,
          portalName,
        },
      },
    };

    setNotificationStatus(null);
    await runWorkflow(executeWorkflowApiArgs);
  };

  return (
    <SplitPage
      contentSide="left"
      main={
        <ForgotPasswordForm
          onSubmit={sendResetPasswordEmail}
          notificationStatus={notificationStatus}
        />
      }
      pageTitle="Forgot Password"
    />
  );
};

export default ForgotPassword;
