import Grid from "@mui/material/Unstable_Grid2";
import React, {useEffect, useState} from "react";
import {getLangCodeFromI18n} from "../../../app/app.component";
import {FormControl, MenuItem, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {VerifyAddressCommandValues} from "../../../features/verification/verification-api";
import {
  GetStatesQueryValues,
  StateDto,
  StatesGetApiArg,
  useStatesGetMutation,
} from "../../../features/states/states-api";
import {getAddressSchema} from "../../../Verifications/pages/validation";
import {SubmitHandler} from "react-hook-form";
import {setContactAddress} from "../../../features/verification/verification-slice";
import {Field, Form, Formik, FormikProps} from "formik";
import {TextField} from "formik-mui";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CountryFilter,
  useGetCountries,
} from "../../../common/hooks/useGetCountries";

type ContactAddressFieldName =
  | "firstName"
  | "lastName"
  | "countryCode"
  | "regionCode"
  | "district"
  | "city"
  | "postalCode"
  | "streetName"
  | "houseNumber"
  | "apartment"
  | "companyName";

export interface AddressFormValues {
  contactId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  countryCode?: string | null;
  regionCode?: string | null;
  district?: string | null;
  city?: string | null;
  postalCode?: string | null;
  streetName?: string | null;
  houseNumber?: string | null;
  apartment?: string | null;
  companyName?: string | null;
}

const defaultInitialValues: AddressFormValues = {
  firstName: "",
  lastName: "",
  countryCode: "",
  regionCode: "",
  district: "",
  city: "",
  postalCode: "",
  streetName: "",
  houseNumber: "",
  apartment: "",
  companyName: "",
};

export interface AddressFormProps {
  initialValues?: AddressFormValues;
  onSubmit?: SubmitHandler<AddressFormValues>;
  onChange?: (formValues: AddressFormValues) => void;
  countryFilter?: CountryFilter;
}

export default function AddressForm({
  initialValues = defaultInitialValues,
  onSubmit,
  onChange,
  countryFilter,
}: AddressFormProps) {
  const lang = getLangCodeFromI18n();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [regions, setRegions] = useState<StateDto[]>();
  const [countryCode, setCountryCode] = useState<string | null>(
    initialValues.countryCode ?? null,
  );
  const [addressFormat, setAddressFormat] = useState<string | null>(null);
  const [validationSchema, setValidationSchema] = useState(
    getAddressSchema(t, addressFormat),
  );

  const {data: countries} = useGetCountries({filter: countryFilter});

  /*  const [verifyAddress, {isLoading, isSuccess}] =
      useVerificationVerifyAddressMutation();

    const [updateContactAddress] = useUpdateContactAddressMutation();
    const [updateUserInfo] = useUserUpdateInfoMutation();*/

  /*  useEffect(() => {
      if (isSuccess) {
        toast.success(`${t("toasts.addressVerificationSuccess")}`);
        handleNext();
      }
    }, [isLoading]);*/

  /*  const handleVerifyAddress: SubmitHandler<VerifyAddressCommandValues> = (
      values,
    ) => {
      values.contactId = contactId;
      const commandArgs: VerificationVerifyAddressApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        verifyAddressCommandValues: {
          ...values,
          customValues: {
            toAddressHouseNumber: values?.houseNumber ?? "",
            toAddressDistrict: values?.district ?? "",
          },
        },
      };
      dispatch(
        setNameSurname({
          firstName: values.firstName,
          lastName: values.lastName,
        }),
      );
      verifyAddress(commandArgs);
      dispatch(verifyContactAddress(initialValues));
    };

    const handleUpdateAddress: SubmitHandler<VerifyAddressCommandValues> = async (
      values,
    ) => {
      try {
        const commandArgs: UpdateContactAddressApiArg = {
          organizationId: process.env
            .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
          contactAddressId: addressInitialValues.contactAddressId,
          updateContactAddressCommandValues: {
            addressLine: values.streetName || "",
            addressLine2: values.apartment || "",
            cityName: values.city || "",
            countryCode: values.countryCode || "",
            postalCode: values.postalCode || "",
            stateCode: values.regionCode || "",
            customValues: {
              toAddressHouseNumber: values?.houseNumber,
              toAddressDistrict: values?.district,
            },
          },
        };
        if (
          (userState?.firstName !== values.firstName ||
            userState?.lastName !== values.lastName) &&
          values.firstName &&
          values.lastName
        ) {
          const updateUserValues: UpdateUserInfoCommand = {
            organizationId: process.env
              .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
            contactId,
            firstName: values.firstName ?? "",
            lastName: values.lastName ?? "",
          };

          await updateUserInfo({updateUserInfoCommand: updateUserValues});

          dispatch(
            setNameSurname({
              firstName: values.firstName,
              lastName: values.lastName,
            }),
          );
        }

        await updateContactAddress(commandArgs);
        toast.success(`${t("toasts.addressWasUpdated")}`);

        onSubmit(values);
      } catch (error: any) {
        toastError(error);
      }
    };*/

  const [getStates] = useStatesGetMutation();

  const getRegions = async (request: GetStatesQueryValues) => {
    const commandArgs: StatesGetApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      getStatesQueryValues: request,
    };
    return getStates(commandArgs);
  };

  useEffect(() => {
    if (countryCode) {
      const getRegionsAsync = async () => {
        const response: any = await getRegions({
          offset: 0,
          limit: 100,
          sort: "name",
          filter: `countryCode:${countryCode} AND CustomValues.state_is_active:true`,
          search: "",
        });
        setRegions(response.data?.items);
      };
      getRegionsAsync();
    }
  }, [countryCode]);

  useEffect(() => {
    const selectedCountry = countries?.find(
      (country) => country.countryCode === countryCode,
    );
    if (selectedCountry) {
      const customAddressFormat =
        selectedCountry?.customValues?.["address-format"];
      setAddressFormat(customAddressFormat === "US" ? "US" : "NonUS");
    }
  }, [countryCode, countries]);

  useEffect(() => {
    setValidationSchema(getAddressSchema(t, addressFormat));
  }, [addressFormat, t]);

  const handleFieldChange = async (
    formikProps: FormikProps<VerifyAddressCommandValues>,
    fieldName: ContactAddressFieldName,
    value: any,
  ) => {
    formikProps.setFieldValue(fieldName, value);

    await formikProps.validateField(fieldName);

    const updatedValues = {
      ...formikProps.values,
      [fieldName]: value,
    };

    dispatch(setContactAddress(updatedValues));

    if (onChange) onChange(updatedValues);
  };

  const renderFieldsByAddressFormat = (
    formikProps: FormikProps<VerifyAddressCommandValues>,
  ) => {
    if (addressFormat === "US") {
      return (
        <>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("addressLineLabel")}
                  name="streetName"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "streetName",
                      event.target.value,
                    )
                  }
                  data-testid="input-streetname"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("addressLine2Label")}
                  name="apartment"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "apartment",
                      event.target.value,
                    )
                  }
                  data-testid="input-apartment"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("cityLabel")}
                  name="city"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(formikProps, "city", event.target.value)
                  }
                  data-testid="input-city"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3}>
                <Autocomplete
                  options={regions ?? []}
                  getOptionLabel={(option: any) => {
                    return option.customValues &&
                      `state_${lang}` in option.customValues
                      ? option.customValues[`state_${lang}`]
                      : option.name;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.stateCode === value.stateCode
                  }
                  disabled={!countryCode}
                  value={
                    regions?.find(
                      (region) =>
                        region.stateCode === formikProps.values.regionCode,
                    ) ?? null
                  }
                  size={isDesktop ? "medium" : "small"}
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      label={t("stateLabel")}
                      data-testid="input-region"
                      name="regionCode"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: {sm: "52px", xs: "40px"},
                          paddingY: {sm: "5px"},
                        },
                      }}
                    />
                  )}
                  onChange={(_: any, value: StateDto | null) =>
                    handleFieldChange(
                      formikProps,
                      "regionCode",
                      value?.stateCode,
                    )
                  }
                />
              </Grid>
              <Grid xs={6} md={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("postalCodeLabel")}
                  name="postalCode"
                  autoComplete="postal-code"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "postalCode",
                      event.target.value,
                    )
                  }
                  data-testid="input-postalcode"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
    if (addressFormat === "NonUS") {
      return (
        <>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3}>
                <Autocomplete
                  options={regions ?? []}
                  getOptionLabel={(option: any) => {
                    return option.customValues &&
                      `state_${lang}` in option.customValues
                      ? option.customValues[`state_${lang}`]
                      : option.name;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.stateCode === value.stateCode
                  }
                  disabled={!countryCode}
                  value={
                    regions?.find(
                      (region) =>
                        region.stateCode === formikProps.values.regionCode,
                    ) ?? null
                  }
                  size={isDesktop ? "medium" : "small"}
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      label={t("regionLabel")}
                      data-testid="input-region"
                      name="regionCode"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: {sm: "52px", xs: "40px"},
                          paddingY: {sm: "5px"},
                        },
                      }}
                    />
                  )}
                  onChange={(_: any, value: StateDto | null) =>
                    handleFieldChange(
                      formikProps,
                      "regionCode",
                      value?.stateCode,
                    )
                  }
                />
              </Grid>
              <Grid xs={6} md={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("districtLabel")}
                  name="district"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "district",
                      event.target.value,
                    )
                  }
                  data-testid="input-district"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("cityLabel")}
                  name="city"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(formikProps, "city", event.target.value)
                  }
                  data-testid="input-city"
                />
              </Grid>
              <Grid xs={6} md={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("postalCodeLabel")}
                  name="postalCode"
                  autoComplete="postal-code"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "postalCode",
                      event.target.value,
                    )
                  }
                  data-testid="input-postalcode"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("streetLabel")}
                  name="streetName"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "streetName",
                      event.target.value,
                    )
                  }
                  data-testid="input-streetname"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("houseNumberLabel")}
                  name="houseNumber"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "houseNumber",
                      event.target.value,
                    )
                  }
                  data-testid="input-housenumber"
                />
              </Grid>
              <Grid xs={6} md={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  label={t("apartmentLabel")}
                  name="apartment"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(
                      formikProps,
                      "apartment",
                      event.target.value,
                    )
                  }
                  data-testid="input-apartment"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
    return null;
  };
  return (
    <Grid>
      <Formik
        onSubmit={(values: VerifyAddressCommandValues) => {
          if (onSubmit) onSubmit(values);
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formikProps: FormikProps<VerifyAddressCommandValues>) => {
          return (
            <Form>
              <Grid container direction={"column"} rowGap={4}>
                <Grid>
                  <Grid container direction={"column"} gap={2}>
                    <Grid>
                      <Grid container columns={6} spacing={2}>
                        <Grid xs={6} md={6}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            fullWidth
                            size={isDesktop ? "normal" : "small"}
                            label={t("companyNameLabel")}
                            name="companyName"
                            autoComplete="organization"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleFieldChange(
                                formikProps,
                                "companyName",
                                event.target.value,
                              )
                            }
                            data-testid="input-companyname"
                          />
                        </Grid>
                        <Grid xs={6} md={3}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            fullWidth
                            size={isDesktop ? "normal" : "small"}
                            label={t("firstNameLabel")}
                            name="firstName"
                            autoComplete="given-name"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleFieldChange(
                                formikProps,
                                "firstName",
                                event.target.value,
                              )
                            }
                            data-testid="input-firstname"
                          />
                        </Grid>
                        <Grid xs={6} md={3}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            fullWidth
                            size={isDesktop ? "normal" : "small"}
                            label={t("lastNameLabel")}
                            name="lastName"
                            autoComplete="family-name"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleFieldChange(
                                formikProps,
                                "lastName",
                                event.target.value,
                              )
                            }
                            data-testid="input-lastname"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid container columns={6} spacing={2}>
                        <Grid xs={6} md={6}>
                          <FormControl
                            sx={{width: "100%"}}
                            size={isDesktop ? "medium" : "small"}
                          >
                            <Field
                              component={TextField}
                              variant="outlined"
                              fullWidth
                              select
                              size={isDesktop ? "normal" : "small"}
                              label={t("countryLabel")}
                              name="countryCode"
                              data-testid="select-country"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleFieldChange(
                                  formikProps,
                                  "countryCode",
                                  event.target.value,
                                );
                                setCountryCode(event.target.value);
                                handleFieldChange(
                                  formikProps,
                                  "regionCode",
                                  "",
                                );
                              }}
                            >
                              {countries &&
                                countries
                                  .filter((country) => country.countryCode)
                                  .map((country) => (
                                    <MenuItem
                                      key={country.countryCode ?? ""}
                                      value={country.countryCode ?? ""}
                                      data-testid={`option-${country.countryCode}`}
                                    >
                                      {country.customValues &&
                                      `country_translation_${lang}` in
                                        country.customValues
                                        ? country.customValues[
                                            `country_translation_${lang}`
                                          ]
                                        : country.name}
                                    </MenuItem>
                                  ))}
                            </Field>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    {renderFieldsByAddressFormat(formikProps)}
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}
