import React from "react";
import {Trans} from "react-i18next";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import InvoicesListForm from "../components";
import InvoicesFilter from "../components/invoicesFilter";

const AllInvoices = () => {
  return (
    <MainLayout pageTitle="Invoices" backLink="../getting-started">
      <ContentLayout
        title={
          <Trans i18nKey="invoices.titleYourInvoices">Your Invoices</Trans>
        }
        backLink="../getting-started"
        content={<InvoicesListForm />}
        withoutMobileTitle={true}
        mobileFilterComponent={<InvoicesFilter />}
      />
    </MainLayout>
  );
};

export default AllInvoices;
