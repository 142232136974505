import React from "react";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import {Trans} from "react-i18next";
import ParcelShipmentsListForm from "../components";
import ParcelShipmentsFilter from "../components/parcelShipmentsFilter";
import {Box, Button, Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {removeOrderStep} from "../../../features/order/parcelShipment-slice";
import {ParcelConfiguration} from "../../parcel.module";
import {NEW_ORDER_FAKE_ID} from "../../../../properties";

type AllShipmentsProps = {
  config: ParcelConfiguration;
};

const AllShipments = ({config}: AllShipmentsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onAddParcel = () => {
    dispatch(removeOrderStep([NEW_ORDER_FAKE_ID]));
    navigate("../delivery/new/shippingAddress");
  };
  return (
    <MainLayout pageTitle="Parcels" backLink="../getting-started">
      <ContentLayout
        title={
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{width: "100%"}}
          >
            <Box sx={{flexGrow: 1}}>
              <Trans i18nKey="parcelShipments.allParcels.titleYourParcels">
                My Shipments
              </Trans>
            </Box>
            {config.createParcelEnabled ? (
              <Button
                sx={{marginRight: "19px"}}
                variant="contained"
                color="secondary"
                onClick={onAddParcel}
              >
                <Trans i18nKey={"createParcelShipment"}>
                  Create Parcel Shipment
                </Trans>
              </Button>
            ) : null}
          </Grid>
        }
        backLink="../getting-started"
        content={<ParcelShipmentsListForm />}
        isPurchase={false}
        withoutMobileTitle={false}
        mobileFilterComponent={<ParcelShipmentsFilter />}
      />
    </MainLayout>
  );
};

export default AllShipments;
