import {SubmitHandler} from "react-hook-form";
import {
  OrderDeleteOrderApiArg,
  useOrderDeleteOrderMutation,
} from "../../../features/order/order-api";

export const usePurchaseDeleteSubmit = (orderId: number) => {
  const [
    deletePurchase,
    {
      isLoading: isDeleteProcess,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useOrderDeleteOrderMutation();

  const onDelete: SubmitHandler<any> = async () => {
    const commandArgs: OrderDeleteOrderApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      orderId,
    };
    await deletePurchase(commandArgs);
  };

  return {
    onDelete,
    isDeleteProcess,
    isDeleteError,
    isDeleteSuccess,
  };
};
