import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {AddBox} from "@mui/icons-material";
import NewCardDetails from "./newCardDetails";
import {PaymentMethodFragmentProps} from "../interfaces";
import {
  ContactPaymentMethodDto,
  GetContactPaymentMethodsApiArg,
  usePaymentMethodsGetPaymentMethodsMutation,
} from "../../../features/paymentMethod/paymentMethod-api";
import {useAppSelector} from "../../../store";
import {setPaymentMethod} from "../../../features/order/parcelShipment-slice";
import {useDispatch} from "react-redux";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GppBadIcon from "@mui/icons-material/GppBad";
import {
  ContactGetContactApiArg,
  useContactGetContactMutation,
} from "../../../features/contact/contact-api";
import {toastError} from "../../../common/utils/toastMessages";
import {AutoPayment} from "../../../common/components/autoPayment";
import {VerificationState} from "../../../features/verification/verification-slice";
import Link from "../../../common/components/link";
import InfoBox from "../../../common/components/infoBox";
import {Helmet} from "react-helmet";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";

export default function PaymentMethodFragment({
  handleNext,
  invoiceInvisible = false,
  withInfoBox = false,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  pageTitle,
}: PaymentMethodFragmentProps) {
  const theme = useTheme();
  const {t} = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [newCardVisible, setNewCardVisible] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<
    ContactPaymentMethodDto[] | []
  >([]);
  const [isLoadingPayments, setIsLoadingPayments] = useState<boolean>(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] =
    useState<ContactPaymentMethodDto | null>(selectedPaymentMethod ?? null);
  const [isVerifiedCurrentMethod, setIsVerifiedCurrentMethod] =
    useState<boolean | null | undefined>();
  const [isAutoPay, setIsAutoPay] = useState(false);
  const [getPaymentMethods] = usePaymentMethodsGetPaymentMethodsMutation();
  const dispatch = useDispatch();

  const {currentOrder, orderIds} = useDeliveryHelper();
  const fetchPaymentMethods = async () => {
    const commandArgs: GetContactPaymentMethodsApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
    };
    return getPaymentMethods(commandArgs);
  };

  const userState = useAppSelector((state: any) => state.userState);
  const verificationState = useAppSelector(
    (state: any) => state.verificationState as VerificationState,
  );
  const [getContact, {isLoading: isContactLoading}] =
    useContactGetContactMutation();

  const getContactInfo = async () => {
    const commandArgs: ContactGetContactApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactId: userState.contactId,
    };
    return getContact(commandArgs);
  };

  const handleChangeAutoPayment = () => {
    setIsAutoPay((current) => !current);
  };

  const handleVisibilityClick = (isVisible: boolean) => {
    setNewCardVisible(isVisible);
  };

  const uploadPaymentMethods = async () => {
    try {
      setIsLoadingPayments(true);
      const paymentMethods: any = await fetchPaymentMethods();
      setPaymentMethods(paymentMethods?.data?.items);

      if (currentOrder?.contactPaymentMethod) {
        setIsAutoPay(currentOrder.customValues.autoPay);
        setSelectedPaymentMethod(
          paymentMethods?.data?.items.find(
            (method: any) =>
              method.contactPaymentMethodId ==
              currentOrder?.contactPaymentMethod?.contactPaymentMethodId,
          ) ?? null,
        );
      } else {
        const contactInfo: any = await getContactInfo();
        if (contactInfo?.data?.customValues) {
          setIsAutoPay(contactInfo?.data?.customValues?.autoPay === "true");
        }
      }

      return paymentMethods?.data?.items;
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoadingPayments(false);
    }
  };

  const handleNewCardSuccess = async (
    contactPaymentMethodId: string | null,
  ) => {
    const paymentMethods = await uploadPaymentMethods();
    const foundedPaymentMethod = paymentMethods.find(
      (pm: ContactPaymentMethodDto) => {
        return pm.contactPaymentMethodId === contactPaymentMethodId;
      },
    );

    if (foundedPaymentMethod) {
      setSelectedPaymentMethod(foundedPaymentMethod);
      setCurrentPaymentMethod(foundedPaymentMethod);
    }
  };

  useEffect(() => {
    if (paymentMethods && paymentMethods.length) {
      const verifiedMethodValue = paymentMethods.find(
        (x) =>
          x.contactPaymentMethodId ===
          currentPaymentMethod?.contactPaymentMethodId,
      )?.isVerified;
      setIsVerifiedCurrentMethod(verifiedMethodValue);
    }
  }, [paymentMethods, currentPaymentMethod]);

  useEffect(() => {
    uploadPaymentMethods();
  }, [newCardVisible]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const infoBoxTitle = (
    <Trans i18nKey="delivery.paymentMethod.infoBoxTitle">
      Add a payment card
    </Trans>
  );
  const infoBoxContent = (
    <Trans i18nKey="delivery.paymentMethod.infoBoxContent">
      Please, confirm the card to pay for the transportation fee. In case of
      request, we will ensure the exact price of the transportation according to
      the prices we have declared on this website and will send you the fee.
    </Trans>
  );

  return (
    <Grid xs={6} md={14} sx={{mb: {xs: 0, md: 3}}}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid
        container
        columns={{xs: 6, md: 12}}
        justifyContent="space-between"
        direction={{xs: "column", md: "row"}}
      >
        <Grid xs={6} md={6.5}>
          <Grid
            container
            direction="column"
            columns={{xs: 6, md: 6.5}}
            mt={{xs: 2.5, md: 0}}
          >
            {withInfoBox && (
              <Grid xs={6} md={5} mb={3}>
                <InfoBox title={infoBoxTitle} content={infoBoxContent} />
              </Grid>
            )}
            <Grid
              xs={6}
              md={4}
              mb={3}
              display={
                verificationState.contactAddress.verified == true
                  ? "none"
                  : "block"
              }
            >
              <Typography color={"error.main"}>
                <Trans i18nKey={"delivery.paymentMethod.verificationNeeded"} />{" "}
                <Link to="../verifications">
                  <Trans
                    i18nKey={"delivery.paymentMethod.completeVerification"}
                  />
                </Link>
              </Typography>
            </Grid>
            <Grid xs={6} md={3}>
              <Typography
                textAlign={"start"}
                variant="body1"
                fontSize={"1.25rem"}
                mb={2}
                fontWeight={500}
              >
                <Trans i18nKey={"chooseYourCard"}>Choose your card</Trans>
              </Typography>
            </Grid>
            <Grid
              xs={6}
              md={3}
              mb={3}
              display={!isDesktop && newCardVisible ? "none" : "flex"}
            >
              <FormControl
                data-testid="choose-card"
                fullWidth
                disabled={!verificationState.contactAddress.verified}
              >
                <InputLabel>
                  <Typography color={"#98A2B3"}>
                    <Trans i18nKey={"selectCard"}>Select Card</Trans>
                  </Typography>
                </InputLabel>
                <Select
                  data-testid="select-card"
                  label={t("selectCard")}
                  value={currentPaymentMethod?.contactPaymentMethodId ?? ""}
                  onChange={(event) => {
                    const payment =
                      paymentMethods.find(
                        (method) =>
                          method.contactPaymentMethodId == event.target.value,
                      ) ?? null;
                    setSelectedPaymentMethod(payment);
                    setCurrentPaymentMethod(payment);
                    if (payment && payment.isVerified) {
                      setNewCardVisible(false);
                      setIsVerifiedCurrentMethod(true);
                    } else setNewCardVisible(true);
                  }}
                  sx={{minHeight: "52px"}}
                  size={"medium"}
                  fullWidth
                >
                  {paymentMethods?.length ? (
                    paymentMethods?.map((method, index) => {
                      return (
                        <MenuItem
                          data-testid={`card-item-${index}`}
                          key={index}
                          value={method?.contactPaymentMethodId}
                          sx={{justifyContent: "space-between"}}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            {method?.description}
                            {method?.isVerified ? (
                              <VerifiedUserIcon
                                sx={{color: "success.main", width: "24px"}}
                              />
                            ) : (
                              <GppBadIcon
                                sx={{color: "error.main", width: "24px"}}
                              />
                            )}
                          </Box>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled={true}>
                      {isLoadingPayments ? (
                        <Trans i18nKey="loading">Loading...</Trans>
                      ) : (
                        <Trans i18nKey="delivery.paymentMethod.noPaymentMethods">
                          No payment methods yet
                        </Trans>
                      )}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6} md={3} mb={3}>
              {!newCardVisible && (
                <Button
                  fullWidth
                  color="primary"
                  size="medium"
                  sx={{borderRadius: "8px", minHeight: "56px"}}
                  variant="outlined"
                  startIcon={
                    <AddBox style={{fontSize: "22px"}} color="primary" />
                  }
                  disabled={!verificationState.contactAddress.verified}
                  onClick={() => {
                    handleVisibilityClick(true);
                    setSelectedPaymentMethod(null);
                    setCurrentPaymentMethod(null);
                  }}
                >
                  <Typography
                    display={"flex"}
                    color="black"
                    textTransform="none"
                  >
                    <Trans i18nKey="addNewCard">Add a new card</Trans>
                  </Typography>
                </Button>
              )}
            </Grid>
            <Grid>
              {newCardVisible && (
                <NewCardDetails
                  cardData={
                    !currentPaymentMethod?.isVerified
                      ? currentPaymentMethod
                      : null
                  }
                  handleNext={undefined}
                  handleVisibilityClick={handleVisibilityClick}
                  onSuccess={handleNewCardSuccess}
                />
              )}
            </Grid>
            {!invoiceInvisible && (!newCardVisible || isDesktop) && (
              <AutoPayment
                isAutoPay={isAutoPay}
                disabled={paymentMethods?.length <= 0}
                handleChangeAutoPayment={handleChangeAutoPayment}
                mt={3}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {!invoiceInvisible && (
        <Grid
          sx={{py: {xs: 2, md: 4}}}
          md={2}
          xs={6}
          display={!newCardVisible || isDesktop ? "flex" : "none"}
        >
          <Button
            data-testid="btn-continue-payment-methods"
            style={{minWidth: "90%"}}
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
              if (currentPaymentMethod)
                dispatch(
                  setPaymentMethod({
                    orderIds: orderIds,
                    paymentMethod: currentPaymentMethod,
                    autoPay: isAutoPay,
                  }),
                );
              handleNext();
            }}
            disabled={!isVerifiedCurrentMethod}
            sx={
              isDesktop
                ? {
                    p: 1,
                  }
                : {
                    width: "100%",
                    p: 1,
                    mb: 9,
                    bottom: 0,
                  }
            }
          >
            {t("btnContinue")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
