import React, {useState} from "react";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {
  PURCHASES_QUERY_DEFAULT_LIMIT,
  PURCHASES_QUERY_DEFAULT_OFFSET,
} from "./purchaseList";
import {SxProps, Theme} from "@mui/material";

interface ParcelShipmentsSearchProps {
  containerSx?: SxProps<Theme>;
}

export default function PurchasesSearch({
  containerSx = {},
}: ParcelShipmentsSearchProps) {
  const {t} = useTranslation();

  const url = new URL(location.href);
  const [, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState<string>(() => {
    const searchQueryParamValue = url.searchParams.get("search");
    return searchQueryParamValue || "";
  });

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newSearchValue = event?.target?.value || "";

    setSearchParams((prevSearchParams) => {
      prevSearchParams.set("search", newSearchValue);
      prevSearchParams.set("offset", PURCHASES_QUERY_DEFAULT_OFFSET);
      prevSearchParams.set("limit", PURCHASES_QUERY_DEFAULT_LIMIT);
      return prevSearchParams;
    });

    setSearchValue(newSearchValue);
  };

  return (
    <Paper
      component="form"
      sx={{
        padding: "0 4px",
        display: "flex",
        alignItems: "center",
        width: {xs: "100%", sm: "375px"},
        borderRadius: "32px",
        border: "1px solid #D8E6FB",
        backgroundColor: "primary.white",
        boxShadow: "none",
        height: "36px",
        ...containerSx,
      }}
    >
      <SearchIcon
        sx={{
          opacity: 0.6,
          paddingLeft: "6.5px",
          width: "2.5rem",
        }}
      />
      <InputBase
        sx={{
          margin: "auto 3px",
          padding: "0 5px 0 0",
          width: "100%",
          ".MuiInputBase-input": {
            padding: 0,
            fontSize: "14px",
            fontFamily: "Inter Medium",
            fontWeight: 500,
            "&::placeholder": {
              color: "primary.dark",
              opacity: 1,
            },
          },
        }}
        placeholder={t("parcelShipments.allParcels.searchPlaceholder") || ""}
        inputProps={{"aria-label": "search shipments"}}
        onChange={handleSearchChange}
        value={searchValue}
        data-testid="input-shipments-search"
      />
    </Paper>
  );
}
