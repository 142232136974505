import React from "react";
import {Box, Modal} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  p: 4,
  textAlign: "center",
  borderRadius: "16px",
  width: {xs: "90%", md: "inherit"},
};

export default function ModalForm({open, customStyle, children}: any) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{backdropFilter: "blur(28.5px)"}}
    >
      <Box sx={style} style={customStyle}>
        {children}
      </Box>
    </Modal>
  );
}
