import React, {useState} from "react";
import {Box, Divider, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {TabMenuProps} from "./interfaces";

export default function TabMenu({
  tabs,
  initialSelected,
  showPaymentMethod,
}: TabMenuProps) {
  const [selected, setSelected] = useState(initialSelected);

  const tabGrids = tabs.map((tab, i) => {
    const textVariant = i == selected - 1 ? "body4" : "body1";
    const textColor = i == selected - 1 ? "primary.main" : "#5A6772";
    const lineColor = i == selected - 1 ? "primary.main" : "#00000000";

    if (i + 1 !== 3 || (showPaymentMethod && i + 1 === 3)) {
      return (
        <Grid
          container
          direction="column"
          rowGap={1}
          onClick={() => {
            if (tab.clickable && i + 1 != selected) {
              tab.onClick();
              setSelected(i + 1);
            }
          }}
          data-testid={`sel-tab-${i + 1}`}
          key={i}
          sx={{
            cursor: tab.clickable && i + 1 != selected ? "pointer" : "auto",
          }}
        >
          <Typography variant={textVariant} color={textColor}>
            {tab.label}
          </Typography>
          <Box sx={{height: "3px", backgroundColor: lineColor}} />
        </Grid>
      );
    }
  });

  return (
    <Grid>
      <Grid
        container
        columns={14}
        justifyContent="center"
        sx={{position: "relative", zIndex: "2", mt: 2}}
      >
        <Grid xs={12}>
          <Grid
            container
            direction="row"
            alignItems="end"
            columnGap={8}
            wrap="nowrap"
          >
            {tabGrids}
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          position: "relative",
          bottom: "2px",
          backgroundColor: "#D8E6FB",
          zIndex: "1",
        }}
      />
    </Grid>
  );
}
