import {Box, IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

interface EditIconProps {
  onClick: () => void;
  marginBottom?: string;
  marginLeft?: string;
}

export default function EditProfileButton({
  onClick,
  marginBottom = "1.5rem",
  marginLeft = "auto",
}: EditIconProps) {
  return (
    <Box
      sx={{
        marginLeft: marginLeft,
        marginBottom: marginBottom,
      }}
    >
      <IconButton
        onClick={() => {
          window.scrollTo(0, 0);
          onClick();
        }}
      >
        <EditIcon
          sx={{
            width: "20px",
            height: "100%",
            minWidth: "20px",
            color: "#6C757D",
          }}
        />
      </IconButton>
    </Box>
  );
}
