import React from "react";
import {MenuItemType, Module, ModuleConfig} from "../core/base.module";
import AccountInfo from "./AccountInfo/pages";
import AccountPasswordChange from "./AccountPasswordChange/pages";
import AccountPaymentMethods from "./AccountPaymentMethods/pages";
import AccountSavedAddresses from "./AccountSavedAddresses/pages";
import BillingAddressInformation from "./BillingAddressInformation/pages";
import EditAccountEmail from "./EditAccountEmail/pages";
import EditAccountName from "./EditAccountName/pages";
import EditAccountPhoneNumber from "./EditAccountPhoneNumber/pages";
import EditResidentialAddress from "./EditResidentialAddress/pages";

export interface ProfileConfiguration extends ModuleConfig {
  disabled?: boolean;
  paymentMethod?: PaymentMethodConfiguration;
}

interface PaymentMethodConfiguration {
  disabled?: boolean;
}

export class ProfileModule implements Module {
  static _instance: ProfileModule;
  config: ProfileConfiguration;

  private constructor(config?: ProfileConfiguration) {
    this.config = config || {};
  }

  static getInstance(config?: ProfileConfiguration): ProfileModule {
    if (!ProfileModule._instance) {
      ProfileModule._instance = new ProfileModule();
    } else if (config) {
      ProfileModule._instance.config = config;
    }
    return ProfileModule._instance;
  }

  getMenuActions(): MenuItemType[] {
    return [];
  }

  getMenuItems() {
    return [];
  }

  getPages() {
    if (!this.config || this.config.disabled !== false) {
      return [];
    }

    const paths = [
      {
        path: "profile",
        element: <AccountInfo config={this.config} />,
      },
      {
        path: "profile/savedAddresses",
        element: <AccountSavedAddresses />,
      },
      {
        path: "profile/changePassword",
        element: <AccountPasswordChange />,
      },
      {
        path: "profile/whatsMyBillingAddress",
        element: <BillingAddressInformation />,
      },
      {
        path: "profile/editName",
        element: <EditAccountName />,
      },
      {
        path: "profile/editPhoneNumber",
        element: <EditAccountPhoneNumber />,
      },
      {
        path: "profile/editEmail",
        element: <EditAccountEmail />,
      },
      {
        path: "profile/editResidentialAddress",
        element: <EditResidentialAddress />,
      },
    ];

    if (!this.config.paymentMethod?.disabled) {
      paths.push({
        path: "profile/paymentMethods",
        element: <AccountPaymentMethods />,
      });
    }

    return paths;
  }
}
