import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PortalOrganizationConfigDto} from "./organizationConfig-api";

export type VerificationType =
  | "email"
  | "phone"
  | "address"
  | "paymentMethod"
  | "acknowledgement";
export type VerificationStatus = "required" | "optional" | "disabled";

export type OrganizationConfigState = {
  verifications: {[key in VerificationType]: VerificationStatus};
  workflows?: any;
  modules?: any;
};

const initialState: OrganizationConfigState = {
  verifications: {
    email: "required",
    phone: "required",
    address: "required",
    paymentMethod: "required",
    acknowledgement: "required",
  },
};

export const organizationConfigSlice = createSlice({
  name: "organizationConfig",
  initialState,
  reducers: {
    setOrganizationConfig: (
      state,
      action: PayloadAction<PortalOrganizationConfigDto[]>,
    ) => {
      action.payload.forEach((config: any) => {
        const configType = config.configName.split(".").slice(-1)[0];
        switch (configType) {
          case "verification":
            state.verifications = config.customValues;
            break;
          case "workflows":
            state.workflows = config.customValues;
            break;
          case "modules":
            state.modules = config.customValues;
            break;
        }
      });
      return state;
    },
  },
});

export default organizationConfigSlice.reducer;

export const {setOrganizationConfig} = organizationConfigSlice.actions;
