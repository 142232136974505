import {useAppSelector} from "../../store";

export const getAddresses = () => {
  const userState = useAppSelector((state) => state.userState);
  return [
    {
      addressLines: [
        {
          key: "Full Name",
          value: `${userState.firstName} ${userState.lastName}`,
        },
        {key: "Address 1", value: "250 Port St"},
        {
          key: `Address 2`,
          value: `${userState.accountNumber ?? ""}`,
        },
        {key: "City", value: "Newark"},
        {key: "State", value: "NJ (New Jersey)"},
        {key: "ZIP", value: "07114"},
        {key: "Phone", value: "+1 (973) 344 7100"},
      ],
      taxTitleKey: "taxMessageNJ",
    },
    {
      addressLines: [
        {
          key: "Full Name",
          value: `${userState.firstName} ${userState.lastName}`,
        },
        {key: "Address 1", value: "4 Lewis Circle"},
        {
          key: `Address 2`,
          value: `${userState.accountNumber ?? ""}`,
        },
        {key: "City", value: "Wilmington"},
        {key: "State", value: "DE (Delaware)"},
        {key: "ZIP", value: "19804"},
        {key: "Phone", value: "+1 (973) 344 7100"},
      ],
      taxTitleKey: "taxMessageDE",
    },
  ];
};
