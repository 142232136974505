import React, {useState} from "react";
import {
  Button,
  Link as MuiLink,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "../../common/components/link";
import MainLayout from "../../common/MainLayout/components/mainLayout";
import WelcomeImg from "../../../images/gettingStarted/getting-started-1.png";
import DeliveryImg from "../../../images/gettingStarted/getting-started-2.png";
import {Trans} from "react-i18next";
import {HowItWorks} from "../components/howItWorks";
import {CountriesList} from "../components/countriesList";
import i18next from "i18next";
import AddressBox from "../../common/components/addressBox";
import {socialMediaLinks} from "../../common/footers/components/mainPageFooter";
import {getContacts} from "../../common/data/contacts";
import {getAddresses} from "../../common/data/addresses";
import {GettingStartedConfiguration} from "../getting-started.module";
import {getCustomValue} from "../../../utils/helper.utils";

const date = new Date();
const time = date.toLocaleTimeString("en-US", {
  timeZone: "America/New_York",
  hour: "2-digit",
  minute: "2-digit",
});

type GettingStartedProps = {
  config: GettingStartedConfiguration;
};

const GettingStarted = ({config}: GettingStartedProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isScreenSm = useMediaQuery(theme.breakpoints.up("sm"));

  const i18nKeyPrefix = "gettingStarted.";

  const trackingNumberMaxLength = 50;
  const [trackingNumber, setTrackingNumber] = useState("");
  const contacts = getContacts();
  const addresses = getAddresses();

  const howItWorksSteps = [
    {
      label: "1",
      title: "Shop online",
      i18nKey: "shopOnline",
      paragraph:
        "When making a purchase, use our US warehouse address as your address at Checkout",
    },
    {
      label: "2",
      title: "Register purchase",
      i18nKey: "registerPurchase",
      paragraph:
        "After receiving the tracking number from the store, add the purchase",
    },
    {
      label: "3",
      title: "Request to ship",
      i18nKey: "requestToShip",
      paragraph:
        'When packages arrive at the warehouse, they will appear in the "Your Purchases" section',
    },
    {
      label: "4",
      title: "Track your purchase",
      i18nKey: "trackYourPurchase",
      paragraph:
        "You will be able to track your purchase after the delivery is made.",
    },
  ];

  return (
    <MainLayout fullWidth pageTitle="Getting Started">
      <>
        {/* Welcome to TRT */}
        <Grid
          display={"flex"}
          flexDirection={{xs: "column-reverse", md: "row"}}
          sx={{
            backgroundColor: theme.palette.info.background,
            height: "auto",
            py: {xs: 0, md: 4},
          }}
          px={{xs: 2, md: 0}}
        >
          <Grid
            md={6}
            mdOffset={1}
            pr={{md: 5}}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "center",
            }}
          >
            <Typography variant="h1" mb={2}>
              <Trans i18nKey={i18nKeyPrefix + "welcomeToTTRT"}>
                Welcome to TRT Express!
              </Trans>
            </Typography>
            <Typography variant="body1" mb={4}>
              <Trans i18nKey={i18nKeyPrefix + "welcomeToTTRTParagraph"}>
                Add your tracking number,
                <br />
                follow the easy steps below
                <br />
                and enjoy your purchase
              </Trans>
            </Typography>
            <Grid
              display={"flex"}
              flexWrap={{xs: "wrap", sm: "nowrap", md: "wrap"}}
              justifyContent={{xs: "center", md: "flex-start"}}
              alignItems={"center"}
              rowGap={2}
              columnGap={2}
            >
              <Grid xs={6} sm={4} md={14} lg={8} xl={7} width={"100%"}>
                <TextField
                  fullWidth
                  size={isScreenSm ? "medium" : "small"}
                  placeholder={"000000000000"}
                  label={
                    <Trans i18nKey={"trackingNumber"}>Tracking number</Trans>
                  }
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                  value={trackingNumber}
                  inputProps={{maxLength: trackingNumberMaxLength}}
                  onChange={(event) => setTrackingNumber(event.target.value)}
                  data-testid={"gettingStarted-trackingNumberInput"}
                />
              </Grid>
              <Grid xs={6} sm={2} md={14} lg={5} xl={5} width={"100%"}>
                <Link to={`../purchases/0?tracknum=${trackingNumber}`}>
                  <Button
                    color={"secondary"}
                    variant={"contained"}
                    sx={{
                      paddingX: 5,
                      textTransform: "none",
                      width: "100%",
                      lineHeight: "18px",
                    }}
                    data-testid={"gettingStarted-trackingNumberSubmit"}
                  >
                    <Trans i18nKey={"addPurchase"}>Add purchase</Trans>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            md={6}
            xs={6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            mb={{xs: 2, md: 0}}
          >
            <img
              src={WelcomeImg}
              alt={"Welcome Image"}
              width={"100%"}
              height={"auto"}
              style={{maxHeight: "500px"}}
            />
          </Grid>
        </Grid>
        {/* Welcome to TRT */}

        {/* Learn how it works */}
        <Grid pt={7} pb={9} px={{xs: 2, md: 0}}>
          <Typography variant="h1" textAlign={"center"} mb={2}>
            <Trans i18nKey={i18nKeyPrefix + "howItWorks.title"}>
              Learn how it works
            </Trans>
          </Typography>
          <HowItWorks
            i18nKeyPrefix={i18nKeyPrefix + "howItWorks."}
            steps={howItWorksSteps}
            isDesktop={isDesktop}
          />
        </Grid>
        {/* Learn how it works */}

        {/* Delivery countries */}
        <Grid
          pt={4}
          sx={{backgroundColor: theme.palette.info.background}}
          display={"flex"}
          flexDirection={{md: "row", xs: "column"}}
          px={{xs: 2, md: 0}}
        >
          <Grid md={4} mt={-8} mdOffset={1}>
            <img
              width={"100%"}
              height={"auto"}
              src={DeliveryImg}
              alt={"delivery countries"}
            />
          </Grid>
          <Grid md={8} pl={{xs: 0, md: 4}} pt={4}>
            <Typography variant={"h2"} mb={2}>
              <Trans i18nKey={i18nKeyPrefix + "deliveryCountries"}>
                Delivery countries
              </Trans>
            </Typography>
            <CountriesList />
            <Typography variant={"h3"} mb={2}>
              <Trans i18nKey={i18nKeyPrefix + "deliveryCountriesProhibited"}>
                Don&apos;t know if your item is deliverable?
              </Trans>
              &nbsp;
              <MuiLink
                display={"block"}
                underline="none"
                target="_blank"
                rel="noopener"
                href={
                  getCustomValue(
                    config?.prohibitedItemsLink,
                    i18next.language,
                  ) ?? ""
                }
              >
                <Trans i18nKey={i18nKeyPrefix + "seeProhibitedItemsList"}>
                  See prohibited items list
                </Trans>
              </MuiLink>
            </Typography>
          </Grid>
        </Grid>
        {/* Delivery countries */}

        {/* Address Cards */}
        {!isDesktop && (
          <Grid sx={{backgroundColor: theme.palette.info.background}} p={2}>
            <Grid pb={2}>
              <Typography variant="h3">
                <Trans i18nKey="copyYourAddress">Contact Us</Trans>
              </Typography>
            </Grid>
            <Grid
              container
              direction={"column"}
              justifyContent={"space-between"}
              spacing={1}
            >
              {addresses.map((address, index) => {
                return (
                  <Grid flex={1} key={index}>
                    <AddressBox address={address} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}

        {/* Contact Us */}
        {!isDesktop && (
          <Grid sx={{backgroundColor: theme.palette.info.background}} p={2}>
            <Grid pb={1}>
              <Typography variant="h3">
                <Trans i18nKey="contactUs">Contact Us</Trans>
              </Typography>
            </Grid>
            <Grid container direction={"row"} justifyContent={"space-between"}>
              <Grid flex={1.5}>
                {contacts.map((item, index) => (
                  <MuiLink
                    key={item.key + index}
                    href={item.href}
                    underline={"hover"}
                    color={"inherit"}
                  >
                    <Typography sx={{display: "block"}} variant="caption2">
                      <Trans i18nKey={item.transKey}>{item.key}</Trans>
                      {": " + item.value}
                    </Typography>
                  </MuiLink>
                ))}
                <Typography sx={{mt: 1}} color="#205A9C" variant="caption2">
                  <Trans i18nKey="timeInNewYork">Time in New York </Trans>{" "}
                  {time}
                </Typography>
              </Grid>
              <Grid flex={1} mt={6}>
                {socialMediaLinks}
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    </MainLayout>
  );
};

export default GettingStarted;
