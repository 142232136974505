import {basePortalApi as api} from "../basePortalApi";
import {ContactAddressDto} from "../verification/verification-api";
import {ChargeDto} from "../order/order-api";
import {OrderDto} from "../order/order-api";
import {LinkDto} from "../verification/verification-api";
import {CommodityDto} from "../order/order-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    shipmentQuoteGetQuoteForOrder: build.mutation<
      ShipmentQuoteGetQuoteForOrderApiResponse,
      ShipmentQuoteGetQuoteForOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/shipmentQuotes/getQuoteForOrder`,
        method: "POST",
        body: queryArg.getQuoteForOrderCommandValues,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any | null;
};
export type WeightUnit = "Lb" | "Kg";
export type QuoteCommodityDto = {
  weight?: number;
  weightUnit?: WeightUnit;
};
export type QuoteAccountingItemDto = {
  accountingItemId?: number;
};
export type ShipmentQuoteGetQuoteForOrderApiResponse =
  /** status 200  */ QuoteOrderResponse;
export type ShipmentQuoteGetQuoteForOrderApiArg = {
  organizationId: number;
  getQuoteForOrderCommandValues: GetQuoteForOrderCommandValues;
};
export type QuoteOrderResponse = {
  routes?: QuoteRoute[] | null;
  quotes?: OrderDto[] | null;
  links?: LinkDto[] | null;
};
export type QuoteRoute = {
  route?: OrderRoute;
  results?: QuoteOrderResult[] | null;
};
export type OrderRoute = {
  seq?: number;
  origin?: ContactAddressDto | null;
  destination?: ContactAddressDto | null;
  carrierContactId?: number | null;
  modeOfTransportId?: number | null;
  rateId?: number | null;
  commodities?: CommodityDto[] | null;
};
export type QuoteOrderResult = {
  charges?: ChargeDto[] | null;
};
export type GetQuoteForOrderCommandValues = {
  order?: OrderDto;
  routes?: OrderRoute[] | null;
  additionalServices?: {
    [key: string]: number;
  } | null;
  container?: CommodityDto | null;
  rateEnableFieldName?: string | null;
};
export const {useShipmentQuoteGetQuoteForOrderMutation} = injectedRtkApi;
