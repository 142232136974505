import React, {useState} from "react";
import TableCell from "@mui/material/TableCell";
import {Box, IconButton, styled, Typography} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {getFormattedDate} from "../../../../utils/formatting.utils";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Collapse from "@mui/material/Collapse";
import {Trans, useTranslation} from "react-i18next";
import {NavLink, useParams} from "react-router-dom";
import {useParcelShipmentData} from "../hooks/useShipmentData";
import {CommodityDto, OrderGQLDto} from "../../../features/order/order-api";
import {getCustomValue} from "../../../../utils/helper.utils";
import {useAppSelector} from "../../../store";

const ShipmentStyledTableCell = styled(TableCell)({
  padding: "0.8rem 1.5rem",
});

const CommoditiesHeaderTableCell = styled(TableCell)({
  border: "none",
  color: "#65758D",
  padding: "0.38rem 1.375rem 0.395rem",
});

const CommoditiesStyledTableCell = styled(TableCell)({
  padding: "1.3rem 1.5rem",
  lineBreak: "anywhere",
});

interface ParcelShipmentTableRowProps {
  parcelShipment: OrderGQLDto;
}

export const getContainerTotalWeight = (
  commodity: CommodityDto | null | undefined,
) => {
  if (commodity && commodity.weightTotal && commodity.weightUnit) {
    return `${commodity.weightTotal} ${commodity.weightUnit}`;
  }
  return null;
};

export default function ParcelShipmentTableRow({
  parcelShipment,
}: ParcelShipmentTableRowProps) {
  const {lang} = useParams();
  const {t} = useTranslation();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const {invoices, statusColor, consignee} =
    useParcelShipmentData(parcelShipment);

  const showCommodityTrackingNumber = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules.parcels
        ?.showCommodityTrackingNumber,
  );

  let shipmentCommoditiesTableHeaders = [
    {label: t("parcelShipments.allParcels.itemName"), width: "30%"},
    {label: t("parcelShipments.allParcels.trackingNumber"), width: "40%"},
    {label: t("parcelShipments.allParcels.declaredValue"), width: "15%"},
    {label: t("parcelShipments.allParcels.parcelsQuantity"), width: "15%"},
  ];

  if (!showCommodityTrackingNumber) {
    shipmentCommoditiesTableHeaders = [
      {label: t("parcelShipments.allParcels.itemName"), width: "50%"},
      {label: t("parcelShipments.allParcels.declaredValue"), width: "25%"},
      {label: t("parcelShipments.allParcels.parcelsQuantity"), width: "25%"},
    ];
  }

  const handleOpen = () => {
    setIsOpened((prevState) => !prevState);
  };

  const invoicesTableCell = () => {
    const invoicesToShow = invoices?.filter(
      (invoice) => invoice.accountingTransactionId && invoice.transactionNumber,
    );
    return invoicesToShow?.length ? (
      invoicesToShow.map((invoice, index) => (
        <NavLink
          to={`../invoices/${invoice.accountingTransactionId}/tracking/${invoice.transactionNumber}`}
          data-testid={"link-to-order-details"}
          style={{
            textDecoration: "none",
          }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          key={`navlink-invoice-${index}`}
        >
          <Box
            component="span"
            sx={{
              lineBreak: "anywhere",
              color: "primary.main",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Typography variant="body1">
              {`${invoice.transactionNumber}${
                index < invoicesToShow.length - 1 ? ", " : ""
              }`}
            </Typography>
          </Box>
        </NavLink>
      ))
    ) : (
      <Typography variant="body1">-</Typography>
    );
  };

  return (
    <>
      <TableRow
        data-testid={`shipment-row-${parcelShipment.trackingNumber}`}
        sx={{
          border: "2px solid #D8E6FB",
          borderBottom: "none",
          boxShadow: "none",
          ".MuiTableCell-root": {border: 0},
          "& > *": {borderBottom: "unset"},
        }}
      >
        <ShipmentStyledTableCell component="th" scope="row">
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={handleOpen}
              sx={{marginLeft: "-8px", marginRight: "5px", padding: "5px"}}
            >
              <ArrowBackIosNewIcon
                color="primary"
                sx={{
                  fontSize: 20,
                  transform: isOpened ? "rotate(90deg)" : "rotate(270deg)",
                  transition: "transform 300ms ease",
                }}
              />
            </IconButton>
            <NavLink
              to={`./${parcelShipment?.orderId}`}
              data-testid={`link-to-order-details`}
              style={{
                textDecoration: "none",
              }}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Box
                component="span"
                sx={{
                  lineBreak: "anywhere",
                  color: "primary.main",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                <Typography data-testid={`shipment-number`} variant="body1">
                  {parcelShipment?.trackingNumber || "-"}
                </Typography>
              </Box>
            </NavLink>
          </Box>
        </ShipmentStyledTableCell>
        <ShipmentStyledTableCell
          component="th"
          scope="row"
          align="left"
          sx={{
            color: invoices ? "primary.main" : "inherit",
            lineBreak: "anywhere",
          }}
        >
          {invoicesTableCell()}
        </ShipmentStyledTableCell>
        <ShipmentStyledTableCell component="th" scope="row" align="left">
          <Typography data-testid={`shipment-creation-date`} variant="body1">
            {getFormattedDate(parcelShipment.created ?? "")}
          </Typography>
        </ShipmentStyledTableCell>
        <ShipmentStyledTableCell component="th" scope="row" align="left">
          <Typography data-testid={`mode-of-transportation`} variant="body1">
            {t(
              `transportMethods.${
                getCustomValue(
                  parcelShipment.customValues,
                  "modeOfTransportationName",
                ) ??
                getCustomValue(
                  parcelShipment.customValues,
                  "modeOfTransportationId",
                )
              }`,
            )}
          </Typography>
        </ShipmentStyledTableCell>
        <ShipmentStyledTableCell component="th" scope="row" align="left">
          <Typography data-testid={`shipment-destination`} variant="body1">
            {consignee?.contactAddress?.formattedAddress || "-"}
          </Typography>
        </ShipmentStyledTableCell>
        <ShipmentStyledTableCell component="th" scope="row" align="left">
          <Typography data-testid={`shipment-consignee`} variant="body1">
            {consignee?.contact?.name || "-"}
          </Typography>
        </ShipmentStyledTableCell>
        <ShipmentStyledTableCell component="th" scope="row" align="left">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography data-testid={`shipment-status`} variant="body1">
              {parcelShipment?.orderStatus?.customValues &&
              `portal_name_${lang}` in parcelShipment.orderStatus.customValues
                ? parcelShipment?.orderStatus?.customValues[
                    `portal_name_${lang}`
                  ]
                : parcelShipment?.orderStatus?.orderStatusName}
            </Typography>
            <Box
              sx={{
                borderRadius: "50%",
                backgroundColor: statusColor,
                width: "14px",
                minWidth: "14px",
                height: "14px",
                marginLeft: "10px",
              }}
            />
          </Box>
        </ShipmentStyledTableCell>
        <ShipmentStyledTableCell component="th" scope="row" align="left">
          <Typography data-testid={`shipment-status-date`} variant="body1">
            {getContainerTotalWeight(
              parcelShipment.commodities?.[0]?.commodity,
            ) ?? "-"}
          </Typography>
        </ShipmentStyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{padding: 0, margin: 0}} colSpan={200}>
          <Collapse in={isOpened} timeout={"auto"}>
            <Table
              sx={{
                ".MuiTableCell-root": {borderBottom: "none"},
                "&:last-child td": {
                  borderBottom: 0,
                },
                background: "#F5F9FF",
              }}
            >
              <TableHead
                sx={{
                  border: "2px solid #D8E6FB",
                  borderLeft: "none",
                  borderRight: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                <TableRow sx={{padding: 0, margin: 0}}>
                  {shipmentCommoditiesTableHeaders.map((header) => (
                    <CommoditiesHeaderTableCell
                      align="left"
                      key={header.label}
                      width={header.width}
                    >
                      <Typography variant="body4">{header.label}</Typography>
                    </CommoditiesHeaderTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {parcelShipment?.commodities?.length ? (
                  parcelShipment.commodities.map((container) =>
                    container.commodity?.containerCommodities?.length ? (
                      container.commodity?.containerCommodities.map(
                        (containerCommodity) => (
                          <TableRow
                            data-testid={`commodity-row-${containerCommodity.commodityId}`}
                            key={containerCommodity.commodityId}
                            sx={{
                              border: "2px solid #D8E6FB",
                              borderBottom: "none",
                              borderLeft: "none",
                              borderRight: "none",
                            }}
                          >
                            <CommoditiesStyledTableCell
                              component="th"
                              scope="row"
                              sx={{
                                color: "#205A9C",
                              }}
                            >
                              <Typography
                                data-testid={`commodity-item-name`}
                                variant="body1"
                              >
                                {containerCommodity.description}
                              </Typography>
                            </CommoditiesStyledTableCell>
                            {showCommodityTrackingNumber ? (
                              <CommoditiesStyledTableCell
                                component="th"
                                scope="row"
                                sx={{
                                  color: "#2F81EF",
                                }}
                              >
                                <Typography
                                  data-testid={`commodity-tracking-number`}
                                  variant="body1"
                                >
                                  {container?.commodity?.trackingNumbers?.find(
                                    (x) => x.isPrimary,
                                  )?.trackingNumber ?? "-"}
                                </Typography>
                              </CommoditiesStyledTableCell>
                            ) : null}

                            <CommoditiesStyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              sx={{
                                color: "#205A9C",
                              }}
                            >
                              <Typography
                                data-testid={`commodity-declared-value`}
                                variant="body4"
                              >
                                {containerCommodity?.quantity &&
                                containerCommodity?.unitaryValue
                                  ? "$" +
                                    (
                                      containerCommodity.quantity *
                                      containerCommodity.unitaryValue
                                    ).toFixed(2)
                                  : "-"}
                              </Typography>
                            </CommoditiesStyledTableCell>
                            <CommoditiesStyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              sx={{
                                color: "#205A9C",
                              }}
                            >
                              <Typography
                                data-testid={`commodity-shipment-quantity`}
                                variant="body4"
                              >
                                {containerCommodity?.quantity || "-"}
                              </Typography>
                            </CommoditiesStyledTableCell>
                          </TableRow>
                        ),
                      )
                    ) : (
                      <TableRow
                        key="noCommodities"
                        sx={{
                          border: "2px solid #D8E6FB",
                          borderBottom: "none",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        <CommoditiesStyledTableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: "#205A9C",
                          }}
                        >
                          <Typography variant="body3">
                            <Trans
                              i18nKey={
                                "parcelShipments.allParcels.noCommodities"
                              }
                            >
                              No items
                            </Trans>
                          </Typography>
                        </CommoditiesStyledTableCell>
                        <CommoditiesStyledTableCell />
                        <CommoditiesStyledTableCell />
                        <CommoditiesStyledTableCell />
                        <CommoditiesStyledTableCell />
                      </TableRow>
                    ),
                  )
                ) : (
                  <TableRow
                    sx={{
                      border: "2px solid #D8E6FB",
                      borderBottom: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    <CommoditiesStyledTableCell
                      component="th"
                      scope="row"
                      sx={{
                        color: "#205A9C",
                      }}
                    >
                      <Typography variant="body3">
                        <Trans
                          i18nKey={"parcelShipments.allParcels.noCommodities"}
                        >
                          No items
                        </Trans>
                      </Typography>
                    </CommoditiesStyledTableCell>
                    <CommoditiesStyledTableCell />
                    <CommoditiesStyledTableCell />
                    <CommoditiesStyledTableCell />
                    <CommoditiesStyledTableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
