import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    statesGet: build.mutation<StatesGetApiResponse, StatesGetApiArg>({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/states/getStates`,
        method: "POST",
        body: queryArg.getStatesQueryValues,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type StatesGetApiResponse = /** status 200  */ StateDtoPagedResult;
export type StatesGetApiArg = {
  organizationId: number;
  getStatesQueryValues: GetStatesQueryValues;
};
export type GetStatesQueryValues = {
  limit?: number | null;
  offset?: number | null;
  sort?: string;
  filter?: string;
  search?: string;
};
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type StateDto = {
  countryCode?: string | null;
  name?: string | null;
  stateCode?: string | null;
  organizationId?: number;
  links?: LinkDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
};
export type StateDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: StateDto[] | null;
  links?: LinkDto[] | null;
};

export const {useStatesGetMutation} = injectedRtkApi;
