import React, {useEffect, useState} from "react";
import {
  AccountingTransactionGqlDtoPagedResult,
  AccountingTransactionsGetAccountingTransactionsApiArg,
  GetAccountingTransactionsQueryValues,
  useAccountingTransactionsGetAccountingTransactionsGqlMutation,
} from "../../../features/accountingTransaction/accountingTransaction-api";
import {
  buildJobTypeFilter,
  QuerySearchParams,
  useQuery,
} from "../../../../utils/helper.utils";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useTranslation} from "react-i18next";
import Pagination from "../../../common/components/pagination";
import InvoiceTable from "./invoiceTable";
import {useAppSelector} from "../../../store";

export const INVOICES_QUERY_DEFAULT_OFFSET = "0";
export const INVOICES_QUERY_DEFAULT_LIMIT = "10";
export const INVOICES_QUERY_DEFAULT_FILTER =
  "accountingTransactionType:Invoice";

export default function InvoiceList() {
  const {t} = useTranslation();

  const jobTypes = useAppSelector(
    (state: any) => state.organizationConfigState?.modules.parcels?.jobType,
  );

  const {
    offset = INVOICES_QUERY_DEFAULT_OFFSET,
    limit = INVOICES_QUERY_DEFAULT_LIMIT,
    sort = "-created",
    filter = INVOICES_QUERY_DEFAULT_FILTER + buildJobTypeFilter(jobTypes),
    search = "",
  } = useQuery() as QuerySearchParams;

  const [invoices, setInvoices] =
    useState<AccountingTransactionGqlDtoPagedResult>();
  const [getAccountingTransactionsGql] =
    useAccountingTransactionsGetAccountingTransactionsGqlMutation();

  const getInvoices = async (request: GetAccountingTransactionsQueryValues) => {
    const commandArgs: AccountingTransactionsGetAccountingTransactionsApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      getAccountingTransactionsQueryValues: request,
    };
    return getAccountingTransactionsGql(commandArgs);
  };

  useEffect(() => {
    const getInvoicesAsync = async () => {
      const response: any = await getInvoices({
        offset: Number(offset),
        limit: Number(limit),
        sort,
        filter,
        search,
      });
      setInvoices(response.data?.accountingTransactions);
    };
    getInvoicesAsync();
  }, [offset, limit, sort, filter, search]);

  if (invoices?.items?.length === 0 || !invoices) {
    return (
      <>
        <Grid container justifyContent={"center"} my={5}>
          <Typography mb={2} variant={"h2"} color={"primary.dark"}>
            {!invoices ? t("loading") : t("invoices.noInvoices")}
          </Typography>
        </Grid>
      </>
    );
  }

  return (
    <Grid container flexDirection={"column"} position={"relative"}>
      <InvoiceTable items={invoices.items} />

      <Pagination
        limit={Number(limit)}
        offset={Number(offset)}
        sort={sort}
        filter={filter}
        search={search}
        totalCount={invoices?.totalCount}
      />
    </Grid>
  );
}
