import React from "react";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans} from "react-i18next";

export default function LinkExpired() {
  return (
    <Grid container columns={{xs: 6, md: 6}}>
      <Grid md />
      <Grid xs={6} md={4}>
        <Grid container direction="column">
          <Typography
            variant="h1"
            component="h1"
            sx={{fontWeight: "bold", mt: 5, mb: 1}}
          >
            <Trans i18nKey="error">Error</Trans>
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{fontWeight: "light", color: "primary.dark", mb: 5}}
          >
            <Trans i18nKey="linkHasExpired">
              The link has expired. Please request a new one.
            </Trans>
          </Typography>
        </Grid>
        <Grid
          xs={6}
          sx={{
            position: {xs: "fixed", md: "inherit"},
            bottom: {xs: 0},
            pr: {xs: 4, md: 0},
          }}
        >
          <Button
            fullWidth
            sx={{p: 1, mb: 2, display: "flex"}}
            type="button"
            variant="outlined"
            color="primary"
            href="../../login"
          >
            <Trans i18nKey="backToLogin">Back to Login</Trans>
          </Button>
        </Grid>
      </Grid>
      <Grid md />
    </Grid>
  );
}
