import React, {useState} from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {DoubleCardProps} from "./interfaces";
import {Typography} from "@mui/material";

export default function DoubleCard({
  variant,
  leftLabel,
  leftContent,
  rightLabel,
  rightContent,
  initialSelected,
  bgColorActive,
  bgColorInactive,
  bgColorDisabled = "#EAEBEE",
  lineColor,
  disableRight = false,
  onClickLeft,
  onClickRight,
  contentSx = {},
}: DoubleCardProps) {
  const [selected, setSelected] = useState(initialSelected);

  const labelPadding = variant == "normal" ? "15px 15px" : "10px 10px";
  const contentPadding = variant == "normal" ? "20px 40px 40px" : "16px";
  const borderWidth = variant == "normal" ? "1px" : "0px";
  const borderRadius = "16px";

  const activeTabSx = {
    width: "50%",
    position: "relative",
    padding: labelPadding,
    backgroundColor: bgColorActive,
    border: "solid",
    borderWidth: borderWidth,
    borderColor: lineColor,
    borderBottomColor: bgColorActive,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    zIndex: "2",
  };
  const inactiveTabSx = {
    width: "50%",
    position: "relative",
    padding: labelPadding,
    backgroundColor: bgColorInactive,
    border: "solid",
    borderWidth: borderWidth,
    borderColor: "#00000000",
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    cursor: "pointer",
    zIndex: "1",
  };

  const disabledTabSx = {
    width: "50%",
    position: "relative",
    padding: labelPadding,
    backgroundColor: bgColorDisabled,
    border: "solid",
    borderWidth: borderWidth,
    borderColor: "#00000000",
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    pointerEvents: "none",
    zIndex: "1",
  };

  return (
    <Grid
      position="relative"
      container
      sx={{
        backgroundColor: disableRight ? bgColorDisabled : bgColorInactive,
        borderRadius: borderRadius,
      }}
    >
      <Grid
        textAlign="center"
        width={"50%"}
        sx={selected == 1 ? activeTabSx : inactiveTabSx}
        onClick={() => {
          setSelected(1);
          if (onClickLeft) {
            onClickLeft();
          }
        }}
        data-testid="sel-left"
      >
        <Typography variant="body4" lineHeight="20px">
          {leftLabel}
        </Typography>
        <Grid
          display={selected == 1 ? "block" : "none"}
          sx={{
            position: "absolute",
            right: `-${borderRadius}`,
            bottom: `-${borderWidth}`,
            height: borderRadius,
            width: borderRadius,
            backgroundColor: bgColorActive,
            pointerEvents: "none",
            zIndex: "3",
          }}
        />
        <Grid
          display={selected == 1 ? "block" : "none"}
          sx={{
            position: "absolute",
            right: `-${borderRadius}`,
            bottom: `-${borderWidth}`,
            height: borderRadius,
            width: borderRadius,
            backgroundColor: disableRight ? bgColorDisabled : bgColorInactive,
            borderBottom: "solid",
            borderLeft: "solid",
            borderWidth: borderWidth,
            borderColor: lineColor,
            borderBottomLeftRadius: borderRadius,
            pointerEvents: "none",
            zIndex: "4",
          }}
        />
      </Grid>
      <Grid
        width={"50%"}
        textAlign="center"
        sx={
          disableRight
            ? disabledTabSx
            : selected == 2
            ? activeTabSx
            : inactiveTabSx
        }
        onClick={() => {
          setSelected(2);
          if (onClickRight) {
            onClickRight();
          }
        }}
        data-testid="sel-right"
      >
        <Typography variant="body4" lineHeight="20px">
          {rightLabel}
        </Typography>
        <Grid
          display={selected == 2 ? "block" : "none"}
          sx={{
            position: "absolute",
            left: `-${borderRadius}`,
            bottom: `-${borderWidth}`,
            height: borderRadius,
            width: borderRadius,
            backgroundColor: bgColorActive,
            pointerEvents: "none",
            zIndex: "3",
          }}
        />
        <Grid
          display={selected == 2 ? "block" : "none"}
          sx={{
            position: "absolute",
            left: `-${borderRadius}`,
            bottom: `-${borderWidth}`,
            height: borderRadius,
            width: borderRadius,
            backgroundColor: bgColorInactive,
            borderBottom: "solid",
            borderRight: "solid",
            borderWidth: borderWidth,
            borderColor: lineColor,
            borderBottomRightRadius: borderRadius,
            pointerEvents: "none",
            zIndex: "4",
          }}
        />
      </Grid>
      <Grid
        xs={6}
        md={12}
        position="relative"
        top={`-${borderWidth}`}
        width="100%"
        padding={contentPadding}
        sx={{
          backgroundColor: bgColorActive,
          border: "solid",
          borderWidth: borderWidth,
          borderColor: lineColor,
          borderTopLeftRadius: selected == 1 ? "0px" : borderRadius,
          borderTopRightRadius: selected == 2 ? "0px" : borderRadius,
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: borderRadius,
          zIndex: "1",
          ...contentSx,
        }}
      >
        <Grid display={selected == 1 ? "block" : "none"}>{leftContent}</Grid>
        <Grid display={selected == 2 ? "block" : "none"}>{rightContent}</Grid>
      </Grid>
    </Grid>
  );
}
