import * as React from "react";
import {ReactComponent as Logo} from "../../../../images/TRTLogo.svg";
import Grid from "@mui/material/Unstable_Grid2";
import LanguageSelect from "../../LanguageSelect/components";
import HeaderMenu from "../../components/headerMenu";
import ProfileMenu from "../../components/profileMenu";
import Link from "../../components/link";

export default function MainPageHeader({
  backLink,
  showPrev,
  showPaymentMethod,
}: any) {
  return (
    <Grid
      display={{xs: "none", md: "flex"}}
      sx={{height: "82px", backgroundColor: "primary.main"}}
      container
      columns={{xs: 6, md: 14}}
      alignItems={"center"}
      position={"sticky"}
      top={"0px"}
      zIndex={1300}
    >
      <Grid
        mdOffset={1}
        md={9}
        display={"flex"}
        alignItems={"center"}
        minWidth={650}
      >
        <Grid style={{marginRight: "4rem"}}>
          <Link to="../getting-started">
            <Grid container alignItems="center">
              <Logo height={38} width={65} style={{minWidth: 65}} fill="#FFF" />
            </Grid>
          </Link>
        </Grid>
        <HeaderMenu />
      </Grid>
      <Grid md={2}>
        <ProfileMenu showPaymentMethod={showPaymentMethod} />
      </Grid>
      <Grid md>
        <LanguageSelect />
      </Grid>
    </Grid>
  );
}
