import _ from "lodash";

export const checkObjectChangeByPath = (
  prevState: any,
  currentState: any,
  path: string,
) => {
  const prevValue = _.get(prevState, path);
  const currentValue = _.get(currentState, path);
  return !_.isEqual(prevValue, currentValue);
};
