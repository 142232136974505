import React, {useState} from "react";
import {
  Box,
  Button,
  Link as MuiLink,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "../../common/components/link";
import MainLayout from "../../common/MainLayout/components/mainLayout";
import WelcomeImg from "../../../images/gettingStarted/getting-started-5.png";
import DeliveryImg from "../../../images/gettingStarted/getting-started-2.png";
import {Trans} from "react-i18next";
import {HowItWorks} from "../components/howItWorks";
import {CountriesList} from "../components/countriesList";
import i18next from "i18next";
import AddressBox from "../../common/components/addressBox";
import {socialMediaLinks} from "../../common/footers/components/mainPageFooter";
import {getContacts} from "../../common/data/contacts";
import {getAddresses} from "../../common/data/addresses";
import {GettingStartedConfiguration} from "../getting-started.module";
import {RootState, useAppSelector} from "../../store";
import {getCustomValue} from "../../../utils/helper.utils";

const date = new Date();
const time = date.toLocaleTimeString("en-US", {
  timeZone: "America/New_York",
  hour: "2-digit",
  minute: "2-digit",
});

type ParcelGettingStartedProps = {
  config: GettingStartedConfiguration;
};

export const ParcelGettingStarted = ({config}: ParcelGettingStartedProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isScreenSm = useMediaQuery(theme.breakpoints.up("sm"));

  const i18nKeyPrefix = "gettingStarted.";

  const trackingNumberMaxLength = 50;
  const [trackingNumber, setTrackingNumber] = useState("");
  const contacts = getContacts();
  const addresses = getAddresses();
  const footerConfig = useAppSelector(
    (state: RootState) => state.organizationConfigState.modules.footer,
  );

  const howItWorksSteps = [
    {
      label: "1",
      title: "Get your package ready",
      i18nKey: "getYourPackage",
      paragraph:
        "Measure the dimensions and weight of your parcel. Ensure your items are well-protected with sufficient cushioning materials to prevent any damage during transit.",
    },
    {
      label: "2",
      title: "Provide Package Details",
      i18nKey: "enterPackageDetails",
      paragraph:
        "Input essential details such as the shipper and recipient addresses. Complete the customs declaration form if required.",
    },
    {
      label: "3",
      title: "Select Shipping Options & Make Payment",
      i18nKey: "selectShippingOptions",
      paragraph:
        "Choose the most suitable shipping method for your needs and proceed with the payment.",
    },
    {
      label: "4",
      title: "Print & Attach Shipping Label:",
      i18nKey: "printYourLabel",
      paragraph:
        "Easily print out the shipping label and securely attach it to your parcel.",
    },
    {
      label: "5",
      title: "Drop Off or Schedule a Pick-Up",
      i18nKey: "bringYourBox",
      paragraph:
        "Drop your package at our nearest location or opt for the convenience of scheduling a pick-up service if available in your area.",
    },
  ];

  return (
    <MainLayout fullWidth pageTitle="Getting Started">
      <>
        <Grid
          display={"flex"}
          flexDirection={{xs: "column-reverse", md: "row"}}
          sx={{
            backgroundColor: theme.palette.info.background,
            height: "auto",
            py: {xs: 0, md: 4},
          }}
          px={{xs: 2, md: 0}}
        >
          <Grid
            md={6}
            mdOffset={1}
            pr={{md: 5}}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "center",
            }}
          >
            <Typography variant="h1" mb={2}>
              <Trans i18nKey={i18nKeyPrefix + "shipYourParcels"}>
                Ship your packages with us online.
              </Trans>
            </Typography>
            <Typography variant="body2" mb={4}>
              <Trans i18nKey={i18nKeyPrefix + "shipYourParcelsDesc"}>
                Easily create and print your shipping label, send your parcel
                through our reliable service, and enjoy real-time tracking until
                it reaches its destination.
              </Trans>
            </Typography>
            <Grid
              display={"flex"}
              flexWrap={{xs: "wrap", sm: "nowrap", md: "wrap"}}
              justifyContent={{xs: "center", md: "flex-start"}}
              alignItems={"center"}
              rowGap={2}
              columnGap={2}
            >
              <Grid xs={4} sm={4} md={14} lg={8} xl={7} width={"100%"}>
                <Link to={`../delivery/new/shippingAddress`}>
                  <Button
                    color={"secondary"}
                    variant={"contained"}
                    sx={{
                      paddingX: 5,
                      textTransform: "none",
                      width: "100%",
                      lineHeight: "18px",
                    }}
                    data-testid={"gettingStarted-printlabel-button"}
                  >
                    <Trans i18nKey={"createParcelShipment"}>
                      Create Parcel Shipment
                    </Trans>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            md={6}
            xs={6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            mb={{xs: 2, md: 0}}
          >
            <img
              src={WelcomeImg}
              alt={"Welcome Image"}
              width={"100%"}
              height={"auto"}
              style={{maxHeight: "500px"}}
            />
          </Grid>
        </Grid>
        {/* Welcome to TRT */}

        {/* Learn how it works */}
        <Grid pt={7} pb={9} px={{xs: 2, md: 0}}>
          <Typography variant="h1" textAlign={"center"} mb={2}>
            <Trans i18nKey={i18nKeyPrefix + "howItWorks.title"}>
              How it works
            </Trans>
          </Typography>
          <HowItWorks
            i18nKeyPrefix={i18nKeyPrefix + "howItWorks."}
            steps={howItWorksSteps}
            isDesktop={isDesktop}
          />
        </Grid>
        {/* Learn how it works */}

        {/* Delivery countries */}
        <Grid
          pt={4}
          sx={{backgroundColor: theme.palette.info.background}}
          display={"flex"}
          flexDirection={{md: "row", xs: "column"}}
          px={{xs: 2, md: 0}}
        >
          <Grid md={4} mt={-8} mdOffset={1}>
            <img
              width={"100%"}
              height={"auto"}
              src={DeliveryImg}
              alt={"delivery countries"}
            />
          </Grid>
          <Grid md={8} pl={{xs: 0, md: 4}} pt={4}>
            <Typography variant={"h2"} mb={2}>
              <Trans i18nKey={i18nKeyPrefix + "deliveryCountries"}>
                Delivery countries
              </Trans>
            </Typography>
            <CountriesList />
            <Typography variant={"h3"} mb={2}>
              <Trans i18nKey={i18nKeyPrefix + "deliveryCountriesProhibited"}>
                Don&apos;t know if your item is deliverable?
              </Trans>
              &nbsp;
              <MuiLink
                display={"block"}
                underline="none"
                target="_blank"
                rel="noopener"
                href={
                  getCustomValue(
                    config?.prohibitedItemsLink,
                    i18next.language,
                  ) ?? ""
                }
              >
                <Trans i18nKey={i18nKeyPrefix + "seeProhibitedItemsList"}>
                  See prohibited items list
                </Trans>
              </MuiLink>
            </Typography>
          </Grid>
        </Grid>
        {/* Delivery countries */}

        {/* Address Cards */}
        {!isDesktop && footerConfig?.showAddresses && (
          <Grid sx={{backgroundColor: theme.palette.info.background}} p={2}>
            <Grid pb={2}>
              <Typography variant="h3">
                <Trans i18nKey="copyYourAddress">Contact Us</Trans>
              </Typography>
            </Grid>
            <Grid
              container
              direction={"column"}
              justifyContent={"space-between"}
              spacing={1}
            >
              {addresses.map((address, index) => {
                return (
                  <Grid flex={1} key={index}>
                    <AddressBox address={address} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}

        {/* Useful links */}
        {!isDesktop && footerConfig?.usefulLinks?.length > 0 && (
          <Box p={2}>
            <Typography sx={{mb: "11px"}} variant="h3" color="#2F81EF">
              <Trans i18nKey={i18nKeyPrefix + "footer.usefulLinks"}>
                Useful Links
              </Trans>
            </Typography>
            {footerConfig.usefulLinks.map((item: any, index: number) => (
              <MuiLink
                key={item.key + index}
                href={item.href}
                underline={"hover"}
                color={"inherit"}
                target={"_blank"}
                width={"max-content"}
                display={"flex"}
              >
                <Typography
                  key={`${item}-${index}`}
                  sx={{display: "block"}}
                  variant="caption2"
                >
                  <Trans i18nKey={i18nKeyPrefix + "footer." + item.key}>
                    {item.key}
                  </Trans>
                </Typography>
              </MuiLink>
            ))}
          </Box>
        )}

        {/* Address */}
        {!isDesktop && footerConfig?.contactAddress?.length > 0 && (
          <Box p={2}>
            <Typography sx={{mb: "11px"}} variant="h3" color="#2F81EF">
              <Trans i18nKey={i18nKeyPrefix + "footer.address"}>Address</Trans>
            </Typography>
            {footerConfig.contactAddress.map((item: any, index: number) => (
              <Typography
                key={`${item}-${index}`}
                sx={{display: "block"}}
                variant="caption2"
              >
                {item}
              </Typography>
            ))}
          </Box>
        )}

        {/* Contact Us */}
        {!isDesktop && (
          <Grid sx={{backgroundColor: theme.palette.info.background}} p={2}>
            <Grid pb={1}>
              <Typography variant="h3" color="#2F81EF">
                <Trans i18nKey="contactUs">Contact Us</Trans>
              </Typography>
            </Grid>
            <Grid container direction={"row"} justifyContent={"space-between"}>
              <Grid flex={1.5}>
                {contacts.map((item, index) => (
                  <MuiLink
                    key={item.key + index}
                    href={item.href}
                    underline={"hover"}
                    color={"initial"}
                  >
                    <Typography sx={{display: "block"}} variant="caption2">
                      <Trans i18nKey={item.transKey}>{item.key}</Trans>
                      {": " + item.value}
                    </Typography>
                  </MuiLink>
                ))}
                <Typography sx={{mt: 1}} color="#205A9C" variant="caption2">
                  <Trans i18nKey="timeInNewYork">Time in New York </Trans>{" "}
                  {time}
                </Typography>
              </Grid>
            </Grid>
            <Grid mt={6}>{socialMediaLinks}</Grid>
          </Grid>
        )}
      </>
    </MainLayout>
  );
};
