import {toastError} from "../../common/utils/toastMessages";
import {basePortalApi as api} from "../basePortalApi";
import {CityDto} from "../cities/cities-api";
import {CountryDto} from "../countries/countries-api";
import {StateDto} from "../states/states-api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    contactAddressGetContactAddress: build.query<
      ContactAddressGetContactAddressApiResponse,
      ContactAddressGetContactAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/getContactAddress`,
        params: {contactAddressId: queryArg.contactAddressId},
      }),
    }),
    contactAddressGetPickupLocations: build.query<
      ContactAddressGetPickupLocationsApiResponse,
      ContactAddressGetPickupLocationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/getPickupLocations`,
      }),
    }),
    contactAddressUpdateContactAddress: build.mutation<
      ContactAddressUpdateContactAddressApiResponse,
      ContactAddressUpdateContactAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/${queryArg.contactAddressId}`,
        method: "PUT",
        body: queryArg.updateContactAddressCommandValues,
      }),
    }),
    contactGetContact: build.mutation<
      ContactGetContactApiResponse,
      ContactGetContactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contacts/${queryArg.contactId}`,
        method: "GET",
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    contactGetContacts: build.query<
      ContactGetContactsApiResponse,
      ContactGetContactsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contacts/getContacts`,
        params: {filter: queryArg.filter, sort: queryArg.sort},
      }),
    }),
    contactGetChildContacts: build.query<
      ContactGetChildContactsApiResponse,
      ContactGetChildContactsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contacts/${queryArg.contactId}/childContacts`,
        params: {filter: queryArg.filter},
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    contactCreateContact: build.mutation<
      ContactCreateContactApiResponse,
      ContactCreateContactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contacts/createContact`,
        method: "POST",
        body: queryArg.createContactCommand,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    contactUpdateContact: build.mutation<
      ContactUpdateContactApiResponse,
      ContactUpdateContactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contacts/${queryArg.contactId}`,
        method: "PUT",
        body: queryArg.updateContactCommandValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    contactPaymentMethodGetContactPaymentMethods: build.query<
      ContactPaymentMethodGetContactPaymentMethodsApiResponse,
      ContactPaymentMethodGetContactPaymentMethodsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactPaymentMethods`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          Sort: queryArg.sort,
          Filter: queryArg.filter,
          Search: queryArg.search,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type ContactAddressGetContactAddressApiResponse =
  /** status 200  */ ContactAddressDto;
export type ContactAddressGetContactAddressApiArg = {
  organizationId: number;
  contactAddressId?: number;
};
export type ContactAddressGetPickupLocationsApiResponse =
  /** status 200  */ ContactAddressDto[];
export type ContactAddressGetPickupLocationsApiArg = {
  organizationId: number;
};
export type ContactAddressUpdateContactAddressApiResponse =
  /** status 204  */ undefined;
export type ContactAddressUpdateContactAddressApiArg = {
  organizationId: number;
  contactAddressId: number;
  updateContactAddressCommandValues: UpdateContactAddressCommandValues;
};
export type ContactGetContactApiResponse = /** status 200  */ GetContactDto;
export type ContactGetContactApiArg = {
  organizationId: number;
  contactId: number;
};
export type ContactGetContactsApiResponse = ContactDtoPagedResult;
export type ContactGetContactsApiArg = {
  organizationId: number;
  filter?: string | null;
  sort?: string | null;
};
export type ContactGetChildContactsApiResponse =
  /** status 200  */ ContactAddressDtoPagedResult;
export type ContactGetChildContactsApiArg = {
  organizationId: number;
  contactId: number;
  filter?: string | null;
};
export type ContactCreateContactApiResponse =
  /** status 200  */ CreateContactDto;
export type ContactCreateContactApiArg = {
  organizationId: number;
  createContactCommand: CreateContactCommand;
};
export type ContactUpdateContactApiResponse = /** status 200  */ unknown;
export type ContactUpdateContactApiArg = {
  organizationId: number;
  contactId: number;
  updateContactCommandValues: UpdateContactCommandValues;
};
export type ContactPaymentMethodGetContactPaymentMethodsApiResponse =
  /** status 200  */ ContactPaymentMethodDtoPagedResult;
export type ContactPaymentMethodGetContactPaymentMethodsApiArg = {
  organizationId: number;
  limit?: number | null;
  offset?: number | null;
  sort?: string | null;
  filter?: string | null;
  search?: string | null;
};
export type AddressType = "Billing" | "Shipping" | "Other";
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type ContactType =
  | "Customer"
  | "Carrier"
  | "Vendor"
  | "Contact"
  | "Driver"
  | "Employee"
  | "SalesPerson"
  | "ForwardingAgent"
  | "FactoringCompany"
  | "Lead";

export enum ContactTypes {
  "Customer" = 1,
  "Carrier",
  "Vendor",
  "Contact",
  "Driver",
  "Employee",
  "SalesPerson",
  "ForwardingAgent",
  "FactoringCompany",
}

export type IdNumberType = "EIN" | "DUNS" | "ForeignEntityId" | "Other";
export type ContactLinkType =
  | "ParentContact"
  | "FactoringCompany"
  | "SalesPerson";
export type VisibleTransactions =
  | "AllTransactions"
  | "DivisionOnly"
  | "DivisionAndDivisionless";
export type ApplicationUserRoleDto = {
  id?: string | null;
  name?: string | null;
  normalizedName?: string | null;
};
export type UserGroupDto = {
  userGroupId?: number;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  name?: string | null;
  organizationId?: number;
  userRoles?: ApplicationUserRoleDto[] | null;
  links?: LinkDto[] | null;
};
export type UserEmployeeDto = {
  userEmployeeId?: number;
  created?: string;
  createdBy?: string | null;
  employeeContactId?: number;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  userId?: string | null;
  visibleTransactions?: VisibleTransactions;
  employeeContactFirstName?: string | null;
  employeeContactLastName?: string | null;
  employeeName?: string | null;
  userFirstName?: string | null;
  userLastName?: string | null;
  userNormalizedUserName?: string | null;
  userUserName?: string | null;
  employeeDivisionId?: number;
  userGroups?: UserGroupDto[] | null;
  links?: LinkDto[] | null;
};
export type PaidAs = "Prepaid" | "Collect";
export type CarrierEquipmentDto = {
  carrierId?: number;
  equipmentTypeId?: number;
  quantity?: number;
};
export type ContactDto = {
  contactAddresses?: ContactAddressDto[] | null;
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  linkToContactId?: number | null;
  linkToContactName?: string | null;
  contactLinkType?: ContactLinkType;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto | null;
  creditLimit?: number | null;
  paidAs?: PaidAs;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export type ContactAddressDto = {
  contactAddressId?: number;
  addressLine?: string | null;
  addressLine2?: string | null;
  addressType?: AddressType;
  cityName?: string | null;
  city?: CityDto;
  contactId?: number;
  countryCode?: string | null;
  countryName?: string | null;
  country?: CountryDto;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  postalCode?: string | null;
  stateCode?: string | null;
  stateName?: string | null;
  state?: StateDto;
  links?: LinkDto[] | null;
  longitude?: number;
  latitude?: number;
  customValues?: {
    [key: string]: string;
  } | null;
  contact?: ContactDto | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  extensions?: {
    [key: string]: any;
  };
  [key: string]: any | null;
};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any | null;
};
export type UpdateContactAddressCommandValues = {
  addressLine?: string;
  addressLine2?: string | null;
  cityName?: string | null;
  countryCode?: string | null;
  postalCode?: string | null;
  stateCode?: string | null;
};
export type GetContactDto = {
  contactAddresses?: ContactAddressDto[] | null;
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  linkToContactId?: number | null;
  linkToContactName?: string | null;
  contactLinkType?: ContactLinkType | null;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto | null;
  creditLimit?: number | null;
  paidAs?: PaidAs;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export type ContactDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: ContactDto[] | null;
  links?: LinkDto[] | null;
};
export type HttpValidationProblemDetails = ProblemDetails & {
  errors?: {
    [key: string]: string[];
  };
  [key: string]: any | null;
};
export type ValidationProblemDetails = HttpValidationProblemDetails & {
  errors?: {
    [key: string]: string[];
  };
  [key: string]: any | null;
};
export type ContactAddressDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: ContactAddressDto[] | null;
  links?: LinkDto[] | null;
};
export type PaymentTermDto = {
  paymentTermId?: number;
  description?: string | null;
  discountPaidWithinDays?: number | null;
  discountPercentage?: number | null;
  isInactive?: boolean;
  netDueDays?: number;
  organizationId?: number;
  links?: LinkDto[] | null;
};
export type CreateContactDto = {
  contactAddresses?: ContactAddressDto[] | null;
  paymentTerm?: PaymentTermDto | null;
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  linkToContactId?: number | null;
  linkToContactName?: string | null;
  contactLinkType?: ContactLinkType;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto | null;
  creditLimit?: number | null;
  paidAs?: PaidAs | null;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export type CreateContactAddressCommandValues = {
  contactAddressId?: number | null;
  countryCode?: string | null;
  regionCode?: string | null;
  district?: string | null;
  cityName?: string | null;
  postalCode?: string | null;
  streetName?: string | null;
  houseNumber?: string | null;
  apartment?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  customValues?: {
    [key: string]: string | null;
  } | null;
};
export type CreateContactCommandValues = {
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  contactType?: ContactType | null;
  contactLinkType?: ContactLinkType | null;
  linkToContactId?: number | null;
  setAsDefault: boolean | null;
  shippingAddress?: CreateContactAddressCommandValues | null;
};
export type UpdateContactCommandValues = {
  contactId?: number;
  customValues?: ContactCustomValues;
};
export type CreateContactCommand = {
  organizationId?: number;
  divisionId?: number;
  values?: CreateContactCommandValues;
};

class ContactCustomValues {
  default_contact_address_ID?: string | null;
  default_payment_id?: string | null;
  autoPay?: boolean | null;
}

export type PaymentType = "Card";
export type ContactPaymentMethodDto = {
  cardHolderName: string;
  contactPaymentMethodId?: string | null;
  contactId?: number;
  customerId?: string;
  description?: string;
  expDate?: string;
  paymentType?: PaymentType;
  customValues?: {
    [key: string]: string;
  } | null;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  isVerified?: boolean | null;
  isDeleted?: boolean | null;
};
export type ContactPaymentMethodDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: ContactPaymentMethodDto[] | null;
  links?: LinkDto[] | null;
};
export type GetContactPaymentMethodsQueryValues = {
  limit?: number | null;
  offset?: number | null;
  sort?: string;
  filter?: string;
  search?: string;
};
export type GetContactPaymentMethodsQuery = {
  organizationId?: number;
  values?: GetContactPaymentMethodsQueryValues;
};
export const {
  useContactAddressGetContactAddressQuery,
  useContactAddressGetPickupLocationsQuery,
  useContactAddressUpdateContactAddressMutation,
  useContactGetContactMutation,
  useLazyContactGetChildContactsQuery,
  useContactCreateContactMutation,
  useContactUpdateContactMutation,
  useContactGetContactsQuery,
  useContactPaymentMethodGetContactPaymentMethodsQuery,
} = injectedRtkApi;
