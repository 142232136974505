import {
  useModeOfTransportationsGetModeOfTransportationQuery,
  ModeOfTransportationsGetModeOfTransportationApiArg,
} from "../../../features/modeOfTransportation/modeOfTransportation-api";

export const useGetModeOftransportation = (
  modeOfTransportationId: string | undefined,
) => {
  let modeOfTransportation;

  if (modeOfTransportationId) {
    const args: ModeOfTransportationsGetModeOfTransportationApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      modeOfTransportationId: Number(modeOfTransportationId),
    };

    modeOfTransportation =
      useModeOfTransportationsGetModeOfTransportationQuery(args).currentData;
  }

  return modeOfTransportation;
};
