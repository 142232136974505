import React from "react";
import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CheckCircleIcon from "../../../images/CheckCircle.svg";
import CheckCircleOutlineIcon from "../../../images/CheckCircleOutline.svg";
import UncheckCircleIcon from "../../../images/UncheckCircle.svg";
import {LinearCheckProgressStepProps} from "./interfaces";

export default function LinearCheckProgressStep({
  sizeList,
  stepIndex,
  setStep,
  orderStep,
  steps,
}: LinearCheckProgressStepProps) {
  const columns = sizeList.reduce((sum, val) => sum + val, 0);
  const stepGrids = steps?.map((s, i) => {
    const isClickable =
      i !== stepIndex && orderStep !== undefined && i + 1 <= orderStep;

    const wordGrids = s.text.split(" ").map((word, j) => {
      return (
        <Grid key={j}>
          <Typography
            variant="body1"
            color={isClickable ? "primary.main" : "#6C757D"}
            sx={{fontSize: {md: "0.75rem", lg: "1rem"}}}
          >
            {word}
          </Typography>
        </Grid>
      );
    });

    return (
      <Grid xs={sizeList[i]} key={i}>
        <Grid
          data-testid={`progress-step-${steps[i].name}`}
          data-step={i}
          container
          alignItems="center"
          justifyContent="center"
          wrap="nowrap"
          onClick={isClickable ? () => setStep(i) : undefined}
        >
          <Grid
            sx={{
              minWidth: "24px",
              cursor: isClickable ? "pointer" : "default",
              pr: 1,
            }}
          >
            <img
              src={
                (orderStep !== undefined && i < orderStep - 1) || i < stepIndex
                  ? CheckCircleIcon
                  : i == stepIndex || i + 1 == orderStep
                  ? CheckCircleOutlineIcon
                  : UncheckCircleIcon
              }
              style={{verticalAlign: "middle"}}
            />
          </Grid>
          <Grid
            sx={{
              cursor: isClickable ? "pointer" : "default",
            }}
            onClick={isClickable ? () => setStep(i) : undefined}
          >
            <Grid container direction="column">
              {wordGrids}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  const lines = sizeList.map((size, i) => {
    return (
      <Grid xs={sizeList[i]} key={i}>
        <Box
          display={i == stepIndex ? "block" : "none"}
          sx={{height: "3px", backgroundColor: "primary.main"}}
        />
      </Grid>
    );
  });

  return (
    <Grid container direction="column" rowGap={2}>
      <Grid>
        <Grid container columns={columns} alignItems="center">
          {stepGrids}
        </Grid>
      </Grid>
      <Grid>
        <Grid container columns={columns}>
          {lines}
        </Grid>
      </Grid>
    </Grid>
  );
}
