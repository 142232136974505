import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box} from "@mui/material";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ProfileTabMenu from "../../components/profileTabMenu";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import AccountSavedAddressesForm from "../components";
import {useTranslation} from "react-i18next";

export enum PageFragments {
  SavedAddressesList = 1,
  PickupLocationsList,
  AddingAddress,
  EditingAddress,
  AddingPickupLocation,
  EditingPickupLocation,
  DeletingAddress,
}

const getPageTitle = (
  pageFragment: PageFragments,
  t: (text: string) => string,
): string => {
  switch (pageFragment) {
    case PageFragments.AddingAddress:
      return t("profile.savedAddresses.titles.addingAddress");
    case PageFragments.EditingAddress:
      return t("profile.savedAddresses.titles.editingAddress");
    case PageFragments.AddingPickupLocation:
      return t("profile.savedAddresses.titles.addingPickupLocation");
    case PageFragments.EditingPickupLocation:
      return t("profile.savedAddresses.titles.editingPickupLocation");
    default:
      return t("profile.savedAddresses.titles.savedAddresses");
  }
};

const getBackLink = (pageFragment: PageFragments): string => {
  if (
    pageFragment === PageFragments.SavedAddressesList ||
    pageFragment === PageFragments.PickupLocationsList
  ) {
    return "../profile";
  }
  return "./";
};

const AccountSavedAddresses = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [pageFragment, setPageFragment] = useState<PageFragments>(
    PageFragments.SavedAddressesList,
  );
  const [triggerHandleBack, setTriggerHandleBack] = useState<
    boolean | undefined
  >(undefined);
  const [triggerContactsRefetch, setTriggerContactsRefetch] = useState<
    boolean | undefined
  >(undefined);

  const handlePrev = () => {
    setTriggerHandleBack((prevState) => !prevState);
    if (
      pageFragment === PageFragments.SavedAddressesList ||
      pageFragment === PageFragments.PickupLocationsList
    ) {
      navigate("../profile");
      return;
    }
    onChangePageState(PageFragments.SavedAddressesList);
  };

  const onChangePageState = (nextPageFragment: PageFragments) => {
    if (
      nextPageFragment !== pageFragment &&
      nextPageFragment === PageFragments.SavedAddressesList
    ) {
      setTriggerContactsRefetch((prevState) => !prevState);
    }

    setPageFragment(nextPageFragment);
  };

  return (
    <MainLayout
      backLink="../profile"
      handlePrevDefault={handlePrev}
      statusBar={<ProfileTabMenu selectedTab={2} />}
      pageTitle="Saved Addresses"
    >
      <ContentLayout
        title={<Box>{getPageTitle(pageFragment, t)}</Box>}
        backLink={getBackLink(pageFragment)}
        handlePrev={handlePrev}
        content={
          <AccountSavedAddressesForm
            onChangePageState={onChangePageState}
            triggerHandleBack={triggerHandleBack}
            triggerContactsRefetch={triggerContactsRefetch}
          />
        }
        containerSx={{mb: "24px"}}
        mobileBoxSx={{paddingX: 0}}
      />
    </MainLayout>
  );
};

export default AccountSavedAddresses;
