import React from "react";
import {Button, SxProps, useTheme, useMediaQuery} from "@mui/material";
import Link from "../../common/components/link";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans} from "react-i18next";
import {Add} from "@mui/icons-material";

type ListAddButtonProps = {
  i18nKey?: string;
  title?: string;
  customStyle?: SxProps;
  link?: string;
  className?: string;
  onClick?: () => void;
};
export default function ListAddButton({
  i18nKey,
  title,
  customStyle,
  link,
  className,
  onClick,
}: ListAddButtonProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return isDesktop ? (
    <Grid
      container
      justifyContent="center"
      sx={customStyle}
      className={className}
      width={"max-content"}
      display={{md: "flex", xs: "none"}}
    >
      <Link to={link}>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            if (onClick && onClick instanceof Function) {
              onClick();
            }
            window.scrollTo(0, 0);
          }}
        >
          <Trans i18nKey={i18nKey}>{title}</Trans>
        </Button>
      </Link>
    </Grid>
  ) : (
    <Grid
      position={"fixed"}
      bottom={"36px"}
      width={"40px"}
      height={"40px"}
      left={"50%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      border={"1px solid #D8E6FB"}
      boxShadow={
        "0px 3px 1px -2px rgba(16, 66, 122, 0.2), 0px 2px 2px rgba(16, 66, 122, 0.14), 0px 1px 5px rgba(16, 66, 122, 0.12)"
      }
      borderRadius={"64px"}
      zIndex={1101}
      sx={{transform: "translateX(-50%)", background: "#FFFFFF"}}
    >
      <Link
        to={link}
        onClick={() => {
          if (onClick && onClick instanceof Function) {
            onClick();
          }
          window.scrollTo(0, 0);
        }}
        sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
      >
        <Add sx={{color: "primary.main"}} />
      </Link>
    </Grid>
  );
}
