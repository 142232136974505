import {
  AccountingTransactionsConfirmInvoicePaymentApiArg,
  useAccountingTransactionsConfirmInvoicePaymentMutation,
} from "../../../features/accountingTransaction/accountingTransaction-api";
import {useAppSelector} from "../../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
  WorkflowExecutionResultDto,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {toastError} from "../../../common/utils/toastMessages";

export type CardValues = {
  number: string;
  expire: string;
  cvv: string;
  cardHolder: string;
};

export const usePayInvoice = (accountingTransactionId: number) => {
  const [confirmInvoicePaymentCommand] =
    useAccountingTransactionsConfirmInvoicePaymentMutation();

  const [useWorkflow] = useWorkflowExecutionMutation();
  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.createPaymentWorkflowId,
  );

  const customerId = useAppSelector((state: any) => state.userState?.contactId);

  const payInvoice = async (
    values: {contactPaymentMethodId?: string; card?: CardValues},
    returnUrl: string,
  ) => {
    const variables = {
      ...values,
      accountingTransactionId,
      customerId,
    };
    const commandArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId,
      values: {variables},
    };
    const result = (await useWorkflow(
      commandArgs,
    )) as WorkflowExecutionResultDto;
    if (result.error) {
      toastError("Failed create payment for invoice.");
    } else {
      return {data: {redirectToUrl: returnUrl}};
    }
  };

  const confirmInvoicePayment = async (payment_intent: string) => {
    const commandArgs: AccountingTransactionsConfirmInvoicePaymentApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      confirmInvoicePaymentCommandValues: {
        accountingTransactionId,
        paymentIntentId: payment_intent,
      },
    };
    return confirmInvoicePaymentCommand(commandArgs);
  };

  return {
    payInvoice,
    confirmInvoicePayment,
  };
};
