import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ChapterFragment from "./chapterFragment";
import HSCodeFragment from "./HSCodeFragment";
import {useGetCommodityTypes} from "../../../../Purchases/EditPurchases/hooks/useGetCommodityTypes";
import {getLangCodeFromI18n} from "../../../../app/app.component";

export interface CategoryModalProps {
  handleClose: () => void;
  index: number | undefined;
  onHandleChange?: any;
}

export function CategoryModal({
  handleClose,
  index,
  onHandleChange,
}: CategoryModalProps) {
  const langCode = getLangCodeFromI18n();
  const [step, setStep] = useState(0);
  const [itemCategory, setItemCategory] = useState("");
  const {getCommodityTypes, commodityTypes} = useGetCommodityTypes();
  const [chapters, setChapters] = useState<string[]>([]);

  useEffect(() => {
    getCommodityTypes({
      offset: 0,
      limit: 1000,
      filter: "isActive:true",
    });
  }, []);

  useEffect(() => {
    if (commodityTypes) {
      const customValuesSet = new Set<string>();
      commodityTypes.forEach((type) => {
        if (type.customValues && `chapter_${langCode}` in type.customValues) {
          customValuesSet.add(type.customValues[`chapter_${langCode}`]);
        }
      });
      const chapters: string[] = Array.from(customValuesSet);
      setChapters(chapters);
    }
  }, [commodityTypes]);

  const handleNext = () => {
    if (step < 1) setStep(step + 1);
  };

  const handleBack = () => {
    if (step !== 0) setStep(step - 1);
  };

  return (
    <Grid
      container
      columns={{xs: 6, md: 12}}
      style={{maxHeight: "75vh", outline: "none"}}
      columnSpacing={0}
    >
      {step === 0 && (
        <ChapterFragment
          chapters={chapters}
          handleClose={handleClose}
          handleNext={handleNext}
          setItemCategory={setItemCategory}
        />
      )}
      {step === 1 && (
        <HSCodeFragment
          handleClose={handleClose}
          handleBack={handleBack}
          itemCategory={itemCategory}
          index={index}
          onHandleChange={onHandleChange}
        />
      )}
    </Grid>
  );
}
