import React from "react";
import {Trans} from "react-i18next";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ProfileTabMenu from "../../components/profileTabMenu";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import AccountInfoForm from "../components";
import {ProfileConfiguration} from "../../profile.module";

export type ProfileProps = {
  config: ProfileConfiguration;
};

const AccountInfo = ({config}: ProfileProps) => {
  return (
    <MainLayout
      backLink="../getting-started"
      statusBar={<ProfileTabMenu selectedTab={1} />}
      pageTitle="Account Profile"
    >
      <ContentLayout
        title={
          <Trans i18nKey={"profile.tabs.accountProfile"}>
            My account profile
          </Trans>
        }
        backLink="../getting-started"
        content={<AccountInfoForm config={config} />}
        containerSx={{mb: "24px"}}
        mobileBoxSx={{paddingX: 0}}
      />
    </MainLayout>
  );
};

export default AccountInfo;
