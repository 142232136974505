import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import {CheckboxWithLabel, TextField} from "formik-mui";
import {LogInSubmit} from "./interfaces";
import {Trans, useTranslation} from "react-i18next";
import {GetTokenCommand} from "../../features/auth/auth-api";
import Link from "../../common/components/link";
import GoogleIcon from "../../../images/Google.svg";
import {useSignInWithGoogle} from "../hooks/useSignInWithGoogle";
import {getValidationSchema} from "../pages/validation";
import DividingOr from "../../common/components/dividingOr";
import {useParams} from "react-router-dom";
import i18next from "i18next";

const initialValues: GetTokenCommand = {
  email: "",
  password: "",
  grantType: "password",
  rememberMe: false,
};

export default function LogIn({
  onSubmit,
  googleSubmit,
  isLoading,
}: LogInSubmit) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };

  const {signInWithGoogle} = useSignInWithGoogle(googleSubmit);
  const {t} = useTranslation();

  return (
    <Grid
      container
      columns={{xs: 6, md: 6}}
      rowSpacing={{xs: 3, md: 4}}
      height={"100%"}
      justifyContent="center"
    >
      <Grid p={0} xs={6} md={4} pt={"40px"} position={"relative"}>
        <Typography
          sx={{
            fontSize: "2rem",
            fontFamily: "Inter SemiBold",
          }}
        >
          <Trans i18nKey="signIn">Log In</Trans>
        </Typography>
        <Grid display={"flex"} pb={0}>
          <Grid xs={6} md={6}>
            <Button
              variant="outlined"
              fullWidth
              data-testid="btn-google"
              onClick={() => signInWithGoogle()}
            >
              <Box
                component="img"
                src={GoogleIcon}
                alt="google-icon"
                pr={"0.7rem"}
              />
              Google
            </Button>
          </Grid>
          {/*<Grid xs={3} md={3} pl={"0.5rem"}>
            <Button
              variant="outlined"
              fullWidth
              data-testid="btn-facebook"
              onClick={() => facebookLogin(lang)}
            >
              <Box
                component="img"
                src={FacebookIcon}
                alt="facebook-icon"
                pr={"0.7rem"}
              />
              Facebook
            </Button>
          </Grid>*/}
        </Grid>
        <Grid
          display={"flex"}
          justifyContent="center"
          py={0}
          color={"gray"}
          textAlign={"center"}
        >
          <DividingOr />
        </Grid>
        <Formik
          onSubmit={async (values: GetTokenCommand) => {
            onSubmit(values);
          }}
          initialValues={initialValues}
          validationSchema={getValidationSchema(t)}
        >
          <Form>
            <Grid>
              <Field
                fullWidth
                component={TextField}
                name="email"
                type="email"
                label={t("email")}
                placeholder="example@gmail.com"
                data-testid="input-email"
                autoComplete="email"
                InputLabelProps={{shrink: true}}
              />
            </Grid>
            <Grid py={0}>
              <Field
                fullWidth
                component={TextField}
                label={t("password")}
                name="password"
                data-testid="input-password"
                type={showPassword ? "text" : "password"}
                InputLabelProps={{shrink: true}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="btn-show-password"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        sx={{opacity: "0.5"}}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              pt={1}
              alignItems={"center"}
              sx={{display: "flex", justifyContent: "space-between"}}
            >
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="rememberMe"
                Label={{label: t("rememberMe")}}
                sx={{color: "#ABABAB"}}
              />
              <Typography variant="body1">
                <Link
                  to={"../forgot-password" + location.search}
                  underline="none"
                >
                  <Trans i18nKey="forgotPassword">Forgot password?</Trans>
                </Link>
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                data-testid="btn-submit"
                disabled={isLoading}
              >
                <Trans i18nKey="signInButton">Sign In</Trans>
              </Button>
              <Grid>
                <Typography variant="body1" textAlign={"center"}>
                  <Trans i18nKey="registerQuestion">
                    Do not have an account?&nbsp;
                    <Link to={"../register" + location.search} underline="none">
                      Register
                    </Link>
                  </Trans>
                </Typography>
              </Grid>
              <Grid textAlign={"center"} py={0}>
                <Typography variant="caption1">
                  <Trans i18nKey="helpQuestion">
                    Need help?&nbsp;
                    <MuiLink
                      href={
                        "https://trtshopping.com/" +
                        (i18next.language !== "ua"
                          ? `ua/${i18next.language}/`
                          : `${i18next.language}/`) +
                        "help/"
                      }
                      underline="none"
                      target="_blank"
                      rel="noopener"
                    >
                      Contact Support
                    </MuiLink>
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
}
