import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    commodityTypeGetCommodityTypes: build.mutation<
      CommodityTypeGetCommodityTypesApiResponse,
      CommodityTypeGetCommodityTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/commodityTypes/getCommodityTypes`,
        method: "POST",
        body: queryArg.getCommodityTypesValues,
      }),
    }),
    getCommodityTypes: build.mutation<
      CommodityTypeGetCommodityTypesApiResponse,
      CommodityTypesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/commodityTypes`,
        method: "POST",
        body: queryArg.values,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type CommodityTypeGetCommodityTypesApiResponse =
  /** status 200  */ CommodityTypeDtoPagedResult;
export type CommodityTypeGetCommodityTypesApiArg = {
  organizationId: number;
  getCommodityTypesValues: GetCommodityTypesValues;
};
export type CommodityTypesGetApiArg = {
  organizationId: number;
  values: CommodityTypesGetValues;
};
export type CommodityTypesGetValues = {
  search: string;
  limit: number;
};
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type CommodityTypeDto = {
  commodityTypeId?: number;
  code?: string | undefined;
  created?: Date;
  createdBy?: string | undefined;
  description?: string | undefined;
  isActive?: boolean;
  lastModified?: Date;
  lastModifiedBy?: string | undefined;
  organizationId?: number;
  links?: LinkDto[] | undefined;
  customValues?: {
    [key: string]: string;
  } | null;
};
export type CommodityTypeDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: CommodityTypeDto[] | null;
  links?: LinkDto[] | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  extensions?: {
    [key: string]: any;
  };
  [key: string]: any | null;
};
export type GetCommodityTypesValues = {
  limit?: number | null;
  offset?: number | null;
  sort?: string;
  filter?: string;
  search?: string;
};
export const {
  useCommodityTypeGetCommodityTypesMutation,
  useGetCommodityTypesMutation,
} = injectedRtkApi;
