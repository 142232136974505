import React, {useState} from "react";
import RegisterForm from "../components";
import {useLoginSubmitHandler} from "../../common/utils/loginSubmitHandler";
import SplitPage from "../../common/SplitPage/components/splitPage";
import {useRegisterSubmitHandler} from "../hooks/useRegisterSubmitHandler";

const Register = () => {
  const {onGoogleSubmitHandler, isLoginLoading} = useLoginSubmitHandler();
  const [promoCodeErrors, setPromoCodeErrors] = useState({});
  const {isLoading: isRegisterLoading, onSubmitHandler} =
    useRegisterSubmitHandler(setPromoCodeErrors);

  return (
    <SplitPage
      contentSide="leftCentered"
      main={
        <RegisterForm
          onSubmit={onSubmitHandler}
          googleSubmit={onGoogleSubmitHandler}
          isLoading={isRegisterLoading || isLoginLoading}
          promoCodeErrors={promoCodeErrors}
        />
      }
      pageTitle="Registration"
    />
  );
};

export default Register;
