import React, {useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import {useSearchParams} from "react-router-dom";
import {
  PURCHASES_QUERY_DEFAULT_FILTER,
  PURCHASES_QUERY_DEFAULT_LIMIT,
  PURCHASES_QUERY_DEFAULT_OFFSET,
} from "./purchaseList";

interface FilterItem {
  filterId: number;
  filterName: string;
  backendFilterName: string;
  isChosen: boolean;
  i18nKey: string;
}

const DEFAULT_FILTER_ITEMS: FilterItem[] = [
  {
    filterId: 0,
    i18nKey: "orderStatus.statusStages.allPurchases",
    filterName: "All Purchases",
    backendFilterName: "",
    isChosen: true,
  },
  {
    filterId: 1,
    i18nKey: "orderStatus.statusStages.pending",
    filterName: "Pending",
    backendFilterName: "orderStatus.customValues.portal_stage:Pending",
    isChosen: false,
  },
  {
    filterId: 2,
    i18nKey: "orderStatus.statusStages.actionNeeded",
    filterName: "Action Needed",
    backendFilterName: "orderStatus.customValues.portal_stage:ActionNeeded",
    isChosen: false,
  },
  {
    filterId: 3,
    i18nKey: "orderStatus.statusStages.inProgress",
    filterName: "In Progress",
    backendFilterName: "orderStatus.customValues.portal_stage:InProgress",
    isChosen: false,
  },
  {
    filterId: 4,
    i18nKey: "orderStatus.statusStages.complete",
    filterName: "Complete",
    backendFilterName: "orderStatus.customValues.portal_stage:Complete",
    isChosen: false,
  },
];

export default function PurchasesFilter() {
  const [filterState, setFilterState] = useState<FilterItem[]>(() => {
    const url = new URL(location.href);
    const filterQueryParamValue = url.searchParams.get("filter");

    const initialFilterItems: FilterItem[] = [];
    for (const defaultFilter of DEFAULT_FILTER_ITEMS) {
      const isFilterChosen = !!filterQueryParamValue?.includes(
        defaultFilter.backendFilterName,
      );
      initialFilterItems.push({...defaultFilter, isChosen: isFilterChosen});
    }

    initialFilterItems[0].isChosen =
      !filterQueryParamValue ||
      filterQueryParamValue === PURCHASES_QUERY_DEFAULT_FILTER;

    return initialFilterItems;
  });
  const [, setSearchParams] = useSearchParams();

  const handleClick = (filterId: number) => {
    const newFilterItems = [...filterState];

    newFilterItems.forEach((i) => {
      if (i.filterId == filterId) {
        i.isChosen = !i.isChosen;
      } else {
        i.isChosen = false;
      }
    });

    if (newFilterItems.every((filter) => !filter.isChosen)) {
      newFilterItems[0].isChosen = true;
    }

    setFilterState(newFilterItems);
  };

  useEffect(() => {
    const chosenFiltersBackendNames: string[] = [];
    let hasNonNullFilter = false;
    for (const filter of filterState) {
      if (filter.isChosen) {
        chosenFiltersBackendNames.push(filter.backendFilterName);
        if (filter.backendFilterName) {
          hasNonNullFilter = true;
        }
      }
    }

    const combinedFilters = hasNonNullFilter
      ? `${PURCHASES_QUERY_DEFAULT_FILTER} AND (${chosenFiltersBackendNames.join(
          " OR ",
        )})`
      : PURCHASES_QUERY_DEFAULT_FILTER;
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set("filter", combinedFilters);
      prevSearchParams.set("offset", PURCHASES_QUERY_DEFAULT_OFFSET);
      prevSearchParams.set("limit", PURCHASES_QUERY_DEFAULT_LIMIT);
      return prevSearchParams;
    });
  }, [filterState]);

  return (
    <Grid
      display={"flex"}
      gap={"16px"}
      sx={{
        margin: 0,
        overflowX: {xs: "scroll", md: "visible"},
        maxWidth: {xs: "100vw", md: "auto"},
        "::-webkit-scrollbar": {
          display: "none",
        },
        padding: {xs: "24px 16px", md: 0},
      }}
    >
      {filterState.map(
        ({filterId, filterName, isChosen, i18nKey}: FilterItem) => (
          <Button
            data-testid={`btn-filter-${i18nKey}`}
            sx={{
              height: "32px!important",
              boxShadow: "none !important",
              margin: 0,
              whiteSpace: "nowrap",
              flexShrink: {xs: "0", md: "1"},
            }}
            key={filterId}
            variant={isChosen ? "contained" : "outlined"}
            onClick={() => handleClick(filterId)}
          >
            <Typography className="filterBtn" variant="caption2">
              <Trans i18nKey={i18nKey}>{filterName}</Trans>
            </Typography>
          </Button>
        ),
      )}
    </Grid>
  );
}
