import {toastError} from "../../common/utils/toastMessages";
import {basePortalApi as api} from "../basePortalApi";
import {LinkDto} from "../order/order-api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    paymentMethodsGetPaymentMethods: build.mutation<
      GetContactPaymentMethodsApiResponse,
      GetContactPaymentMethodsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactPaymentMethods`,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    contactPaymentMethodSoftDelete: build.mutation<
      ContactPaymentMethodSoftDeleteApiResponse,
      ContactPaymentMethodSoftDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactPaymentMethods`,
        method: "POST",
        body: queryArg.softDeleteCommandValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
  }),
  overrideExisting: false,
});
export type GetContactPaymentMethodsApiArg = {
  organizationId: number;
};

export type ContactPaymentMethodSoftDeleteApiResponse = boolean;
export type ContactPaymentMethodSoftDeleteApiArg = {
  organizationId: number;
  softDeleteCommandValues: SoftDeleteCommandValues;
};
export enum PaymentType {
  "Card" = 1,
}
export type ContactPaymentMethodDto = {
  cardHolderName: string;
  contactPaymentMethodId: string;
  contactId: number;
  customerId: number;
  description: string | null;
  expDate: string | null;
  paymentType: PaymentType | null;
  customValues?: {
    [key: string]: string;
  } | null;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  isVerified?: boolean | null;
  isDeleted?: boolean | null;
};

export type ContactPaymentMethodDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: ContactPaymentMethodDto[] | null;
  links?: LinkDto[] | null;
};

export {injectedRtkApi as portalApi};
export type GetContactPaymentMethodsApiResponse =
  ContactPaymentMethodDtoPagedResult;

export type SoftDeleteCommandValues = {
  contactPaymentMethodId?: string;
};
export const {
  usePaymentMethodsGetPaymentMethodsMutation,
  useContactPaymentMethodSoftDeleteMutation,
} = injectedRtkApi;
