import {useParams} from "react-router-dom";
import {useMemo} from "react";
import {useAppSelector} from "../../store";
import {OrderStepData} from "../../features/order/parcelShipment-slice";
import {compareArrays} from "../../../utils/compareArrays.utils";

type UrlParams = {
  orderIds: string;
  step: string;
};

export const useDeliveryHelper = () => {
  const {orderIds: orderIdsParam, step: stepKey} = useParams<
    keyof UrlParams
  >() as UrlParams;

  const orderIds = useMemo(() => {
    return orderIdsParam?.split("&")?.map((x) => Number(x));
  }, [orderIdsParam]);

  const isConsolidated = useMemo(() => {
    return orderIds?.length > 1 || orderIds?.includes(0);
  }, [orderIds]);

  const ordersData = useAppSelector(
    (state: any) => state.parcelShipmentState.ordersData,
  );
  const currentOrderData = useMemo(() => {
    return ordersData.find((x: OrderStepData) =>
      compareArrays(x?.orderIds, orderIds),
    );
  }, [ordersData, orderIds, compareArrays]);

  return {
    stepKey,
    orderIdsParam,
    orderIds,
    isConsolidated,
    ordersData,
    currentOrderData,
    currentOrder: currentOrderData?.order,
    completedSteps: currentOrderData?.completedSteps,
  };
};
