import * as yup from "yup";

export const getEditAccountNameSchema = (t: (text: string) => string) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(t("profile.editName.firstNameRequired"))
      .max(50, t("profile.editName.firstNameLength")),
    lastName: yup
      .string()
      .required(t("profile.editName.lastNameRequired"))
      .max(50, t("profile.editName.lastNameLength")),
  });
