import {Box, Card, CardContent, Typography} from "@mui/material";
import {AddRounded} from "@mui/icons-material";
import {Trans} from "react-i18next";
import React from "react";

const AddAddressCard = ({handleAddAddress, isDesktop}: any) => {
  return (
    <Card
      onClick={handleAddAddress}
      variant="outlined"
      sx={{
        marginTop: isDesktop ? "auto" : 2,
        cursor: "pointer",
        boxSizing: "border-box",
        minWidth: "354px",
        left: "0px",
        top: "0px",
        background: "#FFFFFF",
        border: "1px solid #D8E6FB",
        boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
        borderRadius: "8px",
        ":hover": {
          borderColor: "#00BD75",
        },
      }}
    >
      <CardContent
        sx={{
          paddingTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          flexDirection={isDesktop ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
        >
          <AddRounded
            sx={{
              color: "#9FB3D1",
              width: isDesktop ? 70 : 50,
              height: isDesktop ? 70 : 50,
            }}
          />
          <Typography
            variant="body2"
            component="div"
            gutterBottom
            sx={{
              marginTop: "5px",
              textAlign: "center",
              fontSize: "19px",
              fontFamily: "Inter Regular",
            }}
          >
            <Trans i18nKey={"profile.savedAddresses.addAddress"}>
              Add Address
            </Trans>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
export default AddAddressCard;
