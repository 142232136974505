import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import BoxButton from "../../../common/components/boxButton";
import TotalCost from "../../components/totalCost.component";
import {AdditionalServiceFragmentProps} from "./interfaces";
import AdditionalServicesList from "./additionalServicesList.component";
import {
  ChargeDto,
  CommodityDto,
  CreateChargeCommand,
  OrderDto,
} from "../../../features/order/order-api";
import {CurrencyDto} from "../../../features/currencies/currencies-api";
import {useDispatch} from "react-redux";
import {
  OrderSlice,
  setOrderCharges,
} from "../../../features/order/parcelShipment-slice";
import {useInsuranceGet} from "../../hooks/UseInsuranceGet";
import {useParcelShipmentSubmit} from "../../hooks/UseParcelShipmentSubmit";
import {toastError} from "../../../common/utils/toastMessages";
import {
  GetQuoteForOrderCommandValues,
  QuoteOrderResponse,
} from "../../../features/shipmentQuote/shipmentQuote-api";
import {Helmet} from "react-helmet";
import {UseQuoteForOrder} from "../../hooks/UseQuoteForOrder";
import {getDeliveryPrice} from "../../../../utils/helper.utils";
import {useConsolidationAccountingItem} from "../../../common/Consolidation/useConsolidationAccountingItem";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";

export const deliveryCostPerUnit = (
  order?: OrderSlice,
  t?: any,
  showTotalCost?: boolean,
): string | null => {
  return getDeliveryPrice(
    order?.deliveryMethod,
    t,
    showTotalCost,
    order?.charges,
  );
};

export default function AdditionalServiceFragment({
  handleNext,
  pageTitle,
  config,
}: AdditionalServiceFragmentProps) {
  const {t} = useTranslation();

  const {orderIds, isConsolidated, currentOrder} = useDeliveryHelper();

  const {getPurchase} = useParcelShipmentSubmit();

  const [insuranceAmount, setInsuranceAmount] = useState<number | null>(
    currentOrder?.charges?.find(
      (x: CreateChargeCommand) => x.values?.description === "Insurance",
    )?.values?.quantity ?? null,
  );

  const [currencyId, setCurrencyId] = useState<number | undefined>();
  const [insurance, setInsurance] = useState<any>();
  const [insuranceState, setInsuranceState] = useState<boolean>(true);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState<boolean>(true);
  const [showNumberValidationError, setShowNumberValidationError] =
    useState(false);
  const [showRequiredValidationError, setShowRequiredValidationError] =
    useState(true);
  const [showFormatValidationError, setShowFormatValidationError] =
    useState(false);

  const {
    insuranceData,
    isLoading,
    isSuccess,
    isError,
    error,
    currenciesData,
    isLoadingCurrency,
    isSuccessCurrency,
    isErrorCurrency,
    errorCurrency,
    userContactId,
  } = useInsuranceGet(config?.insuranceAccountingItemCode ?? "");

  const handleContinue = () => {
    if (
      insuranceState &&
      (insuranceAmount === undefined ||
        insuranceAmount === null ||
        insuranceAmount <= 0)
    ) {
      if (insuranceAmount === undefined || insuranceAmount === null) {
        setShowRequiredValidationError(true);
        setShowNumberValidationError(false);
      } else {
        setShowNumberValidationError(true);
        setShowRequiredValidationError(false);
      }
    } else {
      if (!showFormatValidationError) handleNext();
    }
  };

  const handleClick = () => {
    if (
      insuranceState &&
      (showFormatValidationError || showNumberValidationError)
    ) {
      setInsuranceAmount(null);
      setShowFormatValidationError(false);
      setShowNumberValidationError(false);
    }
    setInsuranceState((prev) => !prev);
  };

  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let consolidationItemCode = "";
  if (isConsolidated) {
    consolidationItemCode = useConsolidationAccountingItem() ?? "";
  }

  useEffect(() => {
    if (isConsolidated && consolidationItemCode) {
      setAdditionalServices((services: any) => {
        services[consolidationItemCode] = orderIds?.length;
        return {...services};
      });
    }
  }, [consolidationItemCode]);

  const calculateTotalDeclaredValue = (
    commodities: {
      containerCommodities: any[];
    }[],
  ) => {
    let totalDeclaredValue = 0;
    commodities.forEach((commodity: {containerCommodities: any[]}) => {
      commodity.containerCommodities.forEach((containerCommodity) => {
        totalDeclaredValue +=
          containerCommodity.unitaryValue * containerCommodity.quantity;
      });
    });
    return totalDeclaredValue;
  };

  useEffect(() => {
    setIsPurchaseLoading(true);
    setInsuranceState(currentOrder?.needInsurance ?? true);

    if (!isConsolidated) {
      getPurchase(orderIds?.[0]).then((response: any) => {
        const commodities = response.data.commodities;
        if (insuranceAmount === undefined)
          setInsuranceAmount(calculateTotalDeclaredValue(commodities));
        setBillToContactId(response.data?.billToContactId);
        setOrderCommodities(response.data.commodities);
      });
    } else if (orderIds && orderIds.length > 1) {
      const commodities: any[] = [];
      orderIds?.forEach((orderId) => {
        getPurchase(orderId)
          .then((response: any) => {
            commodities.push(...response.data.commodities);
            setBillToContactId(response.data?.billToContactId);
          })
          .then(() => {
            if (insuranceAmount === undefined)
              setInsuranceAmount(calculateTotalDeclaredValue(commodities));
            setOrderCommodities(commodities);
          });
      });
    }
  }, []);

  useEffect(() => {
    if (insurance && isSuccessCurrency) {
      const currencyIdValue = currenciesData?.items?.find(
        (currency: CurrencyDto) =>
          currency.currencyCode === "USD" && currency.currencyName === "USD",
      )?.currencyId;

      if (!currencyIdValue) {
        toastError(t("toasts.serverError"));
        return;
      }

      setCurrencyId(currencyIdValue);
      setIsPurchaseLoading(false);
    }
  }, [insuranceState, insurance, orderIds, currentOrder, isLoadingCurrency]);

  const {
    getOrderQuote,
    isLoading: isLoadingQuote,
    isError: isErrorQuote,
    error: errorQuote,
  } = UseQuoteForOrder();

  useEffect(() => {
    if (isErrorCurrency) {
      toastError(errorCurrency);
    }
  }, [isLoadingCurrency]);

  useEffect(() => {
    if (isErrorQuote) {
      toastError(errorQuote);
    }
  }, [isLoadingQuote]);

  useEffect(() => {
    if (isSuccess) {
      setInsurance(insuranceData);
    }
    if (isError) {
      toastError(error);
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      insuranceState &&
      (insuranceAmount === undefined ||
        insuranceAmount === null ||
        insuranceAmount <= 0)
    ) {
      if (insuranceAmount === undefined || insuranceAmount === null) {
        setShowRequiredValidationError(true);
        setShowNumberValidationError(false);
      } else {
        setShowNumberValidationError(true);
        setShowRequiredValidationError(false);
      }
    } else {
      setShowRequiredValidationError(false);
      setShowNumberValidationError(false);
    }
  }, [insuranceState, insuranceAmount]);

  const [billToContactId, setBillToContactId] = useState<number | null>(null);
  const [orderCommodities, setOrderCommodities] = useState<CommodityDto[]>([]);
  const [additionalServices, setAdditionalServices] = useState<any>({});
  const [calculatedCharges, setCalculatedCharges] = useState<ChargeDto[]>([]);

  useEffect(() => {
    if (insurance) {
      if (insuranceAmount && insuranceState) {
        setAdditionalServices((services: any) => {
          services[insurance.itemCode] = insuranceAmount;
          return {...services};
        });
      } else {
        setAdditionalServices((services: any) => {
          delete services[insurance.itemCode];
          return {...services};
        });
      }
    }
  }, [insuranceAmount, insurance, insuranceState]);

  useEffect(() => {
    if (
      billToContactId &&
      currentOrder &&
      currentOrder.deliveryMethod?.rateId &&
      orderCommodities
    ) {
      const order = {...currentOrder} as OrderDto;
      order.billToContactId = billToContactId;
      order.commodities = orderCommodities;
      const values: GetQuoteForOrderCommandValues = {
        order: order,
        routes: [{rateId: currentOrder.deliveryMethod?.rateId}],
        additionalServices: additionalServices,
      };

      getOrderQuote(values).then((response: any) => {
        const data: QuoteOrderResponse = response?.data;
        let charges: ChargeDto[] = [];
        data.routes?.forEach((route) => {
          route.results?.forEach((result) => {
            if (result.charges) charges = [...charges, ...result.charges];
          });
        });
        setCalculatedCharges(charges);
        const chargesCommands = charges?.map((charge) => {
          const command: CreateChargeCommand = {
            organizationId: process.env
              .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
            values: {
              ...charge,
              chargeStatus:
                charge.accountingItemCode == config?.insuranceAccountingItemCode
                  ? "Open"
                  : "Pending",
            },
          };
          return command;
        });
        dispatch(
          setOrderCharges({orderIds: orderIds, charges: chargesCommands}),
        );
      });
    }
  }, [billToContactId, orderCommodities, additionalServices]);

  const addInsuranceButton = (
    <>
      {t("delivery.additionalServices.addAdditional")} {!isDesktop && <br />}
      {t("delivery.additionalServices.insurance")}
    </>
  );

  const noInsuranceButton = (
    <>
      {t("delivery.additionalServices.noInsurance")} {!isDesktop && <br />}
      {t("delivery.additionalServices.withoutInsurance")}
    </>
  );

  const addInsuranceSubtext = (
    <>
      {t("delivery.additionalServices.includeInsurance.beforePercent")}
      <span data-testid="insurance-percent">
        {(insurance?.price * 100).toFixed(2)}
      </span>
      {t("delivery.additionalServices.includeInsurance.afterPercent")}
    </>
  );

  const noInsuranceSubtext = (
    <>{t("delivery.additionalServices.doNotInsure")}</>
  );

  return (
    <Grid xs={6} md={14} sx={{mb: {xs: 0, md: 3}}}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid
        container
        columns={{xs: 6, md: 12}}
        justifyContent="space-between"
        direction={{xs: "column", md: "row"}}
      >
        <Grid xs={6} md={6.5}>
          <Grid
            container
            direction="column"
            columns={{xs: 6, md: 6.5}}
            mt={{md: 2, xs: 3}}
          >
            <Grid xs={6} md={6.5}>
              <Typography variant={isDesktop ? "h1" : "h3"} mb={{xs: 1, md: 2}}>
                {t("delivery.additionalServices.insurance")}
              </Typography>
              <Typography variant={isDesktop ? "body1" : "caption1"} mb={3}>
                {t("delivery.additionalServices.insureDescriptionStart")}
                <span data-testid="insurance-percent">
                  {isLoading || isPurchaseLoading ? (
                    <Grid sx={{display: "inline", mt: 3}}>
                      <CircularProgress size={14} />
                    </Grid>
                  ) : (
                    (insurance?.price * 100).toFixed(1)
                  )}
                </span>
                {t("delivery.additionalServices.insureDescriptionEnd")}
              </Typography>
              <Typography variant={isDesktop ? "h2" : "h3"} mb={2} mt={3}>
                {t("delivery.additionalServices.insureShipment")}
              </Typography>
            </Grid>

            {isLoading || isPurchaseLoading ? (
              <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                container
                columns={{xs: 6, md: 6}}
                direction={{md: "row", xs: "column"}}
                justifyContent="space-between"
              >
                <Grid xs={6} md={6} mb={2} height="100%">
                  <BoxButton
                    enterAmount={true}
                    data-testid="btn-add-insurance"
                    label={addInsuranceButton}
                    subText={addInsuranceSubtext}
                    clicked={insuranceState}
                    handleClick={handleClick}
                    insuranceAmount={insuranceAmount}
                    setInsuranceAmount={setInsuranceAmount}
                    setShowFormatValidationError={setShowFormatValidationError}
                    showValidationError={
                      showNumberValidationError ||
                      showFormatValidationError ||
                      showRequiredValidationError
                    }
                  />
                  {(showNumberValidationError ||
                    showFormatValidationError ||
                    showRequiredValidationError) && (
                    <Typography
                      sx={{
                        pt: 0,
                        pb: 0,
                        pl: 2,
                        pr: 2,
                        color: "red",
                      }}
                    >
                      {showFormatValidationError
                        ? t("delivery.additionalServices.formatAmountError")
                        : showNumberValidationError
                        ? t("delivery.additionalServices.enterAmountError")
                        : t("delivery.additionalServices.requiredAmountError")}
                    </Typography>
                  )}
                </Grid>
                <Grid xs={6} md={6} height="100%">
                  <BoxButton
                    data-testid="btn-no-insurance"
                    label={noInsuranceButton}
                    subText={noInsuranceSubtext}
                    clicked={!insuranceState}
                    handleClick={handleClick}
                  />
                </Grid>
              </Grid>
            )}

            <Grid xs={6} md={6.5}>
              <Typography variant={isDesktop ? "h1" : "h3"} mb={2} mt={3}>
                {t("delivery.statusBar.additionalServices")}
              </Typography>

              <Grid xs={6} md={6.5} sx={{mb: {xs: 2, md: 5}}}>
                <AdditionalServicesList
                  isLoadingCurrency={isLoadingCurrency}
                  setAdditionalServices={setAdditionalServices}
                  insuranceAccountingItemCode={
                    config?.insuranceAccountingItemCode
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={6} md={5} sx={{mt: {xs: 2, md: 9}}}>
          {isLoading || isPurchaseLoading ? (
            <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
              <CircularProgress />
            </Grid>
          ) : (
            <Box sx={{position: "sticky", top: "100px"}}>
              <TotalCost
                deliveryCostPerUnit={
                  config?.showTotalCost
                    ? getFormattedPrice(
                        currentOrder?.deliveryMethod?.totalAmount,
                      )
                    : currentOrder?.deliveryMethod?.estimatedCost
                }
                charges={calculatedCharges}
                consolidationItemCode={consolidationItemCode}
                insuranceItemCode={config?.insuranceAccountingItemCode}
                showTotalCost={config?.showTotalCost}
              />
              {/* <Grid mt={2}>
                <Typography variant="caption1" color={shipmentCostNoteColor}>
                  *
                  {t("delivery.totalCost.noteForTotalCost")}
                </Typography>
              </Grid> */}
            </Box>
          )}
        </Grid>

        <Grid md={2} xs={6} sx={{py: {xs: 2, md: 0}}}>
          <Button
            disabled={isLoadingQuote}
            data-testid="btn-continue-additional-services"
            fullWidth
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleContinue}
            sx={{
              p: 1,
              mb: 3,
            }}
          >
            {t("btnContinue")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
