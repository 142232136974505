import {basePortalApi as api} from "../basePortalApi";
import {CityDto} from "../cities/cities-api";
import {CountryDto} from "../countries/countries-api";
import {StateDto} from "../states/states-api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    contactAddressGetContactAddress: build.query<
      ContactAddressGetContactAddressApiResponse,
      ContactAddressGetContactAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/getContactAddress`,
        params: {contactAddressId: queryArg.contactAddressId},
      }),
    }),
    contactAddressGetDefaultAddress: build.query<
      ContactAddressGetDefaultAddressApiResponse,
      ContactAddressGetDefaultAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/getDefaultAddress`,
        params: {contactId: queryArg.contactId},
      }),
    }),
    getPickupLocations: build.mutation<
      ContactAddressGetPickupLocationsApiResponse,
      ContactAddressGetPickupLocationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/getPickupLocations`,
        method: "POST",
        body: queryArg.values,
      }),
    }),
    contactAddressUpdateContactAddress: build.mutation<
      ContactAddressUpdateContactAddressApiResponse,
      ContactAddressUpdateContactAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/${queryArg.contactAddressId}`,
        method: "PUT",
        body: queryArg.updateContactAddressCommandValues,
      }),
    }),
    updateContactAddress: build.mutation<
      UpdateContactAddressApiResponse,
      UpdateContactAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/updateAddress/${queryArg.contactAddressId}`,
        method: "PUT",
        body: queryArg.updateContactAddressCommandValues,
      }),
    }),
    contactAddressDeleteContactAddress: build.mutation<
      ContactAddressDeleteContactAddressApiResponse,
      ContactAddressDeleteContactAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/contactAddresses/${queryArg.contactAddressId}`,
        method: "DELETE",
        params: queryArg.deleteContactAddressCommandValues,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type ContactAddressGetContactAddressApiResponse =
  /** status 200  */ ContactAddressDto;
export type ContactAddressGetContactAddressApiArg = {
  organizationId: number;
  contactAddressId?: number;
};
export type ContactAddressGetDefaultAddressApiResponse =
  /** status 200  */ ContactAddressDto;
export type ContactAddressGetDefaultAddressApiArg = {
  organizationId: number;
  contactId?: number;
};
export type ContactAddressGetPickupLocationsApiResponse =
  /** status 200  */ ContactAddressDto[];
export type ContactAddressGetPickupLocationsApiArg = {
  organizationId: number;
  values: ContactAddressGetPickupLocationsValues;
};
export type ContactAddressGetPickupLocationsValues = {
  cityName: string;
  stateCode: string;
  countryCode: string;
};
export type ContactAddressUpdateContactAddressApiResponse =
  /** status 204  */ undefined;
export type ContactAddressUpdateContactAddressApiArg = {
  organizationId: number;
  contactAddressId: number;
  updateContactAddressCommandValues: UpdateContactAndContactAddressCommandValues;
};
export type UpdateContactAddressApiResponse = /** status 204  */ undefined;
export type UpdateContactAddressApiArg = {
  organizationId: number;
  contactAddressId: number;
  updateContactAddressCommandValues: UpdateContactAddressCommandValues;
};
export type ContactAddressDeleteContactAddressApiResponse = unknown;
export type ContactAddressDeleteContactAddressApiArg = {
  organizationId: number;
  contactAddressId: number;
  deleteContactAddressCommandValues: DeleteContactAddressCommandValues;
};
export type AddressType = "Billing" | "Shipping" | "Other";
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type ContactType =
  | "Customer"
  | "Carrier"
  | "Vendor"
  | "Contact"
  | "Driver"
  | "Employee"
  | "SalesPerson"
  | "ForwardingAgent"
  | "FactoringCompany"
  | "Lead";
export type IdNumberType = "EIN" | "DUNS" | "ForeignEntityId" | "Other";
export type ContactLinkType =
  | "ParentContact"
  | "FactoringCompany"
  | "SalesPerson";
export type VisibleTransactions =
  | "AllTransactions"
  | "DivisionOnly"
  | "DivisionAndDivisionless";
export type ApplicationUserRoleDto = {
  id?: string | null;
  name?: string | null;
  normalizedName?: string | null;
};
export type UserGroupDto = {
  userGroupId?: number;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  name?: string | null;
  organizationId?: number;
  userRoles?: ApplicationUserRoleDto[] | null;
  links?: LinkDto[] | null;
};
export type UserEmployeeDto = {
  userEmployeeId?: number;
  created?: string;
  createdBy?: string | null;
  employeeContactId?: number;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  userId?: string | null;
  visibleTransactions?: VisibleTransactions;
  employeeContactFirstName?: string | null;
  employeeContactLastName?: string | null;
  employeeName?: string | null;
  userFirstName?: string | null;
  userLastName?: string | null;
  userNormalizedUserName?: string | null;
  userUserName?: string | null;
  employeeDivisionId?: number;
  userGroups?: UserGroupDto[] | null;
  links?: LinkDto[] | null;
};
export type PaidAs = "Prepaid" | "Collect";
export type CarrierEquipmentDto = {
  carrierId?: number;
  equipmentTypeId?: number;
  quantity?: number;
};
export type ContactDto = {
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  linkToContactId?: number | null;
  linkToContactName?: string | null;
  contactLinkType?: ContactLinkType;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto | null;
  creditLimit?: number | null;
  paidAs?: PaidAs;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: ContactAddressCustomValues | null;
  links?: LinkDto[] | null;
};
export type ContactAddressCustomValues = {
  toAddressHouseNumber?: string | null;
  toAddressDistrict?: string | null;
  toAddressApartment?: string | null;
  addressCompanyName?: string | null;
};
export type ContactAddressDto = {
  contactAddressId?: number;
  addressLine?: string | null;
  addressLine2?: string | null;
  addressType?: AddressType;
  cityName?: string | null;
  city?: CityDto;
  contactId?: number;
  countryCode?: string | null;
  countryName?: string | null;
  country?: CountryDto;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  postalCode?: string | null;
  stateCode?: string | null;
  stateName?: string | null;
  state?: StateDto;
  links?: LinkDto[] | null;
  longitude?: number;
  latitude?: number;
  customValues?: {
    [key: string]: string;
  } | null;
  contact?: ContactDto | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  extensions?: {
    [key: string]: any;
  };
  [key: string]: any | null;
};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any | null;
};
export type UpdateContactAndContactAddressCommandValues = {
  addressLine?: string;
  addressLine2?: string | null;
  cityName?: string | null;
  countryCode?: string | null;
  postalCode?: string | null;
  stateCode?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  customValues?: ContactAddressCustomValues | null;
};

export type UpdateContactAddressCommandValues = {
  addressLine?: string;
  addressLine2?: string | null;
  cityName?: string | null;
  countryCode?: string | null;
  postalCode?: string | null;
  stateCode?: string | null;
  customValues?: ContactAddressCustomValues | null;
};

export type DeleteContactAddressCommandValues = {
  contactId: number | null;
  customerId: number | null;
  isDefault: boolean;
  newDefaultId: number | null;
};
export const {
  useContactAddressGetContactAddressQuery,
  useLazyContactAddressGetContactAddressQuery,
  useContactAddressGetDefaultAddressQuery,
  useGetPickupLocationsMutation,
  useContactAddressUpdateContactAddressMutation,
  useUpdateContactAddressMutation,
  useContactAddressDeleteContactAddressMutation,
} = injectedRtkApi;
