import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function ConfirmationEditStepCard({
  header,
  content,
  switchStepCallback,
}: {
  header: string;
  content: any;
  switchStepCallback: any;
}): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      xs={6}
      md={3}
      sx={{border: "1px solid #D8E6FB", borderRadius: {xs: 0, md: "16px"}}}
      p={{xs: "16px", md: "24px"}}
    >
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        flexWrap={"nowrap"}
        mb={{xs: 0, md: "11px"}}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontFamily: "Inter Regular",
            fontWeight: 600,
            mr: 1,
          }}
        >
          {header}
        </Typography>
        <Grid>
          <EditIcon
            sx={{fontSize: "24px", color: "primary.dark", cursor: "pointer"}}
            onClick={switchStepCallback}
            data-testid={`btn-confirmation-${header
              .replace(" ", "")
              .toLowerCase()}`}
          />
        </Grid>
      </Grid>
      <Grid
        data-testid={`conformation-card-content-${header
          .replace(" ", "")
          .toLowerCase()}`}
        sx={{
          "::first-letter": {
            textTransform: "upperCase",
          },
          fontSize: {xs: "16px", md: "14px"},
          fontFamily: "Inter Regular",
          fontWeight: {xs: 500, md: 400},
        }}
      >
        {content}
      </Grid>
    </Grid>
  );
}
