/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  BaseQueryApi,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import {logout, setStorageToken} from "./auth/auth-slice";
import {logout as userLogout} from "./user/user-slice";
import {RefreshTokenCommand, TokenInfo} from "./auth/auth-api";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
  credentials: "include",
  prepareHeaders: (headers, {getState}) => {
    const tokenStorage = getStoredToken(getState());
    if (tokenStorage.access_token) {
      if (tokenStorage.access_token.trim().length > 0) {
        headers.set("authorization", `Bearer ${tokenStorage.access_token}`);
      }
    }
    return headers;
  },
});

const anonymousQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
  credentials: "include",
});

const getCurrentRootAddress = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;
};

const backToLoginPage = (api: BaseQueryApi) => {
  api.dispatch(logout());
  api.dispatch(userLogout());
  location.replace(getCurrentRootAddress() as string);
};

const getStoredToken = (state: any) => state.authState;

export type ExtraOptionsPortal = {
  alwaysAnonymous?: any;
};

let refreshTokenPromise: any = null;

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions: ExtraOptionsPortal) => {
  let result;
  if (extraOptions?.alwaysAnonymous) {
    result = await anonymousQuery(args, api, extraOptions);
  } else {
    result = await baseQuery(args, api, extraOptions);
  }

  const requestError: any = result?.error;
  if (
    requestError &&
    requestError?.originalStatus === 500 &&
    requestError?.error?.includes("Unexpected token")
  ) {
    backToLoginPage(api);
  }

  if (result.error && result.error.status === 401) {
    const token = getStoredToken(api.getState());
    const refreshToken = token.refresh_token;

    if (!refreshToken?.length) {
      backToLoginPage(api);
    }

    const refreshTokenCommand: RefreshTokenCommand = {
      refreshToken,
    };

    if (!refreshTokenPromise) {
      refreshTokenPromise = baseQuery(
        {
          url: "/api/Token/refresh/",
          method: "POST",
          body: refreshTokenCommand,
        },
        api,
        extraOptions,
      );
    }

    try {
      const refreshResult = await refreshTokenPromise;
      if (refreshResult && !refreshResult.error) {
        api.dispatch(
          setStorageToken(refreshResult.data as unknown as TokenInfo),
        );

        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } else {
        backToLoginPage(api);
      }
    } finally {
      refreshTokenPromise = null;
    }
  }

  return result;
};

export const basePortalApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
