import React from "react";
import {CircularProgress, Stack} from "@mui/material";
import AdditionalServiceCardParcels from "./additionalServiceCardParcels.component";
import Grid from "@mui/material/Unstable_Grid2";
import {AdditionalServicesListProps} from "./interfaces";
import {getCustomValue} from "../../../../utils/helper.utils";
import i18next from "i18next";

export default function AdditionalServicesListParcels({
  additionalServices,
  isLoadingAdditionalServices,
  isLoadingCurrency,
  insuranceAccountingItemCode,
}: AdditionalServicesListProps) {
  const lang = i18next.language;

  return (
    <>
      {isLoadingAdditionalServices && isLoadingCurrency && (
        <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
          <CircularProgress />
        </Grid>
      )}
      <Stack spacing={{xs: 1, md: 2}}>
        {additionalServices?.map((data: any, index: number) => (
          <AdditionalServiceCardParcels
            data-testid={`additional-service-card-${index}`}
            key={data.accountingItemId}
            index={index}
            serviceData={data}
            insuranceAccountingItemCode={insuranceAccountingItemCode}
          >
            {getCustomValue(data?.customValues, `service_description_${lang}`)}
          </AdditionalServiceCardParcels>
        ))}
      </Stack>
    </>
  );
}
