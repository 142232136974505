import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";
import ProgressBar from "./progressBar";
import PaymentVerificationForm from "./paymentVerificationFragment";
import PhoneVerificationForm from "./phoneVerificationFragment";
import MainMobileHeader from "../../common/headers/components/mainMobileHeader";
import AddressVerification from "./addressVerificationFragment";
import {VerificationProgressContainerProps} from "./interfaces";
import EmailVerificationFragment from "./emailVerificationFragment";
import {Trans} from "react-i18next";
import MainMobileFooter from "../../common/footers/components/mainMobileFooter";
import UserAcknowledgementFragment from "./userAknowledgementFragment";
import {RootState, useAppSelector} from "../../store";
import PhoneFragment from "./phoneFragment";

export const shorterStepsText = [
  "Email",
  "Phone Number",
  "Contact Details",
  "Payment Details",
  "User Acknowledgement",
];
export const stepText = [
  "email address",
  "phone number",
  "contact and address details",
  "payment information",
  "acknowledgement",
];

export default function Verification({
  stepIndex,
  handlePrev,
  handleNext,
  steps,
}: VerificationProgressContainerProps) {
  const usePhoneVerification =
    useAppSelector(
      (state: RootState) =>
        state.organizationConfigState?.modules?.verification
          ?.usePhoneVerification,
    ) ?? true;

  const renderVerificationFragment = () => {
    const currentStep = steps[stepIndex];
    switch (currentStep.name) {
      case "email":
        return (
          <EmailVerificationFragment
            step={currentStep}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        );
      case "phone":
        return usePhoneVerification ? (
          <PhoneVerificationForm
            step={currentStep}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        ) : (
          <PhoneFragment
            step={currentStep}
            handlePrev={handlePrev}
            handleNext={handleNext}
            onSkip={currentStep?.required ? null : handleNext}
          />
        );
      case "address":
        return (
          <AddressVerification
            step={currentStep}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case "paymentMethod":
        return (
          <PaymentVerificationForm
            step={currentStep}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case "acknowledgement":
        return (
          <UserAcknowledgementFragment
            step={currentStep}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
    }
  };

  const isLastStep = stepIndex === steps.length - 1;

  return (
    <>
      <Grid
        md={8}
        sx={{
          display: {xs: "none", md: "block"},
          paddingTop: "73px",
          paddingBottom: "73px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Grid container columns={8}>
          <Grid md={1} />
          <Grid md={6}>
            <Grid
              container
              direction={"column"}
              rowGap={8}
              sx={{height: "100%"}}
            >
              <Grid>
                <Typography variant={"body2"} color={"gray"}>
                  <Trans
                    i18nKey={
                      isLastStep
                        ? "finalVerificationCongratMessage"
                        : "verificationCongratsMessage"
                    }
                  >
                    Congratulations! You are almost here!
                  </Trans>
                </Typography>
              </Grid>
              <Grid>
                <ProgressBar
                  variant="linear"
                  stepIndex={stepIndex}
                  steps={steps}
                />
              </Grid>
              <Grid>{renderVerificationFragment()}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* mobile */}
      <Grid xs={6} sx={{display: {xs: "block", md: "none"}}}>
        <Grid
          container
          direction={"column"}
          justifyContent={"space-between"}
          sx={{height: "100%"}}
        >
          <MainMobileHeader handlePrev={handlePrev} step={stepIndex} />
          <Grid flexGrow={1}>
            <Grid
              container
              direction={"column"}
              rowGap={2}
              sx={{height: "100%"}}
            >
              <Grid
                sx={{
                  mt: "-4px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  position: "sticky",
                  top: "56px",
                }}
              >
                <ProgressBar
                  variant="radial"
                  stepIndex={stepIndex}
                  steps={steps}
                />
              </Grid>
              <Grid
                id={"content"}
                flexGrow={1}
                sx={{
                  paddingTop: "16px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "12px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #D8E6FB",
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                  boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
                  zIndex: "20",
                }}
              >
                {renderVerificationFragment()}
              </Grid>
            </Grid>
          </Grid>
          <MainMobileFooter />
        </Grid>
      </Grid>
    </>
  );
}
