import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
  TokenGetApiArg,
  useTokenGetMutation,
} from "../../features/auth/auth-api";
import {
  useLazyUserGetInfoQuery,
  UserRegistrationRegisterApiArg,
  useUserRegistrationRegisterMutation,
} from "../../features/user/user-api";
import {useAppSelector} from "../../store";
import {useGoogleAnalytics} from "../../common/GoogleAnalitics/googleAnalitics.context";
import {toastError} from "../../common/utils/toastMessages";
import i18next from "i18next";

export const useRegisterSubmitHandler = (setPromoCodeErrors: any) => {
  const userContactId = useAppSelector(
    (state: any) => state.userState.contactId,
  );

  const {sendEvent} = useGoogleAnalytics();

  const [
    registerUser,
    {isLoading: isRegisterLoading, isSuccess, isError, error: registerError},
  ] = useUserRegistrationRegisterMutation();

  const [getToken, {isLoading: isTokenLoading, isSuccess: isTokenSuccess}] =
    useTokenGetMutation();

  const [getUserInfo, {isFetching: isUserLoading, isSuccess: isUserSuccess}] =
    useLazyUserGetInfoQuery();

  const onSubmitHandler = async (values: any) => {
    const commandArgs: UserRegistrationRegisterApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      createUserCommandValues: values,
    };
    const registerResponse = await registerUser(commandArgs);
    if ((registerResponse as any)?.error) {
      return false;
    }
    if ((registerResponse as any)?.data?.promoCodeErrors) {
      toastError(
        (registerResponse as any)?.data?.promoCodeErrors[i18next.language],
      );
      setPromoCodeErrors((registerResponse as any)?.data?.promoCodeErrors);
      return false;
    } else {
      setPromoCodeErrors({});
    }
    const tokenArgs: TokenGetApiArg = {
      getTokenCommand: {
        email: commandArgs.createUserCommandValues.email,
        password: commandArgs.createUserCommandValues.password,
      },
    };
    await getToken(tokenArgs);
    await getUserInfo();

    return true;
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserLoading && isUserSuccess) {
      navigate("../verifications" + location.search, {replace: true});
    }
  }, [isUserLoading, isUserSuccess]);

  useEffect(() => {
    if (isSuccess && isUserSuccess) {
      /*ReactGA.event({
        category: "User",
        action: "registration",
        label: userContactId as string,
      });*/
      sendEvent("user", "registration", userContactId as string, 1);
    }
  });

  return {
    isLoading: isRegisterLoading,
    onSubmitHandler,
  };
};
