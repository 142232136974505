import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../store";
import {logout as userLogout} from "../../features/user/user-slice";
import {logout as authLogout} from "../../features/auth/auth-slice";
import {logout as parcelLogout} from "../../features/order/parcelShipment-slice";

import {
  VerificationStatus,
  VerificationType,
} from "../../features/organizationConfig/organizationConfig-slice";
import {
  logout as verificationLogout,
  setIsVerified,
  setStep,
  VerificationState,
} from "../../features/verification/verification-slice";
import {useNavigate, useSearchParams} from "react-router-dom";

export type VerificationStep = {
  name: VerificationType;
  required?: boolean;
  disabled?: boolean;
  verified?: boolean;
  text?: string;
};

export type Confirmations = {
  emailConfirmed: boolean;
  phoneNumberConfirmed: boolean;
  contactAddressConfirmed: boolean;
  paymentMethodConfirmed: boolean;
  acknowledgementConfirmed: boolean;
};

export const useVerificationSteps = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("return_url");

  const verifications = useSelector(
    (state: RootState) =>
      state.organizationConfigState?.modules?.verification?.steps,
  );

  const {
    emailConfirmed = false,
    phoneNumberConfirmed = false,
    contactAddressConfirmed = false,
    paymentMethodConfirmed = false,
    acknowledgementConfirmed = false,
  } = useAppSelector((state: RootState) => state.userState) as Confirmations;

  const verificationState: VerificationState = useAppSelector(
    (state: RootState) => state.verificationState,
  );

  const verificationSteps = useMemo(() => {
    if (verifications) {
      return Object.keys(verifications)
        .map((key: string) => {
          const verificationStatus: VerificationStatus =
            verifications[key as VerificationType] || "disabled";
          let verified = false;
          switch (key as VerificationType) {
            case "email":
              verified = emailConfirmed;
              break;
            case "phone":
              verified = phoneNumberConfirmed;
              break;
            case "address":
              verified = contactAddressConfirmed;
              break;
            case "paymentMethod":
              verified = paymentMethodConfirmed;
              break;
            case "acknowledgement":
              verified = acknowledgementConfirmed;
          }
          return {
            name: key as VerificationType,
            required: verificationStatus == "required",
            disabled: verificationStatus == "disabled",
            verified,
          };
        })
        .filter((step) => !step.disabled);
    }
    return [];
  }, [
    verifications,
    emailConfirmed,
    phoneNumberConfirmed,
    contactAddressConfirmed,
    paymentMethodConfirmed,
    acknowledgementConfirmed,
  ]);

  const initialStep = verificationSteps.findIndex((step) => !step.verified);
  useEffect(() => {
    dispatch(
      setIsVerified({
        isEmailVerified: emailConfirmed,
        isPhoneVerified: phoneNumberConfirmed,
        isAddressVerified: contactAddressConfirmed,
        isPaymentVerified: paymentMethodConfirmed,
        isAcknowledgementVerified: acknowledgementConfirmed,
      }),
    );
    if (verificationState.step < initialStep) {
      dispatch(setStep(initialStep));
    }
  }, [
    emailConfirmed,
    phoneNumberConfirmed,
    contactAddressConfirmed,
    paymentMethodConfirmed,
    initialStep,
  ]);

  const handleExitVerification = () => {
    dispatch(setStep(-1));
    navigate(returnUrl ? returnUrl : "../getting-started");
  };

  const handlePrev = () => {
    if (verificationSteps?.length > 0) {
      let newStep = verificationState?.step;
      while (newStep > 0 && verificationSteps[newStep - 1].verified) {
        newStep = newStep - 1;
      }
      newStep -= 1;
      if (newStep === 0 && verificationSteps[newStep].verified) {
        handleExitVerification();
      } else if (newStep < 0) {
        dispatch(userLogout());
        dispatch(authLogout());
        dispatch(parcelLogout());
        dispatch(verificationLogout());
      } else {
        dispatch(setStep(newStep));
      }
    } else {
      handleExitVerification();
    }
  };

  const handleNext = () => {
    if (verificationSteps?.length > 0) {
      let newStep = verificationSteps
        .slice(verificationState.step + 1)
        .findIndex((s) => {
          return !s.verified && !s.disabled;
        });
      if (newStep > -1) {
        newStep = newStep + verificationState.step + 1;
      }
      if (newStep >= verificationSteps.length || newStep === -1) {
        handleExitVerification();
      } else {
        dispatch(setStep(newStep));
      }
    } else {
      handleExitVerification();
    }
  };

  const verified = verificationSteps.every(
    (step) => !step.required || (step.required && step.verified),
  );

  const fullyVerified = verificationSteps.every((step) => step.verified);

  return {
    initialStep,
    verificationSteps,
    handleNext,
    handlePrev,
    verified,
    fullyVerified,
    handleExitVerification,
  };
};
