import React, {useEffect, useState} from "react";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import ParcelShipmentDetails from "../components/parcelShipmentDetails";
import {useParams} from "react-router-dom";
import {
  GetOrdersCommandValues,
  OrderGetOrdersApiArg,
  QueryKeysEnum,
  useOrderGetOrdersGQLMutation,
} from "../../../features/order/order-api";
import {useTranslation} from "react-i18next";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import {useAppSelector} from "../../../store";
import Grid from "@mui/material/Unstable_Grid2";
import {mapI18LangToCulture} from "../../../common/utils/mapI18LangToCulture";
import i18next from "i18next";

const ParcelShipmentInfo = () => {
  type UrlParams = {orderId: string; purchaseId: string};
  const lang = i18next.language;
  const {orderId} = useParams<keyof UrlParams>() as UrlParams;
  const [parcelShipment, setParcelShipment] = useState<any>(null);
  const [ordersIsLoading, setOrdersIsLoading] = useState<boolean>(true);
  const [allowToShow, setAllowToShow] = useState<boolean>(true);
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const currentCustomerId = useAppSelector(
    (state) => state.userState.contactId,
  );

  const filter = `orderType:ParcelShipment AND orderId:${orderId} AND NOT OrderStatus.OrderStatusName:"Canceled"`;
  const [getOrders] = useOrderGetOrdersGQLMutation();

  const fetchOrders = async (request: GetOrdersCommandValues) => {
    const commandArgs: OrderGetOrdersApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      getOrdersCommandValues: request,
    };
    return getOrders(commandArgs);
  };

  useEffect(() => {
    setOrdersIsLoading(true);
    fetchOrders({
      search: "",
      sort: "-created",
      limit: 1,
      offset: 0,
      filter,
      queryKey: QueryKeysEnum.ParcelShipmentsInformation,
      lang: mapI18LangToCulture(lang),
    })
      .then((response: any) => {
        if (
          Array.isArray(response.data.orders.items) &&
          response.data.orders.items.length > 0
        ) {
          setParcelShipment(response.data.orders.items[0]);
          setAllowToShow(
            response.data.orders.items[0].billToContactId == currentCustomerId,
          );
        }
      })
      .finally(() => setOrdersIsLoading(false));
  }, [filter]);

  return (
    <MainLayout pageTitle="Parcel Information" backLink="../parcelShipments">
      {!allowToShow || ordersIsLoading ? (
        <Grid
          container
          justifyContent={"center"}
          sx={{height: "100%", alignItems: "center"}}
        >
          <Typography mb={2} variant={"h2"} color={"primary.dark"}>
            {ordersIsLoading ? t("loading") : t("youHaveNoRights")}
          </Typography>
        </Grid>
      ) : (
        parcelShipment && (
          <ContentLayout
            title={t("parcelShipments.shipmentDetails.shipmentTracking")}
            withoutTitle={isDesktop}
            backLink={"../parcelShipments"}
            content={<ParcelShipmentDetails parcelShipment={parcelShipment} />}
          />
        )
      )}
    </MainLayout>
  );
};

export default ParcelShipmentInfo;
