import {
  GetContactPaymentMethodsApiArg,
  usePaymentMethodsGetPaymentMethodsMutation,
} from "../../../features/paymentMethod/paymentMethod-api";

export const useGetPaymentMethods = () => {
  const [getPaymentMethods, {isLoading}] =
    usePaymentMethodsGetPaymentMethodsMutation();

  const fetchPaymentMethods = async () => {
    const commandArgs: GetContactPaymentMethodsApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
    };
    return getPaymentMethods(commandArgs);
  };

  return {
    fetchPaymentMethods,
    isLoading,
  };
};
