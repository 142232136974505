import React from "react";
import {Button, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Trans} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";

export default function SuccessModal() {
  return (
    <Grid px={{xs: 0, md: 2}}>
      <CheckCircleIcon color={"primary"} sx={{fontSize: "56px"}} />
      <Typography
        data-testid="modal-modal-title"
        variant="subtitle1"
        component="h6"
        sx={{fontWeight: "bold"}}
      >
        <Trans i18nKey="passwordChangedTitle">
          Password changed successfully
        </Trans>
      </Typography>
      <Typography
        data-testid="modal-modal-description"
        variant="subtitle1"
        component="h6"
      >
        <Trans i18nKey="passwordChangedSubtitle">
          You can continue logging
        </Trans>
      </Typography>
      <Button
        fullWidth
        type="button"
        variant="contained"
        color="secondary"
        href="../../login"
        sx={{mt: 3}}
      >
        OK
      </Button>
    </Grid>
  );
}
