import React from "react";
import SplitPage from "../../common/SplitPage/components/splitPage";
import {useUserGetInfoQuery} from "../../features/user/user-api";
import {useAppSelector} from "../../store";
import VerificationBackground from "../components/verificationBackground";
import VerificationProgressContainer from "../components/verificationProgressContainer";
import {useVerificationSteps} from "../../common/OrganizationConfig/useVerificationSteps";
import {VerificationState} from "../../features/verification/verification-slice";

const Verifications = () => {
  useUserGetInfoQuery();

  const verificationState: VerificationState = useAppSelector(
    (state: any) => state.verificationState,
  );

  const {
    initialStep,
    handlePrev,
    handleNext,
    verificationSteps,
    handleExitVerification,
  } = useVerificationSteps();

  const stepIndex =
    verificationState.step > 0 ? verificationState.step : initialStep;

  if (stepIndex === -1) {
    handleExitVerification();
  } else
    return (
      <SplitPage
        contentSide="right"
        main={
          <VerificationProgressContainer
            stepIndex={stepIndex}
            handlePrev={handlePrev}
            handleNext={handleNext}
            steps={verificationSteps}
          />
        }
        pageTitle="Verification"
        backside={
          <VerificationBackground
            steps={verificationSteps}
            stepIndex={stepIndex}
            handlePrev={handlePrev}
          />
        }
        background={"#F5F9FF"}
      />
    );

  return <div></div>;
};

export default Verifications;
