import {
  AccountingItemsGetInsuranceApiArg,
  useAccountingItemsGetInsuranceQuery,
} from "../../features/accountingItem/accountingItems-api";
import {
  CurrencyGetCurrenciesApiArg,
  useCurrencyGetCurrenciesQuery,
} from "../../features/currencies/currencies-api";
import {useAppSelector} from "../../store";

export const useInsuranceGet = (insuranceAccountingItemCode: string) => {
  const userContactId = useAppSelector(
    (state: any) => state.userState.contactId,
  );

  const args: AccountingItemsGetInsuranceApiArg | CurrencyGetCurrenciesApiArg =
    {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      getInsuranceCommandValues: {
        insuranceAccountingItemCode,
      },
    };

  const {
    data: insuranceData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useAccountingItemsGetInsuranceQuery(args);

  const {
    data: currenciesData,
    isLoading: isLoadingCurrency,
    isSuccess: isSuccessCurrency,
    isError: isErrorCurrency,
    error: errorCurrency,
  } = useCurrencyGetCurrenciesQuery(args);

  return {
    insuranceData,
    isLoading,
    isSuccess,
    isError,
    error,
    currenciesData,
    isLoadingCurrency,
    isSuccessCurrency,
    isErrorCurrency,
    errorCurrency,
    userContactId,
  };
};
