import {basePortalApi as api} from "../basePortalApi";
import {
  setAcknowledgementConfirmed,
  setUserAddressConfirmed,
  setUserEmailConfirmed,
  setUserPaymentConfirmed,
  setUserPhoneConfirmed,
  setUserPhoneNumber,
} from "../user/user-slice";
import {toastError} from "../../common/utils/toastMessages";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    verificationPhoneCode: build.mutation<
      VerificationPhoneCodeApiResponse,
      VerificationPhoneCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/userVerification/sendPhoneCode`,
        method: "POST",
        body: queryArg.sendPhoneCodeCommand,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    verificationVerifyCode: build.mutation<
      VerificationVerifyCodeApiResponse,
      VerificationVerifyCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/userVerification/verifyCode`,
        method: "POST",
        body: queryArg.verifyPhoneCodeCommand,
      }),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
          dispatch(setUserPhoneNumber(args.verifyPhoneCodeCommand.phoneNumber));
          dispatch(setUserPhoneConfirmed());
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    verificationChargedAmount: build.mutation<
      VerificationChargedAmountApiResponse,
      VerificationChargedAmountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/userVerification/sendChargedAmount`,
        method: "POST",
        body: queryArg.sendChargedAmountCommand,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    verificationVerifyChargedAmount: build.mutation<
      VerificationVerifyChargedAmountApiResponse,
      VerificationVerifyChargedAmountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/userVerification/verifyChargedAmount`,
        method: "POST",
        body: queryArg.verifyChargedAmountCommand,
      }),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
          dispatch(setUserPaymentConfirmed());
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    verificationEmailCode: build.mutation<
      VerificationEmailCodeApiResponse,
      VerificationEmailCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/userVerification/sendNewEmailCode`,
        method: "POST",
        body: queryArg.sendNewEmailCodeCommand,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    verificationVerifyEmail: build.mutation<
      VerificationVerifyEmailApiResponse,
      VerificationVerifyEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/userVerification/verifyEmail`,
        method: "POST",
        body: queryArg.verifyEmailCommand,
      }),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
          dispatch(setUserEmailConfirmed());
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    verificationVerifyAddress: build.mutation<
      VerificationVerifyAddressApiResponse,
      VerificationVerifyAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/userVerification/verifyAddress`,
        method: "POST",
        body: queryArg.verifyAddressCommandValues,
      }),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
          dispatch(setUserAddressConfirmed());
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    verificationVerifyAcknowledgement: build.mutation<
      VerificationVerifyAcknowledgementApiResponse,
      VerificationVerifyAcknowledgementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/userVerification/verifyAcknowledgement`,
        method: "POST",
        body: queryArg.verifyAcknowledgementCommand,
      }),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
          dispatch(setAcknowledgementConfirmed());
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type VerificationPhoneCodeApiResponse =
  /** status 200  */ CreateContactDto;
export type VerificationPhoneCodeApiArg = {
  organizationId: number;
  sendPhoneCodeCommand: SendPhoneCodeCommand;
};
export type VerificationVerifyCodeApiResponse =
  /** status 200  */ CreateContactDto;
export type VerificationVerifyCodeApiArg = {
  organizationId: number;
  verifyPhoneCodeCommand: VerifyPhoneCodeCommand;
};
export type VerificationChargedAmountApiResponse = any;
export type VerificationChargedAmountApiArg = {
  organizationId: number;
  sendChargedAmountCommand: SendChargedAmountCommand;
};
export type VerificationVerifyChargedAmountApiResponse = unknown;
export type VerificationVerifyChargedAmountApiArg = {
  organizationId: number;
  verifyChargedAmountCommand: VerifyChargedAmountCommand;
};
export type VerificationVerifyAddressApiResponse =
  /** status 201  */ ContactAddressDto;
export type VerificationVerifyAddressApiArg = {
  organizationId: number;
  verifyAddressCommandValues: VerifyAddressCommandValues;
};
export type AddressType = "Billing" | "Shipping" | "Other";
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type ContactAddressDto = {
  contactAddressId?: number;
  addressLine?: string | null;
  addressLine2?: string | null;
  addressType?: AddressType;
  cityName?: string | null;
  contactId?: number;
  countryCode?: string | null;
  countryName?: string | null;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  postalCode?: string | null;
  stateCode?: string | null;
  stateName?: string | null;
  links?: LinkDto[] | null;
};
export type PaymentTermDto = {
  paymentTermId?: number;
  description?: string | null;
  discountPaidWithinDays?: number | null;
  discountPercentage?: number | null;
  isInactive?: boolean;
  netDueDays?: number;
  organizationId?: number;
  links?: LinkDto[] | null;
};
export type ContactType =
  | "Customer"
  | "Carrier"
  | "Vendor"
  | "Contact"
  | "Driver"
  | "Employee"
  | "SalesPerson"
  | "ForwardingAgent"
  | "FactoringCompany"
  | "Lead";
export type IdNumberType = "EIN" | "DUNS" | "ForeignEntityId" | "Other";
export type ContactLinkType =
  | "ParentContact"
  | "FactoringCompany"
  | "SalesPerson";
export type VisibleTransactions =
  | "AllTransactions"
  | "DivisionOnly"
  | "DivisionAndDivisionless";
export type ApplicationUserRoleDto = {
  id?: string | null;
  name?: string | null;
  normalizedName?: string | null;
};
export type UserGroupDto = {
  userGroupId?: number;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  name?: string | null;
  organizationId?: number;
  userRoles?: ApplicationUserRoleDto[] | null;
  links?: LinkDto[] | null;
};
export type UserEmployeeDto = {
  userEmployeeId?: number;
  created?: string;
  createdBy?: string | null;
  employeeContactId?: number;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  userId?: string | null;
  visibleTransactions?: VisibleTransactions;
  employeeContactFirstName?: string | null;
  employeeContactLastName?: string | null;
  employeeName?: string | null;
  userFirstName?: string | null;
  userLastName?: string | null;
  userNormalizedUserName?: string | null;
  userUserName?: string | null;
  employeeDivisionId?: number;
  userGroups?: UserGroupDto[] | null;
  links?: LinkDto[] | null;
};
export type PaidAs = "Prepaid" | "Collect";
export type CarrierEquipmentDto = {
  carrierId?: number;
  equipmentTypeId?: number;
  quantity?: number;
};
export type CreateContactDto = {
  contactAddresses?: ContactAddressDto[] | null;
  paymentTerm?: PaymentTermDto | null;
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  linkToContactId?: number | null;
  linkToContactName?: string | null;
  contactLinkType?: ContactLinkType;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto | null;
  creditLimit?: number | null;
  paidAs?: PaidAs;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  additionalProperties?: {
    [key: string]: any;
  } | null;
  [key: string]: any | null;
};
export type SendPhoneCodeCommand = {
  phoneNumber?: string;
  sendingType?: string | null;
};
export type VerifyPhoneCodeCommand = {
  verificationCode?: string;
  phoneNumber?: string;
  username?: string | null;
};
export type SendChargedAmountCommand = {
  cardNumber: string;
  holderName: string;
  expDate: string;
  code: string;
  contactId?: number | null;
  cardType?: string;
  contactPaymentMethodId?: string | null;
};
export type VerifyChargedAmountCommand = {
  contactPaymentMethodId?: string | null;
  chargedAmount: string;
  sendChargedAmount: string;
  verifyChargedAmount: string;
  username: string | null | undefined;
};

export type VerificationEmailCodeApiResponse = /** status 200  */ unknown;
export type VerificationEmailCodeApiArg = {
  organizationId: number;
  sendNewEmailCodeCommand: SendNewEmailCodeCommand;
};
export type SendNewEmailCodeCommand = {
  email?: string;
  organizationId: number;
};
export type VerificationVerifyEmailApiResponse = unknown;
export type VerificationVerifyEmailApiArg = {
  organizationId: number;
  verifyEmailCommand: SendVerifyEmailCommand;
};
export type SendVerifyEmailCommand = {
  verificationCode: string;
};
export type VerifyAddressCommandValues = {
  contactId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  countryCode?: string | null;
  regionCode?: string | null;
  district?: string | null;
  city?: string | null;
  postalCode?: string | null;
  streetName?: string | null;
  houseNumber?: string | null;
  apartment?: string | null;
  customValues?: {
    [key: string]: string;
  } | null;
};
export type VerificationVerifyAcknowledgementApiResponse =
  /** status 204  */ undefined;
export type VerificationVerifyAcknowledgementApiArg = {
  organizationId: number;
  verifyAcknowledgementCommand: VerifyAcknowledgementCommand;
};
export type VerifyAcknowledgementCommand = {
  isConfirmed: boolean;
};
export const {
  useVerificationPhoneCodeMutation,
  useVerificationVerifyCodeMutation,
  useVerificationChargedAmountMutation,
  useVerificationVerifyChargedAmountMutation,
  useVerificationVerifyEmailMutation,
  useVerificationEmailCodeMutation,
  useVerificationVerifyAddressMutation,
  useVerificationVerifyAcknowledgementMutation,
} = injectedRtkApi;
