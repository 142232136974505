import React from "react";
import {Button, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "../../../common/components/link";

interface ConfirmationModalProps {
  trackingNumber: number | undefined;
  parcelId?: number;
  onClose?: any;
}

export default function ConfirmationModal({
  trackingNumber,
  parcelId,
  onClose,
}: ConfirmationModalProps) {
  return (
    <Grid px={{xs: 0, md: 2}}>
      <Typography
        data-testid="modal-modal-title"
        variant="subtitle1"
        component="h6"
        sx={{fontWeight: "normal"}}
      >
        <Trans i18nKey="delivery.confirmation.modalTitle">
          Once we weigh your shipment and it&apos;s ready to be shipped,
          <br />
          we&apos;ll send you an invoice with the total cost
        </Trans>
      </Typography>
      <Typography
        data-testid="modal-modal-description"
        variant="subtitle1"
        component="h6"
      >
        <Trans i18nKey="delivery.confirmation.modalSubtext">
          Your shipment request has been successfully submitted!
          <br />
          Your tracking number
        </Trans>
        <span data-testid="modal-modal-tracking-number">
          <b>{trackingNumber}</b>
        </span>
      </Typography>
      <Link to={`../parcelShipments/${parcelId}`}>
        <Button
          data-testid="btn-ok-modal-modal"
          fullWidth
          variant="contained"
          color="secondary"
          sx={{mt: 3}}
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          OK
        </Button>
      </Link>
    </Grid>
  );
}
