import React, {useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {SvgIcon} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
import ExtraMenuIcon from "../../../../images/extraMenu.svg";
import ExtraMenuEnabledIcon from "../../../../images/extraMenuEnabled.svg";
import ExtraMenu from "./ExtraMenu";
import {GettingStartedModule} from "../../../GettingStarted/getting-started.module";
import {PurchasesModule} from "../../../Purchases/purchases.module";
import {ParcelModule} from "../../../ParcelShipments/parcel.module";
import {DeliveryModule} from "../../../Delivery/delivery.module";
import {InvoicesModule} from "../../../Invoices/invoices.module";
import {MenuItemType} from "../../../core/base.module";

export default function MainMobileFooter() {
  const menuButtonSize = "24px";
  const [isExtraMenuOpen, setExtraMenuOpen] = useState(false);
  const location = useLocation();

  const handleIconClick = () => {
    setExtraMenuOpen(!isExtraMenuOpen);
  };

  const menuItems: MenuItemType[] = [
    ...GettingStartedModule.getInstance().getMenuItems(),
    ...PurchasesModule.getInstance().getMenuItems(),
    ...ParcelModule.getInstance().getMenuItems(),
    ...DeliveryModule.getInstance().getMenuItems(),
    ...InvoicesModule.getInstance().getMenuItems(),
  ];

  return (
    <>
      {isExtraMenuOpen && <ExtraMenu setExtraMenuOpen={setExtraMenuOpen} />}
      <Grid
        display={{xs: "block", md: "none"}}
        sx={{
          height: "64px",
          position: "sticky",
          bottom: "0px",
          boxShadow: "0px 0px 8px rgba(18, 70, 128, 0.2)",
          background: "#FFFFFF",
          zIndex: 1300,
        }}
      >
        <Grid
          container
          justifyContent={"space-around"}
          alignItems={"center"}
          sx={{height: "100%"}}
        >
          {menuItems.map(({page, to, svgIcon}: MenuItemType) => {
            return (
              <NavLink key={page} to={to} style={{textDecoration: "none"}}>
                <SvgIcon
                  component={svgIcon}
                  sx={{fontSize: `${menuButtonSize}`}}
                  color={location.pathname.includes(page) ? "primary" : "info"}
                />
              </NavLink>
            );
          })}

          <div onClick={handleIconClick}>
            <img
              src={isExtraMenuOpen ? ExtraMenuEnabledIcon : ExtraMenuIcon}
              alt="Extra Menu"
              style={{fontSize: `${menuButtonSize}`}}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
