import {Chip} from "@mui/material";
import React from "react";

export default function ClickableChip({
  label,
  color,
  style,
  clicked,
  handleClick,
}: any) {
  return (
    <Chip
      label={label}
      variant={clicked ? "filled" : "outlined"}
      color={color}
      sx={
        style ?? {fontWeight: 500, fontSize: "14px", p: "6px 18px", mr: "8px"}
      }
      onClick={handleClick}
      clickable={false}
    />
  );
}
