import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Button, Typography} from "@mui/material";
import {SelectPurchasesFragmentProps} from "./interfaces";
import {Trans, useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import PurchasesSearch from "../../../Purchases/AllPurchases/components/purchasesSearch";
import PurchaseList from "../../../Purchases/AllPurchases/components/purchaseList";
import {useDispatch} from "react-redux";
import {useOrganizationConfigGetManyQuery} from "../../../features/organizationConfig/organizationConfig-api";
import {getPortalNameFromDomain} from "../../../common/OrganizationConfig/useOrganizationConfig";
import {useNavigate} from "react-router-dom";
import {
  initOrder,
  setStepState,
} from "../../../features/order/parcelShipment-slice";
import i18next from "i18next";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";

export default function SelectPurchasesFragment({
  handleNext,
  pageTitle,
}: SelectPurchasesFragmentProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {orderIds} = useDeliveryHelper();

  const [selectedPurchasesIds, setSelectedPurchasesIds] = useState<number[]>(
    orderIds?.filter((x) => x > 0),
  );
  const [availableForConsolidationFilter, setAvailableForConsolidationFilter] =
    useState<string | undefined>(undefined);

  const organizationId = process.env
    .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number;
  const portalName =
    process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain();
  const {data: configs} = useOrganizationConfigGetManyQuery({
    organizationId,
    portalName,
  });

  useEffect(() => {
    if (configs && configs.items && configs.items.length) {
      const consolidatedShipmentConfig = configs.items.find((config) =>
        config.configName.includes("consolidatedShipment"),
      );
      if (
        consolidatedShipmentConfig &&
        consolidatedShipmentConfig.customValues &&
        "purchaseStatusesAvailableForConsolidation" in
          consolidatedShipmentConfig.customValues
      ) {
        const availableStatuses: string[] =
          consolidatedShipmentConfig.customValues
            .purchaseStatusesAvailableForConsolidation;
        if (availableStatuses && availableStatuses.length) {
          const filter = `(${availableStatuses
            .map((item) => `orderStatus.orderStatusName:"${item}"`)
            .join(" OR ")})`;
          setAvailableForConsolidationFilter(filter);
        }
      }
    }
  }, [configs]);

  const handleNextToTheAddresses = () => {
    const orderIds = selectedPurchasesIds?.filter((x) => x !== 0);
    dispatch(initOrder({orderIds: [...orderIds]}));
    dispatch(
      setStepState({
        orderIds: orderIds,
        step: 0,
        isCompleted: true,
      }),
    );

    navigate(`/${i18next.language}/delivery/${orderIds?.join("&")}/address`);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid sx={{pt: 1}} container columns={{xs: 6, md: 14}}>
        <Grid xs={6} md={14}>
          <Grid mb={4}>
            <Typography component={"div"} variant="body1">
              {t("consolidatedShipment.youCanSetUpAConsolidatedShipment")}
            </Typography>
            <Typography component={"div"} variant="body1">
              {t("consolidatedShipment.onceTheFinalProductArrives")}
            </Typography>
          </Grid>
          <PurchasesSearch />
          <Grid mt={4} mb={2} textAlign={"right"}>
            <Typography component={"span"} variant="h3" mr={2}>
              {selectedPurchasesIds.length}&nbsp;
              {t("consolidatedShipment.purchasesSelected")}
            </Typography>
          </Grid>
          <PurchaseList
            isConsolidated={true}
            setSelectedPurchasesIds={setSelectedPurchasesIds}
            selectedPurchasesIds={selectedPurchasesIds}
            //customFilter={availableForConsolidationFilter}
          />
          <Grid md={3} xs={6}>
            <Button
              data-testid="btn-continue-delivery-method"
              disabled={selectedPurchasesIds.length < 2}
              fullWidth
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleNextToTheAddresses}
              sx={{
                p: 1,
                mb: 3,
              }}
            >
              <Trans i18nKey="btnContinue">Continue</Trans>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
