import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    accountingTransactionPaymentsGetAccountingTransactionPayments:
      build.mutation<
        AccountingTransactionPaymentGetAccountingTransactionPaymentsApiResponse,
        AccountingTransactionPaymentsGetAccountingTransactionPaymentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.organizationId}/accountingTransactionPayments/getAccountingTransactionPayments`,
          method: "POST",
          body: queryArg.getAccountingTransactionsQueryValues,
        }),
      }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};

export type AccountingTransactionPaymentGetAccountingTransactionPaymentsApiResponse =
  /** status 200  */ AccountingTransactionPaymentDtoPagedResult;

export type AccountingTransactionPaymentsGetAccountingTransactionPaymentsApiArg =
  {
    organizationId: number;
    getAccountingTransactionsQueryValues: GetAccountingTransactionPaymentsQueryValues;
  };

export type PaymentDto = {
  paymentId: number;
  accountingAccountId: number;
  amountReceived: number;
  applyToContactId: number;
  checkNumber?: string | null;
  divisionId?: number;
  organizationId: number;
  paymentDate: string;
  accountingTransactionPayments?: AccountingTransactionPaymentDto[];
  currencyDecimals?: number;
};

export type AccountingTransactionPaymentDto = {
  accountingTransactionId: number;
  amountApplied: number;
};

export type AccountingTransactionPaymentDtoPagedResult = {
  accountingTransactionPayments: any;
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: PaymentDto[] | null;
  links?: LinkDto[] | null;
};

export type GetAccountingTransactionPaymentsQueryValues = {
  limit?: number | null;
  offset?: number | null;
  sort?: string;
  filter?: string;
  search?: string;
};

export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};

export const {
  useAccountingTransactionPaymentsGetAccountingTransactionPaymentsMutation,
} = injectedRtkApi;
