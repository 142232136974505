import React, {useCallback} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import {Trans} from "react-i18next";
import InvoiceDetailsTable from "./invoiceDetailsTable";
import {AccountingTransactionGqlDto} from "../../../features/accountingTransaction/accountingTransaction-api";
import {t} from "i18next";
import Link from "../../../common/components/link";
import {getCustomValue} from "../../../../utils/helper.utils";
import {OrderCommodity} from "../../../features/order/order-api";

export type InvoiceDetailsInfoProps = {
  item: AccountingTransactionGqlDto | undefined | null;
};

export default function InvoiceDetailsInfo({item}: InvoiceDetailsInfoProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const contactContextValue = item?.applyToContact;
  const invoiceContactAddress = item?.billToContactAddress;

  const whiteSpace = "\u0020";

  const invoiceBillToHeaders = [
    t("invoices.name"),
    t("invoices.zipCode"),
    t("country"),
    t("delivery.confirmation.billingAddress"),
    t("progressBarPhone"),
  ];

  const getAddressField = (field: any) => {
    if (field && field !== "") return `${field}, `;
    return "";
  };

  const getFormatAddress = () => {
    return `${getAddressField(invoiceContactAddress?.state?.name)}
    ${getAddressField(invoiceContactAddress?.cityName)}
    ${getAddressField(invoiceContactAddress?.addressLine)}
    ${getAddressField(
      getCustomValue(
        invoiceContactAddress?.customValues,
        "toAddressHouseNumber",
      ),
    )}
    ${getAddressField(
      getCustomValue(invoiceContactAddress?.customValues, "toAddressApartment"),
    )}
    ${getAddressField(invoiceContactAddress?.addressLine2)}`.replace(
      /[,\s]+$/,
      "",
    );
  };

  const initialValuesBillTo = {
    name: item?.applyToContact?.name ?? whiteSpace,
    zipCode: invoiceContactAddress?.postalCode ?? whiteSpace,
    countryName: invoiceContactAddress?.country?.name ?? whiteSpace,
    address: getFormatAddress(),
    phoneNumber: contactContextValue?.mobilePhoneNumber ?? whiteSpace,
  };

  const getOrderCommoditiesTotalCustomerDeclaredWeight = useCallback(
    (orderCommodities: OrderCommodity[] | null | undefined) => {
      if (!orderCommodities) return null;
      let weightTotal = 0;
      let weightUnitTotal: string | null = null;
      orderCommodities.forEach((orderCommodity) => {
        const containerCustomValues = orderCommodity?.commodity?.customValues;
        const weight = Number(
          getCustomValue(containerCustomValues, "customer_declared_weight"),
        );
        if (weight) weightTotal += weight;
        const weightUnit = getCustomValue(
          containerCustomValues,
          "customer_declared_weight_unit",
        )?.toLowerCase();
        if (weightUnit && !weightUnitTotal) weightUnitTotal = weightUnit;
      });
      return weightTotal && weightUnitTotal
        ? [weightTotal, weightUnitTotal].join(" ")
        : null;
    },
    [getCustomValue],
  );

  return (
    <Grid
      container
      md={12}
      mb={4}
      xs={12}
      direction="column"
      sx={{
        border: isDesktop ? "1px solid #2F81EF" : "none",
        fontSize: "16px",
        borderRadius: "16px",
      }}
      columnSpacing={0}
    >
      <Grid
        container
        direction={isDesktop ? "row" : "column"}
        md={12}
        xs={12}
        display={"flex"}
      >
        <Grid width={{md: "50%", sm: "100%", xs: "100%"}}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              height: {md: "70px", xs: "56px"},
              background: isDesktop ? "#f5f9ff" : "#D8E6FB",
              padding: "0 1rem ",
              borderRadius: {md: "16px 0 0 0", xs: "16px 16px 0px 0px"},
              ml: isDesktop ? 0 : -2,
              mr: isDesktop ? 0 : -2,
            }}
          >
            <Typography variant={isDesktop ? "h2" : "h3"}>
              <Trans i18nKey={"invoices.billTo"}>Bill</Trans>
            </Typography>
          </Grid>
          <Grid>
            {isDesktop ? (
              <Grid direction="column" columnSpacing={0} md={12} m={1}>
                <Grid container wrap="nowrap">
                  <Grid
                    md={12}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    marginY={2}
                  >
                    {Object.entries(initialValuesBillTo).map(
                      ([key, value], index) => (
                        <Grid
                          md={12}
                          key={key}
                          ml={1}
                          style={{overflowWrap: "break-word"}}
                          display={"inline-flex"}
                        >
                          <Grid md={2.2}>
                            <Typography variant="body1">
                              {invoiceBillToHeaders[index]}:
                            </Typography>
                          </Grid>
                          <Grid
                            md={8}
                            style={{overflowWrap: "break-word"}}
                            pr={12}
                            pl={1}
                          >
                            <Typography
                              variant="body4"
                              data-testid={`invoice-details-${key}`}
                            >
                              {value}
                            </Typography>
                          </Grid>
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction={"column"}
                justifyContent="flex"
                marginY={2}
                gap={2}
                columnSpacing={0}
              >
                <Grid>
                  <Typography variant={"body1"}>
                    <Trans i18nKey={"invoices.name"}>Name</Trans>:
                  </Typography>
                  <Typography
                    variant={"body4"}
                    data-testid="invoice-details-name"
                  >
                    {item?.applyToContact?.name ?? whiteSpace}
                  </Typography>
                </Grid>

                <Grid>
                  <Typography variant={"body1"}>
                    <Trans i18nKey={"invoices.zipCode"}>Zip-Code</Trans>:
                  </Typography>
                  <Typography
                    variant={"body4"}
                    data-testid="invoice-details-zipCode"
                  >
                    {invoiceContactAddress?.postalCode ?? whiteSpace}
                  </Typography>
                </Grid>

                <Grid>
                  <Typography variant={"body1"}>
                    <Trans i18nKey={"country"}>Country</Trans>:
                  </Typography>
                  <Typography
                    variant={"body4"}
                    data-testid="invoice-details-country"
                  >
                    {invoiceContactAddress?.country?.name ?? whiteSpace}
                  </Typography>
                </Grid>

                <Grid>
                  <Typography variant={"body1"}>
                    <Trans i18nKey={"delivery.confirmation.billingAddress"}>
                      Billing Address
                    </Trans>
                    :
                  </Typography>
                  <Typography
                    variant={"body4"}
                    data-testid="invoice-details-address"
                  >
                    {getFormatAddress()}
                  </Typography>
                </Grid>

                <Grid>
                  <Typography variant={"body1"}>
                    <Trans i18nKey={"phoneLabel"}>Phone number</Trans>:
                  </Typography>
                  <Typography
                    variant={"body4"}
                    data-testid="invoice-details-phoneNumber"
                  >
                    {contactContextValue?.mobilePhoneNumber ?? whiteSpace}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid width={{md: "50%", xs: "100%"}} mt={{xs: 2, md: 0}}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              height: {md: "70px", xs: "56px"},
              background: isDesktop ? "#f5f9ff" : "#D8E6FB",
              padding: {xs: "0 0.5rem ", md: 0},
              borderRadius: {md: "0 16px 0 0", xs: "16px 16px 0px 0px"},
              ml: isDesktop ? 0 : -2,
              mr: isDesktop ? 0 : -2,
            }}
          >
            <Typography variant={isDesktop ? "h2" : "h3"} pl={1}>
              <Trans i18nKey={"shippingInformation"}>
                Shipping information
              </Trans>
            </Typography>
          </Grid>
          {item?.jobs?.map((job) => {
            return job.orders
              ?.filter((o) => o.orderType === "ParcelShipment")
              .map((parcelShipment, index) => {
                const orderId = parcelShipment?.orderId;
                const customerDeclaredWeight =
                  getOrderCommoditiesTotalCustomerDeclaredWeight(
                    parcelShipment?.orderCommodities,
                  );
                const labelColumnsMd = 2.5;
                const valueColumnsMd = 12 - labelColumnsMd;
                return (
                  <Grid
                    key={orderId ?? "" + index}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Grid
                      mt={{md: 3, xs: 2}}
                      ml={{md: 1, xs: 0}}
                      display={{md: "inline-flex"}}
                      container
                    >
                      <Grid md={labelColumnsMd} xs={12}>
                        <Typography variant={"body1"}>
                          <Trans i18nKey={"invoices.shipmentNumber"}>
                            Shipment number
                          </Trans>
                          :
                        </Typography>
                      </Grid>
                      <Grid sx={{pl: {md: 2}}} md={valueColumnsMd} xs={12}>
                        <Link
                          data-testid="invoice-details-shipment-number"
                          to={orderId ? `../parcelShipments/${orderId}` : "#"}
                        >
                          <Typography variant={"body4"} color={"primary"}>
                            # {parcelShipment?.trackingNumber ?? ""}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                    {customerDeclaredWeight && (
                      <Grid
                        mt={{md: 2, xs: 1}}
                        ml={{md: 1, xs: 0}}
                        display={{md: "inline-flex"}}
                        container
                      >
                        <Grid md={labelColumnsMd} xs={12}>
                          <Typography variant={"body1"}>
                            <Trans i18nKey={"invoices.weight"}>Weight</Trans>:
                          </Typography>
                        </Grid>
                        <Grid sx={{pl: {md: 2}}} md={valueColumnsMd} xs={12}>
                          <Typography variant={"body4"}>
                            {customerDeclaredWeight}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                );
              });
          })}
        </Grid>
      </Grid>
      <Grid md={12}>
        <InvoiceDetailsTable invoice={item} />
      </Grid>
    </Grid>
  );
}
