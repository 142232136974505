import React, {useState} from "react";
import {Button, Chip, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../style/horizontalScroll.scss";
import ClickableChip from "../../../common/components/chip";

export default function DeletePurchaseModal({closeModal}: any) {
  const [showWhy, setShowWhy] = useState<boolean>(false);
  const [showYourVariant, setShowYourVariant] = useState<boolean>(false);
  return showWhy ? (
    <Grid>
      <Typography
        data-testid="modal-modal-title"
        variant="h3"
        component="h6"
        p={"0 25px"}
        mb={"4px"}
      >
        Why are you deleting this purchase?
      </Typography>

      <Grid
        container
        flexDirection={"column"}
        alignItems={"start"}
        mt={"12px"}
        width={"100%"}
        overflow={"scroll"}
        className={"why-deleting"}
      >
        <Grid container flexWrap={"nowrap"} mb={"8px"}>
          <ClickableChip
            label="Your variant"
            color="primary"
            clicked={showYourVariant}
            handleClick={() => setShowYourVariant(!showYourVariant)}
          />
          <Chip
            label="The purchase was returned to the store"
            variant="outlined"
            color="success"
            sx={{fontWeight: 500, fontSize: "14px", p: "6px 18px", mr: "8px"}}
            onClick={() => setShowYourVariant(false)}
          />
          <Chip
            label="I chose a different shipping method"
            variant="outlined"
            color="success"
            sx={{fontWeight: 500, fontSize: "14px", p: "6px 18px"}}
            onClick={() => setShowYourVariant(false)}
          />
        </Grid>
        <Grid container flexWrap={"nowrap"}>
          <Chip
            label="Purchase was lost to carriers"
            variant="outlined"
            color="success"
            sx={{fontWeight: 500, fontSize: "14px", p: "6px 18px", mr: "8px"}}
            onClick={() => setShowYourVariant(false)}
          />
          <Chip
            label="Changed my mind"
            variant="outlined"
            color="success"
            sx={{fontWeight: 500, fontSize: "14px", p: "6px 18px", mr: "8px"}}
            onClick={() => setShowYourVariant(false)}
          />
          <Chip
            label="I ordered not what I wanted"
            variant="outlined"
            color="success"
            sx={{fontWeight: 500, fontSize: "14px", p: "6px 18px"}}
            onClick={() => setShowYourVariant(false)}
          />
        </Grid>
      </Grid>

      {showYourVariant && (
        <TextField
          label="Enter your reason"
          placeholder="You can write here whatever you want"
          multiline
          rows={5}
          maxRows={5}
          sx={{width: "100%", mt: "16px"}}
        />
      )}

      <Grid container justifyContent={"space-between"} mt={"24px"}>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          sx={{flex: "0.5", mr: "16px"}}
          onClick={closeModal}
        >
          Skip
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          sx={{flex: "0.5"}}
        >
          Send
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid>
      <Typography
        data-testid="modal-modal-title"
        variant="h3"
        component="h6"
        p={"0 40px"}
        mb={"4px"}
      >
        Do you want to delete this purchase?
      </Typography>
      <Typography
        data-testid="modal-modal-description"
        variant="caption1"
        component="h6"
        color={"primary.dark"}
        fontWeight={500}
      >
        By deleting you agree to the liquidation of our purchase if it arrives
        at the warehouse TRT
      </Typography>
      <Grid container justifyContent={"space-between"} mt={"24px"}>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          sx={{flex: "0.5", mr: "16px"}}
          onClick={closeModal}
        >
          No
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          sx={{flex: "0.5"}}
          onClick={() => setShowWhy(true)}
        >
          Yes
        </Button>
      </Grid>
    </Grid>
  );
}
