import React from "react";
import {styled, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ParcelShipmentTableRow from "./parcelShipmentTableRow";
import {OrderGQLDto} from "../../../features/order/order-api";
import ParcelShipmentCard from "./parcelShipmentCard";
import Grid from "@mui/material/Unstable_Grid2";

const StyledHeaderTableCell = styled(TableCell)({
  padding: "0.92rem 1.375rem",
  border: "2px solid #D8E6FB",
});

export type ParcelShipmentTableProps = {
  parcelShipments: OrderGQLDto[] | undefined | null;
};

export default function ParcelShipmentTable({
  parcelShipments,
}: ParcelShipmentTableProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const tableHeaders = [
    {label: t("parcelShipments.allParcels.trtShipmentNumber"), width: "20%"},
    {label: t("parcelShipments.allParcels.invoice"), width: "10%"},
    {label: t("parcelShipments.allParcels.creationDate"), width: "12%"},
    {label: t("parcelShipments.allParcels.transportMethod"), width: "12%"},
    {label: t("parcelShipments.allParcels.destination"), width: "23%"},
    {label: t("parcelShipments.allParcels.consignee"), width: "12%"},
    {label: t("parcelShipments.allParcels.statusColumn"), width: "150px"},
    {label: t("parcelShipments.allParcels.weight"), width: "10%"},
  ];

  return isDesktop ? (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          tableLayout: "auto",
          border: "2px solid #D8E6FB",
          "& > *": {borderBottom: "unset"},
        }}
        aria-label="shipments table"
      >
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <StyledHeaderTableCell
                align="left"
                key={header.label}
                width={header.width}
              >
                <Typography variant="body4">{header.label}</Typography>
              </StyledHeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {parcelShipments && parcelShipments?.length > 0
            ? parcelShipments?.map((shipment) => {
                return (
                  <ParcelShipmentTableRow
                    key={shipment.orderId}
                    parcelShipment={shipment}
                  />
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Grid
      display={"flex"}
      flexDirection={"column"}
      gap={"16px"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"calc(100vw - 2rem);"}
    >
      {parcelShipments && parcelShipments?.length > 0
        ? parcelShipments?.map((shipment) => {
            return (
              <ParcelShipmentCard
                key={shipment.orderId}
                parcelShipment={shipment}
              />
            );
          })
        : null}
    </Grid>
  );
}
