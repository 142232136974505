import {SubmitHandler} from "react-hook-form";
import {
  CreateOrderPortalCommand,
  Operation,
  OrderCreateOrderApiArg,
  OrderGetOrderApiArg,
  OrderPatchOrderApiArg,
  useOrderCreateOrderMutation,
  useOrderGetOrderMutation,
  useOrderPatchOrderMutation,
} from "../../features/order/order-api";
import {useAppSelector} from "../../store";

export const useParcelShipmentSubmit = () => {
  const userContactId = useAppSelector(
    (state: any) => state.userState.contactId,
  );

  const [createParcelShipment, {isLoading, isError, isSuccess}] =
    useOrderCreateOrderMutation();
  const [getOrder, {isLoading: isOrderLoading}] = useOrderGetOrderMutation();
  const [patchOrder] = useOrderPatchOrderMutation();

  const onSubmit: SubmitHandler<CreateOrderPortalCommand> = (
    values: CreateOrderPortalCommand,
  ) => {
    const commandArgs: OrderCreateOrderApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      createOrderPortalCommand: values,
    };
    return createParcelShipment(commandArgs);
  };

  const getPurchase = async (orderId: number) => {
    const commandArgs: OrderGetOrderApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      orderId: orderId,
    };
    return getOrder(commandArgs);
  };

  const patchParcelShipment = async (
    orderId: number,
    operations: Operation[],
  ) => {
    const commandArgs: OrderPatchOrderApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      orderId: orderId,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      patchOrderCommandValues: {operations: operations},
    };
    return patchOrder(commandArgs);
  };

  return {
    onSubmit,
    getPurchase,
    patchParcelShipment,
    isLoading,
    isError,
    isSuccess,
    isOrderLoading,
    userContactId,
  };
};
