import AmericanExpress from "../../../images/cardTypes/amex.svg";
import Diners from "../../../images/cardTypes/diners.svg";
import Discover from "../../../images/cardTypes/discover.svg";
import Elo from "../../../images/cardTypes/elo.svg";
import Hiper from "../../../images/cardTypes/hiper.svg";
import HiperCard from "../../../images/cardTypes/hipercard.svg";
import JCB from "../../../images/cardTypes/jcb.svg";
import Maestro from "../../../images/cardTypes/maestro.svg";
import Mastercard from "../../../images/cardTypes/mastercard.svg";
import Mir from "../../../images/cardTypes/mir.svg";
import UnionPay from "../../../images/cardTypes/unionpay.svg";
import Visa from "../../../images/cardTypes/visa.svg";
import Unknown from "../../../images/cardTypes/unknown.svg";

export const cardTypesLogos: any = {
  "american-express": AmericanExpress,
  "diners-club": Diners,
  discover: Discover,
  elo: Elo,
  hiper: Hiper,
  hipercard: HiperCard,
  jcb: JCB,
  maestro: Maestro,
  mastercard: Mastercard,
  mir: Mir,
  unionpay: UnionPay,
  visa: Visa,
  unknown: Unknown,
};
