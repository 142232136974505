import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    attachmentFetchAttachments: build.mutation<
      AttachmentFetchAttachmentsApiResponse,
      AttachmentFetchAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/attachments/fetchAttachments`,
        method: "POST",
        body: queryArg.fetchAttachmentsCommandValues,
      }),
    }),
    attachmentGetAttachment: build.mutation<
      AttachmentGetAttachmentApiResponse,
      AttachmentGetAttachmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/attachments/${queryArg.attachmentId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type AttachmentFetchAttachmentsApiResponse =
  /** status 200  */ AttachmentDtoPagedResult;
export type AttachmentFetchAttachmentsApiArg = {
  organizationId: number;
  fetchAttachmentsCommandValues: FetchAttachmentsCommandValues;
};
export type AttachmentGetAttachmentApiResponse =
  /** status 200  */ AttachmentDto;
export type AttachmentGetAttachmentApiArg = {
  organizationId: number;
  attachmentId: number;
};
export type AttachmentType = "Picture" | "OtherDocument";
export type AttachmentParentType =
  | "Order"
  | "Contact"
  | "AccountingTransaction"
  | "EquipmentType";
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type AttachmentDto = {
  attachmentId?: number;
  attachmentType?: AttachmentType;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  fileName?: string | null;
  fileUri?: string | null;
  previewUri?: string | null;
  thumbnailUri?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  parentId?: number;
  parentType?: AttachmentParentType;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  presignedFileUri?: string | null;
  presignedPreviewUri?: string | null;
  presignedThumbnailUri?: string | null;
  links?: LinkDto[] | null;
};
export type AttachmentDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: AttachmentDto[] | null;
  links?: LinkDto[] | null;
};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any | null;
};
export type FetchAttachmentsCommandValues = {
  orderIds?: number[];
};
export enum S3UriTypes {
  FileUri = "FileUri",
  PreviewUri = "PreviewUri",
  ThumbnailUri = "ThumbnailUri",
}
export const {
  useAttachmentFetchAttachmentsMutation,
  useAttachmentGetAttachmentMutation,
} = injectedRtkApi;
