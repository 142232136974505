import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ChargeDto, CommodityDto} from "../../features/order/order-api";
import {useAppSelector} from "../../store";
import {LoadingButton} from "@mui/lab";
import i18next from "i18next";
import {getFormattedPrice} from "../../../utils/formatCurrency.utils";
import {deliveryCostPerUnit} from "../AdditionalServices/components/additionalServiceFragment";
import {Helmet} from "react-helmet";
import {
  ContactAddressGetContactAddressApiArg,
  useContactAddressGetContactAddressQuery,
} from "../../features/contactAddress/contactAddress-api";
import {getLangCodeFromI18n} from "../../app/app.component";
import {getConsigneeLocation} from "../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";
import {DeliveryConfiguration} from "../delivery.module";
import Card from "../../common/components/card";
import EditableComponent from "../../common/components/editableComponent";
import CardDivider from "../../common/components/cardDivider";
import {useNavigate} from "react-router-dom";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TotalCostParcels from "../Insurance/components/totalCostParcels.component";
import {getCustomValue} from "../../../utils/helper.utils";
import {getCommoditiesTotalDeclaredValue} from "../../../utils/shipRequest.utils";
import Divider from "@mui/material/Divider";

export function FinishAndPayFragment({
  config,
  handleNext,
  isSubmitting,
}: {
  config: DeliveryConfiguration;
  handleNext: () => void;
  isSubmitting: boolean;
}) {
  const i18KeyPrefix = "delivery.finishAndPay.";
  const lang = getLangCodeFromI18n();
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const orderData = useAppSelector((state) => state.parcelState);
  const isPaymentRequired =
    getCustomValue(
      orderData?.order?.deliveryToTrtMethod?.carrier?.customValues,
      "is_payment_required",
    ) === "true";

  const thisOrder: any = orderData.order;
  const [consigneeAddressLines, setConsigneeAddressLines] = useState<string[]>(
    [],
  );
  const [yourAddressLines, setYourAddressLines] = useState<string[]>([]);

  const [calculatedCharges, setCalculatedCharges] = useState<ChargeDto[]>([]);

  const getConsigneeAddressArgs: ContactAddressGetContactAddressApiArg = {
    organizationId: process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
    contactAddressId: thisOrder?.consigneeAddressValues?.contactAddressId ?? 0,
  };

  const {data: consigneeAddressData, isSuccess: consigneeAddressIsSuccess} =
    useContactAddressGetContactAddressQuery(getConsigneeAddressArgs);

  useEffect(() => {
    if (consigneeAddressIsSuccess) {
      getConsigneeLocation(consigneeAddressData, lang, t).then((result) => {
        setConsigneeAddressLines([
          [
            consigneeAddressData?.contact?.contactFirstName,
            consigneeAddressData?.contact?.contactLastName,
          ].join(" "),
          consigneeAddressData?.contact?.phoneNumber ?? "",
          ...result.filter(
            (value) => value && value.length && value.length > 0,
          ),
        ]);
      });
    }
  }, [consigneeAddressIsSuccess, lang]);

  useEffect(() => {
    if (thisOrder?.contactAddressValues) {
      getConsigneeLocation(
        {
          ...thisOrder?.contactAddressValues,
          stateName: thisOrder?.contactAddressValues?.regionName,
          addressLine: thisOrder?.contactAddressValues?.streetName,
          addressLine2: thisOrder?.contactAddressValues?.appartment,
          cityName: thisOrder?.contactAddressValues?.city,
        },
        lang,
        t,
      ).then((result) => {
        setYourAddressLines([
          [
            thisOrder?.contactValues?.firstName,
            thisOrder?.contactValues?.lastName,
          ].join(" "),
          ...result.filter(
            (value) => value && value.length && value.length > 0,
          ),
        ]);
      });
    }
  }, [thisOrder?.contactAddressValues, lang]);

  const getDropOffMethod = () => {
    if (thisOrder?.deliveryToTrtMethod) {
      return (
        /*<Typography variant={"caption2"}>
          {thisOrder.deliveryToTrtMethod.deliveryType[
            `service_description_${i18next.language}`
          ] ?? "Not selected"}
        </Typography>*/
        <Typography>
          {thisOrder.deliveryToTrtMethod.carrier?.name ?? "Not selected"}
        </Typography>
      );
    }
  };

  const getDeliveryMethod = () => {
    if (thisOrder?.deliveryMethod) {
      return (
        <Typography variant={"caption2"}>
          {thisOrder.deliveryMethod.deliveryType[
            `service_description_${i18next.language}`
          ] ?? "Not selected"}
        </Typography>
      );
    }
  };

  useEffect(() => {
    const charges: ChargeDto[] = thisOrder?.charges?.map((charge: any) => {
      const calculatedCharge: ChargeDto = {
        accountingItemCode: charge?.values?.accountingItemCode,
        applyBy: charge?.values?.applyBy,
        accountingItemName:
          charge?.values?.accountingItem?.description ??
          charge?.values?.accountingItemName,
        totalAmount: charge?.values?.totalAmount,
        price: charge?.values?.price,
        accountingItemId: charge?.values?.accountingItemId,
      };
      return calculatedCharge;
    });
    setCalculatedCharges(charges);
  }, [thisOrder?.charges, setCalculatedCharges]);

  return (
    <>
      <Helmet>
        <title>{"Ship Request - Confirmation"}</title>
      </Helmet>
      <Grid
        container
        columns={{xs: 6, md: 13}}
        justifyContent={"space-between"}
        rowGap={{md: "24px", xs: "12px"}}
        p={0}
        mx={{xs: "-16px", md: 0}}
      >
        {!isDesktop && (
          <Grid mt={2} mb={1} mx={2}>
            <Typography variant="h2">{t(i18KeyPrefix + "title")}</Typography>
          </Grid>
        )}
        <Box width={"100%"}>
          <Card>
            <Grid width={"100%"}>
              <EditableComponent
                onEdit={() => {
                  navigate("./../shippingAddress");
                }}
              >
                <Grid display={"flex"} flexDirection={"column"}>
                  <Typography variant={"body4"} mb={2.5} color={"#3E80FF"}>
                    {t(i18KeyPrefix + "shippingFrom")}
                  </Typography>
                  {yourAddressLines?.map((line: any, index: any) => {
                    return (
                      <Typography
                        key={`address-line-from-${index}`}
                        variant={"caption2"}
                      >
                        {line}
                      </Typography>
                    );
                  })}
                </Grid>
              </EditableComponent>
            </Grid>
            <CardDivider />
            <Grid width={"100%"}>
              <EditableComponent
                onEdit={() => {
                  navigate("./../address");
                }}
              >
                <Grid display={"flex"} flexDirection={"column"}>
                  <Typography variant={"body3"} mb={2.5} color={"#3E80FF"}>
                    {t(i18KeyPrefix + "shippingTo")}
                  </Typography>
                  {consigneeAddressLines?.map((line: any, index: any) => {
                    return (
                      <Typography
                        key={`address-line-to-${index}`}
                        variant={"caption2"}
                      >
                        {line}
                      </Typography>
                    );
                  })}
                </Grid>
              </EditableComponent>
            </Grid>
            <CardDivider />
            <Grid width={"100%"}>
              <EditableComponent
                onEdit={() => {
                  navigate("./../method");
                }}
              >
                <Grid display={"flex"} flexDirection={"column"}>
                  <Typography
                    variant={"body3"}
                    mb={2.5}
                    color={"#3E80FF"}
                    data-testid="drop-off-location"
                  >
                    {t(i18KeyPrefix + "dropOffLocation")}
                  </Typography>
                  {getDropOffMethod()}
                  <Typography
                    variant={"body3"}
                    my={2.5}
                    data-testid="delivery-method"
                  >
                    {t(i18KeyPrefix + "deliveryMethod")}
                  </Typography>
                  {getDeliveryMethod()}
                </Grid>
              </EditableComponent>
            </Grid>
          </Card>
        </Box>

        <Box width={"100%"}>
          <Card>
            <EditableComponent onEdit={() => navigate("./../declaration")}>
              <Grid width={"100%"}>
                <Typography variant={"body4"} color={"#3E80FF"}>
                  {t("consolidatedShipment.itemsInYourPackage")}
                </Typography>
                {isDesktop ? (
                  <Table
                    width={"100%"}
                    sx={{
                      marginBottom: 2,
                      ".MuiTableCell-root": {
                        borderBottom: "none",

                        "&:first-of-type": {
                          paddingLeft: "0px",
                        },
                        "&:last-of-type": {
                          paddingRight: "0px",
                        },
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant={"body3"}>
                            {t(i18KeyPrefix + "item")}
                          </Typography>
                        </TableCell>
                        <TableCell align={"right"}>
                          <Typography variant={"body3"}>
                            {t(i18KeyPrefix + "quantity")}
                          </Typography>
                        </TableCell>
                        <TableCell align={"right"}>
                          <Typography variant={"body3"}>
                            {t(i18KeyPrefix + "declaredValue")}
                          </Typography>
                        </TableCell>
                        <TableCell align={"right"}>
                          <Typography variant={"body3"}>
                            {t(i18KeyPrefix + "category")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {thisOrder?.commodities?.map(
                        (commodity: CommodityDto, index: any) => {
                          return (
                            <TableRow
                              key={commodity.commodityId}
                              data-testid={`item-${index}`}
                            >
                              <TableCell>
                                <Typography variant={"body1"}>
                                  {commodity.description}
                                </Typography>
                              </TableCell>
                              <TableCell align={"right"}>
                                <Typography variant={"body1"}>
                                  {commodity.quantity}
                                </Typography>
                              </TableCell>
                              <TableCell align={"right"}>
                                <Typography variant={"body1"}>
                                  {commodity.unitaryValue
                                    ? getFormattedPrice(commodity.unitaryValue)
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell align={"right"}>
                                <Typography variant={"body1"}>
                                  {commodity?.commodityType?.customValues &&
                                  `simplified_description_${lang}` in
                                    commodity.commodityType.customValues
                                    ? commodity.commodityType?.customValues[
                                        `simplified_description_${lang}`
                                      ]
                                    : commodity.commodityType?.description}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        },
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  <Grid mt={2}>
                    {thisOrder?.commodities?.map(
                      (commodity: CommodityDto, index: any) => {
                        return (
                          <Grid
                            key={commodity?.commodityId?.toString() + index}
                          >
                            <Table
                              sx={{
                                ".MuiTableRow-root>.MuiTableCell-root:first-child":
                                  {
                                    width: "140px",
                                    paddingLeft: 0,
                                    fontSize: "0.875rem",
                                    "&>.MuiTypography-root": {
                                      fontSize: "0.875rem",
                                    },
                                  },
                                ".MuiTableRow-root>.MuiTableCell-root:last-child":
                                  {
                                    paddingRight: 0,
                                  },
                                ".MuiTableRow-root>.MuiTableCell-root": {
                                  padding: 1,
                                  borderBottom: "none",
                                },
                              }}
                            >
                              <TableBody>
                                <TableRow
                                  key={commodity.commodityId}
                                  data-testid={`item-${index}`}
                                >
                                  <TableCell>
                                    <Typography variant={"body3"}>
                                      {t(i18KeyPrefix + "item")}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant={"caption2"}>
                                      {commodity.description}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Typography variant={"body3"}>
                                      {t(i18KeyPrefix + "quantity")}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant={"caption2"}>
                                      {commodity.quantity}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Typography variant={"body3"}>
                                      {t(i18KeyPrefix + "declaredValue")}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant={"caption2"}>
                                      {commodity.unitaryValue
                                        ? getFormattedPrice(
                                            commodity.unitaryValue,
                                          )
                                        : "-"}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Typography variant={"body3"}>
                                      {t(i18KeyPrefix + "category")}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant={"caption2"}>
                                      {commodity?.commodityType?.customValues &&
                                      `simplified_description_${lang}` in
                                        commodity.commodityType.customValues
                                        ? commodity.commodityType?.customValues[
                                            `simplified_description_${lang}`
                                          ]
                                        : commodity.commodityType?.description}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            <Divider
                              orientation={"horizontal"}
                              sx={{
                                borderColor: theme.palette.info.border,
                                my: 2,
                              }}
                            />
                          </Grid>
                        );
                      },
                    )}
                  </Grid>
                )}

                <Grid display={"flex"} flexDirection={"column"}>
                  <Typography variant={"body1"}>
                    {t(i18KeyPrefix + "parcelWeight")}:{" "}
                    <b>
                      {thisOrder?.container?.weight}{" "}
                      {thisOrder?.container?.weightUnit}
                    </b>
                  </Typography>
                  <Typography variant={"body1"}>
                    {t(i18KeyPrefix + "parcelSize")}:{" "}
                    <b>
                      {thisOrder?.container?.length}×
                      {thisOrder?.container?.width}×
                      {thisOrder?.container?.height}{" "}
                      {thisOrder?.container?.dimensionsUnit}
                    </b>
                  </Typography>
                  <Typography variant={"body1"}>
                    {t(i18KeyPrefix + "totalDeclaredValue")}:{" "}
                    <b>
                      {getFormattedPrice(
                        getCommoditiesTotalDeclaredValue(
                          thisOrder?.commodities,
                        ),
                      )}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </EditableComponent>
          </Card>
        </Box>
        <Grid xs={6} md={13} justifyContent={"center"} mx={{xs: "16px", md: 0}}>
          <Grid xs={6} md={5}>
            <TotalCostParcels
              headerColor={"#3E80FF"}
              sx={{
                border: "none",
                marginTop: 2,
              }}
              deliveryCostPerUnit={deliveryCostPerUnit(
                thisOrder,
                t,
                config?.showTotalCost,
              )}
              charges={calculatedCharges}
              showTotalCost={config?.showTotalCost}
              insuranceItemCode={config?.insuranceAccountingItemCode}
            />
          </Grid>
        </Grid>
        <Grid
          width={"100%"}
          mb={4}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Grid
            xs={6}
            md={3}
            justifyContent={"center"}
            mt={{md: 0, xs: 2}}
            mb={{md: 5, xs: 1}}
            mx={{md: 0, xs: 2}}
          >
            <LoadingButton
              data-testid="btn-confirmation-shipment"
              fullWidth
              type="button"
              variant="contained"
              color="secondary"
              sx={{
                p: 1,
              }}
              loading={isSubmitting}
              loadingPosition="center"
              endIcon={<></>}
              onClick={() => handleNext()}
            >
              <Typography variant="body3" textTransform={"none"}>
                {t(
                  i18KeyPrefix +
                    (isPaymentRequired ? "confirmAndPay" : "confirm"),
                )}
              </Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
