import React, {useState} from "react";
import {t} from "i18next";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import SelectRequestTypeFragment from "./selectRequestTypeFragment";

export default function SelectRequestType() {
  const [showItemsMobile, setShowItemsMobile] = useState<any>(false);

  const title = t("delivery.selectRequestType.pageTitle");

  return (
    <MainLayout
      backLink={!showItemsMobile && "../purchases"}
      showPrev={setShowItemsMobile}
      pageTitle={title}
    >
      <ContentLayout
        title={title}
        backLink="../purchases"
        content={<SelectRequestTypeFragment />}
      />
    </MainLayout>
  );
}
