import {useState} from "react";
import {
  CommodityTypeDto,
  CommodityTypeDtoPagedResult,
  CommodityTypeGetCommodityTypesApiArg,
  GetCommodityTypesValues,
  useCommodityTypeGetCommodityTypesMutation,
} from "../../../features/commodityTypes/commodityTypes-api";

export const useGetCommodityTypes = () => {
  const [commodityTypes, setCommodityTypes] = useState<CommodityTypeDto[]>([]);
  const [commodityTypesPageResult, setCommodityTypesPageResult] =
    useState<CommodityTypeDtoPagedResult>();
  const [
    fetchCommodityTypes,
    {
      isLoading: isLoadingommodityTypes,
      isError: isErrorCommodityTypes,
      isSuccess: isSuccessGetCommodityTypes,
    },
  ] = useCommodityTypeGetCommodityTypesMutation();

  const getCommodityTypes = async (values: GetCommodityTypesValues) => {
    const commandArgs: CommodityTypeGetCommodityTypesApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      getCommodityTypesValues: values,
    };
    const response: any = await fetchCommodityTypes(commandArgs);
    const data: CommodityTypeDtoPagedResult = response?.data;
    setCommodityTypesPageResult(data);
    if (!commodityTypes?.length && data?.items?.length) {
      setCommodityTypes(data.items);
    }
  };

  return {
    getCommodityTypes,
    commodityTypes,
    isLoadingommodityTypes,
    isErrorCommodityTypes,
    isSuccessGetCommodityTypes,
    commodityTypesPageResult,
  };
};
