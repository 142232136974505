import {Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {useEffect, useState} from "react";
import {
  GetOrdersCommandValues,
  OrderDto,
  OrderGetOrdersApiArg,
  OrderGQLDto,
  OrderGQLDtoPagedResult,
  QueryKeysEnum,
  ShipmentInfo,
  useOrderGetOrdersGQLMutation,
} from "../../../features/order/order-api";
import {PurchaseListCardForm} from "../../PurchaseListCard/components";
import {QuerySearchParams, useQuery} from "../../../../utils/helper.utils";
import {useTranslation} from "react-i18next";
import Pagination from "../../../common/components/pagination";

export const PURCHASES_QUERY_DEFAULT_OFFSET = "0";
export const PURCHASES_QUERY_DEFAULT_LIMIT = "8";
export const PURCHASES_QUERY_DEFAULT_FILTER =
  'orderType:Purchase AND NOT OrderStatus.OrderStatusName:"Canceled"';

type PurchaseListProps = {
  isConsolidated?: boolean;
  setSelectedPurchasesIds?: React.Dispatch<React.SetStateAction<number[]>>;
  selectedPurchasesIds?: number[];
  customFilter?: string;
};

export default function PurchaseList({
  isConsolidated,
  setSelectedPurchasesIds,
  selectedPurchasesIds,
  customFilter = undefined,
}: PurchaseListProps) {
  const {
    offset = PURCHASES_QUERY_DEFAULT_OFFSET,
    limit = PURCHASES_QUERY_DEFAULT_LIMIT,
    sort = "-created",
    filter = customFilter
      ? `${PURCHASES_QUERY_DEFAULT_FILTER} AND ${customFilter}`
      : PURCHASES_QUERY_DEFAULT_FILTER,
    search = "",
  } = useQuery() as QuerySearchParams;
  const [purchases, setPurchases] = useState<OrderGQLDtoPagedResult>();
  const [purchasesIsLoading, setPurchasesIsLoading] = useState<boolean>(true);

  const {t} = useTranslation();

  const [getOrders] = useOrderGetOrdersGQLMutation();

  const fetchOrders = async (request: GetOrdersCommandValues) => {
    const commandArgs: OrderGetOrdersApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      getOrdersCommandValues: request,
    };
    return getOrders(commandArgs);
  };

  useEffect(() => {
    if (isConsolidated) {
      setPurchasesIsLoading(true);
      fetchOrders({
        offset: Number(offset),
        limit: Number(limit),
        sort,
        filter,
        search,
        queryKey: QueryKeysEnum.PurchasesListWithoutJobs,
      })
        .then((response: any) => {
          setPurchases(response.data?.ordersWithoutJobs ?? []);
        })
        .finally(() => setPurchasesIsLoading(false));
    } else {
      setPurchasesIsLoading(true);
      fetchOrders({
        offset: Number(offset),
        limit: Number(limit),
        sort,
        filter,
        search,
        queryKey: QueryKeysEnum.PurchaseList,
      })
        .then((response: any) => {
          setPurchases(response.data?.orders ?? []);
        })
        .finally(() => setPurchasesIsLoading(false));
    }
  }, [offset, limit, sort, filter, search]);

  if (!purchases?.items?.length || purchasesIsLoading) {
    return (
      <>
        <Grid container justifyContent={"center"} my={5}>
          <Typography mb={2} variant={"h2"} color={"primary.dark"}>
            {purchasesIsLoading
              ? t("loading")
              : search
              ? t("purchases.noPurchasesSearch")
              : t("purchases.noPurchases")}
          </Typography>
        </Grid>
      </>
    );
  }

  return (
    <Grid container flexDirection={"column"} position={"relative"}>
      {purchases?.items?.map((purchase: OrderGQLDto) => {
        const parcelShipment = purchase.shipments?.find(
          (order) => order.orderType === "ParcelShipment",
        );
        const openInvoice = purchase.jobs?.flatMap((job) =>
          job.accountingTransactions?.filter(
            (inv) =>
              inv.accountingTransactionType == "Invoice" &&
              inv.accountingTransactionStatus == "Open",
          ),
        )?.[0];

        const parcelShipmentInfo: ShipmentInfo = {
          ...(parcelShipment as OrderDto),
          ...openInvoice,
        };

        return (
          <PurchaseListCardForm
            key={purchase.orderId}
            purchase={purchase}
            parcelShipmentInfo={parcelShipmentInfo}
            isConsolidated={isConsolidated}
            handleCheckbox={setSelectedPurchasesIds}
            isSelected={
              purchase?.orderId
                ? selectedPurchasesIds?.includes(purchase.orderId)
                : false
            }
          />
        );
      })}

      <Pagination
        limit={Number(limit)}
        offset={Number(offset)}
        sort={sort}
        filter={filter}
        search={search}
        totalCount={purchases?.totalCount}
      />
    </Grid>
  );
}
