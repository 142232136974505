import {useDispatch} from "react-redux";
import {
  PortalOrganizationConfigDto,
  useOrganizationConfigGetManyQuery,
} from "../../features/organizationConfig/organizationConfig-api";
import {setOrganizationConfig} from "../../features/organizationConfig/organizationConfig-slice";

export const getPortalNameFromDomain = () => {
  const domain = window.location.hostname;
  const subdomain = domain.split(".")[0];

  // remove -dev
  return subdomain.replace("-dev", "");
};

export const useOrganizationConfig = () => {
  let isLoading = true;

  const organizationId = process.env.REACT_APP_PORTAL_ORGANIZATION_ID
    ? parseInt(process.env.REACT_APP_PORTAL_ORGANIZATION_ID, 10)
    : 1;

  const portalName =
    process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain();

  const dispatch = useDispatch();

  const result = useOrganizationConfigGetManyQuery(
    {
      organizationId,
      portalName,
    },
    {
      pollingInterval: 15 * 60 * 1000, // 15 minutes
    },
  );

  if (result.isSuccess) {
    isLoading = false;
    const {items} = result.data;
    if (items.length > 0) {
      dispatch(setOrganizationConfig(items));
    }
    return {
      moduleConfig: items.find(
        (item) => item.configName.split(".").slice(-1)[0] === "modules",
      ) as PortalOrganizationConfigDto,
      isLoading,
    };
  }
  return {moduleConfig: null, isLoading};
};
