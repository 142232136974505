import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {AddressBoxProps} from "./interfaces";
import toast from "react-hot-toast";
import {t} from "i18next";
import {Trans} from "react-i18next";

export const getAddressElement = (line: string) => (
  <Typography variant="caption3">{line}</Typography>
);

export default function AddressBox({address, styles}: AddressBoxProps) {
  const copyAddress = address.addressLines
    .map((addressLine: any) => addressLine.value)
    .join(", ");

  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyAddress).then(() => {
      setCopySuccess(true);
    });
  };

  useEffect(() => {
    if (copySuccess) {
      toast.success(t("gettingStarted.addressCopied"));
      setCopySuccess(false);
    }
  }, [copySuccess]);

  return (
    <Box
      sx={{
        border: "1px solid #D8E6FB",
        boxShadow: "0px 0px 8px 0px #0F4A841A",
        backgroundColor: "white",
        borderRadius: "16px",
        paddingY: "1rem",
        paddingX: "1rem",
        minWidth: "320px",
        ...styles,
      }}
      width={"100%"}
      justifyContent={"start"}
      onClick={copyToClipboard}
    >
      <Grid display={"flex"} mb={1} gap={1} flexDirection={"column"}>
        <Typography variant="caption3" color={"primary"}>
          <Trans i18nKey={address.taxTitleKey}>{address.taxTitleKey}</Trans>
        </Typography>
      </Grid>
      <Grid container direction={"column"}>
        {address.addressLines.map((addressLine: any) => {
          return getAddressElement(addressLine.key + ": " + addressLine.value);
        })}
      </Grid>
    </Box>
  );
}
