import React from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import {useTranslation} from "react-i18next";

export type BoxButtonProps = {
  label: any;
  subText?: any;
  clicked: boolean;
  handleClick: any;
  enterAmount?: boolean;
  setInsuranceAmount?: any;
  insuranceAmount?: number | null;
  showValidationError?: boolean;
  setShowFormatValidationError?: any;
};

const BoxButton = ({
  label,
  subText,
  clicked,
  handleClick,
  enterAmount,
  setInsuranceAmount,
  insuranceAmount,
  showValidationError,
  setShowFormatValidationError,
}: BoxButtonProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();

  const outlinedBoxStyles = {
    border: 1,
    borderColor: {xs: "#D8E6FB", md: "#2F81EF"},
    backgroundColor: "#FFFFFF",
    boxShadow: {xs: "0px 0px 8px rgba(15, 74, 132, 0.1)", md: "none"},
    borderRadius: {md: "8px", xs: "16px"},
    "&:hover": {
      md: {
        backgroundColor: "#D8E6FB",
        borderColor: "transparent",
        "& .addInsuranceButton": {
          color: "#205A9C",
        },
      },
    },
  };

  const clickedBoxStyles = {
    border: 1,
    backgroundColor: "#2F81EF",
    borderColor: "transparent",
    borderRadius: {md: "8px", xs: "16px"},
    boxShadow: {xs: "0px 0px 8px rgba(15, 74, 132, 0.1)", md: "none"},
  };

  const handleInsuranceAmountChange = (value: any) => {
    if (setInsuranceAmount) {
      const parsedValue = parseFloat(value);
      setInsuranceAmount(value);
      if (!isNaN(parsedValue)) {
        if (/^-?([1-9]\d*|[0]{1})(\.{1}\d{1,})?$/.test(value)) {
          setInsuranceAmount(Number(parsedValue.toFixed(2)));
          setShowFormatValidationError(false);
        } else {
          setShowFormatValidationError(true);
        }
      } else {
        setInsuranceAmount(null);
      }
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "16px",
        backgroundColor: clicked ? "primary.main" : "",
        border: clicked ? "0px solid primary.main" : "1px solid primary.main",
        cursor: "pointer",
        padding: isDesktop ? "1rem" : "0.75rem 1rem 1rem",
        justifyContent: "center",
      }}
      sx={clicked ? clickedBoxStyles : outlinedBoxStyles}
      onClick={!clicked ? handleClick : undefined}
    >
      <Grid
        container
        direction="column"
        height="fit-content"
        justifyContent="center"
      >
        <Typography
          variant={isDesktop ? "h2" : "h3"}
          className="addInsuranceButton"
          sx={{
            color: !clicked ? "primary.main" : "white",
            margin: 0,
            marginBottom: isDesktop ? "8px" : "4px",
          }}
        >
          {label}
        </Typography>
        {subText && (
          <Typography
            variant="caption2"
            sx={{
              color: clicked ? "white" : "",
              lineHeight: isDesktop ? "" : "20px",
            }}
          >
            {subText}
          </Typography>
        )}
        {enterAmount && (
          <div
            style={{
              display: isDesktop ? "flex" : "block",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant={isDesktop ? "h3" : "body1"}
              sx={{
                pt: isDesktop ? 1 : 2,
                pb: isDesktop ? 0 : 1,
                color: clicked ? "white" : "",
                lineHeight: isDesktop ? "" : "20px",
              }}
            >
              {t("delivery.additionalServices.pleaseEnterAmount")}
            </Typography>
            <FormControl
              sx={{
                m: isDesktop ? 1 : 0,
                width: isDesktop ? 162 : "100%",
                height: 40,
                pt: isDesktop ? 0 : 1,
              }}
              variant="outlined"
              error={!!showValidationError}
            >
              <OutlinedInput
                sx={{backgroundColor: "white"}}
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="end">$</InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={insuranceAmount}
                onChange={(event) =>
                  handleInsuranceAmountChange(event.target.value)
                }
                onKeyPress={(event) => {
                  const isValidKey =
                    event.key === "." ||
                    event.key === "-" ||
                    /^\d$/.test(event.key);

                  if (!isValidKey) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </div>
        )}
      </Grid>
    </Box>
  );
};

export default BoxButton;
