import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  SendPhoneCodeCommand,
  VerifyAddressCommandValues,
} from "./verification-api";

export type VerificationState = {
  step: number;
  email: {
    value: string | null;
    verified: boolean;
  };
  phoneNumber: {
    value: SendPhoneCodeCommand | null;
    verified: boolean;
  };
  contactAddress: {
    value: VerifyAddressCommandValues | null;
    verified: boolean;
  };
  paymentMethod: {
    value: number | null;
    verified: boolean;
  };
};

export interface VerifiedValues {
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  isAddressVerified: boolean;
  isPaymentVerified: boolean;
  isAcknowledgementVerified: boolean;
}

const initialState: VerificationState = {
  step: -1,
  email: {
    value: null,
    verified: false,
  },
  phoneNumber: {
    value: null,
    verified: false,
  },
  contactAddress: {
    value: null,
    verified: false,
  },
  paymentMethod: {
    value: null,
    verified: false,
  },
};

export const verificationSlice = createSlice({
  initialState,
  name: "verificatonSlice",
  reducers: {
    logout: () => {
      return initialState;
    },
    setVerificationState: (
      state: VerificationState,
      action: PayloadAction<VerificationState>,
    ) => {
      const newState = {...action.payload};
      state.step = newState.step;
      state.email = newState.email;
      state.phoneNumber = newState.phoneNumber;
      state.contactAddress = newState.contactAddress;
      state.paymentMethod = newState.paymentMethod;
    },
    setInitialVerificationState: (state: VerificationState) => {
      state.step = initialState.step;
      state.email = initialState.email;
      state.phoneNumber = initialState.phoneNumber;
      state.contactAddress = initialState.contactAddress;
      state.paymentMethod = initialState.paymentMethod;
    },
    setStep: (state: VerificationState, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setEmail: (state: VerificationState, action: PayloadAction<string>) => {
      state.email.value = action.payload;
    },
    verifyEmail: (state: VerificationState, action: PayloadAction<string>) => {
      state.email.value = action.payload;
      state.email.verified = true;
    },
    setPhoneNumber: (
      state: VerificationState,
      action: PayloadAction<SendPhoneCodeCommand>,
    ) => {
      const newPhoneNumber = {
        value: {...action.payload},
        verified: state.phoneNumber.verified,
      };
      state.phoneNumber = newPhoneNumber;
    },
    verifyPhoneNumber: (
      state: VerificationState,
      action: PayloadAction<SendPhoneCodeCommand>,
    ) => {
      const newPhoneNumber = {
        value: {...action.payload},
        verified: true,
      };
      state.phoneNumber = newPhoneNumber;
    },
    setContactAddress: (
      state: VerificationState,
      action: PayloadAction<VerifyAddressCommandValues>,
    ) => {
      const newContactAddress = {
        value: {...action.payload},
        verified: state.contactAddress.verified,
      };
      state.contactAddress = newContactAddress;
    },
    verifyContactAddress: (
      state: VerificationState,
      action: PayloadAction<VerifyAddressCommandValues>,
    ) => {
      const newContactAddress = {
        value: {...action.payload},
        verified: true,
      };
      state.contactAddress = newContactAddress;
    },
    setPaymentMethod: (
      state: VerificationState,
      action: PayloadAction<number>,
    ) => {
      state.paymentMethod.value = action.payload;
    },
    verifyPaymentMethod: (
      state: VerificationState,
      action: PayloadAction<number>,
    ) => {
      state.paymentMethod.value = action.payload;
      state.paymentMethod.verified = true;
    },
    clearContactAddress: (state: VerificationState) => {
      (state.contactAddress.value = null),
        (state.contactAddress.verified = false);
    },
    setIsVerified: (
      state: VerificationState,
      action: PayloadAction<VerifiedValues>,
    ) => {
      state.email.verified = action.payload.isEmailVerified;
      state.paymentMethod.verified = action.payload.isPaymentVerified;
      state.phoneNumber.verified = action.payload.isPhoneVerified;
      state.contactAddress.verified = action.payload.isAddressVerified;
    },
  },
});

export default verificationSlice.reducer;

export const {
  logout,
  setVerificationState,
  setInitialVerificationState,
  setStep,
  setEmail,
  verifyEmail,
  setPhoneNumber,
  verifyPhoneNumber,
  setContactAddress,
  verifyContactAddress,
  setPaymentMethod,
  verifyPaymentMethod,
  clearContactAddress,
  setIsVerified,
} = verificationSlice.actions;
