import * as yup from "yup";
import {getPasswordSchema} from "../../../Register/pages/validation";

export const getChangePasswordSchema = (t: (text: string) => string) =>
  yup.object().shape({
    newPassword: getPasswordSchema(t),
    confirmPassword: yup
      .string()
      .required(t("validation.common.confirmPasswordRequired"))
      .when("newPassword", (newPassword, schema) => {
        return schema.test({
          test: (confirmPassword: string) => newPassword === confirmPassword,
          message: t("profile.changePassword.passwordsAreNotEqual"),
        });
      }),
  });
