import React from "react";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {RemovePurchaseModalProps} from "../pages/interfaces";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";

export default function RemovePurchaseModal({
  closeModal,
  onDelete,
  isDeleteSucces,
  isDeleteProcess,
}: RemovePurchaseModalProps) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    if (isDeleteSucces) {
      toast.success(t("toasts.purchaseDeleted"));
      navigate("../purchases");
    }
  }, [isDeleteProcess]);

  return (
    <Grid
      sx={{
        py: {xs: 0, md: 1},
      }}
    >
      <Typography
        data-testid="modal-modal-title"
        variant="h3"
        component="h6"
        px={{xs: 0, md: 3}}
        mb={1}
      >
        Do you really want to delete this purchase?
      </Typography>
      <Grid container justifyContent={"space-between"} mt={3}>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          sx={{flex: "0.5", mr: 2}}
          onClick={closeModal}
        >
          No
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          sx={{flex: "0.5"}}
          onClick={async () => {
            await onDelete();
          }}
        >
          Yes
        </Button>
      </Grid>
    </Grid>
  );
}
