import {t} from "i18next";

export function tryTranslateError(message: string) {
  if (message && message?.length) {
    const key = camelize(message);
    if (key) {
      return t(`toasts.${key}`, message);
    }
  }
  return message;
}

function camelize(str: string) {
  return str
    .replace(/[^a-zA-Z]+/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
