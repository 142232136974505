import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    accountingItemsGetAccountingItems: build.query<
      AccountingItemsGetAccountingItemsApiResponse,
      AccountingItemsGetAccountingItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/accountingItems/getAccountingItems`,
      }),
    }),
    accountingItemsGetAdditionalServices: build.query<
      AccountingItemsGetAdditionalServicesApiResponse,
      AccountingItemsGetAdditionalServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/accountingItems/getAdditionalServices`,
      }),
    }),
    accountingItemsGetInsurance: build.query<
      AccountingItemsGetInsuranceApiResponse,
      AccountingItemsGetInsuranceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/accountingItems/getInsurance`,
        method: "POST",
        body: queryArg.getInsuranceCommandValues,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type AccountingItemsGetAccountingItemsApiResponse =
  AccountingItemDtoPagedResult;
export type AccountingItemsGetAccountingItemsApiArg = {
  organizationId: number;
};
export type AccountingItemsGetAdditionalServicesApiResponse =
  AccountingItemDto[];
export type AccountingItemsGetAdditionalServicesApiArg = {
  organizationId: number;
};
export type AccountingItemsGetInsuranceApiResponse = AccountingItemDto;
export type AccountingItemsGetInsuranceApiArg = {
  organizationId: number;
  getInsuranceCommandValues: GetInsuranceCommandValues;
};
export type GetInsuranceCommandValues = {
  insuranceAccountingItemCode: string;
};
export type ItemType =
  | "Other"
  | "Freight"
  | "Valuation"
  | "Tax"
  | "OtherFreight"
  | "Inventory";
export type Tariff = {
  testData?: number;
};
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type AccountingItemDto = {
  organizationId?: number;
  accountingItemId?: number;
  accountId?: number;
  description?: string | null;
  isInactive?: boolean;
  itemCode?: string | null;
  itemType?: ItemType;
  price?: number | null;
  salesTaxId?: number | null;
  tariff?: Tariff | null;
  accountName?: string | null;
  salesTaxCode?: string | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export type AccountingItemDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: AccountingItemDto[] | null;
  links?: LinkDto[] | null;
};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any | null;
};
export const {
  useAccountingItemsGetAccountingItemsQuery,
  useAccountingItemsGetAdditionalServicesQuery,
  useAccountingItemsGetInsuranceQuery,
} = injectedRtkApi;
