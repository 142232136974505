import React from "react";
import {ProgressBarProps} from "./interfaces";
import LinearProgressStep from "../../common/components/linearProgressStep";
import RadialProgressStep from "../../common/components/radialProgressStep";
import {useTranslation} from "react-i18next";
import {ProgressStep} from "../../common/components/interfaces";

export default function ProgressBar({
  variant,
  stepIndex,
  steps,
}: ProgressBarProps) {
  const {t} = useTranslation();
  const preparedSteps =
    steps?.map((step) => {
      const s: ProgressStep = {
        name: step.name,
        checked: step.verified || false,
        text: "",
      };
      switch (step.name) {
        case "email":
          s.text = t("progressBarEmail");
          break;
        case "phone":
          s.text = t("progressBarPhone");
          break;
        case "address":
          s.text = t("progressBarContact");
          break;
        case "paymentMethod":
          s.text = t("progressBarPayment");
          break;
        case "acknowledgement":
          s.text = t("progressBarUserAcknowledgment");
          break;
      }
      return s;
    }) || [];

  return variant == "linear" ? (
    <LinearProgressStep stepIndex={stepIndex} steps={preparedSteps} />
  ) : variant == "radial" ? (
    <RadialProgressStep steps={preparedSteps} stepIndex={stepIndex} />
  ) : (
    <></>
  );
}
