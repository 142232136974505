import {Helmet} from "react-helmet";
import React, {useMemo} from "react";
import {RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {getCustomValue} from "../../../utils/helper.utils";
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import DropOffLocationsMap from "./dropOffLocationsMap";
import i18next, {t} from "i18next";
import parse from "html-react-parser";
import {Trans} from "react-i18next";
import {useTheme} from "@mui/system";
import {setOrderCustomValues} from "../../features/parcel/parcel-slice";

export interface DropOffLocationsFragmentProps {
  handleNext: any;
  pageTitle: any;
}

export default function DropOffLocationsFragment({
  handleNext,
  pageTitle,
}: DropOffLocationsFragmentProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const firstMileCarrier = useSelector(
    (state: RootState) =>
      state.parcelState?.order?.deliveryToTrtMethod?.carrier,
  );
  const useCustomDropOffInstruction = useMemo(() => {
    return (
      getCustomValue(
        firstMileCarrier?.customValues,
        "use_custom_drop_off_instruction",
      ) === "true"
    );
  }, [firstMileCarrier?.customValues, getCustomValue]);

  const carrierInstruction = useMemo(() => {
    if (!useCustomDropOffInstruction) return null;
    const instruction = getCustomValue(
      firstMileCarrier?.customValues,
      `drop_off_instruction_${i18next.language}`,
    );
    if (instruction) return parse(instruction);
    return null;
  }, [
    useCustomDropOffInstruction,
    firstMileCarrier?.customValues,
    i18next.language,
    getCustomValue,
    parse,
  ]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {!isDesktop ? (
        <Typography variant={"h2"} textTransform={"capitalize"} mt={3}>
          {t("delivery.dropOffLocations.pageTitle")}
        </Typography>
      ) : null}
      {useCustomDropOffInstruction ? (
        <Grid mt={4} minHeight={"50vh"}>
          <Typography variant={"h3"}>{carrierInstruction}</Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{width: "100%", maxWidth: "300px", marginTop: "4rem"}}
            onClick={() => {
              dispatch(
                setOrderCustomValues({
                  values: {
                    selected_agent_contact_id: null,
                    selected_agent_address_id: null,
                  },
                }),
              );
              if (handleNext) handleNext();
            }}
          >
            <Typography variant="body3" textTransform={"none"}>
              <Trans i18nKey="btnContinue">Continue</Trans>
            </Typography>
          </Button>
        </Grid>
      ) : (
        <DropOffLocationsMap handleNext={handleNext} />
      )}
    </>
  );
}
