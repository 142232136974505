import {useEffect, useState} from "react";
import {AccountingItemDto} from "../../../features/accountingItem/accountingItems-api";
import {useAppSelector} from "../../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {toastError} from "../../../common/utils/toastMessages";

export const useGetAdditionalServices = () => {
  const [additionalServices, setAdditionalServices] = useState<
    AccountingItemDto[] | undefined
  >([]);

  const getAdditionalServicesWorkflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.getAdditionalServicesWorkflowId,
  );
  const [runWorkflow, {isLoading}] = useWorkflowExecutionMutation();

  useEffect(() => {
    const fetchAdditionalServices = async () => {
      const workflowArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: getAdditionalServicesWorkflowId,
        values: {},
      };

      const additionalServicesWorkflowResult: any = await runWorkflow(
        workflowArgs,
      );
      const additionalServicesData =
        additionalServicesWorkflowResult?.data?.outputs?.additionalServices
          ?.items;

      if (additionalServicesData) {
        setAdditionalServices(additionalServicesData);
      } else {
        toastError(additionalServicesWorkflowResult?.error);
      }
    };

    fetchAdditionalServices();
  }, []);

  return {additionalServices: additionalServices, isLoading};
};
