import {basePortalApi as api} from "../basePortalApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    workflowExecution: build.mutation<
      WorkflowExecutionApiResponse,
      WorkflowExecutionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/workflowTriggers/${queryArg.workflowId}`,
        method: "POST",
        body: queryArg.values,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type WorkflowExecutionApiResponse =
  /** status 200  */ WorkflowExecutionResultDto;
export type WorkflowExecutionApiArg = {
  organizationId: number;
  workflowId?: string;
  values?: PortalExecuteWorkflowCommandValuesDto;
};
export type PortalExecuteWorkflowCommandValuesDto = {
  variables?: {
    [key: string]: any;
  };
};
export type WorkflowExecutionResultDto = {
  outputs?: {
    [key: string]: any;
  };
  executionId?: string;
  workflowId?: string;
  isAsync?: boolean;
  error?: any;
};
export const {useWorkflowExecutionMutation} = injectedRtkApi;
