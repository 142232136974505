import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import {useTheme} from "@mui/system";

interface EditableComponentProps {
  onEdit: any;
  children: React.ReactNode;
}

export default function EditableComponent({
  onEdit,
  children,
}: EditableComponentProps) {
  const theme = useTheme();

  return (
    <Grid width={"100%"} position={"relative"} container display={"flex"}>
      {children}
      <EditIcon
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          color: theme.palette.primary.dark,
          cursor: "pointer",
        }}
        onClick={onEdit}
      />
    </Grid>
  );
}
