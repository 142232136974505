import {toastError} from "../../common/utils/toastMessages";
import {basePortalApi as api} from "../basePortalApi";
import {PaymentDto} from "../accountingTransactionPayment/accountingTransactionPayment-api";
import toast from "react-hot-toast";
import {t} from "i18next";
import {AccountingItemDto} from "../accountingItem/accountingItems-api";
import {
  ChargeGqlDto,
  ContactAddressGqlDto,
  ContactGQLDto,
  Job,
} from "../order/order-api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    accountingTransactionsGetAccountingTransactions: build.mutation<
      AccountingTransactionsGetAccountingTransactionsApiResponse,
      AccountingTransactionsGetAccountingTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/accountingTransactions/getAccountingTransactions`,
        method: "POST",
        body: queryArg.getAccountingTransactionsQueryValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    accountingTransactionsGetAccountingTransactionsGql: build.mutation<
      AccountingTransactionsGetAccountingTransactionsGqlApiResponse,
      AccountingTransactionsGetAccountingTransactionsGqlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/accountingTransactions/getAccountingTransactionsGql`,
        method: "POST",
        body: queryArg.getAccountingTransactionsQueryValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    accountingTransactionsGetAccountingTransaction: build.mutation<
      AccountingTransactionsGetAccountingTransactionApiResponse,
      AccountingTransactionsGetAccountingTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/accountingTransactions/${queryArg.accountingTransactionId}`,
        method: "GET",
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    accountingTransactionsGetAccountingTransactionGql: build.mutation<
      AccountingTransactionsGetAccountingTransactionGqlApiResponse,
      AccountingTransactionsGetAccountingTransactionGqlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/accountingTransactions/gql/${queryArg.accountingTransactionId}`,
        method: "GET",
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    accountingTransactionsPayInvoice: build.mutation<
      AccountingTransactionsPayInvoiceApiResponse,
      AccountingTransactionsPayInvoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/invoiceManagers/pay-invoice`,
        method: "POST",
        body: queryArg.payInvoiceCommandValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toast.error(t("toasts.paymentError"));
        }
      },
    }),
    accountingTransactionsConfirmInvoicePayment: build.mutation<
      AccountingTransactionsConfirmInvoicePaymentApiResponse,
      AccountingTransactionsConfirmInvoicePaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/invoiceManagers/confirm-invoice-payment`,
        method: "POST",
        body: queryArg.confirmInvoicePaymentCommandValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
          toast.success(t("toasts.paymentConfirmed"));
        } catch (error: any) {
          toast.error(t("toasts.paymentConfirmationError"));
        }
      },
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type AccountingTransactionsGetAccountingTransactionsApiResponse =
  /** status 200  */ AccountingTransactionDtoPagedResult;
export type AccountingTransactionsGetAccountingTransactionsApiArg = {
  organizationId: number;
  getAccountingTransactionsQueryValues: GetAccountingTransactionsQueryValues;
};
export type AccountingTransactionsGetAccountingTransactionsGqlApiResponse =
  /** status 200  */ AccountingTransactionGqlDtoPagedResult;
export type AccountingTransactionsGetAccountingTransactionsGqlApiArg = {
  organizationId: number;
  getAccountingTransactionsQueryValues: GetAccountingTransactionsQueryValues;
};
export type AccountingTransactionsGetAccountingTransactionApiResponse =
  AccountingTransactionDto;
export type AccountingTransactionsGetAccountingTransactionApiArg = {
  organizationId: number;
  accountingTransactionId: number;
};
export type AccountingTransactionsGetAccountingTransactionGqlApiResponse =
  AccountingTransactionGqlDto;
export type AccountingTransactionsGetAccountingTransactionGqlApiArg = {
  organizationId: number;
  accountingTransactionId: number;
};
export type AccountingTransactionsPayInvoiceApiResponse = PayInvoiceResultDto;
export type AccountingTransactionsPayInvoiceApiArg = {
  organizationId: number;
  payInvoiceCommandValues: PayInvoiceCommandValues;
};
export type AccountingTransactionsConfirmInvoicePaymentApiResponse =
  ConfirmInvoicePaymentResultDto;
export type AccountingTransactionsConfirmInvoicePaymentApiArg = {
  organizationId: number;
  confirmInvoicePaymentCommandValues: ConfirmInvoicePaymentCommandValues;
};
export type AccountType = "AccountReceivable" | "AccountPayable";
export type AccountingTransactionStatus = "Open" | "Paid" | "Void";
export type AccountingTransactionType = "Invoice" | "Bill";
export type ContactType =
  | "Customer"
  | "Carrier"
  | "Vendor"
  | "Contact"
  | "Driver"
  | "Employee"
  | "SalesPerson"
  | "ForwardingAgent"
  | "FactoringCompany"
  | "Lead";
export type PaidAs = "Prepaid" | "Collect";
export type ApplyBy = "Pieces" | "Weight" | "Volume" | "Container";
export type ChargeType = "Income" | "Expense";
export type ChargeStatus = "Open" | "Posted" | "Paid" | "Void";
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type ChargeDto = {
  organizationId?: number;
  chargeId?: number;
  currencyId?: number;
  accountingItemId?: number;
  accountingItem?: AccountingItemDto;
  accountingItemCode?: string | null;
  amount?: number;
  applyBy?: ApplyBy;
  applyToContactId?: number;
  chargeType?: ChargeType;
  description?: string | null;
  freightServiceClassId?: number | null;
  grossVolume?: number;
  grossWeight?: number;
  isConsolidated?: boolean;
  paidAs?: PaidAs;
  pieces?: number;
  price?: number;
  quantity?: number;
  showInDocuments?: boolean;
  unit?: string;
  salesTaxCode?: string | null;
  applyToContactName?: string | null;
  applyToContactType?: ContactType;
  accountingItemName?: string | null;
  chargeStatus?: ChargeStatus;
  salesTaxId?: number | null;
  salesTaxRate?: number;
  salesTaxAmount?: number;
  totalAmount?: number;
  note?: string | null;
  currencyName?: string | null;
  currencySymbol?: string | null;
  currencyDecimals?: number | null;
  orders?: ChargeOrderDto[] | null;
  links?: LinkDto[] | null;
  accountingTransactions?: ChargeAccountingTransactionDto[] | null;
  allowAutomaticUpdate?: boolean;
};
export type ChargeAccountingTransactionDto = {
  transactionNumber?: string;
  accountingTransactionStatus: AccountingTransactionStatus;
  accountingTransactionId: number;
};
export type AccountingTransactionDto = {
  accountingTransactionId?: number;
  divisionId?: number;
  divisionName?: string | null;
  accountId?: number;
  accountName?: string | null;
  accountType?: AccountType;
  accountingTransactionStatus?: AccountingTransactionStatus;
  accountingTransactionType?: AccountingTransactionType;
  applyToContactID?: number;
  applyToContactName?: string | null;
  applyToContactType?: ContactType;
  billToContactAddressId?: number | null;
  billToContactAddressName?: string | null;
  dueDate?: string;
  paidDate?: string | null;
  note?: string | null;
  organizationId?: number;
  paymentTermsId?: number | null;
  paymentTermsDescription?: string | null;
  transactionDate?: string;
  transactionNumber?: string | null;
  amountDue?: number;
  amountPaid?: number;
  paidAs?: PaidAs;
  charges?: ChargeDto[] | null;
  totalAmount?: number;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  links?: LinkDto[] | null;
  payments?: PaymentDto[] | null;
};

export type AccountingTransactionGqlDto = {
  accountingTransactionId?: number;
  divisionId?: number;
  divisionName?: string | null;
  accountId?: number;
  accountName?: string | null;
  accountType?: AccountType;
  accountingTransactionStatus?: AccountingTransactionStatus;
  accountingTransactionType?: AccountingTransactionType;
  applyToContactID?: number;
  applyToContact?: ContactGQLDto | null;
  billToContactAddressId?: number | null;
  billToContactAddress?: ContactAddressGqlDto | null;
  dueDate?: string;
  paidDate?: string | null;
  note?: string | null;
  organizationId?: number;
  paymentTermsId?: number | null;
  paymentTermsDescription?: string | null;
  transactionDate?: string;
  transactionNumber?: string | null;
  amountDue?: number;
  amountPaid?: number;
  paidAs?: PaidAs;
  charges?: ChargeGqlDto[] | null;
  totalAmount?: number;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  links?: LinkDto[] | null;
  payments?: PaymentDto[] | null;
  jobs?: Job[] | null;
};
export type AccountingTransactionDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: AccountingTransactionDto[] | null;
  links?: LinkDto[] | null;
};
export type AccountingTransactionGqlDtoPagedResult = {
  totalCount?: number;
  items?: AccountingTransactionGqlDto[] | null;
};
export type GetAccountingTransactionsQueryValues = {
  limit?: number | null;
  offset?: number | null;
  sort?: string;
  filter?: string;
  search?: string;
};
export type ChargeOrder = {
  orderId: number;
  orderType: string;
};
export type ChargeOrderDto = {
  chargeOrders: ChargeOrder[];
  orderNumber: string;
  trackingNumber?: string | null;
};
export type PayInvoiceCommandValues = {
  accountingTransactionId: number;
  contactPaymentMethodId: string;
  returnUrl: string;
};
export type ConfirmInvoicePaymentCommandValues = {
  accountingTransactionId: number;
  paymentIntentId: string;
};
export type PayInvoiceResultDto = {
  redirectToUrl: string;
};
export type ConfirmInvoicePaymentResultDto = {
  status: string;
};
export type PayInvoiceQueryParams = {
  payment_intent?: string | undefined;
};

export enum DocumentTemplateType {
  RateConfirmationDocument = "Rate Confirmation Document",
  RateConfirmationEmail = "Rate Confirmation Email",
  InvoiceDocument = "Invoice Document",
  InvoiceEmail = "Invoice Email",
  BillOfLadingDocument = "Bill of Lading Document",
  BillOfLadingEmail = "Bill of Lading Email",
  ExportBillOfLadingDocument = "Export Bill of Lading Document",
  QuoteConfirmationDocument = "Quote Confirmation Document",
  QuoteConfirmationEmail = "Quote Confirmation Email",
  ResetPasswordEmail = "Reset Password Email",
  VerifyEmailAddressEmail = "Verify Email Address Email",
  CustomsDeclarationDocument = "Customs Declaration Document",
  ExportManifestDocument = "Export Manifest Document",
  MasterBillOfLadingDocument = "Master Bill Of Lading Document",
  DockReceiptDocument = "Dock Receipt Document",
  AirWaybillDocument = "Air Waybill Document",
  ShippingLabelDocument = "Shipping Label Document",
  ExportManifestExcelDocument = "ExportManifestExcelDocument",
  EventEmail = "EventEmail",
  EmailNotification = "EmailNotification",
}

export const {
  useAccountingTransactionsGetAccountingTransactionsMutation,
  useAccountingTransactionsGetAccountingTransactionsGqlMutation,
  useAccountingTransactionsGetAccountingTransactionMutation,
  useAccountingTransactionsGetAccountingTransactionGqlMutation,
  useAccountingTransactionsPayInvoiceMutation,
  useAccountingTransactionsConfirmInvoicePaymentMutation,
} = injectedRtkApi;
