import {useCallback} from "react";
import {MessageParams} from "yup/lib/types";
import {t} from "i18next";
import {DimensionsUnits} from "../../../features/order/order-api";

export const useParcelValidation = () => {
  const validateParcelWeight = useCallback((container: any, config: any) => {
    if (
      config?.maxWeight &&
      container?.weight &&
      container?.weightUnit &&
      container?.weightUnit in config.maxWeight
    ) {
      const num = Number(container?.weight);
      if (num) return num <= config.maxWeight[container.weightUnit];
      else return false;
    }
    return true;
  }, []);

  const calculateDimWeight = useCallback(
    (container: any, dimFactor: number) => {
      const {length, width, height} = container;
      if (length && width && height && dimFactor) {
        return (length * width * height) / dimFactor;
      }
      return null;
    },
    [],
  );

  const getDimWeightUnit = useCallback(
    (dimensionsUnit: DimensionsUnits | null) => {
      if (dimensionsUnit) return dimensionsUnit === "In" ? "Lb" : "Kg";
      return null;
    },
    [],
  );

  const validateParcelDimWeight = useCallback(
    (container: any, config: any) => {
      const {length, width, height, dimensionsUnit} = container;
      const dimFactorWeightUnit = getDimWeightUnit(dimensionsUnit);
      if (
        length &&
        width &&
        height &&
        dimensionsUnit &&
        dimFactorWeightUnit &&
        config?.dimFactor &&
        config?.maxDimWeight &&
        dimFactorWeightUnit in config.maxDimWeight &&
        dimFactorWeightUnit in config.dimFactor
      ) {
        const dimFactor = config.dimFactor[dimFactorWeightUnit];
        const maxDimWeight = config.maxDimWeight[dimFactorWeightUnit];
        const dimWeight = calculateDimWeight(container, dimFactor);
        if (dimWeight) {
          return dimWeight <= maxDimWeight;
        } else return false;
      }
      return true;
    },
    [calculateDimWeight],
  );

  const validateParcelDimensions = useCallback(
    (container: any, config: any) => {
      const {length, width, height, dimensionsUnit} = container;
      if (
        length &&
        width &&
        height &&
        dimensionsUnit &&
        config?.maxDimensions &&
        dimensionsUnit in config.maxDimensions
      ) {
        const dims = [length, width, height].map(Number).sort((a, b) => b - a);
        const maxDims = config?.maxDimensions[dimensionsUnit] ?? [];

        for (let i = 0; i < dims.length; i++) {
          if (dims[i] > maxDims[i]) return false;
        }
      }
      return true;
    },
    [],
  );

  const getDimensionsStringFromConfig = useCallback(
    (values: any, config: any) => {
      if (
        config &&
        config?.maxDimensions &&
        values?.dimensionsUnit &&
        values?.dimensionsUnit in config.maxDimensions
      ) {
        const dims: number[] =
          config.maxDimensions[values?.dimensionsUnit] ?? [];
        return (
          dims.join(" x ") + " " + values?.dimensionsUnit.toLowerCase() ?? ""
        );
      }
      return "";
    },
    [],
  );

  const getWeightStringFromConfig = useCallback((values: any, config: any) => {
    if (
      config &&
      config?.maxWeight &&
      values?.weightUnit &&
      values.weightUnit in config.maxWeight
    ) {
      return (
        config?.maxWeight[values.weightUnit] +
          " " +
          values.weightUnit.toLowerCase() ?? ""
      );
    }
    return "";
  }, []);

  const getDimWeightStringFromConfig = useCallback(
    (values: any, config: any) => {
      const dimWeightUnit = getDimWeightUnit(values?.dimensionsUnit);
      if (
        config &&
        config?.maxDimWeight &&
        dimWeightUnit &&
        dimWeightUnit in config.maxDimWeight
      ) {
        return (
          config.maxDimWeight[dimWeightUnit] + " " + dimWeightUnit.toLowerCase()
        );
      }
      return "";
    },
    [],
  );
  const validateParcel = useCallback(
    (container: any, config: any) => {
      return (
        validateParcelDimWeight(container, config) &&
        validateParcelDimensions(container, config) &&
        validateParcelWeight(container, config)
      );
    },
    [validateParcelDimWeight, validateParcelDimensions, validateParcelWeight],
  );

  const handleParcelValidationMessage = useCallback(
    (params: MessageParams, config: any) => {
      const isDimWeightValid = validateParcelDimWeight(params.value, config);
      const isDimensionsValid = validateParcelDimensions(params.value, config);
      const isWeightValid = validateParcelWeight(params.value, config);

      const maxDimWeightErrorMessage = isDimWeightValid
        ? null
        : `${t(
            "validation.declaration.maxDimWeight",
          )} ${getDimWeightStringFromConfig(params.value, config)}`;
      const maxDimensionsErrorMessage = isDimensionsValid
        ? null
        : `${t(
            "validation.declaration.maxDimensions",
          )} ${getDimensionsStringFromConfig(params.value, config)}`;
      const maxWeightErrorMessage = isWeightValid
        ? null
        : `${t("validation.declaration.maxWeight")} ${getWeightStringFromConfig(
            params.value,
            config,
          )}`;

      return {
        length: maxDimensionsErrorMessage ?? maxDimWeightErrorMessage,
        width: maxDimensionsErrorMessage ?? maxDimWeightErrorMessage,
        height: maxDimensionsErrorMessage ?? maxDimWeightErrorMessage,
        weight: maxWeightErrorMessage,
      };
    },
    [
      validateParcelDimWeight,
      validateParcelDimensions,
      validateParcelWeight,
      getDimWeightStringFromConfig,
      getDimensionsStringFromConfig,
      getWeightStringFromConfig,
      t,
    ],
  );

  return {validateParcel, handleParcelValidationMessage};
};
