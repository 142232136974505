import React, {useEffect} from "react";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";

import {useSignInWithFacebook} from "./useSignInWithFacebook";
import {toastError} from "../common/utils/toastMessages";

export const FacebookRedirect = () => {
  const navigate = useNavigate();
  const {lang} = useParams();

  const location = useLocation();
  const params = new URLSearchParams(location.hash);

  const token: string | null = params.get("#access_token");

  const {signInWithFacebook} = useSignInWithFacebook();

  useEffect(() => {
    if (token && token?.length > 0) {
      signInWithFacebook(token);
    }
  }, [token]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      toastError("Problem with Facebook");
      navigate(`/${lang}/login`);
    }, 10000);

    return () => clearTimeout(timeOut);
  }, []);

  if (token && token?.length > 0) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100vw"}
        height={"100vh"}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    toastError("Problem with Facebook");
    return <Navigate to={`/${lang}/login`} />;
  }
};
