import React, {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {Trans, useTranslation} from "react-i18next";
import {Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {TextField} from "formik-mui";
import {Formik, Form, Field, FormikProps} from "formik";
import {LoadingButton} from "@mui/lab";
import {SubmitHandler} from "react-hook-form";
import {useDispatch} from "react-redux";
import {getCodeSchema, getEmailSchema} from "../pages/validation";
import {useAppSelector} from "../../store";
import {setUserEmail} from "../../features/auth/auth-slice";
import PinInputTemplate from "./pinInputComponent";
import {
  SendNewEmailCodeCommand,
  SendVerifyEmailCommand,
  useVerificationVerifyEmailMutation,
  VerificationVerifyEmailApiArg,
} from "../../features/verification/verification-api";
import {
  VerificationState,
  clearContactAddress,
  setEmail,
  verifyEmail,
} from "../../features/verification/verification-slice";
import {useLazyUserGetInfoQuery} from "../../features/user/user-api";
import {VerificationStepSubmit} from "./interfaces";
import {useWorkflowExecutionMutation} from "../../features/workflowTriggers/workflowExecution-api";
import {WorkflowExecutionApiArg} from "../../features/workflowTriggers/workflowExecution-api";
import {toastError} from "../../common/utils/toastMessages";

const initialEmailCodeValues: SendVerifyEmailCommand = {
  verificationCode: Array(6).fill(" ").join(""),
};

export default function EmailVerificationFragment({
  handleNext,
  step,
}: VerificationStepSubmit) {
  const {t} = useTranslation();

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("md"));

  const userEmail = useAppSelector((state) => state.userState.email);
  const userVerificationEmail = useAppSelector(
    (state) => state.verificationState?.email?.value,
  );
  const verificationState: VerificationState = useAppSelector(
    (state: any) => state.verificationState,
  );
  const sendEmailWorkflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.sendVerifyEmailWorkflowId,
  );

  const [currentUserEmail, setCurrentUserEmail] = useState(userEmail);
  const [emailEditMode, setEmailEditMode] = useState(false);
  const [initialEmailValues, setInitialEmailValues] =
    useState<SendNewEmailCodeCommand>({
      email: "",
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
    });
  const dispatch = useDispatch();
  const [runWorkflow, {isLoading, isSuccess}] = useWorkflowExecutionMutation();

  useEffect(() => {
    const initialEmail = userVerificationEmail || userEmail;
    if (initialEmail) {
      setInitialEmailValues((prev) => {
        prev.email = initialEmail;
        return {...prev};
      });
      if (!verificationState.email.value) {
        dispatch(setEmail(initialEmail));
      }
    }
  }, [userEmail, userVerificationEmail]);

  const [getUserInfo] = useLazyUserGetInfoQuery();

  const getEmailCode: SubmitHandler<SendNewEmailCodeCommand> = async (
    values,
  ) => {
    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: sendEmailWorkflowId,
      values: {
        variables: {
          userEmail: values?.email,
        },
      },
    };

    try {
      const response = await runWorkflow(executeWorkflowApiArgs);
      const workflowError = (response as any)?.error;
      if (workflowError) {
        toastError(workflowError?.data?.errors?.General?.[1]);
        return;
      }
    } catch (error) {
      toastError(error);
      return;
    }

    await getUserInfo();
    toast.success(t("toasts.emailCodeSent"), {duration: 5000});
    setCurrentUserEmail(values.email);
    setEmailEditMode(false);
  };

  const [
    verifyEmailCode,
    {
      isLoading: isLoadingVerifyEmail,
      isSuccess: isSuccessVerifyEmail,
      isError: isErrorVerifyEmail,
    },
  ] = useVerificationVerifyEmailMutation();

  const checkEmailCode: SubmitHandler<SendVerifyEmailCommand> = (values) => {
    const commandArgs: VerificationVerifyEmailApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      verifyEmailCommand: values,
    };
    verifyEmailCode(commandArgs);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserEmail(initialEmailValues));
      setEmailEditMode(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessVerifyEmail) {
      dispatch(verifyEmail(initialEmailValues.email ?? ""));
      toast.success(`${t("toasts.emailVerificationSuccess")}`);
      dispatch(clearContactAddress());
      handleNext();
    }
  }, [isSuccessVerifyEmail]);

  const onChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<SendNewEmailCodeCommand>,
  ) => {
    formikProps.setFieldValue("email", event.target.value, true);
    dispatch(setEmail(event.target.value));
  };

  return (
    <Grid container columns={{xs: 6, md: 6}} columnSpacing={2}>
      <Grid xs={6} md={5}>
        <Typography variant="h3" component="h3" sx={{mb: 3}}>
          <Trans i18nKey="verifCodeGreetings">
            Check your email for a verification code
          </Trans>
        </Typography>
        <Typography
          variant="body4"
          component="h3"
          sx={{mb: 1, fontWeight: 400, color: "#6C757D"}}
        >
          <Trans i18nKey="verifCodeSmallGreetings">
            We have send access-code to
          </Trans>
        </Typography>
        {!emailEditMode && (
          <>
            <Typography variant="body4" component="span" sx={{mb: 3, mr: 1}}>
              {currentUserEmail}
            </Typography>
            <Button
              variant="link"
              onClick={() => setEmailEditMode((prev) => !prev)}
              data-testid={`edit-email-btn`}
              sx={{minWidth: "unset"}}
            >
              <Trans i18nKey="btnEdit">Edit</Trans>
            </Button>
          </>
        )}
      </Grid>
      <Grid xs={6} md={5}>
        {emailEditMode && (
          <Grid container direction="column" columns={{xs: 6, md: 5}} mt={2}>
            <Formik
              onSubmit={async (values) => {
                await getEmailCode(values);
              }}
              initialValues={initialEmailValues}
              validationSchema={getEmailSchema(t)}
            >
              {(formikProps: FormikProps<SendNewEmailCodeCommand>) => (
                <Form>
                  <Grid md={4}>
                    <Field
                      component={TextField}
                      sx={{mb: 2}}
                      fullWidth
                      label={t("email")}
                      placeholder="example@example.ex"
                      name="email"
                      autoComplete="email"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeEmail(event, formikProps)
                      }
                      data-testid={`edit-email-input`}
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    md={6}
                    sx={{
                      position: {xs: "fixed", md: "inherit"},
                      bottom: {xs: "72px"},
                      pr: {xs: "26px", md: 0},
                      pb: {xs: 0, md: 3},
                      mt: {xs: 0, md: 2},
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      columns={{xs: 6, md: 7}}
                      sx={{
                        display: {
                          xs: isLoading ? "none" : "flex",
                          md: "flex",
                        },
                      }}
                    >
                      <Grid md={2} xs>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="secondary"
                          disabled={isLoading}
                          loading={isLoading}
                          loadingPosition="end"
                          endIcon={<></>}
                          data-testid={`get-email-code-btn`}
                        >
                          <Trans i18nKey="sendPinCodeToEmail">Get Code</Trans>
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
        <Grid
          container
          direction="column"
          columns={{xs: 6, md: 12}}
          sx={{display: "flex"}}
        >
          <Formik
            onSubmit={async (values) => {
              checkEmailCode(values);
            }}
            initialValues={initialEmailCodeValues}
            validationSchema={getCodeSchema(t)}
          >
            {({values}) => (
              <Form>
                <Typography
                  component="h3"
                  variant="body4"
                  sx={{color: "#6C757D", fontWeight: 400}}
                  mb={2}
                  ml={1}
                >
                  <Trans i18nKey="enterPinCodePlace">
                    Enter access-code here
                  </Trans>
                </Typography>
                <Field
                  component={PinInputTemplate}
                  name="verificationCode"
                  disabled={isLoadingVerifyEmail || isSuccessVerifyEmail}
                  mt={2}
                  pinLength={6}
                />
                <Typography
                  variant="body4"
                  sx={{
                    color: "#6C757D",
                    pb: 1.1,
                    ml: 1,
                    display: "block",
                  }}
                >
                  <Trans i18nKey="isPinCodeWasSend">
                    Did not receive a code?
                  </Trans>
                  {isXS ? <br /> : <>&nbsp;</>}
                  <Button
                    variant="link"
                    disabled={isLoading}
                    data-testid={`resend-email-code-btn`}
                    onClick={async () => {
                      if (userEmail)
                        await getEmailCode({
                          ...initialEmailValues,
                          email: userEmail,
                        });
                    }}
                  >
                    <Trans i18nKey="resendPinCodeToEmailButton">
                      Click here
                    </Trans>
                  </Button>
                  &nbsp;
                  <Trans i18nKey="resendPinCodeLabel">to resend it</Trans>
                </Typography>
                <Grid mt={1} xs={6} md={10}>
                  <Grid container direction="row" columns={{xs: 6, md: 6}}>
                    {!step.required ? (
                      <Grid md={2} xs={3}>
                        <Button
                          fullWidth
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={handleNext}
                        >
                          <Trans i18nKey="skip">Skip</Trans>
                        </Button>
                      </Grid>
                    ) : null}
                    <Grid
                      md={2}
                      xs={3}
                      display={{
                        xs: emailEditMode ? "none" : "block",
                        md: "block",
                      }}
                    >
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={
                          (values.verificationCode &&
                            values.verificationCode.replaceAll &&
                            values.verificationCode?.replaceAll(" ", "")
                              .length < 6) ||
                          isLoadingVerifyEmail ||
                          isSuccessVerifyEmail
                        }
                        loading={isLoadingVerifyEmail}
                        loadingPosition="end"
                        endIcon={<></>}
                        data-testid={`verify-email-code-btn`}
                        style={{minWidth: "130px"}}
                      >
                        <Trans i18nKey="verifyPinCodeButton">Verify</Trans>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
                {isErrorVerifyEmail && (
                  <Grid md={4}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: "light",
                        color: "red",
                        mt: 2,
                      }}
                    >
                      <Trans i18nKey="emailCodeVerificationError" />
                    </Typography>
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
}
