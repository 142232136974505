import React, {useEffect, useState} from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Info, Search} from "@mui/icons-material";
import Done from "../../../../../images/cardIcons/done.svg";
import {Trans} from "react-i18next";
import {t} from "i18next";

interface CategoryModalProps {
  handleClose: () => void;
  chapters: string[] | undefined;
  handleNext: () => void;
  setItemCategory: (category: string) => void;
}

export default function ChapterFragment({
  chapters,
  handleClose,
  handleNext,
  setItemCategory,
}: CategoryModalProps) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<string[] | undefined>([]);
  const handleListItemClick = (event: any, index: number, chapter: string) => {
    setSelectedIndex(index);
    setItemCategory(chapter);
  };

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (chapters && chapters?.length > 0) {
      const results = chapters?.filter((chapter) =>
        chapter.toLowerCase().includes(searchTerm),
      );
      if (results) {
        setSearchResults(results);
      }
    }
  }, [chapters, searchTerm]);

  return (
    <Grid md={12} minWidth={isDesktop ? "570px" : "none"} columnSpacing={0}>
      <Grid
        container
        alignItems={"center"}
        justifyContent="flex-start"
        gap={"8px"}
        pt={{xs: 2, md: 0}}
      >
        <Info color="primary"></Info>
        <Typography variant="body4" fontSize={"18px"} fontWeight={"600"}>
          <Trans i18nKey={"delivery.declaration.findCode"}>
            Find your Item HS Code
          </Trans>
        </Typography>
      </Grid>
      <Grid mt={2}>
        <Grid
          display={isDesktop ? "flex" : "none"}
          justifyContent={"space-between"}
          gap={"8px"}
        >
          <Typography variant="body4" fontWeight={"500"} textAlign={"start"}>
            <Trans i18nKey={"delivery.declaration.chooseItemCategory"}>
              Please choose your item category according to <br></br> Harmonized
              Tariff System Codes
            </Trans>
          </Typography>
          <Typography color={"#6C757D"} variant="body4" fontWeight={"500"}>
            <Trans i18nKey={"delivery.declaration.1of2Steps"}>
              1 of 2 step
            </Trans>
          </Typography>
        </Grid>
        <Grid container gap={"16px"} display={isDesktop ? "none" : "flex"}>
          <Typography color={"#6C757D"} variant="body4" fontWeight={"500"}>
            <Trans i18nKey={"delivery.declaration.1of2Steps"}>
              1 of 2 step
            </Trans>
          </Typography>
          <Typography variant="body4" fontWeight={"500"} textAlign={"start"}>
            <Trans i18nKey={"delivery.declaration.chooseAccordingHTSC"}>
              Choose item category according to HTSC
            </Trans>
          </Typography>
        </Grid>
        <Grid py={2}>
          <TextField
            fullWidth
            label={t("categoryPurchaseItem")}
            value={searchTerm}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid sx={{marginX: isDesktop ? "0" : "-16px", overflowY: "scroll"}}>
          <List
            sx={{
              width: "100%",
              height: {md: "30vh", xs: "unset"},
              bgcolor: "background.paper",
            }}
          >
            {searchResults?.map((value, index) => {
              const labelId = `checkbox-list-label-${index}`;
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <div
                      style={{
                        paddingRight: "16px",
                        display: selectedIndex === index ? "flex" : "none",
                      }}
                    >
                      <img src={Done} alt="" />
                    </div>
                  }
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index, value)}
                  disablePadding
                  disableGutters
                  sx={
                    !isDesktop
                      ? {
                          borderTop: "1px solid rgb(62 128 255 / 18%)",
                          paddingY: "7px",
                        }
                      : null
                  }
                >
                  <ListItemButton
                    alignItems={"flex-start"}
                    sx={{padding: "0 16px 0 16px", border: "8px"}}
                    disableGutters
                    dense
                  >
                    <ListItemText
                      id={labelId}
                      primary={
                        <Grid>
                          <Typography
                            variant="body3"
                            style={{color: "#005CC7"}}
                          >
                            {value}
                          </Typography>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{mt: 2}}
        gap={"24px"}
        columnSpacing={3}
        mb={isDesktop ? 0 : 6}
      >
        <Grid md={4} xs={12}>
          <Button
            data-testid="btn-ok-modal-modal"
            fullWidth
            type="button"
            variant="contained"
            color="secondary"
            disabled={selectedIndex < 0}
            onClick={handleNext}
          >
            <Trans i18nKey={"verificationNextStep"}>Next</Trans>
          </Button>
        </Grid>

        <Grid md={4} xs={12}>
          <Button
            data-testid="btn-ok-modal-modal"
            fullWidth
            type="button"
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            <Trans i18nKey={"profile.savedAddresses.cancel"}>Cancel</Trans>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
