import React from "react";
import {Module, ModuleConfig} from "../core/base.module";
import AllShipments from "./AllParcelShipments/pages";
import ParcelShipmentInfo from "./ParcelShipmentInfo/pages";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddBoxIcon from "@mui/icons-material/AddBox";

export interface ParcelConfiguration extends ModuleConfig {
  disabled?: boolean;
  createParcelEnabled?: boolean;
}

export class ParcelModule implements Module {
  static _instance: ParcelModule;
  config: ParcelConfiguration;

  private constructor(config: ParcelConfiguration) {
    this.config = config;
  }

  static getInstance(config?: ParcelConfiguration) {
    if (!ParcelModule._instance) {
      ParcelModule._instance = new ParcelModule(config || {});
    } else if (config) {
      ParcelModule._instance.config = config;
    }
    return ParcelModule._instance;
  }

  getPages() {
    if (!this.config || this.config?.disabled !== false) {
      return [];
    }
    return [
      {path: "parcelShipments", element: <AllShipments config={this.config} />},
      {
        path: "parcelShipments/:orderId/:purchaseId",
        element: <ParcelShipmentInfo />,
      },
    ];
  }

  getMenuItems() {
    if (!this.config || this.config?.disabled !== false) {
      return [];
    }
    return [
      {
        page: "parcelShipments",
        to: "../parcelShipments",
        i18nKey: "menuItemParcels",
        isInternal: true,
        svgIcon: LocalShippingIcon,
      },
    ];
  }

  getMenuActions() {
    if (!this.config || this.config?.disabled !== false) {
      return [];
    }

    if (this.config.createParcelEnabled)
      return [
        {
          page: "parcel-create",
          to: "../delivery/new",
          i18nKey: "createParcelShipment",
          isInternal: true,
          svgIcon: AddBoxIcon,
        },
      ];

    return [];
  }
}
