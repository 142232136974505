import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Typography} from "@mui/material";
import Card from "../../../common/components/card";
import BoxImage from "../../../../images/selectRequestType/box.png";
import BoxesImage from "../../../../images/selectRequestType/boxes.png";
import {useNavigate, useParams} from "react-router-dom";
import i18next, {t} from "i18next";
import {Trans} from "react-i18next";
import {useContinueVerificationModal} from "../../hooks/useContinueVerificationModal";
import {useDispatch} from "react-redux";
import {initOrder} from "../../../features/order/parcelShipment-slice";

type UrlParams = {
  orderId: string;
};

export default function SelectRequestTypeFragment() {
  const navigate = useNavigate();
  const lang = i18next.language;
  const dispatch = useDispatch();

  const {render: renderContinueVerificationModal} =
    useContinueVerificationModal();

  const {orderId} = useParams<keyof UrlParams>() as UrlParams;
  const onClickSingle = () => {
    dispatch(initOrder({orderIds: [Number(orderId)]}));
    navigate("./..");
    return;
  };
  const onClickConsolidated = () => {
    navigate(`/${lang}/delivery/${orderId}&0/selectPurchases`);
    return;
  };

  const i18nextPrefix = "delivery.selectRequestType.";

  const requestTypes = [
    {
      title: t(i18nextPrefix + "singleShipment.title"),
      description: t(i18nextPrefix + "singleShipment.description"),
      img: BoxImage,
      onClick: onClickSingle,
    },
    {
      title: t(i18nextPrefix + "consolidatedShipment.title"),
      description: t(i18nextPrefix + "consolidatedShipment.description"),
      img: BoxesImage,
      onClick: onClickConsolidated,
    },
  ];

  return (
    <>
      <Grid mb={8}>
        <Typography variant="h3" mb={3} mt={{md: 0, xs: 3}}>
          <Trans i18nKey={i18nextPrefix + "title"}>
            Select delivery option
          </Trans>
        </Typography>

        <Grid
          display={"flex"}
          flexDirection={{md: "row", xs: "column"}}
          gap={4}
          maxWidth={"1230px"}
        >
          {requestTypes.map((requestType, index) => {
            return (
              <Card key={index} onClick={requestType.onClick}>
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={{md: "row", xs: "column"}}
                  gap={4}
                >
                  <Box width={{md: "168px", xs: "100px"}}>
                    <img src={requestType.img} alt={"box"} width={"100%"} />
                  </Box>
                  <Grid
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                  >
                    <Grid>
                      <Typography variant="h3">{requestType.title}</Typography>
                    </Grid>
                    <Grid>
                      <Grid>
                        <Typography variant="caption1">
                          {requestType.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            );
          })}
        </Grid>
      </Grid>
      {renderContinueVerificationModal()}
    </>
  );
}
