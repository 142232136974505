import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {InfoBoxProps} from "./interfaces";
import InfoIcon from "@mui/icons-material/Info";

export default function InfoBox({title, content, styles}: InfoBoxProps) {
  return (
    <Box
      sx={{
        border: "1px solid #2F81EF",
        background: "#F5F9FF",
        borderRadius: "16px",
        padding: "2rem",
        ...styles,
      }}
    >
      {title && (
        <Grid display={"flex"} alignItems={"center"} mb={1}>
          <InfoIcon color="primary" />
          <Typography
            textAlign={"start"}
            variant="body1"
            fontSize={"1.25rem"}
            fontWeight={600}
          >
            &nbsp;{title}
          </Typography>
        </Grid>
      )}
      <Typography textAlign={"start"} variant="body2" fontSize={"1rem"}>
        {content}
      </Typography>
    </Box>
  );
}
