import React from "react";
import MainLayout from "../../../common/MainLayout/components/mainLayout";
import ProfileTabMenu from "../../components/profileTabMenu";
import ContentLayout from "../../../common/ContentLayout/components/contentLayout";
import EditResidentialAddressForm from "../components";
import {Trans} from "react-i18next";

const EditResidentialAddress = () => {
  return (
    <MainLayout
      pageTitle="Residential address"
      backLink="../profile"
      statusBar={<ProfileTabMenu selectedTab={1} />}
    >
      <ContentLayout
        title={
          <Trans i18nKey={"profile.accountInfo.editResidentialAddress"}>
            Edit residential address
          </Trans>
        }
        backLink="../profile"
        content={<EditResidentialAddressForm />}
        containerSx={{mb: "24px"}}
      />
    </MainLayout>
  );
};

export default EditResidentialAddress;
